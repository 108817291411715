import React, { useContext, useEffect} from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

//SERVICES
import  TramitesSvc  from '../../../../services/TramitesSvc';

//UTILS
import { userFindActive } from "../UsersUtil";

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType, IActionProps, INodoOrigen, IOperacion, Types } from '../../../../reducers/Model';
import { AppContext } from '../../../../Context';
import { ViewName } from '../../../../reducers/ViewReducer';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { EFieldObj } from '../../../../form/fields/field';

interface IData { onhide: any, data : IActionProps  }

export const Act106CerrarMensaje: React.FC<IData> = ( act106Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  useEffect(() => {
    init()
  }, []); 


  const init = () => {
    // ESTOS VALORES ESTAN EN LA DEFINCION DE LA ARISTA...			
    let dataMsg = { id:"msg",  type:EFieldObj.TXT, save:1,  label:"Instrucción",  value:"Cerrar mensaje" };            
  
    // ESTOS VALORES ESTAN EN LA DEFINCION DE LA ARISTA...			      
    setPageState(EPageState.STEP0, "");

    // COLCOAR CONSTANTES .... 
    let operacion : IOperacion = {  traid:act106Props.data.tramite.traid , etaid:act106Props.data.etaid, oprid:act106Props.data.oprid, bucket:act106Props.data.bucket, oprpath:act106Props.data.oprpath };
    let origen: INodoOrigen = { nodori: act106Props.data.nodori, edoori:act106Props.data.nodori, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
    let accion = { accid:act106Props.data.accid, tdatid:FormType.CONFIRMAR_LECTURA, data:dataMsg, edoid:act106Props.data.edodst  };
                        
    TramitesSvc.TraAction( JsonPost(operacion, origen, accion,  state.user.cic) )                   
    .then(response => {         				
      if ( response.status === 200 ) {					
        dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
        return;
      } else {
        setPageState(EPageState.ERROR, "No fue posible registrar la prevención, vuelva a intentarlo");			
        return ;					
      }
    })
    .catch( (e) => {                
      setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor!");  
      console.log(e);
    });
  }    

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {		
      case EPageState.INIT:			      
				return <ProgressBar mode="indeterminate" />;

      case EPageState.STEP0:
        return <ProgressBar mode="indeterminate" />;

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}