import React, { useState, useEffect} from 'react'
import { ProgressBar } from 'primereact/progressbar';
import TramitesSvc from '../../services/TramitesSvc';
import { EPageState, usePageState } from '../../ui/uses/usePageState';

import { LocalDataErrorView } from "../states/LoadDataErrorView";
import { NodoHeader } from "./NodoHeader";
import { NodoResponder } from "./NodoResponder";
import { TramPrintFiles } from './TramPrintFiles';
import { TramFilesOnly } from './TramFilesOnly';

interface IData { action : any, init: any, setListTask:any  }
interface IOprSegData { nodfecha: string, nodfecatendido :string, edodesc:string, tdatdesc:string, etadesc:string, etaicono:string, 
    datcontenido:{}, psnid :number, insid :number, denombre :string, paranombre:string,  };

export const NNEstadoActual: React.FC<IData> = (propsBody) => {
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  const [oprSegData, setOprSegData] = useState([]);
  
  useEffect(() => {
    //VERIFICAR SI TINE LA PROIEDAD
    TramitesSvc.AfdFuncSeguimiento(propsBody.init.tramite.oprid)
    .then ((response) => {         
      if ( response.status !== 200 ) {          
        return setPageState(EPageState.ERROR, "¡No fue posible obtener datos de esta actividad, vuelva a intentar!");  
      } else {
        setOprSegData(response.data);
        return setPageState(EPageState.STEP0, ""); 
      }            
    })
    .catch( (e) => {                
      return setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });        
  }, []); 

  //EStas instrucciones va a desaparecer para colcoarlas en la base de datos..
  const drawInst = (inst:string) => {
    const parse = require('html-react-parser');
    if ( inst!== undefined && inst!== null)
      return parse(inst);
    else
      return (
        <div style={{ paddingLeft:"40px", borderTop:"2px solid #a2a2a2", width:"98%"}}> 
          <div><strong> Favor de seleccionar alguna opción con los botones azules</strong></div>
        </div>);
  }
    
  const drawSeguimiento = () => {
    let htmlAdd: JSX.Element[] = [];
        

    htmlAdd.push(<div key="Titulo1"  style={{ paddingTop:"10px" }} ><strong> DOCUMENTOS GENERALES</strong></div>);
    htmlAdd.push( 
      <TramFilesOnly traid={ propsBody.init.operacion.traid} etaid={propsBody.init.operacion.etaid} oprid={propsBody.init.operacion.oprid} insid={0}   />
    );

    for (let intIdx = 0; intIdx < oprSegData.length; intIdx++) {
      const item: IOprSegData = oprSegData[intIdx];      
      if ( item.denombre !== item.paranombre) {        
        const contenido = JSON.parse( item.datcontenido.toString()); 
          htmlAdd.push( 
            <div key={`item-header-${intIdx}`} className="row" style={{ paddingTop:"10px", borderTop:"2px solid #a2a2a2", width:"98%"}} >
              <div key={ "divDe" + intIdx } className="col-md-12" ><strong>De:</strong> {item.denombre} </div>
              <div key={ "divEnviado" + intIdx } className="col-md-12" ><strong>Enviado el:</strong> {item.nodfecha}</div>
              <div key={ "divPara" + intIdx } className="col-md-12" ><strong>Para:</strong> {item.paranombre }</div>
              <div key={ "divAsunto" + intIdx } className="col-md-12" style={{paddingBottom:"20px"}} ><strong>Asunto:</strong><span style={{ color:"red"}}>  {item.etadesc}</span> - {item.edodesc}</div>                                
            </div>);
  
          htmlAdd.push( <TramPrintFiles data={contenido.accion.data} bucket={propsBody.init.operacion.bucket} ></TramPrintFiles>)
  
          if ( contenido.accion.hasOwnProperty("optional")  
            && Object.keys(contenido.accion.optional).length > 0 
            && contenido.accion.optional.hasOwnProperty("data")) {
            htmlAdd.push( <TramPrintFiles data={contenido.accion.optional.data} bucket={propsBody.init.operacion.bucket}  ></TramPrintFiles> );          
          }      
      }

  }
          
    return <div className="col-md-12" style={{ height:"100%", width:"95%", left:"35px" }}> {htmlAdd} </div>
  }
  const drawBody = () => {
    try {
        let accion = propsBody.init.aristas;                  
        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={propsBody.init.operacion.etaid} action={accion} oprid={propsBody.action.oprid} nodori={propsBody.action.nodid} 
            setListTask={propsBody.setListTask} 
              edoori={propsBody.action.edoid}  bucket={propsBody.init.operacion.bucket}  
              oprpath={propsBody.init.operacion.oprpath} 
              tramite={propsBody.init.tramite} />
              
            <NodoHeader remitente={propsBody.action.psnnombrecomp} fecha={propsBody.action.nodfecha} estado={propsBody.action.edodesc} titulo=""  
              folio={propsBody.action.oprfolio} insid={propsBody.action.oriinst} ></NodoHeader>    
            
            { drawInst(propsBody.action.edoinst) }            
            { drawSeguimiento() }            
          </div>
        );
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }     
  }

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger" style={{ marginTop:"20px" }} >{pageState.msg}</div>
        </div>
      )
    }
	}

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			case EPageState.INIT:							
				return <ProgressBar mode="indeterminate" />;

			case EPageState.STEP0:
				return drawBody();

			default:
				return DrawMsg();
		}
	}

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}


  /*
  //EStas instrucciones va a desaparecer para colcoarlas en la base de datos..
  const drawInst = (inst:string) => {
    const parse = require('html-react-parser');
    if ( inst!== undefined && inst!== null)
      return parse(inst);
    else
      return null;
  }
*/