import React, { useContext, useEffect, useState } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType,  IActionProps } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import {  tramiteAction } from '../ActUtil';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import TramitesSvc from '../../../../services/TramitesSvc';

interface IData { onhide: any, data : IActionProps  }

export const Act150UsrSolDict : React.FC<IData> = ( act150Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });  
  const [filePdf, setFilePDF] = useState<string >();

  useEffect(() => {

    const XHR = new XMLHttpRequest();
    XHR.open('GET', TramitesSvc.URL + "afd/consulta/soldictamen/" +act150Props.data.oprid );
    XHR.responseType = 'blob';
    XHR.send();

    XHR.onload = function(excp : any) {
      if (this.status === 200) {
        var blob = new Blob([this.response], {type: 'application/pdf'});
        setFilePDF(window.URL.createObjectURL(blob));
        setPageState(EPageState.STEP0, "");    

        let files : File[] = [];
        files.push(new File([blob], "SolicitudDictamen.pdf",{type:"application/pdf", lastModified:new Date().getTime()}))                  
        let newData = {...dataProcess}
        newData.docefirma.value = files;
        onChangeAll(newData);

/*        
        var link=document.createElement('a');
        var filename : string = this.getResponseHeader('content-disposition');
        var idxName = filename.indexOf('filename=') + 9;
        filename = filename.substring(idxName);
*/
        
        

      }else{        
        console.error("Error al recupera los archivos..");
      }
    };

    XHR.onerror= function(errorDesc:any) {
      console.error(errorDesc);
    }

/*
    TramitesSvc.AfdSolDictamen( act150Props.data.oprid )
*/    
  }, []); 
  
  const DATA_PROCESS = {				
    // COLOCAR UN TEXTO EL FORMATO A CREAR        
    titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Ingrese sus archivos para firmar electrónicamente",  display:EFieldDisplay.SHOW },
  
    docefirma:	{ gpo:'desechar',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo en formato PDF", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.SOLICITUD_DICTAMEN},

    dockey:	{ gpo:'fintech',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },

    doccert:	{ gpo:'fintech',  id:"doccert",  type:EFieldObj.FILE, label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-7", classNameObj:"col-md-5", save:1, classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'fintech',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW ,
      classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"}
  }
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");
    dataProcess.docefirma.display = EFieldDisplay.SHOW;
    tramiteAction(act150Props,  FormType.SOLICITUD_DICTAMEN, dataProcess, state, dispatch,  setPageState, {});         
  }
      
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <object width="95%" height="500px"  data={filePdf} type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
				</object>
      	
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act150Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}