import React, { useContext } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';


// ESTILOS 
import '../Action.css';
import { FormType, IActionProps, RESP_FAVORABLE} from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';

import {  tramiteAction } from '../ActUtil';

interface IData { onhide: any, data : IActionProps  }

export const Act129ConNotRecDes : React.FC<IData> = ( act129Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  const DATA_PROCESS = {				        
    titleDesechar:{ type:EFieldObj.H5, id:"titleDesechar", label:"Notificar que la reclamación se desecha por flata de datos",  display:EFieldDisplay.SHOW },

    numoficio:	{ gpo:"respusr",	id:"numoficio",    type:EFieldObj.TXT, save:1,  label:"Número de oficio",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, style:{width:"200px"},
      varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Número de oficio" },
          
    docefirma:	{ gpo:'respusr',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo en formato PDF", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.PROYECTO  },
          
    sentidoresp:	{ gpo:'respusr',   id:"sentidoresp", type:EFieldObj.CBO, save:1, label:"Sentido de la respuesta", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione una opción", list:RESP_FAVORABLE},
      
      
		titlefirma: { type:EFieldObj.H3, id:"titlefirma", label:"Favor de ingresar su Firma electrónica (eFirma SAT) para generar la solicitud y firmarla con los datos antes presentados", display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'dictamen',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
		doccert: { gpo:'dictamen',  id:"doccert",  type:EFieldObj.FILE, save:1,  label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },		
		efirma: { gpo:'dictamen',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"}
  }
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");          
    tramiteAction(act129Props,  FormType.PROYECTO, dataProcess, state, dispatch,  setPageState, {});          
  }
      
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act129Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}