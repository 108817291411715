
import { ProgressBar } from 'primereact/progressbar';
import React from 'react'

// ESTILOS 
interface IData { msg : string , color:string }

export const LocalDataView: React.FC<IData> = (props) => {
      
  return (
    <div style={{ width:"100%", height:"40vh", padding:"auto", textAlign:"center", color:props.color}}>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>        
      <p ><i className="pi pi-refresh" style={{ fontSize:'96px'}} ></i></p>
      <p> { props.msg ==="" ? "Obteniendo datos...." : props.msg  }</p>
      <ProgressBar mode="indeterminate" />
    </div>
  )

}