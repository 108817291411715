import React from 'react'
import './MenuLogout.css';
import { AppContext } from '../../Context';
import { ViewName } from '../../reducers/ViewReducer'
import { Types } from '../../reducers/Model'

export interface IMenuLogoutProps { menu: string | undefined; titulo: string | undefined; header: boolean; }
                                                       
export const MenuLogout: React.FC<IMenuLogoutProps> = (props) => {
  //state 
  const { dispatch } = React.useContext(AppContext);
        
  const btnMenuOptOnClick = ( viewName : string ) => {
    dispatch({ type: Types.PAGE_SELECT, page: { view:viewName, msg:"", refresh:""  }});
  }

  const drawTitle = () => {
    if (props.titulo !== undefined) {
      return (
        <div className="container " >
          <h2>{props.titulo}</h2>
          <hr className="red"/>
        </div>
      )
    }
  }

  const drawSection = () => {
    return (
    <div className="container " >
      <div className="row" >
        <div className="col-sm-12">
          <ol className="breadcrumb" style={{ marginBottom:"0px"}}>       
            <li><a href="https://www.gob.mx/condusef/"><i className="icon icon-home"></i></a></li>              
            <li className="active" style={{cursor: "pointer"}}><span onClick={ evt => btnMenuOptOnClick(ViewName.MAIN) } >Inicio</span> </li>
            { props.menu !== undefined ? <li className="active">{props.menu}</li> : null }                          
          </ol> 
        </div>
      </div>
  </div>
  )}

  return (    
    <React.Fragment>       
      <header id="header" className="alt gobmx-header" style={{ marginTop:"70px"}}>        
        <nav className="navbar navbar-inverse sub-navbar navbar-fixed-top">
        <div className="container">          
            <div className="navbar-header">
              <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#subenlaces">
                <span className="sr-only">Interruptor de Navegación</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="https://www.condusef.gob.mx/">CONDUSEF</a>
            </div>            
            <div className="collapse navbar-collapse" id="subenlaces">                             
              <ul className="nav navbar-nav navbar-right">
                <li className="menuItem" onClick={ e => btnMenuOptOnClick( ViewName.MAIN )}>Generar Cita</li>
                <li className="menuItem" onClick={ e => btnMenuOptOnClick( ViewName.SESSION_LOGIN )}>Iniciar sesión</li>
              </ul>                
            </div>            
          </div>
        </nav>
      </header>             
      
      { (props.header === true) ? drawSection() : null }
      {drawTitle() }
      
    </React.Fragment>
  )
}