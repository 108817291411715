export const stringToDate = ( strDate:string ): Date	 => {
 
  if (strDate.length === 16) {
    return new Date(
      parseInt(strDate.substring(0,4)),
      parseInt(strDate.substring(5,7))-1,
      parseInt(strDate.substring(8,10)),
      parseInt(strDate.substring(11,13)),
      parseInt(strDate.substring(14,16))
    );

  } else {
    return new Date(Date.now());
  }
}


export const dateGreaterThan = ( strDate1:any, strDate2:any  ) : any	 => {

  if ( (typeof strDate1 === 'object') && (typeof strDate2 === 'object') ) {
    return strDate1 > strDate2;
  } else {
    return "Favor de seleccionar una fecha";
  }

}

export const dateToString = ( datFecha:any ) : String => {
  
  if ( datFecha instanceof Date && !isNaN(datFecha.valueOf()) ) {
    var dd = String(datFecha.getDate()).padStart(2, '0');
    var mm = String(datFecha.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = datFecha.getFullYear();
  
    return dd + '/' + mm + '/' + yyyy;
  } else {
    return datFecha;
  }
}

export function getLastDayOfMonth(year:number, month:number) {
  return new Date(year, month + 1, 0);
}

export function getFirstDayOfMonth(year:number, month:number) {
  return new Date(year, month, 1);
}


