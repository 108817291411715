import React, { useContext, useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType, IActionProps} from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';

import {  tramiteAction } from '../ActUtil';
import TramitesSvc from '../../../../services/TramitesSvc';

interface IData { onhide: any, data : IActionProps  }

export const Act146ConConvenio : React.FC<IData> = ( act146Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  

  const DATA_PROCESS = {		    
    titlepartes:{ type:EFieldObj.H5, id:"titlepartes", label:"Sellos digitales de la firma de las partes", display:EFieldDisplay.SHOW },
		firmausuario: { gpo:"fintech",	id:"firmausuario",  type:EFieldObj.AREA, save:1,  label:"Firma por parte del usuario",  oper:EFieldOper.UPDATE, value:"", rules:[], varrules:{ }, error: [], style:{resize: "none"}, 
			 display:EFieldDisplay.SHOW, classNameLabel:"col-md-4", classNameObj:"col-md-8", classNameGroup:"col-md-12", placeholder:"", rows:"3", cols:"40" },
      
    firmainst: { gpo:"fintech",	id:"firmainst",  type:EFieldObj.AREA, save:1,  label:"Firma de la institución",  oper:EFieldOper.UPDATE, value:"", rules:[], varrules:{ }, error: [], style:{resize: "none"},
			 display:EFieldDisplay.SHOW, classNameLabel:"col-md-4", classNameObj:"col-md-8", classNameGroup:"col-md-12", placeholder:"", rows:"3", cols:"40" },

    firmasec: { gpo:"fintech",	id:"firmasec",  type:EFieldObj.AREA, save:1,  label:"Firma conciliador (CONDUSEF)",  oper:EFieldOper.UPDATE, value:"", rules:[], varrules:{ }, error: [], 
			style:{resize: "none"}, display:EFieldDisplay.SHOW, classNameLabel:"col-md-4", classNameObj:"col-md-8", classNameGroup:"col-md-12", placeholder:"", rows:"3", cols:"40" },      

    titleAdmitir:{ type:EFieldObj.H5, id:"titleAdmitir", label:"Ingrese el acta de audiencia para firma", display:EFieldDisplay.SHOW },
    docefirma:	{ gpo:'admitir',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo en formato PDF", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW, 
      classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.PROYECTO  },
        
    titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Ingrese sus archivos para firmar electrónicamente", display:EFieldDisplay.SHOW },
    dockey:	{ gpo:'admitir',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },

    doccert:	{ gpo:'admitir',  id:"doccert",  type:EFieldObj.FILE,    label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-6", classNameObj:"col-md-6", save:1, classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'admitir',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
      classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"}         
  }

  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )
  useEffect(() => {
    TramitesSvc.TraFirma( act146Props.data.nodori)
    .then ( (response:any) => {                              
      if ( response.status !== 200 ) {          
        setPageState(EPageState.ERROR, "¡No fue posible obtener datos, vuelva a intentar!");  
        return;
      }        
      let Efirma = response.data;      
      let newData = {...dataProcess}

      newData.firmausuario.value = Efirma.audusr;
      newData.firmainst.value =  Efirma.audinst;
      newData.firmasec.value =  Efirma.audservpub;
      onChangeAll(newData);
      setPageState(EPageState.STEP0, "");  
    })
    .catch( (e) => {                
      setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });   
    
  }, []); 

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }    
    setPageState(EPageState.STEP1, "");          
    tramiteAction(act146Props,  FormType.PROYECTO, dataProcess, state, dispatch,  setPageState, {});  
  }
    
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act146Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
		
      case EPageState.INIT:			      
				return <ProgressBar mode="indeterminate" />;
 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}