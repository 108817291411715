import React from 'react'

import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj } from '../../form/fields/field';
import TramitesSvc from '../../services/TramitesSvc';
import { InputTextarea } from 'primereact/inputtextarea';

// ESTILOS 
interface ITramite { data: any, bucket: string  }



export const TramPrintFiles: React.FC<ITramite> = ( tramiteProps ) => {

  const file_Click = (filename: string, bucket :string, path:string ) => {
    TramitesSvc.DocumentoGet(filename, bucket, path);    
  }
  
  const drawBody = () => {        
    let contenido:any = tramiteProps.data;

      
    let htmlAdd: any = [];    
    const dataKeys = Object.keys(contenido);

    try {      
      for ( const item of dataKeys) {
  
        if ( contenido[item].display === EFieldDisplay.SHOW || contenido[item].name === 'NotificarInst.pdf' ) {
          if ( contenido[item].type === EFieldObj.CBO ) {
            htmlAdd.push(
              <div key={"renglo_" + item }  className="row"  >
                <div key={"rendiv" + item } className="col-md-3" >
                  <strong key={"rendivstr" + item }  >{ contenido[item].label } :</strong>
                </div>      
                <div key={"rendivdiv" + item }  className="col-md-9" >
                  { contenido[item].name }
                </div>      
              </div>);
          } else if ( contenido[item].type === EFieldObj.CHKB ) { 
  
            let strValue : string ="";
            if ( contenido[item].value) {
              strValue="Seleccionado";
            } else {
              strValue="No seleccionado";
            }
            htmlAdd.push(
              <div key={"renglo_" + item }  className="row"  >
                <div key={"rendiv" + item } className="col-md-3" >
                  <strong key={"rendivstr" + item }>{ contenido[item].label } :</strong>
                </div>      
                <div  key={"rendivdiv" + item } className="col-md-9" >
                  { strValue }
                </div>      
              </div>);
          } else if ( contenido[item].type === EFieldObj.FILE &&
            contenido[item].name.indexOf(".cer") === -1 &&
            contenido[item].name.indexOf(".key") === -1 ) { 

              if (contenido[item].name.length > 0 && Number(contenido[item].value) > 0 ){
                htmlAdd.push(
                  <div key={"renglo_" + item }  className="row"  >
                    <div  key={"rendiv" + item } className="col-md-3" >
                      <strong key={"rendivstr" + item }>{ contenido[item].label } :</strong>
                    </div>                          
                    <div key={"rendivdiv" + item } className="col-md-9" >
                      <Button key={"btnFile-" + contenido[item].value } label={ contenido[item].name } icon="pi pi-file"  
                        className="p-button-outlined p-button-success"
                        onClick={ (evt:any) =>  file_Click( contenido[item].value + "_" + contenido[item].name, tramiteProps.bucket, contenido[item].url)}
                        style={{ marginRight:"15px", marginTop:"5px", marginBottom:"2px", width:"99%", height:"32px", fontSize:"16px" }} />
                    </div>      
                  </div>);
              } else {
                htmlAdd.push(
                  <div key={"renglo_" + item }  className="row"  >
                    <div key={"rendiv" + item } className="col-md-3" >
                      <strong key={"rendivstr" + item } >{ contenido[item].label } :</strong>
                    </div>      
                    <div key={"rendiv2" + item } className="col-md-9" >No, se anexo archivo</div>      
                  </div>);
              }
          } else if ( contenido[item].type === EFieldObj.AREA ) { 

            let datoArea = contenido[item].value;
            if (item === 'firmausuario' || item === 'firmainst' || item === 'firmasec'  ) {
              if (datoArea !== ""){
                datoArea = "FIRMADO"
              } else {
                datoArea = "NO FIRMADO"
              }
          }
            
            htmlAdd.push(
              <div key={"renglo_" + item } className="row"  >
                <div key={"rendiv" + item } className="col-md-3" >
                <strong key={"rendivstr" + item } >{ contenido[item].label } :</strong>
                </div>      
                <div key={"rendiv2" + item } className="col-md-9" >                                                      
                  <InputTextarea key={"txtrenglo_" + item} rows={1} cols={90} value={datoArea}  autoResize />
                </div>      
              </div>);

/*

            if (opts.name == 'firmausuario' || opts.name == 'firmainst' || opts.name == 'firmasec'  ) {
                if (opts.value != ""){
                    opts.value = "FIRMADO"
                } else {
                    opts.value = "NO FIRMADO"
                }
            }

*/
          } else if ( contenido[item].type !== EFieldObj.PWD && contenido[item].type !== EFieldObj.FILE) { 
  
            let strValue : string =contenido[item].value;
            if (strValue === undefined){
              strValue="";
            }
  
            htmlAdd.push(
              <div key={"renglo_" + item } className="row"  >
                <div key={"rendiv" + item } className="col-md-3" >
                <strong key={"rendivstr" + item } >{ contenido[item].label } :</strong>
                </div>      
                <div key={"rendiv1" + item }className="col-md-9" >
                  <div key={"txtrenglo_" + item}  className="fontTexto"  style={{width:"500px", whiteSpace:"nowrap"}}>
                    { contenido[item].value }
                  </div>                
                </div>      
              </div>);
          }           
        }
      }
    } catch( err ){
      console.error(err);
    }
    return <div key={"divfileprint"} style={{paddingBottom:"30px"}} >{htmlAdd}</div>;        
  }
  
  return (
    drawBody() 
  );
}

/*
    <div key="data-tramite" className="col-md-12" style={{ height:"100%", width:"95%", left:"35px" }}>      
      
    </div>      
*/