import React, { Fragment, useContext} from 'react'

import './HeaderApp.css';
import { AppContext } from '../../Context';
import { ViewName } from '../../reducers/ViewReducer'
import { InstIdTypes, PerfilTypes, RUT_REDIRECT, Types } from '../../reducers/Model';
import { Menubar } from 'primereact/menubar';

import imageBG from '../../images/CondusefVerdeNoTitulo.png';
import { VerificarPerfil } from '../../util/seguridad';

export const HeaderApp: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);
  document.body.style.paddingTop="0";
  
  const PflReport = [PerfilTypes.FINTECH_DIR,PerfilTypes.FINTECH_SUBDIR , PerfilTypes.DICTAMEN_DIR, PerfilTypes.DICTAMEN_PRY, PerfilTypes.DICTAMEN_REVISOR];
  const PflTracing = [PerfilTypes.FINTECH_DIR,PerfilTypes.FINTECH_SUBDIR , PerfilTypes.DICTAMEN_DIR, PerfilTypes.DICTAMEN_PRY, PerfilTypes.DICTAMEN_REVISOR];
  const PflBcd= [PerfilTypes.CONSULTIVA_DIR, PerfilTypes.CONSULTIVA_BCD_COOR];

  const itemsBCD = [
    {
        label: 'CONCENTRADO EXCEL',
        icon: 'pi pi-refresh',
        command: () => { icon_Click( ViewName.TRAMITE_BCD_XLS) }
    },
    {
        label: 'EFIRMA RESPUESTA',
        icon: 'pi pi-external-link',
        command: () => { alert("EFIRMA") }
    }
];

  const icon_Click = ( viewname : ViewName ) => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: viewname , msg:"", refresh:""} });
  }

  const salir_Click = ( ) => {    
    if (Object.keys(state.user.cic).length > 0){
      RUT_REDIRECT();      
    } else {
      window.location.reload(); 
    }
  }

  const drawOptReport = () => {
    if ( VerificarPerfil( state.user.pflid, PflReport) ){
      return (
        <span className="oh-icon" > 
          <i className="glyphicon glyphicon-print"  onClick={ (evt) => icon_Click( ViewName.REPORT) } ></i>
        </span>
      );        
    } else {
      return null;
    }
  }

  const drawOptTracing = () => {
  if ( VerificarPerfil( state.user.pflid, PflTracing)){
      return (
        <span className="oh-icon" > 
          <i className="glyphicon glyphicon-list"  onClick={ (evt) => icon_Click( ViewName.TRACING) } ></i>
        </span>
      );        
    } else {
      return null;
    }
  }

  const drawOptBcd = () => {    
    if ( VerificarPerfil( state.user.pflid, PflBcd)){
      return <Menubar model={itemsBCD} style={{background:"#DDC9A3"}} />
    } else {
      return null;
    }
  }

  const drawReport = () => {    
    // BUSCAR EL PERFIL     
    return (<>
      { drawOptReport() }
      { drawOptTracing() }      
    </>);        
  }

  const drawPerfil = () => {    
    let correo = "";
    let nombre = "";
    let htmlOption = [];
    let selPsnid = 0;

  for ( var item of state.user.users )  {
    if ( item.active === 1) {
      correo = item.psncorreo;
      nombre = item.psnnombrecomp;
      selPsnid = item.psnid
    }            
    htmlOption.push(<option key={"opt-" +item.psnid}  value={item.psnid}  style={{backgroundColor:"#3a7263"}} >{item.psnnombrecomp}</option>)      
  }

  if ( state.user.users.length === 1  ){
    return(
      <span style={{ height:"60px", position:"absolute",left:"200px", fontSize:"24px", color:"white" }}>
        <span style={{  position:"relative",top:"6px"}}>{ nombre } </span>
          <p style={{  fontSize:"18px"  }}>  
            <span>{ correo } </span>
          </p>
      </span>
    );
  } else {

    return(        
      <span  style={{ height:"60px", position:"absolute",left:"200px", fontSize:"24px", color:"white" }}>
        <span style={{  position:"relative",top:"6px"}}>
          <select name="selOpc" id="selOpc" value={selPsnid} style={{border:"none",  borderRadius:"4px", minWidth:"720px", backgroundColor:"transparent" }} disabled  >
            { htmlOption }
          </select>
        </span>
          <p style={{  fontSize:"18px"  }}>  
            <span>{ correo } </span>
          </p>
      </span>
    );
  }
}

  //CREAR LOS PERFILES EN LA APLICACION

/*
  <div className="oh-icon" > 
  <i className="glyphicon glyphicon-calendar"  onClick={ (evt) => icon_Click( ViewName.CALENDAR) } ></i>
</div>
*/  
  const drawMenu = () => {
    if  ( state.page.view === ViewName.MAIN_PROCESOS ) {
      return (              
        <div className="oh-icon" > 
          <i className="glyphicon glyphicon-retweet"  onClick={ (evt) => icon_Click( ViewName.MAIN_VIEWNBOX) } ></i>
        </div>
      );
    } else if  ( state.page.view === ViewName.MAIN_VIEWNBOX ) {

      if ( state.user.insid === 0){
        return (
          <span>
            <div className="oh-icon"  >           
              <i className="glyphicon glyphicon-cog" onClick={ (evt) => icon_Click(ViewName.MAIN_PROCESOS) } ></i>
            </div>
          </span>
        );
        } else if( state.user.insid === InstIdTypes.CONDUSEF){
          return (
            <span>
              <div className="oh-icon" > 
                <i className="glyphicon glyphicon-cog" onClick={ (evt) => icon_Click(ViewName.MAIN_PROCESOS) } ></i>
              </div>      
              <div className="oh-icon" > 
                <i className="glyphicon glyphicon-retweet"  onClick={ (evt) => icon_Click( ViewName.MAIN_VIEWNBOX) } ></i>
              </div>
              { drawReport()}
            </span>
          );       
      } else {
        return null;
      }
    } else {
      return (
        <span>
          <div className="oh-icon" > 
            <i className="glyphicon glyphicon-cog" onClick={ (evt) => icon_Click(ViewName.MAIN_PROCESOS) } ></i>
          </div>      
          <div className="oh-icon" > 
            <i className="glyphicon glyphicon-retweet"  onClick={ (evt) => icon_Click( ViewName.MAIN_VIEWNBOX) } ></i>
          </div>
          { drawReport()}
        </span>
      );        
    }
  }

	const drawHeader = () =>{    
		return (
      <div className="oh-header"  >        
        <img src={imageBG} width="170px" height="60px" alt="Logo" />    
        { drawPerfil() }          
        <div className="oh-icon" > 
          <i className="glyphicon glyphicon-log-out" onClick={ (evt) => salir_Click() } ></i>        
        </div>
        { drawMenu() }
      </div>        
    );
  }

  return (
		<Fragment>               
      {drawHeader() }
      { drawOptBcd() }      
		</Fragment>
	);
}  

