import React, { useContext, useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';


// ESTILOS 
import '../Action.css';
import { FormType,  IActionProps  } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import {  tramiteAction } from '../ActUtil';

import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { ProgressBar } from 'primereact/progressbar';
import TramitesSvc from '../../../../services/TramitesSvc';

interface IData { onhide: any, data : IActionProps  }

export const ActAcuseGral : React.FC<IData> = ( actAcuseGralProps ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  const DATA_PROCESS = {
    titleDocNoti: { type:EFieldObj.H3, id:"titleDocNoti", label:"Identificador de la notificación", display:EFieldDisplay.SHOW },

    edoterm:	{ gpo:'firmar',   id:"edoterm", type:EFieldObj.TXT, save:1, label:"plazo o término procesal", oper:EFieldOper.READ, value:10, rules:[], error: [], 
      display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione una opción"  },      


    numoficio:	{ gpo:'firmar',	id:"numoficio",    type:EFieldObj.TXT, save:1,  label:"folio",  oper:EFieldOper.READ, value:"", rules:[], uppercase:true,
      varrules:{}, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"" },

    notifdoc: { gpo:'firmar',  id:"notifdoc",  type:EFieldObj.FILE, save:1,  label:"Notificación", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.NOTIFICAR  },

		titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Favor de confirmar la recepción, por medio de su firma electrónica",  display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'efirma',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },

		doccert:	{ gpo:'efirma',  id:"doccert",  type:EFieldObj.FILE,    label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", save:1, classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'efirma',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"},

    titlefirmaCIC: { type:EFieldObj.H3, id:"titlefirmaCIC", label:"Está notificación sera sellado con el medio de identificación CIC de forma automática", display:EFieldDisplay.SHOW },
    docacuse: { gpo:'header',  id:"docacuse",  type:EFieldObj.FILE, save:1,  label:"Acuse", oper:EFieldOper.READ, value: "", rules:[], 
      varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.ACUSE  }
  }
    
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )
  
  useEffect(() => {
    let newData = {...dataProcess};

    newData.titlefirma.display = EFieldDisplay.SHOW
    newData.dockey.display = EFieldDisplay.SHOW
    newData.doccert.display = EFieldDisplay.SHOW
    newData.efirma.display = EFieldDisplay.SHOW
    newData.titlefirmaCIC.display = EFieldDisplay.NO_SHOW          
    newData.docacuse.display = EFieldDisplay.NO_SHOW

    if (Object.keys(state.user.cic).length > 0 && state.user.cic.tipofirma !== '1'  ){      
      newData.titlefirma.display = EFieldDisplay.NO_SHOW
      newData.dockey.display = EFieldDisplay.NO_SHOW
      newData.doccert.display = EFieldDisplay.NO_SHOW
      newData.efirma.display = EFieldDisplay.NO_SHOW
      newData.titlefirmaCIC.display = EFieldDisplay.SHOW                
    }
    onChangeAll(newData);
    setPageState(EPageState.STEP0, "");
    
    TramitesSvc.TraDatos( actAcuseGralProps.data.oprid, actAcuseGralProps.data.nodori, "numoficio" )
    .then ( (response:any) => {                              
      if ( response.status !== 200 ) {          
        setPageState(EPageState.ERROR, "¡No fue posible obtener datos, vuelva a intentar!");  
        return;
      } 

      let newData = {...dataProcess};
      newData.numoficio.value = response.data.value;
      newData.notifdoc.value = response.data.name;
      onChangeAll(newData);
    })
    .catch( ( e ) => {
      setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });
  }, []); 

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "")
    tramiteAction(actAcuseGralProps,  FormType.ASIGNAR, dataProcess, state,  dispatch,  setPageState, {});
  }
        
  const DrawMsg = () => {
    if (pageState.msg.length > 0) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => actAcuseGralProps.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }


  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
 
			case EPageState.INIT:											
				return <ProgressBar mode="indeterminate" />;

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
        return drawStep1();
        
			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}