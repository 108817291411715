import React, { Fragment, useContext} from 'react'

import './Procesos.css';
import { AppContext } from '../../Context';
import { Types} from '../../reducers/Model'
import { ViewName  } from '../../reducers/ViewReducer'
import { HeaderApp }from '../main/HeaderApp';


import image1 from '../../images/Img-AU-FINTECH.jpg';
import image2 from '../../images/Img-AU-AgendaCita.jpg';
import image3 from '../../images/Img-AU-CONDUSEFEnLinea.jpg';
import image4 from '../../images/Img-AU-SolicitudConciliacionT.jpg';
import image5 from '../../images/Img-RC-OtraInformacion.jpg';

export const Procesos: React.FC = () => {
  const { state, dispatch } = useContext(AppContext);	

  document.body.style.backgroundColor="white";
  
  const PerfilMenu = (strPerfil : string) => {
    if (strPerfil === "USUARIOS"){
      return drawUsuarios();
    }
  }

  const CardClick = ( vista : string) => {
    dispatch({ type: Types.PAGE_SELECT, page: {view:vista, msg:"", refresh:""} });
  }
  
  const drawBody = () => {
    if ( Object.keys(state.user.cic ).length === 0 ) {					
      return DrawPerfilFintech();
    } else {
      return DrawPerfilRut();
    }
  }

  const DrawPerfilRut = () =>{
    return(
      <div>
        <div className="col-sm-4">
          <div className="thumbnail"> <img alt="100%x200" src={image2} style={{height:"200px", width:"100%", display: "block"}}/>
          <div className="caption">
              <p>Solicitud de búsqueda de Beneficiarios de Cuentas de Depósito</p>
              <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_BCD) } >Registrar</button></p>
            </div>
          </div>
        </div>

        <div className="col-sm-4">
            <div className="thumbnail"> <img alt="100%x200" src={image1} style={{height:"200px", width:"100%", display: "block"}}/>
            <div className="caption">
                <p>Queja en contra de una Institución de Tecnología Financiera</p>
                <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_FINTECH) } >Registrar</button></p>
              </div>
            </div>
        </div>

        <div className="col-sm-4">
            <div className="thumbnail"> <img alt="100%x200" src={image3} style={{height:"200px", width:"100%", display: "block"}}/>
            <div className="caption">
                <p>Solicitud Dictamen Técnico (valoración técnica y jurídica)</p>
                <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_DICTAMEN) } >Registrar</button></p>
              </div>
            </div>
        </div>

        <div className="col-sm-4">
            <div className="thumbnail"> <img alt="100%x200" src={image4} style={{height:"200px", width:"100%", display: "block"}}/>
            <div className="caption">
                <p>Solicitud de Defensoría Legal gratuito</p>
                <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_DEFENSORIA) } >Registrar</button></p> 
              </div>
            </div>
        </div>
      </div>
    );
  }

  const DrawPerfilFintech = () =>{
    return (
      <div className="col-sm-4">
        <div className="thumbnail"> <img alt="100%x200" src={image1} style={{height:"200px", width:"100%", display: "block"}}/>
          <div className="caption">
            <p>Queja en contra de una Institución de Tecnología Financiera</p>
            <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_FINTECH) } >Registrar</button></p>
          </div>
        </div>
      </div>
    )
  }


  const DrawPerfilFintechServPub = () =>{

    // modificar via PERIFL.. 

    if (state.user.psnid === 29 || state.user.psnid === 75) {
      return (
        <div className="col-sm-4">
          <div className="thumbnail"> <img alt="100%x200" src={image1} style={{height:"200px", width:"100%", display: "block"}}/>
            <div className="caption">
              <p><i><strong> Agregar QUEJA FINTECH recibida en Oficialía de Partes</strong></i></p>
              <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.TRAMITE_FINTECH_SERVPUB) } >Registrar</button></p>                  
            </div>
          </div>
        </div>
      )
    } else {
      return null;
    }

  }

  
  const DrawSeguimiento = () =>{
    return (
      <div className="col-sm-4">
        <div className="thumbnail">     
          <img alt="100%x200" src={image5} style={{height:"200px", width:"100%", display: "block"}}/>
          <div className="caption">
            <p>Conocer el avance y situación de los trámites iniciados</p>
            <p><button className="btn btn-primary" type="button"  onClick={ (evt) => CardClick(ViewName.MAIN_VIEWNBOX) } ><i className="glyphicon glyphicon-retweet" ></i>     Seguimiento</button></p>                     
          </div>
        </div>
    </div>
    )
  }

	const drawUsuarios = () =>{
		return (
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <h4>Iniciar Trámites</h4>
            <hr className="red"/>
            <ul>
              <li>Usuarios</li>
            </ul>
          </div>
          <div className="col-sm-9">
            <br/>            
            {drawBody()} 
            {DrawPerfilFintechServPub()}
          </div>
        </div>

        <br/>
        <br/>
        <div className="row">
          <div className="col-sm-3">
            <h4>Dar seguimiento</h4>
            <hr className="red"/>
          </div>
          <div className="col-sm-9">
            <br/>
            <br/>
            {DrawSeguimiento()} 
          </div>
        </div>
      </div>);
  }

  return (
		<Fragment> 
      <HeaderApp />
      {  PerfilMenu("USUARIOS") }
		</Fragment>
	);
}