import React, { useState } from 'react'

import { EPageState, usePageState } from '../../../ui/uses/usePageState';


///import { NNConfLectura } from '../node/NNConfLectura';
import { NNoticarEfirma } from '../node/fintech/NNoticarEfirma';


//import { N101ConRevisarQueja } from '../node/fintech/N101ConRevisarQueja';
//import { N105ConConfLectura } from '../node/fintech/N105ConConfLectura';
//import { N111ConDesecharQueja } from '../node/fintech/N111ConDesecharQueja';
//import { N112UsrCanNoDesahogo } from '../node/fintech/N112UsrCanNoDesahogo';
//import { N114UsrQuejaAviso } from '../node/fintech/N114UsrQuejaAviso';


import { NNEstadoActual } from '../NNEstadoActual';

import { N202ConDatosFalt } from '../node/bcd/N202ConDatosFalt';

/*
  import { N201ConRevisarSol } from '../node/bcd/N201ConRevisarSol';  
  import { N203UsrNotiSimple } from '../node/bcd/N203UsrNotiSimple'
  import { N204ConOficioEfirma } from '../node/bcd/N204ConOficioEfirma';
  import { N205ConSolCorregir } from '../node/bcd/N205ConSolCorregir';
  import { N206UsrRespBCD } from '../node/bcd/N206UsrRespBCD';
  import { N207ConConfLectura } from '../node/bcd/N207ConConfLectura';
  import { N208ConImproEfirma } from '../node/bcd/N208ConImproEfirma';
  import { N209UsrRespImproc } from '../node/bcd/N209UsrRespImproc';
  import { N210UsrPrevencion } from '../node/bcd/N210UsrPrevencion';
*/

import { N301ConRevisarSol } from '../node/dictamen/N301ConRevisarSol';
import { N302ConSIO } from '../node/dictamen/N302ConSIO';
import { N303UsrNotiSimple } from '../node/dictamen/N303UsrNotiSimple';
import { N304ConOficioEfirma } from '../node/dictamen/N304ConOficioEfirma';
import { N305ConSolCorregir } from '../node/dictamen/N305ConSolCorregir';
import { N307ConConfLectura } from '../node/dictamen/N307ConConfLectura';
import { N310UsrPrevencion } from '../node/dictamen/N310UsrPrevencion';

import { N401ConRevisarSol } from '../node/defensoria/N401ConRevisarSol';
import { N402ConSIO } from '../node/defensoria/N402ConSIO';
import { N403UsrNotiSimple } from '../node/defensoria/N403UsrNotiSimple';
import { N404ConOficioEfirma } from '../node/defensoria/N404ConOficioEfirma';
import { N405ConSolCorregir } from '../node/defensoria/N405ConSolCorregir';
import { N407ConConfLectura } from '../node/defensoria/N407ConConfLectura';
import { N408ConImproEfirma } from '../node/defensoria/N408ConImproEfirma';
import { N410UsrPrevencion } from '../node/defensoria/N410UsrPrevencion';


// COMPONENTES 
import { LocalDataView } from '../../states/LoadDataView';

// ESTILOS 
import './TaskNew.css';
import { LocalDataErrorView } from '../../states/LoadDataErrorView';
import { IOpciones } from '../../../reducers/Model';

interface IItemData { 
  data: IOpciones;  
  setListTask :any;
  init: string;
  document:any;
}

export const TaskNew: React.FC<IItemData> = (props) => {
  const [pageState, setPageState] = usePageState({ state: props.init, msg:"" });
  const [item, setItem] = useState<number>(0);
  
  const drawBody = () => {		
    if ( props.data === undefined) {
      setPageState(EPageState.INIT, "");  
      return;
    } else if (props.data.nodid !== item   ) {
      setPageState(EPageState.INIT, "");  
      return;
    } else {

      let document = props.document;

      switch ( props.data.edoid ) {
        case 3:
        case 4:
        case 5:
        case 6:
        case 7: 
        case 8:        
        case 11:
        case 13:
        case 15:
        case 16:
        case 17:
        case 18:
        case 19:
        case 20:
        case 22:
        case 23:
        case 24:
        case 25:
        case 26:
        case 27:
        case 28:
        case 29:
        case 213:
          return <NNoticarEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>                    



/*
        case 105:
          return <N105ConConfLectura init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 111:
          return <N111ConDesecharQueja init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 112:
          return <N112UsrCanNoDesahogo init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 114:
          return <N114UsrQuejaAviso init={document}  action={ props.data} setListTask = { props.setListTask}/>          
*/



/*
        case 201:
          return <N201ConRevisarSol init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 202:
          return <N202ConSECOBAN init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 203:
          return <N203UsrNotiSimple init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 204:
          return <N204ConOficioEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 205:
          return <N205ConSolCorregir init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 206:
          return <N206UsrRespBCD init={document}  action={ props.data} setListTask = { props.setListTask} />
        case 207:
          return <N207ConConfLectura init={document}  action={ props.data} setListTask = { props.setListTask} />
        case 208:
          return <N208ConImproEfirma init={document}  action={ props.data} setListTask = { props.setListTask} />          
        case 209:
          return <N209UsrRespImproc init={document}  action={ props.data} setListTask = { props.setListTask} />                    
        case 210:
          return <N210UsrPrevencion init={document}  action={ props.data} setListTask = { props.setListTask} />                              
*/          

        case 301:
          return <N301ConRevisarSol init={document}  action={ props.data} setListTask = { props.setListTask} />                                        
        case 302:
          return <N302ConSIO init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 303:
          return <N303UsrNotiSimple init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 304:
          return <N304ConOficioEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 305:
          return <N305ConSolCorregir init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 306:
          return <NNoticarEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 307:
          return <N307ConConfLectura init={document}  action={ props.data} setListTask = { props.setListTask} />          
        case 309:
          return <NNoticarEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>              
        case 310:
          return <N310UsrPrevencion init={document}  action={ props.data} setListTask = { props.setListTask} />             
          

        case 401:
          return <N401ConRevisarSol init={document}  action={ props.data} setListTask = { props.setListTask} />
        case 402:
          return <N402ConSIO init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 403:
          return <N403UsrNotiSimple init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 404:
          return <N404ConOficioEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 405:
          return <N405ConSolCorregir init={document}  action={ props.data} setListTask = { props.setListTask}/>
        case 406:
          return <NNoticarEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>          
        case 407:
          return <N407ConConfLectura init={document}  action={ props.data} setListTask = { props.setListTask} />
        case 408:
          return <N408ConImproEfirma init={document}  action={ props.data} setListTask = { props.setListTask} />          
        case 409:
          return <NNoticarEfirma init={document}  action={ props.data} setListTask = { props.setListTask}/>             
        case 410:
          return <N410UsrPrevencion init={document}  action={ props.data} setListTask = { props.setListTask} />             
          

        default:
          return <NNEstadoActual init={document}  action={ props.data} setListTask = { props.setListTask} />
      }    
    }        
  }

	const drawStateCtrl = () => {	    

    if (   props.data === undefined  ){
      return;
    }

    if (props.data.nodid !== item   ) {
      setItem(props.data.nodid);
      setPageState(EPageState.INIT, "");        
      return;
    }

		switch ( pageState.state ) {			

      case EPageState.INIT:
        return drawBody();


      case EPageState.LOAD_DATA:
				return <LocalDataView msg="" color={"black"}/>;
        
      case EPageState.ERROR:
				return <LocalDataErrorView msg={pageState.msg} color={"white"}/>


			default:
				return <LocalDataErrorView msg={pageState.msg} color={"black"}/>
		}
  }
  return (<div style={{ height:"100%", width:"100%", backgroundColor:"whitesmoke"}}> {drawStateCtrl()} </div>);
}