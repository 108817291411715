import React, {Fragment} from "react"
import {Field} from  "./Field";

import { Button } from 'primereact/button';
import { RULE_NO_EMPTY, RULE_NO_CERO } from '../fields/fieldRules';
import { EFieldDisplay, EFieldObj} from '../fields/field';
import {DrawFileBoxMsg }  from './DrawFileBoxMsg';

/*  SE REQUIENRE ESTILOS DE BOOTSTRAP
// Todo los datos me llegan por propiedades
// {  props.dataSection,  props.onEditorTextChange, props.onChangeDataTable,props.onSelectedDataTableRow, props.dataLayout=0 }
*/

interface IDrawFieldsGroup { 
  dataFields: any;		
  onItemChange: any;		
  onEditorTextChange: any;
  onCompleteMethod: any;
  onFieldsValidate: any;
  onValidateAll: any;
  [key: string]: any;		
}

export const DrawFieldsGroup: React.FC< IDrawFieldsGroup> = (props) => {

  const objPropField = (field : any) => {    
    return {
      data: field,
      onClick: null,
      onChange: props.onItemChange,
      onCompleteMethod: props.onCompleteMethod,
      onEditorTextChange: props.onEditorTextChange
    }
  }
             
  const drawField = (dataField : any)=> {
    let htmlAdd = [];    
  
    if (dataField.type !== EFieldObj.CHKB ) {
      if (dataField.rules.length > 0 ) {
        let result = dataField.rules.filter( (rule:any) => ( rule.name === RULE_NO_EMPTY || rule.name ===RULE_NO_CERO) ? true: false);
        if (result) {
          htmlAdd.push(<label key={ "lblkey" + dataField.id } className={dataField.classNameLabel } >{dataField.label }*:</label> );        
        } else {        
          htmlAdd.push(<label key={ "lblkey" + dataField.id } className={dataField.classNameLabel } >{dataField.label }:</label> );
        }    
      } else {
          htmlAdd.push(<label key={ "lblkey" + dataField.id } className={ dataField.classNameLabel} >{dataField.label }:</label> ); 
      }
    }

    if ( dataField.hasOwnProperty('tooltip') ) {    
      htmlAdd.push(
        <Button  className="p-button-rounded p-button-info" icon="pi pi-info" 
          key={ "btnkey" + dataField.id }
          tooltip={ dataField.tooltip} 
        />);
    }

    if ( dataField.hasOwnProperty('info') &&  dataField.info === "CURP") {    
      htmlAdd.push(<span key={ "spnkey" + dataField.id } style={{ fontSize:"14px"}}>
        <a key={ "akey" + dataField.id } style={{float:"right"}} href="https://consultas.curp.gob.mx/CurpSP/gobmx/inicio.jsp" target="_blank" rel="noopener noreferrer">¡Consultar CURP!</a>
      </span>);
    }

    if ( dataField.hasOwnProperty('info') &&  dataField.info === "CP") {    
      htmlAdd.push(<span  key={ "spnkey" + dataField.id } style={{ fontSize:"14px"}}>
        <a key={ "akey" + dataField.id } style={{float:"right"}} href="https://www.correosmexico.com.mx/consulta-de-codigo-postal-de-mexico?ubicacion=" target="_blank" rel="noopener noreferrer">¡Consultar CP!</a>
      </span>);
    }
    
    // agregar KEY
    htmlAdd.push(<Field key={"field_" + dataField.id} { ...objPropField(dataField)} />);
        
    return htmlAdd;
  }  

  const objDrawGroup = () => {  
    let htmlAdd: any = [];

    Object.keys(props.dataFields).forEach( key => {            
      let fieldAct = props.dataFields[key];      

      if ( fieldAct.display === EFieldDisplay.SHOW ){

        if ( fieldAct.type === EFieldObj.H1 ){
          htmlAdd.push( <label key={ "h1" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  style={{ width:"98%", fontSize:32, fontWeight:"bold", marginTop:"30px", position:"static" }}>{fieldAct.label }</label>);        
          htmlAdd.push( <div key={ "divh1" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  > <hr key={ "h1Red" + fieldAct.id }  className="red"/> </div> );
        } else if ( fieldAct.type === EFieldObj.H2 ){
          htmlAdd.push( <label key={ "h2" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  style={{ width:"98%", fontSize:28, fontWeight:"bold", marginTop:"30px", position:"static" }}>{fieldAct.label }</label>);
          htmlAdd.push( <div key={ "divh2" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  > <hr key={ "h2Red" + fieldAct.id }  className="red"/> </div> );          
        } else if ( fieldAct.type === EFieldObj.H3 ){
          htmlAdd.push( <label key={ "h3" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  style={{ width:"98%", fontSize:24, fontWeight:"bold", marginTop:"30px", position:"static" }}>{fieldAct.label }</label>);
          htmlAdd.push( <div key={ "divh3" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  > <hr key={ "h3Red" + fieldAct.id }  className="red"/> </div> );          
        } else if ( fieldAct.type === EFieldObj.H4 ){
          htmlAdd.push( <label key={ "h4" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  style={{ width:"98%", fontSize:22, fontWeight:"bold", marginTop:"30px", position:"static" }}>{fieldAct.label }</label>);
          htmlAdd.push( <div key={ "divh4" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  > <hr key={ "h4Red" + fieldAct.id }  className="red"/> </div> );          
        } else if ( fieldAct.type === EFieldObj.H5 ){
          htmlAdd.push( <label key={ "h5" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  style={{ width:"98%", fontSize:20, fontWeight:"bold", marginTop:"30px", position:"static" }}>{fieldAct.label }</label>);
          htmlAdd.push( <div key={ "divh5" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12"  > <hr key={ "h5Red" + fieldAct.id }  className="red"/> </div> );          

        } else if ( fieldAct.type === EFieldObj.MSJ ){      
          htmlAdd.push( <div key={"msj-" + fieldAct.id } style={{color:"#FF0000"}} className="col-md-12" ><strong>{fieldAct.label}</strong></div>);

        } else if ( fieldAct.type === EFieldObj.LABEL ){      
          htmlAdd.push( <div key={"msj-" + fieldAct.id } className="col-xs-12 col-md-12 col-lg-12" ><strong>{fieldAct.label}</strong></div>);
          
        } else if ( fieldAct.type === EFieldObj.URL ){      
          htmlAdd.push( <a key={"a-" + fieldAct.id } href={fieldAct.value} target="_blank" rel="noopener noreferrer" style={fieldAct.style} >{fieldAct.label}</a>);

        } else if ( fieldAct.type === EFieldObj.FILE && fieldAct.hasOwnProperty('info') && fieldAct.info === "BOX" )  {    
          htmlAdd.push(DrawFileBoxMsg( objPropField(fieldAct) ));      
        } else {    
          if ( fieldAct.display === EFieldDisplay.SHOW ){ 
            if ( fieldAct.type === EFieldObj.EDIT ){
              htmlAdd.push(
                <div key={"div-"+ fieldAct.id }  className={ fieldAct.classNameGroup } style={{height:"270px", justifyContent:"space-between", marginTop:"10px" }}>
                  { drawField( fieldAct) }
                </div>            
              );
            } else {
              htmlAdd.push(
                <div key={"div-"+ fieldAct.id }  className={ fieldAct.classNameGroup } style={{fontSize:"18px", justifyContent:"space-between", marginTop:"10px"}}>
                  { drawField( fieldAct) }
                </div>            
              );          
            }
          }
        }
      }
    });
    return htmlAdd;
  }
  
  return (
    <Fragment>
      <div className="row">
        { objDrawGroup() }
      </div>
    </Fragment>
  );
}