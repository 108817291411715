import React, {useContext, Fragment, useEffect} from 'react';

import { Main } from './Main';

import { AppContext} from '../../Context'
import { ViewName } from '../../reducers/ViewReducer'
import { CitaConsultar} from '../tramite/CitaConsultar'


/* TRAMITES */
import { Tramite} from '../tramite/CITAS____Tramite'
///import { Fintech} from '../tramite/Fintech'


import { Login } from './Login';
import { Procesos } from './Procesos';
import { Welcome } from './Welcome';
///////import { Act101UsrSiab } from '../afd/action/Act101UsrSiab';

import { Act101UsrFintech } from '../afd/action/fintech/Act101UsrFintech';
import { Act201UsrBcd} from '../afd/action/bcd/Act201UsrBcd';
import { Act401UsrDefensoria } from '../afd/action/defensoria/Act401UsrDefensoria';
import { Act301UsrDictamen } from '../afd/action/dictamen/Act301UsrDictamen';

import TramitesSvc from '../../services/TramitesSvc';
import { TramRutStrType, TramRutType, Types } from '../../reducers/Model';
import { MainBoxView } from '../afd/tracing/MainBoxView';
import { ViewRefreshSeg } from './ViewRefreshSeg';
import { ViewCalendar } from './ViewCalendar';
import { ReportPluf } from './ReportPluf';
import { LoginError } from './LoginError';
import { Tracing } from '../trace/Tracing';
import { BcdXlsx } from '../bcdxlsx/BcdXlsx';


// ESTA DEBE SER UN CUATRO RESUMEN - TABLERO

export const ViewCtrl = () => {  
  const { state, dispatch } = useContext(AppContext);	

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search.replaceAll("&amp;", "&"));

    let tokenT = urlParams.get('tokenT');
    const tramite = urlParams.get('tramite');
    const folio = urlParams.get('folio');

    if ( tokenT !== null) {
      let formData = new FormData();        
      formData.append("token", tokenT!);

      if ( TramRutStrType.BCD === tramite)
        formData.append("idtramite", TramRutType.BCD);
      else if ( TramRutStrType.DICTAMEN === tramite)
        formData.append("idtramite", TramRutType.DICTAMEN);
      else if ( TramRutStrType.DEFENSORIA === tramite)
        formData.append("idtramite", TramRutType.DEFENSORIA);
      else if ( TramRutStrType.FINTECH === tramite)
        formData.append("idtramite", TramRutType.FINTECH);
      else if ( TramRutStrType.SEGUIMIENTO === tramite)
        formData.append("idtramite", TramRutType.SEGUIMIENTO);
      else if ( TramRutStrType.PUR === tramite)
        formData.append("idtramite", TramRutType.PUR_FINTECH);
                
      formData.append("tramite", tramite!);
      formData.append("folio", folio!);
      
      TramitesSvc.SegCic(formData)
      .then(response => { 
        if ( response.status ===  200) {
          let auxCIC = JSON.parse(response.data.cic);
          auxCIC["folio"] = folio;
          if ( response.data.tramite === 'SEGUIMIENTO'){
            auxCIC["inprocess"] = 1;
          } else {
            auxCIC["inprocess"] = 0;
          }
            
          dispatch({ type: Types.USER_INIT, page:{ mainpsnid: response.data.mainpsnid, maininsid: response.data.maininsid, psnid:response.data.psnid, insid:response.data.insid, users:[...response.data.users], 
            session:"12324313", type: response.data.type, cic: auxCIC, pflid: response.data.pflid }});          
          if ( response.data.tramite === TramRutStrType.BCD ){
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_BCD , msg:"", refresh:""} });
          } else if ( response.data.tramite === TramRutStrType.DICTAMEN){
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_DICTAMEN , msg:"", refresh:""} });
          } else if ( response.data.tramite === TramRutStrType.DEFENSORIA){
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_DEFENSORIA , msg:"", refresh:""} });
          } else if ( response.data.tramite === TramRutStrType.SEGUIMIENTO){
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX, msg:"", refresh:""} });
          } else if ( response.data.tramite === TramRutStrType.PUR){
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX, msg:"", refresh:""} });                                
          } else {
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_PROCESOS, msg:"", refresh:""} });
          }
        } else {
          dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.LOGIN_ERROR , msg:"", refresh:""} });    
        }

      })
      .catch(e => {
        dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.LOGIN_ERROR , msg:e.response.data, refresh:""} });    
      });  
    } else {
      dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.SESSION_LOGIN , msg:"", refresh:""} });
    }
    ////// SI AHY ERROR REGRESARLO AL NUEVAMETNE A DESARROLLO...
    window.history.replaceState({}, document.title, "/");  
  }, []); // Solo se ejecuta una sola vez

  const htmlError = () => {
    return (
      <Fragment>        
        <div className="container">	  
          <div className="col-sm-12" style={{ textAlign:"center", height:"300px"}}>
            <br />
            <pre>Ha ocurrido un error en el aplicación favor de internarlo nuevamente</pre>
            <br />
          </div>
        </div>
      </Fragment>
    )
  }

  // RENDER A PARTIR DE LA PAGINA 
  const drawBody = () => {  

    switch (state.page.view) {

      case ViewName.CALENDAR:
        return <ViewCalendar />
      
      case ViewName.MAIN:
        return <Main />
        
      case ViewName.MAIN_VIEWNBOX:
        return <MainBoxView />      
        
      case ViewName.MAIN_REFRESH_SEG:
        return <ViewRefreshSeg />

      case ViewName.CITA_CONSULTAR:
        return <CitaConsultar clave={ state.pageBuscar.clave}  validar={state.pageBuscar.validar }/>

      case ViewName.REPORT:
        return <ReportPluf/>
        
      case ViewName.TRACING:
        return <Tracing/>

      case ViewName.TRAMITE_NUEVO:
        return <Tramite />
                    
      case ViewName.TRAMITE_BCD:
        return <Act201UsrBcd />
        
      case ViewName.TRAMITE_BCD_XLS:
        return <BcdXlsx />



      case ViewName.TRAMITE_DICTAMEN:
        return <Act301UsrDictamen /> 

      case ViewName.TRAMITE_DEFENSORIA:
        return <Act401UsrDefensoria /> 
        
      case ViewName.TRAMITE_FINTECH:        
        return <Act101UsrFintech />        
                              
      case ViewName.SESSION_LOGIN:                    
        //return <Tracing/>
        //return <Act201UsrBcd />
        //return <BcdXlsx />
        return <Login />
        
      case ViewName.WELCOME:              
        return <Welcome />        
        
      case ViewName.LOGIN_ERROR:              
        return <LoginError />                
        
/*      
      return <Mantenimiento />
      case ViewName.MAIN_SEGUIMIENTO:        
        return <Seguimiento />       
*/
        
      case ViewName.MAIN_PROCESOS:
        return <Procesos />       

/*
      case STATE.CITA_SOLICITAR:
        return <CitaSolicitar />

      case STATE.CITAS_ADM:
        return <CitasAdm />

      case STATE.TRAMITES:
        return <Tramites />

      case STATE.CITA_NUEVO_TRAMITE:
        return <CitaNuevoTramite />
*/
      default:                 
        return  htmlError();
    }
  }

  return (  drawBody() );  
}