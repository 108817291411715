import React, { Fragment, useContext, useState, useEffect } from 'react'

import { ProgressBar } from 'primereact/progressbar';
import { AppContext } from '../../../../Context';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_CERO, RULE_NO_EMPTY, RULE_NUMBER_GREATER } from '../../../../form/fields/fieldRules';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { DelegacionType, FormType, EtapaType, IActionProps,IPersonData, TramitesType, Types, PERSONA_TYPE } from '../../../../reducers/Model';
import { ViewName } from '../../../../reducers/ViewReducer';
import TramitesSvc from '../../../../services/TramitesSvc';
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';

import imageEdoCuenta from '../../../../images/estadoCuenta.jpg';
import { validateFilesSize } from '../../../../util/fileUtil';
import { tramiteAction, tramLinkAction } from '../ActUtil';
////import { tramLinkAction } from '../ActUtil';

interface ICatalogos { 
	[key: string]: any;		
}

interface IData { onhide: any, data : IActionProps  }

export const Act122UsrReclamacion: React.FC<IData> = ( act122Props ) => {  

	const { state, dispatch } = useContext(AppContext);	
	const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
	const [reclamar, setReclamar] = useState<ICatalogos>({});
	const [usrPerson, setUsrPerson] = useState<IPersonData>();
	
  const BtnEnvia_Click = () => {    
		dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
  }
		
	const cboSectorOnChange = (evt: any) => {		
		TramitesSvc.FinInstSector( evt.target.value)		
		.then ((response: any) => {  									      								
			if (response.data.itf.length > 0) {
				let objInstitucion: { label: any; value: any }[] = [];

				response.data.itf.forEach((item : any) => { 
					objInstitucion.push( { label:item.nombre_ins, value:item.id_ins } )
				});
	
				let newData = {...dataProcess}

				newData.institucion.list= objInstitucion;
				newData.institucion.value = [];
				newData.sectoritf.value =evt.target.value;			
				newData.sectoritf.error = [];

			
				let objServ: ICatalogos = [];
				response.data.serv.forEach((item : any) => { 
					objServ.push( { label:item.pdsvdescripcion, value:item.pdsvid } )
				});

				newData.servicio.list= objServ;
				newData.servicio.value = [];
				////newData.institucion.value =evt.target.value;							
				newData.institucion.error = [];

				onChangeAll(newData);
			} else {
				setPageState(EPageState.STEP1,"No existen datos para esta selección");	
			}

		}).catch( (e:any) => {
			setPageState(EPageState.STEP1,"No fue posible obtener el catálogo de Institución");
		});		
	}

	const montoOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.monto.value = evt.value
		onChangeAll(newData);
	}

	const txtCodPosOnChange = (evt: any) =>{			
		if ( evt.target.value.length === 5 && !isNaN(evt.target.value) 
			&& evt.target.value !== dataProcess.solcodpos.value  ) {		
				
			TramitesSvc.GeoColoniaCPGet(evt.target.value)		
				.then( (response: any) => {    
					if ( response.data.length > 0) {
						let dato = 	response.data[0];	
						let newData = {...dataProcess};
						newData.solentfed.list = [{ label: dato.estado , value: dato.id_edo }];
						newData.solentfed.value = dato.id_edo;
						newData.solentfed.error = [];
						newData.solmunicipio.list = [{ label: dato.municipio , value: dato.id_mun }];
						newData.solmunicipio.value = dato.id_mun;
						newData.solmunicipio.error = [];
						newData.solcolonia.list = [];
						newData.solcolonia.value = dato.id_col;
						newData.solcolonia.error = [];
						newData.solcodpos.error= [];

						response.data.forEach((item : any) => { 
							newData.solcolonia.list.push( { label:item.colonia , value:item.id_col } )
						});												
						onChangeAll(newData);
					} else {
						let newData = {...dataProcess};
						newData.solcodpos.error.push("El código postal es invalido");
						onChangeAll(newData);
					}
					return;
				})
				.catch( (e:any) => {
					setPageState(EPageState.STEP1,"No fue posible conectarse a los catálogos");
					return;
				})				
			}
		onItemChange(evt);
	}
	
	const DATA_PROCESS = {
		traid: { gpo:"header",	id:"traid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:TramitesType.FINTECH, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tdatid: { gpo:"header",	id:"tdatid",  type:EFieldObj.PINT, save:1,  label:"Tipo de documento",  oper:EFieldOper.READ, value: FormType.RECLAMACION, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramdelid: { gpo:"header",	id:"tramdelid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:DelegacionType.FINTECH, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramseq: { gpo:"header",	id:"tramseq",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:"", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },

		tramfolio: { gpo:"fintech",	id:"tramfolio",  type:EFieldObj.TXT, save:1,  label:"No. de Folio",  oper:EFieldOper.READ, value:0, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramfecrec: { gpo:"fintech",	id:"tramfecrec",  type:EFieldObj.TXT, save:1,  label:"Fecha de recepción",  oper:EFieldOper.READ, value:"", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramdoc: { gpo:'header',  id:"tramdoc",  type:EFieldObj.FILE, save:1,  label:"Trámite solicitud", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.QUEJA  },		
	
		TitDatPer:{ type: EFieldObj.H3, id:"TitDatPer", label:"Datos personales del Solicitante ", display:EFieldDisplay.SHOW  },
		psntipo: { gpo:"fintech", id:"psntipo", type:EFieldObj.SBUTTON, save:1, label:"Tipo de persona", oper:EFieldOper.UPDATE, value:1, rules:[],
			varrules:{}, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"", classNameGroup:"col-md-12", options: PERSONA_TYPE,
			conditions: { DISPLAY_FIELDS: [ 
				{ value:1, target:[ "solempresa", "solemprfc"], display:EFieldDisplay.NO_SHOW},
				{ value:2, target:[ "solempresa", "solemprfc"], display:EFieldDisplay.SHOW}
			]}},
			
		solempresa: { gpo:"fintech",	id:"solempresa",  type:EFieldObj.TXT, save:1,  label:"Denominación social",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-8", placeholder:"Ingresar denominación" },
		solemprfc: { gpo:"fintech",	id:"solemprfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-12",classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },	

		solnombre:	{ gpo:"fintech",	id:"solnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		solcurp: { gpo:"fintech",	id:"solcurp",      type:EFieldObj.TXT, save:1,  label:"CURP",       oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY], uppercase:true, maxlength:"18",
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6",classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Ingresa tu CURP",  info:"CURP"},
		solcorreo:{ gpo:"fintech",	id:"solcorreo",	type:EFieldObj.TXT,  save:1, label:"Correo Electrónico", oper:EFieldOper.READ, value:"", rules:[], uppercase:true,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12",classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Ingresa tu correo"},															


		solidentificacion:	{ gpo:"fintech",   id:"solidentificacion", type:EFieldObj.CBO, save:1, label:"Tipo de identificación", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12 p-md-6", classNameObj:"col-md-12 p-md-6 ", classNameGroup:"col-md-4", placeholder:"Selecciona una opción", list: [] },
		solnumid:	{ gpo:"fintech",   id:"solnumid", type:EFieldObj.TXT,  save:1, label:"Número de identificación", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Selecciona una opción", varrules:{ minvalue:2 }, maxlength:15 },
		soltelfijo:	{ gpo:"fintech",   id:"soltelfijo",      type:EFieldObj.PINT, save:1,   label:"Tel. fijo", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12",classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número de 10 digitos", maxlength:10 },
		soltelmovil:		{ gpo:"fintech",   id:"soltelmovil",      type:EFieldObj.PINT, save:1,   label:"Tel. móvil", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número de 10 digitos", maxlength:10 },
			
			
		TitDatDom:{ type:EFieldObj.H3, id:"TitDatDom", label:"Domicilio (para recibir notificaciones)" , display:EFieldDisplay.SHOW  },		
		solcodpos:		{ gpo:"fintech",	id:"solcodpos",      type:EFieldObj.PINT, save:1,  label:"CP", oper:EFieldOper.UPDATE, value:0, rules:[RULE_NUMBER_GREATER], varrules:{ minvalue:999 }, error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-3", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Ingresa tu código postal", maxlength:5, onChange: txtCodPosOnChange, info:"CP" },
		solentfed:		{ gpo:"fintech",  id:"solentfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{LINK_COMBO: {target:"municipio"}}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-5", placeholder:"Selecciona la Entidad Federativa"}, 
		solmunicipio:  { gpo:"fintech",  id:"solmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{LINK_COMBO: {target:"colonia"}},  display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						

		solcolonia:    { gpo:"fintech",  id:"solcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Selecciona la Colonia" },              
		solcalle:   { gpo:"fintech",   id:"solcalle",       type:EFieldObj.TXT, save:1,   label:"Calle",         oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón"}, 						
		solnumext:     { gpo:"fintech",   id:"solnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número exterior"}, 						
		solnumint:     { gpo:"fintech",   id:"solnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número interior" },
	
		titRepres:{ type:EFieldObj.H3, id:"titRepres", label:"Datos del Asunto", display:EFieldDisplay.SHOW  },
		sectoritf:	{ gpo:"fintech",  id:"sectoritf",  type:EFieldObj.CBO, save:1,   label:"Sector",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{LINK_COMBO: {target:"institucion"}}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Selecciona un sector", onChange:cboSectorOnChange},			
		institucion:	{ gpo:"fintech",  id:"institucion",  type:EFieldObj.CBO, save:1,   label:"Institución Tecnología Financiera",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Selecciona una institución"}, 	
		servicio:		{ gpo:"fintech",  id:"servicio",  type:EFieldObj.CBO, save:1,   label:"Producto",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Selecciona el servicio"}, 		
		causa:			{ gpo:"fintech",  id:"causa",  type:EFieldObj.CBO, save:1,   label:"Causa",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Selecciona el servicio"}, 	
			
		contrato:		{ gpo:"fintech",	id:"contrato",    type:EFieldObj.TXT, save:1,  label:"Contrato/Cuenta",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Ingresa tu contrato"  },

		monto:		{ gpo:"fintech",   id:"monto",      type:EFieldObj.INUM, save:1,   label:"Monto de la queja (MXN)", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_CERO], error: [], display:EFieldDisplay.SHOW, onChange:montoOnChange, max:24325296,
			classNameLabel:"col-md-10", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Monto en pesos (mxn) de la queja", tooltip:"Si el monto de tu queja está relacionado a un mismo producto financiero, coloca en este campo la suma del mismo, de lo contrario te invitamos a registrar una queja por cada producto con el que tengas problemas." },	


		prestaciones:		{ gpo:"fintech",   id:"prestaciones",      type:EFieldObj.AREA,   save:1, label:"Pretenciones", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,style:{resize: "none"},
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Relatoría de las pretenciones", rows:"10", cols:"40"},
		hechos:		{ gpo:"fintech",   id:"hechos",      type:EFieldObj.AREA,   save:1, label:"Hechos", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Relatoría de los hechos", rows:"10", cols:"40" },
	
		titDocumentos:{ type:EFieldObj.H3, id:"titDocumentos", label:"Documentación soporte", display:EFieldDisplay.SHOW  },
		titDocReglas:{ type:EFieldObj.H5, id:"titDocReglas", label:"Esta forma de captura solo admite archivos en formato PDF y la suma del tamaño de los archivos no debe ser mayor a 40MB.", display:EFieldDisplay.SHOW  },
			
		docedocuenta:	{ gpo:"fintech",  id:"docedocuenta",  type:EFieldObj.FILE, save:1,   label:"ESTADO DE CUENTA O SIMILAR",   oper:EFieldOper.UPDATE, value: "",  rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:"col-md-12", placeholder:"", 
			info:"BOX", image:imageEdoCuenta, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.EDO_CUENTA,
			text:[ { section:"Documento oficial emitido por la Institución Financiera en donde se informa de los movimientos, operaciones, consumos o cargos y montos a pagar en relación a un crédito o préstamo otorgado o cualquier otro producto o servicio contratado con dicha institución."},
				{ section:"Es muy importante que muestre tu nombre con el objeto de validar la relación contractual, en caso de que tu problemática pueda corroborase en el mismo, márcalo con amarillo para realizar un mejor análisis de tu queja."} ] }, 
		
		docevidencia:	{ gpo:"fintech",  id:"docevidencia",  type:EFieldObj.FILE,  save:1,  label:"DOCUMENTO QUE ACREDITE UNA EVIDENCIA", oper:EFieldOper.UPDATE, value: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:"col-md-12", placeholder:"", 
			info:"BOX", image:imageEdoCuenta, alt:"Documento evidencia", accept:"application/pdf", typedoc:FormType.EVIDENCIA, 
			text:[ { section:"Evidencia base de la reclamación:"}, 
				{ section:"Ingrese cualquier otro documento como capturas de pantalla, imagen, archivos electrónicos o digitales que evidencien el motivo de la queja, como respuestas que le haya dado la institución financiera."}] },
	
		titlefirma:{ type:EFieldObj.H3, id:"titlefirma", label:"Favor de ingresar su Firma electrónica (eFirma SAT) para enviar sus documentos y firmar la presente queja", display:EFieldDisplay.SHOW  },
		dockey:	{ gpo:"fintech",  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
	
		doccert:	{ gpo:"fintech",  id:"doccert",  type:EFieldObj.FILE, save:1, label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },
				
		efirma: { gpo:"fintech",   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-5", classNameObj:"col-md-3", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"},

		docacuse: { gpo:'header',  id:"docacuse",  type:EFieldObj.FILE, save:1,  label:"Acuse", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.ACUSE }

	}
	
	const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )

	useEffect(() => {
    OnInit();
  }, []); 

	const OnInit = () =>{		
		//let usrServPub: any = act122Props.data.source;
		let usrServPub: any = {}

		let usrPerson: IPersonData = { insid: usrServPub.insid,  empid:usrServPub.empid,  psnid :usrServPub.psnid,
			psnnombrecomp: usrServPub.psnnombrecomp, psncorreo: usrServPub.psncorreo, 
			pstnombre: usrServPub.pstnombre , inssiglas: usrServPub.inssiglas, active:0};

		let tramite = JSON.parse(act122Props.data.tramite.datcontenido);
		let newData = {...dataProcess};
		
		newData.psntipo.oper = EFieldOper.READ;
		newData.tramfolio.value = tramite.tramfolio.value;
		newData.solidentificacion.list = [{ label:  tramite.solidentificacion.name, value:tramite.solidentificacion.value }];
		newData.solidentificacion.value = tramite.solidentificacion.value;
		newData.solidentificacion.oper = EFieldOper.READ;
		newData.solnombre.value = tramite.solnombre.value;
		newData.solnombre.oper = EFieldOper.READ;
		newData.solcorreo.value = tramite.solcorreo.value;
		newData.solcorreo.oper = EFieldOper.READ;
		newData.solcurp.value = tramite.solcurp.value;
		newData.solcurp.oper = EFieldOper.READ;
	
		newData.solnumid.value = tramite.solnumid.value;
		newData.solnumid.oper = EFieldOper.READ;
		newData.soltelfijo.value = tramite.soltelfijo.value;
		newData.soltelfijo.oper = EFieldOper.READ;
		newData.soltelmovil.value = tramite.soltelmovil.value;
		newData.soltelmovil.oper = EFieldOper.READ;
					
		newData.solcodpos.value = tramite.solcodpos.value;
		newData.solcodpos.oper = EFieldOper.READ;			

		newData.solentfed.list = [{ label:  tramite.solentfed.name, value:tramite.solentfed.value }];
		newData.solentfed.value = tramite.solentfed.value;
		newData.solentfed.oper = EFieldOper.READ;

		newData.solmunicipio.list = [{ label:  tramite.solmunicipio.name, value:tramite.solmunicipio.value }];
		newData.solmunicipio.value = tramite.solmunicipio.value;
		newData.solmunicipio.oper = EFieldOper.READ;

		newData.solcolonia.list = [{ label:  tramite.solcolonia.name, value:tramite.solcolonia.value }];
		newData.solcolonia.value = tramite.solcolonia.value;
		newData.solcolonia.oper = EFieldOper.READ;

		newData.solcalle.value = tramite.solcalle.value;
		newData.solcalle.oper = EFieldOper.READ;
		newData.solnumext.value = tramite.solnumext.value;
		newData.solnumext.oper = EFieldOper.READ;
		newData.solnumint.value = tramite.solnumint.value;
		newData.solnumint.oper = EFieldOper.READ;

		
		newData.sectoritf.list=  [{ label:  tramite.sectoritf.name, value:tramite.sectoritf.value }];
		newData.sectoritf.value = tramite.sectoritf.value;
		newData.sectoritf.oper = EFieldOper.READ;

		newData.institucion.list = [{ label:  tramite.institucion.name, value:tramite.institucion.value }];
		newData.institucion.value = tramite.institucion.value;
		newData.institucion.oper = EFieldOper.READ;

		newData.servicio.list = [{ label:  tramite.servicio.name, value:tramite.servicio.value }];
		newData.servicio.value = tramite.servicio.value;
		newData.servicio.oper = EFieldOper.READ;

		newData.causa.list = [{ label:  tramite.causa.name, value:tramite.causa.value }];
		newData.causa.value = tramite.causa.value;
		newData.causa.oper = EFieldOper.READ;

		newData.contrato.value = tramite.contrato.value;
		newData.contrato.oper = EFieldOper.READ;
		newData.monto.value = tramite.monto.value;
		newData.monto.oper = EFieldOper.UPDATE;

		newData.prestaciones.value = tramite.prestaciones.value;
		newData.hechos.value = tramite.hechos.value;

		onChangeAll(newData);
		setPageState(EPageState.STEP1,"");
		setUsrPerson(usrPerson);
	}

	const btnSaveOnClick = () => {
		// tamaño de los archivos...
		let intErrorTotal = onValidateAll();
		let intErrorSize = validateFilesSize(dataProcess, 10240);
				
		intErrorTotal += intErrorSize;

		try {
			if (intErrorTotal === 0 ){					
				setPageState(EPageState.STEP2, "");							
				tramLinkAction(act122Props,  FormType.RECLAMACION, dataProcess, state,  setPageState, setReclamar, {etaid:EtapaType.QUEJA });							

			} else {
				if ( intErrorSize > 0) {				
					setPageState(EPageState.STEP1, "La suma de los archivos suman más de 20MB");
				} else {
					setPageState(EPageState.STEP1, "Favor de revisar los datos de captura");
				}						
			}						
		} catch (error) {
			setPageState(EPageState.STEP1, "No fue posible registrar la solicitud, vuelva a intentarlo");
			return ;
		}
	}

	const drawStep3 = () => {
		return (
			<div className="container " >
				<br/>
				<h3>RECLAMACIÓN EN CONTRA DE UNA INSTITUCIÓN DE TECNOLOGÍA FINANCIERA</h3>
				<hr className="red"></hr>
				<div className="row">        
					<label className="col-sm-3 col-md-6">Fecha de registro</label>
					<label className="col-sm-8 col-md-6">{ reclamar.fecha }</label>
				</div>
				<br />
				<div className="row">        
					<label className="col-sm-3 col-md-6">Número de Folio</label>
					<label className="col-sm-8 col-md-6">{ reclamar.folio }</label>
				</div>

				<br />
				<button className="btn btn-primary " id="envia" type="button" onClick={ evt => { BtnEnvia_Click() } }>Ir a seguimiento 
					<span className="glyphicon glyphicon-ok"></span>
				</button>				

				<br />
				<br />

				<div className="row">
					<object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + reclamar.tramite  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
					</object>          
				</div>

				
				<div className="row">
					<object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + reclamar.acuse  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
					</object>          
				</div>   
			</div>  
		);     
	}

	const FormaContenido = () => {
		return (
			<div>
				{ DrawMsg() }
				<div id="formReg" >
					<DrawFieldsGroup 
						dataFields={dataProcess}
						onItemChange={onItemChange}
						onEditorTextChange={onEditorTextChange}
						onCompleteMethod={onCompleteMethod}  
						onFieldsValidate={onFieldsValidate}
						onValidateAll={onValidateAll}
				 	/>
					<br/>
					<div className="pull-left text-muted text-vertical-align-button">*Campos obligatorios</div>
					<br/>				
					<br/>			
					{ DrawMsg() }
					</div>
			</div>
		);
	}

	const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}
		
	const drawStep2 = () => {
		return (
			<div className="container" >        	
				{ FormaContenido() }
				<ProgressBar mode="indeterminate" />
				<br/>
			</div>  
		);     
	}

	const drawStep1 = () => {		
		return (
			<div className="container" >        	
				<br />
				<h1 style={{textAlign:"center", fontSize:"40px"}} >INICIAR RECLAMACIÓN FINTECH</h1>
				{ FormaContenido() }
				<br/>
				<div className="pull-right" style={{ marginBottom:"40px" }}  >
					<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSaveOnClick() } >Enviar <span className="glyphicon glyphicon-ok"></span></button>
				</div>				
				<br/>
				<br/>
			</div>  
		);     
	}

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			
			case EPageState.INIT:							
				return <ProgressBar mode="indeterminate" />;

			case EPageState.IN_PROCESS:        
				return <ProgressBar mode="indeterminate" />
			
			case EPageState.STEP1:
				return drawStep1();

			case EPageState.STEP2:
				return drawStep2();
				
			case EPageState.STEP3:
				return drawStep3();
								
			default:
				return DrawMsg();
		}
	}

  return (
		<Fragment>      			
			{ drawStateCtrl() }
		</Fragment>
	);
} 
