import { IAccion, IAccionFind, INodoOrigen, IOperacion, Types } from "../../../reducers/Model";
import { ViewName } from "../../../reducers/ViewReducer";
import TramitesSvc from "../../../services/TramitesSvc";
import { JsonPost } from "../../../ui/uses/UseFormDataETL";
import { EPageState } from "../../../ui/uses/usePageState";
import { userFindActive } from "./UsersUtil";


export const tramiteAction = (actProp:any, tdatid:number, dataProcess:any,  state:any, dispatch:any, setPageState:any, optional:any ) => {
  let operacion : IOperacion = {  traid:actProp.data.tramite.traid , etaid:actProp.data.etaid, oprid:actProp.data.oprid, bucket:actProp.data.bucket,  oprpath:actProp.data.oprpath };
  let origen: INodoOrigen = { nodori: actProp.data.nodori, edoori: actProp.data.edoid, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
  let accion :IAccion = { accid:actProp.data.accid, tdatid:tdatid, data:dataProcess, edoid:actProp.data.edodst, optional:optional };

  TramitesSvc.TraAction( JsonPost(operacion, origen, accion,  state.user.cic) )           
  .then((response : any) => {
    if ( response.status === 200 ) {
      dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
      return;
    } else {
      setPageState(EPageState.STEP0, "No fue posible registrar su queja, vuelva a intentarlo");			
      return ;					
    }
  })
  .catch( (e :any) => {           
    if ( e.response.data.indexOf("MSG") > -1) {
      setPageState(EPageState.STEP0, e.response.data.substring(5));  
    } else {
      setPageState(EPageState.STEP0, "¡" + e.response.data + "!");  
    }
    
  });
}


export const tramLinkAction = (actProp:any, tdatid:number, dataProcess:any,  state:any, setPageState:any, setData:any, optional:any ) => {
  let operacion : IOperacion = {  traid:actProp.data.tramite.traid , etaid:actProp.data.etaid, oprid:actProp.data.oprid, bucket:actProp.data.bucket,  oprpath:actProp.data.oprpath };
  let origen: INodoOrigen = { nodori: actProp.data.nodori, edoori:actProp.data.nodori,   psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
  let accion :IAccion = { accid:actProp.data.accid, tdatid:tdatid, data:dataProcess, edoid:actProp.data.edodst,  optional:optional };

  TramitesSvc.TraAction( JsonPost(operacion, origen, accion,  state.user.cic) )           
  .then((response : any) => {         				
    if ( response.status === 200 ) {					
      setData(response.data);
      setPageState(EPageState.STEP3, "");      
      return;
    } else {
      setPageState(EPageState.STEP1, "No fue posible registrar su queja, vuelva a intentarlo");			
      return ;					
    }
  })
  .catch( (e:any) => {                
    setPageState(EPageState.STEP1, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
  });
}


//BORRAR YA QUE EL TIPO DOS PARALLEO DESAPARECEN..
export const  findActionTipo = ( lstData: [] ) : IAccionFind => { 
  let resAccion: IAccionFind = {edodst:0, accid:0, accdesc:"", acctipo:0};

  lstData.forEach(function(item : IAccionFind){
      if ( item.acctipo === 2) {
        resAccion.accid = item.accid;
        resAccion.accdesc = item.accdesc;
        resAccion.acctipo = item.acctipo;
        resAccion.edodst = item.edodst;
        return resAccion;
    }
  });
  return resAccion;
}