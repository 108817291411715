export const fecNacimiento = ( curp :string) => {

  let periodo = 0;
  let mes  = "";
  let dia = "";

    
  periodo = parseInt(curp.substring(4,6));
  if (periodo < 15)
    periodo = periodo + 2000;
  else
    periodo = periodo + 1900;

  mes  = curp.substring(6,8);
  dia = curp.substring(8,10);
  
  //return periodo.toString() + "-" + mes + "-" + dia;
  return dia + "/" + mes + "/" + periodo.toString();
}


export const fecSexo = ( curp :string) => {
  if ( curp.substring(10,11) === 'M')
    return "1";
    // 1: FEMENINO
  else
    return "2";
    // 2: MASCULINO
};