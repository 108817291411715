import React, { useContext, useState,useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType, IActionProps, IDocProp } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';

import {  tramiteAction } from '../ActUtil';
import TramitesSvc from '../../../../services/TramitesSvc';

interface IData { onhide: any, data : IActionProps  };

export const Act148AllFirmarActa : React.FC<IData> = ( act148Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  
  const [acta, setActa] = useState<string>("");

  useEffect(() => {    
    TramitesSvc.TraDatos( act148Props.data.oprid, act148Props.data.nodori, "docactaconciliar" )
    .then ( (response:any) => {                              
      if ( response.status !== 200 ) {          
        setPageState(EPageState.ERROR, "¡No fue posible obtener datos, vuelva a intentar!");  
        return;
      }        
      let acta : IDocProp = { name: response.data.name, value: response.data.value, url: response.data.url };
      let docActa = acta.value + "_" + acta.name + "/" + act148Props.data.bucket +  "/" + act148Props.data.oprpath;
      setActa(docActa);
      setPageState(EPageState.STEP0, "");  
    })
    .catch( ( e ) => {
      setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });      
  }, []); 

  const DATA_PROCESS = {				
		titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Favor de firmar electrónicamente en la acta de la audiencia",  display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'conciliar',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },

		doccert:	{ gpo:'conciliar',  id:"doccert",  type:EFieldObj.FILE,    label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-7", classNameObj:"col-md-5", save:1, classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'conciliar',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"},
  }

  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");          
    tramiteAction(act148Props,  FormType.PROYECTO, dataProcess, state, dispatch,  setPageState, {});  
  }
    
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {    
		return (
      <form>
      	<object width="95%" height="500px" data={ TramitesSvc.URL + "doc/consulta/" + acta  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
				</object>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        { DrawMsg() }
        <br />
      </form>
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"5px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act148Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
      
      case EPageState.INIT:
        return <LocalDataView msg="" color={"black"} />;

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}