import { EFieldObj } from "../form/fields/field";


// PORQUE ES UN ARREGLO 
//dataProcess[key].value[0]
//dataProcess[key].value.size[0]

export const validateFilesSize = ( dataProcess:any, maxSize: number )	 => {
  let filesSize = 0;

  Object.keys(dataProcess).forEach( key => {
    if (dataProcess[key].type === EFieldObj.FILE  && dataProcess[key].value !== undefined) {
      filesSize += Math.round( dataProcess[key].value.size / 1024 );				
    }
  });

  if (filesSize > maxSize ){
    return 1;
  }
  return 0;
}