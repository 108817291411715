import React from 'react'

interface IData { msg : string, color:string  }

export const NoDataView: React.FC<IData> = (props) => {        

  return(
    <div style={{ width:"100%", height:"40vh", padding:"auto", textAlign:"center", color:props.color}}>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>        
      <p ><i className="pi pi-file" style={{ fontSize:'96px'}} ></i></p>
      <p> { props.msg ==="" ? "No existen datos a mostrar" : props.msg  }</p>
    </div>
  );

}