import { EFieldObj} from '../../form/fields/field';
import { dateToString } from '../../util/dateUtil';

const htmlEncode = (str ) => {
    if ( str === undefined){
      return undefined;

    } else {
      return String(str)
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
    }
};

export const JsonPost = ( operacion, origen, accion,  cicusr) => {
  let formData = new FormData();

  Object.entries(accion.data).forEach(item => {
    if (item[1].type === EFieldObj.FILE 
      && item[1].value !== undefined && item[1].value !== ''  ) { 
      formData.append(item[1].id, item[1].value[0]);
    }
  });
  
  if (accion.hasOwnProperty("optional")) {
    if ( accion.optional.hasOwnProperty("data") ){
      Object.entries(accion.optional.data).forEach(item => {
        if (item[1].type === EFieldObj.FILE 
          && item[1].value !== undefined && item[1].value !== ''  ) { 
          formData.append(item[1].id, item[1].value[0]);        
        }
      });
    }
  }

  accion.data = UseFormDataJsonETL(accion.data);
  if (accion.hasOwnProperty("optional")) {
    if ( accion.optional.hasOwnProperty("data") ){
      accion.optional.data = UseFormDataJsonETL(accion.optional.data);      
    }
  }

  let action = {
    "operacion":operacion, 
    "origen": origen,
    "accion": accion, 
    "cic": cicusr,
  }

  formData.append("action", JSON.stringify(action));    
  //AGREGAR ARCHIVOS
  return formData;
}

export const UseFormDataJsonETL = (Data) => {
  let jsonData = {};
    
  Object.entries(Data).forEach(item => {
    if ( item[1].hasOwnProperty('value') ){ 
    
      if (item[1].type === EFieldObj.FILE ) {
          // UN CICLO SI ES MULTIPLE....
        ///if (item[1].value[0].name === undefined){
        if (item[1].value === undefined || item[1].value[0] === undefined){
          jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "", "value": "0", "typedoc":item[1].typedoc, "accept":item[1].accept, "url":"N/A" };
        } else{
          jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display,"save":item[1].save, "label":item[1].label, "name": item[1].value[0].name, "value": "0", "typedoc":item[1].typedoc, "accept":item[1].accept, "url":"" };
        }      
      } else if (item[1].type === EFieldObj.CBO  ) {
        // Colocamos la descripción
        if ( Array.isArray(item[1].list) && (item[1].value !== ''  )) {
          let strDesc = item[1].list.filter(word => word.value === item[1].value );
          if (strDesc.length >0)
            jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": strDesc[0].label, "value": item[1].value};
          else
            jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value": item[1].value};
        } else {
          jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value": item[1].value};
        }                  
        
      } else if (item[1].type === EFieldObj.SBUTTON  ) {
        let strDesc = item[1].options.filter(word => word.value === item[1].value );
        if (strDesc.length >0)
          jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": strDesc[0].name, "value": item[1].value};
        else
          jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value": item[1].value};
                
      } else if (item[1].type === EFieldObj.DATE ) {
        jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value": dateToString(item[1].value)};  
        
      } else if (item[1].type === EFieldObj.AREA || item[1].type === EFieldObj.TXT ) {
        jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value":  htmlEncode(item[1].value)};  
        
      } else {
        if (item[1].value === ''){
          item[1].value= "N/A";
        }

        jsonData[item[1].id] = {"type":item[1].type, "display":item[1].display, "save":item[1].save, "label":item[1].label, "name": "N/A", "value": item[1].value};
      }        
    }
  });    
  return  jsonData;
};