import React from 'react'
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFiles } from '../../TramFiles';
import { TramFinQueja } from '../../TramFinQueja';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N401ConRevisarSol: React.FC<IData> = (props401) => {
  const drawBody = () => {

    if (props401.action.hasOwnProperty("datcontenido")=== true) {
      let contenido = JSON.parse(props401.action.datcontenido);
      let origen = contenido.origen.psn;      
      let accion = props401.init.aristas;  

      let strMsg = "";
      if (props401.action.hasOwnProperty('accion') )
        strMsg = contenido.accion.data.value;
      else
        strMsg = "Usted ha recibido la siguiente queja :";
  
      return (
        <div style={{ marginTop:"10px"}}>
          <NodoResponder etaid={props401.init.operacion.etaid} action={accion} oprid={props401.action.oprid} nodori={props401.action.nodid} setListTask={props401.setListTask }  
          edoori={props401.action.edoid }   bucket={props401.init.operacion.bucket}  oprpath={props401.init.operacion.oprpath} 
          tramite={props401.init.tramite}></NodoResponder>
           
          <NodoHeader remitente={origen.psnnombrecomp} fecha={props401.action.nodfecha} estado={props401.action.edodesc} titulo={""}  folio={props401.action.oprfolio} insid={origen.insid}></NodoHeader>    
          <TramFiles traid={ props401.init.tramite.traid} etaid={props401.init.tramite.etaid} oprid={props401.init.tramite.oprid} insid={0}   />
          <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} > 
            <div className="col-xs-12 col-md-12 col-lg-12" style={{ marginBottom:"10px" }}> {strMsg }</div>            
            <TramFinQueja data={props401.init.tramite} bucket={props401.action.bukdesc}  ></TramFinQueja>
          </div>        
        </div>
      )
    } else {
      return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
    }

  }    
  return drawBody();
}