export enum Accion {
  REGISTRAR_TRAMITE = 1,

  
  FINTECH_USR_ENVIAR_QUEJA = 101,
  FINTECH_USR_CONFIRMAR_EFIRMA_CIC = 107,
  
  FINTECH_CON_ENVIAR_QUEJA_DIGITALIZADA= 100,
  FINTECH_CON_NOTIFICAR_ADMISION = 117,


  BCD_USR_ENVIAR_SOLICITUD = 201,
  BCD_CON_NOTIFICAR_ADMISION = 203, 
  BCD_USR_CONFIRMAR_EFIRMA_CIC = 208, 

  DICTAMEN_SOLICITUD_ENVIAR = 301,
  DICTAMEN_CON_NOTIFICAR_ADMISION = 303, 

  DEFENSORIA_SOLICITUD_ENVIAR = 401,
  DEFENSORIA_CON_NOTIFICAR_ADMISION = 403


}

export enum NodoEstado {
  TRAMITE = 1,
  
  FINTECH_REVISAR = 101,
  FINTECH_AVISO_LECTURA_REVISAR_QUEJA = 105,
  FINTECH_USR_QUEJA_ADMITIDA = 114,

  
  BCD_REVISAR = 201,
  BCD_AVISO_LECTURA_REVISAR_BCD = 207,
  BCD_OFICIO_IMPROCEDENCIA_FIRMAR = 208,
  BCD_USR_SOLICITUD_ADMITIDA = 203,

  DICTAMEN_SOLICITUD_REVISAR = 301,
  DICTAMEN_USR_SOLICITUD_ADMITIDA = 303,

  DEFENSORIA_SOLICITUD_REVISAR = 401,
  DEFENSORIA_USR_SOLICITUD_ADMITIDA = 403  

}

export enum OperDataPerson {
  ASESOR = "asesor",
  ASIGNAR = "asignar",
  USRSERVFIN = "usrservfin"
}


