import React, { useContext } from 'react'
import { AppContext } from '../../../Context';
import { userColor } from '../../../util/cssUtil';

// COMPONENTES 
interface IItemData { 
  itemSelect: any;  
  setItemInbox: any;
  listTask:any;
}

export const ListTaskNew: React.FC<IItemData> = (propsLstNewAct ) => {  
  const {state } = useContext(AppContext);	
  
  const appStylesSelect = {
    borderTop:"1px solid #888888",
    
    background:  "lightgray",
    fontWeight: "bold", 
    fontSize:"20px",    
    color:"black",
    cursor: "default"
  };
    
  const appStyles = {    
    background:  "white",
    borderTop:"1px solid #888888",
    color:"black"    
  };

  const drawNewItem = ( oprid:number,  edoid:number ) => {
    if ( edoid === 101) {
      return (
        <span key={`item-row-icon-user-${oprid}-${edoid} `} className="glyphicon glyphicon-star" 
          style={{ marginTop:"4px", color: "red", alignContent:"revert" }}></span>
      )
    }
    return null;
  }

  const drawEtapa = ( traid:number,  etaid:number,  oprid:number ) => {
    if ( traid === 1 && etaid === 1) {
      return (
        <span key={`item-row-icon-user-A${etaid}-${oprid} `} className="col-xs-3 glyphicon glyphicon glyphicon-pawn" style={{ color: "#006400", alignContent:"revert" }}></span>
      )
    } else if ( traid === 1 && etaid === 2) {
      return (
        <span className="col-xs-3">
          <span key={`item-row-icon-user-A${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-pawn" style={{  color: "#006400", alignContent:"revert" }}></span>
          <span key={`item-row-icon-user-B${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-knight" style={{  color: "#006400", alignContent:"revert" }}></span>
        </span>
      )
    } else if ( traid === 1 && etaid === 3) {
      return (
        <span className="col-xs-3">
          <span key={`item-row-icon-user-A${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-pawn" style={{  color: "#006400" }}></span>
          <span key={`item-row-icon-user-B${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-knight" style={{ color: "#006400" }}></span>
          <span key={`item-row-icon-user-C${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-tower" style={{ color:"#006400" }}></span>
        </span>
      )
    } else if ( traid === 1 && etaid === 4) {
      return (
        <span className="col-xs-3">
          <span key={`item-row-icon-user-A${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-pawn" style={{  color: "#006400" }}></span>
          <span key={`item-row-icon-user-B${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-knight" style={{ color: "#006400" }}></span>
          <span key={`item-row-icon-user-C${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-tower" style={{ color:"#006400" }}></span>
          <span key={`item-row-icon-user-D${etaid}-${oprid} `} className="glyphicon glyphicon glyphicon-bishop" style={{ color:"#006400" }}></span>
        </span>
      )          
    } else {
      return null;
    }
    
  }
  
  
  const drawItem = (data : any) => {
    let idx = data.nodid;
    
    let appStyle; 
    if ( propsLstNewAct.itemSelect === idx ){
      appStyle = appStylesSelect;
    } else {
      appStyle = appStyles;
    }

    const iconColor = userColor(data.oriinst);
      return (
        <div key={`div-${idx}`} style={{ marginTop:"5px" }}>
          <div key={`item-${idx}`} className="col-xs-12 item" style={ appStyle } onClick={ () => propsLstNewAct.setItemInbox(idx, data.edoid, data.traid   )}>   
            <div key={`item-header-${idx}`} className="row"  >
              <table key={`item-table-${idx}`}>
                <thead key={`item-table-thead${idx}`}>
                  <tr key={`item-table-thead-tr1${idx}`}>
                    <th key={`item-th-col1-${idx}`} style={{ width:"10%" }} ></th>
                    <th key={`item-th-col2-${idx}`} style={{ width:"90%" }} ></th>
                  </tr>              
                </thead>
                <tbody key={`item-tbody-${idx}`}>
                  <tr key={`item-table-thead-tr3${idx}`}>
                    <td key={`item-table-thead-td1${idx}`}>
                      <span key={`item-header-image-${idx}`} className={data.traicono} style={{ fontSize:"32px", color:"#BC955C", marginLeft:"8px" }} ></span>                      
                    </td>
                    <td key={`item-table-thead-td2${idx}`}>
                      <div key={`item-row-nombre${idx}`}  className="col-xs-10" style={{ textOverflow: "ellipsis", fontSize:"15px", marginTop:"4px" }}>{ data.psnnombrecomp }</div>                                               
                      <span key={`item-row-icon-user${idx}`} className="col-xs-1 glyphicon glyphicon-bookmark" style={{ marginTop:"4px", color: iconColor, alignContent:"revert" }}></span>                      
                       {drawNewItem (data.oprid, data.edoid)}
                      <div key={`item-row-desc${idx}`}    className="col-xs-12" style={{ textOverflow: "ellipsis", fontSize:"13px", fontWeight:"bold" }}>{data.edodesc }</div>                        
                      <div key={`item-row-folio${idx}`}   className="col-xs-12" style={{ textOverflow: "ellipsis", fontSize:"13px" }}>Folio: {data.oprfolio.trim() }</div>
                      <div key={`item-row-fecha${idx}`}   className="col-xs-9" style={{ textOverflow: "ellipsis", fontSize:"13px", marginBottom:"4px" }}>{ data.nodfecha} </div>
                      { drawEtapa ( data.traid, data.etaid, data.oprid) }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )
  }

  const drawBody = () => {	
    let htmlAdd : any = [];

    if ( propsLstNewAct.listTask.length > 0) {
      propsLstNewAct.listTask.forEach( (item: any) => {
          htmlAdd.push(drawItem ( item )); 
      });
    }      
    
    if (htmlAdd.length > 0) {      
      return (
        <div>
          { htmlAdd }
          <div className="col-xs-12" style={{  borderTop:"1px solid #888888"  }}> </div>
        </div>
      );
    } else {
      return (
        <div style={{ color:"black"}}>          
          <div style={{ textAlign:"center" }}>
            <br/>
            No existen actividades
          </div>
        </div>
      )
    }         
  }

  return ( drawBody() );
}