import React from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFiles } from '../../TramFiles';
import { TramFinQueja } from '../../TramFinQueja';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N302ConSIO: React.FC<IData> = (props302) => {

  const drawBody = () => {
    try {
      if (props302.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props302.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props302.init.aristas;  
        let changeableData = contenido.accion.data;      
        let strMsg = changeableData.mensaje.value;
        if (strMsg === '' ) {
          strMsg = "Sin instrucciones adicionales ";
        }
            
        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props302.init.operacion.etaid} action={accion} oprid={props302.action.oprid} nodori={props302.action.nodid} setListTask={props302.setListTask} 
              edoori={props302.action.edoid }    bucket={props302.init.operacion.bucket}  oprpath={props302.init.operacion.oprpath} 
              tramite={props302.init.tramite}></NodoResponder>
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props302.action.nodfecha} estado={props302.action.edodesc} titulo={""}  folio={props302.action.oprfolio} insid={origen.insid}></NodoHeader>    
            <TramFiles traid={ props302.init.tramite.traid} etaid={props302.init.tramite.etaid} oprid={props302.init.tramite.oprid} insid={0}   />
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div> 
                <p>Instrucciones adicionales:</p>
                <div style={{ marginBottom:"10px" }}> {strMsg }</div>
              </div>      

              <TramFinQueja data={props302.init.tramite} bucket={props302.action.bukdesc} ></TramFinQueja>
                    
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return drawBody();
}