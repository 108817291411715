import React, { useContext } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

//SERVICES
import  TramitesSvc  from '../../../../services/TramitesSvc';

//UTILS
import { userFindActive } from "../UsersUtil";

// PRIME
import {ProgressBar} from 'primereact/progressbar';


// ESTILOS 
import '../Action.css';
import { FormType, IAccion, IActionProps, INodoOrigen, IOperacion, RESP_FAVORABLE_ONE, SioOperacion, Types } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { ViewName } from '../../../../reducers/ViewReducer';
import { Accion, NodoEstado } from '../../AfdUtil';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';

interface IData { onhide: any, data : IActionProps  }

export const Act202ConSio : React.FC<IData> = ( act202Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
/*
    sentidoresp:	{ gpo:'BCD',   id:"sentidoresp", type:EFieldObj.CBO, save:1, label:"Sentido de la respuesta", oper:EFieldOper.UPDATE, value:1, rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione una opción", list:RESP_FAVORABLE_ONE  }
*/
  const DATA_PROCESS = {				        
    TitDatDom:{ type:EFieldObj.H3, id:"TitDatDom", label:"Enviar solicitud para capturar folios SIO y CECBAN" , display:EFieldDisplay.SHOW  },		
  
    foliosio:	{ gpo:"BCD",	id:"foliosio",    type:EFieldObj.TXT, save:1,  label:"Asigne el folio SIO",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
      varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Folio SIO" },
      
    foliobcd:	{ gpo:"BCD",	id:"foliobcd",    type:EFieldObj.TXT, save:1,  label:"Asigne el folio BCD",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
      varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Folio BCD" },

    foliocecoban:	{ gpo:"BCD",	id:"foliocecoban",    type:EFieldObj.TXT, save:1,  label:"Asigne el folio CECOBAN",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
      varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Folio BCD" },

    observaciones:	{ gpo:'BCD',   id:"observaciones", type:EFieldObj.AREA,  save:1, label:"Observaciones", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
      classNameLabel:"col-md-12", classNameObj:"col-md-12",  classNameGroup:"col-md-12", placeholder:"", varrules:{}, rows:"5", cols:"40" },

  }
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )
  
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");
    let dataMsg = { id:"Mensaje", name:"Mensaje", type:EFieldObj.TXT, save:1,  label:"Comentarios",  value:"Su solicitud ha sido admitida por parte de la CONDUSEF, esto es un mensaje informativo" };    

    // ENVIAR EL DATO ASI MISMO....
    let operacion : IOperacion = {  traid:act202Props.data.tramite.traid , etaid:act202Props.data.etaid, oprid:act202Props.data.oprid, bucket:act202Props.data.bucket, oprpath:act202Props.data.oprpath };    
    let origen: INodoOrigen = { nodori: act202Props.data.nodori, edoori: act202Props.data.nodori, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
    let accion : IAccion = { accid:act202Props.data.accid, tdatid:FormType.ASIGNAR, data:dataProcess, edoid:act202Props.data.edodst,        
      optional:{ accid:Accion.BCD_CON_NOTIFICAR_ADMISION,  	tdatid:FormType.NOTIFICAR, data:dataMsg, edoid:NodoEstado.BCD_USR_SOLICITUD_ADMITIDA }   };
                        
    TramitesSvc.TraAction( JsonPost(operacion, origen, accion, state.user.cic) )           
    .then(response => {         				
      if ( response.status === 200 ) {					
        dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
        return;
      } else {
        setPageState(EPageState.STEP0, "No fue posible registrar su queja, vuelva a intentarlo");			
        return ;					
      }
    })
    .catch( (e) => {                
      setPageState(EPageState.STEP0, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });
  }
        
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act202Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
		 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}