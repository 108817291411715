import React, { useContext, useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

//SERVICES
import  TramitesSvc  from '../../../../services/TramitesSvc';

//UTILS
import { createLstPerfil } from "../UsersUtil";

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType,  IActionProps,  InstIdTypes,  PerfilTypes  } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import { tramiteAction } from '../ActUtil';

interface IData { onhide: any, data : IActionProps  }

export const Act102ConAsigAsesor : React.FC<IData> = ( act102Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  const DATA_PROCESS = {				
    lstPerfil4:	{ gpo:'dictamen', id:"lstPerfil4", type:EFieldObj.CBO, save:1, label:"Seleccione a una persona para elaborar el proyecto de respuesta", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Seleccionar una persona", list: [] },
        
    mensaje:	{ gpo:'dictamen',   id:"mensaje", type:EFieldObj.AREA,  save:1, label:"Instrucciones adicionales", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
      classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"", varrules:{} }
  }
  
  useEffect( () => {
    if ( act102Props.data === undefined ){
      setPageState(EPageState.ERROR, "No hay datos que mostrar"); 
    } else {      

      TramitesSvc.TraEdoPsnPfl( act102Props.data.tramite.traid, PerfilTypes.FINTECH_SUBDIR, InstIdTypes.CONDUSEF  )
                                  
      .then ((response) => {                              
         if ( response.status !== 200 ) {          
          setPageState(EPageState.ERROR, "¡No fue posible obtener datos, vuelva a intentar!");  
          return;
        }
        let newData = {...dataProcess}
        newData.lstPerfil4.list = createLstPerfil(response.data, PerfilTypes.FINTECH_SUBDIR);
        newData.lstPerfil4.value = "";

        setPageState(EPageState.STEP0, "");
        onChangeAll(newData);
      })
      .catch( (e) => {                
        setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
      });
      setPageState(EPageState.LOAD_DATA, "");  
    }
  }, []); 
  
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL );
  
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();
				
		if (intErrorTotal > 0 ) {
			return;
    }
        
    setPageState(EPageState.STEP1, "");            
    tramiteAction(act102Props,  FormType.ASIGNAR, dataProcess, state, dispatch,  setPageState, {});
  }

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act102Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
		 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
        return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}