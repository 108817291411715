import React from 'react'
import { EFieldDisplay, EFieldObj } from '../../form/fields/field';

// ESTILOS 
interface ITramite { data: any, bucket: string  }

export const TramPrint: React.FC<ITramite> = ( tramiteProps ) => {
  
  const drawBody = () => {        
    let contenido:any = "";

    if ( typeof tramiteProps.data === 'string'){
      contenido = JSON.parse( tramiteProps.data); 
    } if (  tramiteProps.data.hasOwnProperty("datcontenido") ){
      contenido = JSON.parse( tramiteProps.data.datcontenido); 
    } else {
      contenido = tramiteProps.data;
    }
      
    
    const dataKeys = Object.keys(contenido);

    let htmlAdd: any = [];    
    for ( const item of dataKeys) {

      if ( contenido[item].display === EFieldDisplay.SHOW ) {
        if ( contenido[item].type === EFieldObj.CBO ) {
          htmlAdd.push(
            <div key={"renglo_" + item }  className="row"  >
              <div className="col-md-4" >
                <strong>{ contenido[item].label } :</strong>
              </div>      
              <div className="col-md-8" >
                { contenido[item].name }
              </div>      
            </div>);
        } else if ( contenido[item].type === EFieldObj.CHKB ) { 

          let strValue : string ="";
          if ( contenido[item].value) {
            strValue="Seleccionado";
          } else {
            strValue="No seleccionado";
          }
          htmlAdd.push(
            <div key={"renglo_" + item }  className="row"  >
              <div className="col-md-4" >
                <strong>{ contenido[item].label } :</strong>
              </div>      
              <div className="col-md-8" >
                { strValue }
              </div>      
            </div>);
        } else if ( contenido[item].type !== EFieldObj.FILE && contenido[item].type !== EFieldObj.PWD ) { 

          let strValue : string =contenido[item].value;
          if (strValue === undefined){
            strValue="";
          }

          htmlAdd.push(
            <div key={"renglo_" + item } className="row"  >
              <div className="col-md-4" >
              <strong>{ contenido[item].label } :</strong>
              </div>      
              <div className="col-md-8" >
                <div key={"txtrenglo_" + item}  className="fontTexto"  style={{width:"500px", whiteSpace:"nowrap"}}>
                  { contenido[item].value }
                </div>                
              </div>      
            </div>);
        }   
      }
    }
    return <div style={{paddingBottom:"30px"}} >{htmlAdd}</div>;    
  }
  
  return (
    <div key="data-tramite" className="col-md-12" style={{ height:"100%", width:"95%", left:"35px" }}>      
      { drawBody() }
    </div>      
  );
}