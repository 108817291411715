import React, {useContext, useState, Fragment, useEffect } from 'react';
import { AppContext } from '../../../Context';
import './Seguimiento.css'
import { Tree } from 'primereact/tree';
import { Badge } from 'primereact/badge';
import { BlockUI } from 'primereact/blockui';

import { EPageState, usePageState } from '../../../ui/uses/usePageState';
import { LocalDataView } from '../../states/LoadDataView';
import { LocalDataErrorView } from '../../states/LoadDataErrorView';
import TramitesSvc from '../../../services/TramitesSvc';

interface IUserBox {  usrid: number; boxid: number; selectedKey:string}
interface IData { treeStatus:any,  userBox:IUserBox, setUserBox:any, setPageState:any, setListTask:any, setItemSelect:any  }

export const TreeBoxView: React.FC<IData> = (propsTree) => {
  const { state } = useContext(AppContext);
  const [nodes, setNodes] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState({});
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  const [selectedKey, setSelectedKey] = useState(null);
  const [blockedDocument, setBlockedDocument] = useState(false);
    
  function getUserInst( intPnsid:Number ){
    let userNew = {...state.user};

    if ( userNew.users.length > 1) {
      for ( let idx =0;  idx <  userNew.users.length; idx++ )  {      
        if ( userNew.users[idx].psnid === intPnsid ) {
          return userNew.users[idx].insid;
        }
      }
      return 0;
    } else {
      return userNew.insid;
    }
  }
  
  useEffect(() => {    
    let intMax = state.user.users.length;
    let strUsers = "";
  
    for ( let idx =0;  idx < intMax; idx++ )  {      
      strUsers += "," + state.user.users[idx].psnid;
    }
    let inst = getUserInst(Number(strUsers.substring(1)) )

    TramitesSvc.AfdBoxCount(strUsers.substring(1), inst)
    .then ((response) => {                              
      if ( response.status !== 200 ) {          
        setPageState(EPageState.ERROR, "¡No fue posible obtener datos de esta actividad, vuelva a intentar!");  
      }        
      init(response.data);                         
      return;
    })
    .catch( (e) => {                
      setPageState(EPageState.ERROR, "¡No fue posible realizar la consulta, vuelva a intentar!");  
    });

  }, [propsTree.treeStatus]);
  
  const expand = () => {
    let _expandedKeys = {};
    for (let node of nodes) {
      expandNode(node, _expandedKeys);
    }
    setExpandedKeys(_expandedKeys);
    setPageState(EPageState.STEP1, "");
    return null;
  }

  const expandNode = (node:any, _expandedKeys:any) => {
    if (node.children && node.children.length) {
        _expandedKeys[node.key] = true;
        for (let child of node.children) {
            expandNode(child, _expandedKeys);
        }
    }
  }

  const ChangeUserActive = ( intPnsid: number) =>{
    let userNew = {...state.user};

    if ( userNew.users.length > 1) {
      for ( let idx =0;  idx <  userNew.users.length; idx++ )  {      
        if ( userNew.users[idx].psnid === intPnsid ) {
          userNew.users[idx].active = 1;
        } else {
          userNew.users[idx].active = 0;
        }
      }
      userNew.psnid = intPnsid;
    }
  }
  
  const onNodeSelect = (node:any) => {
    propsTree.setItemSelect(-2);
    setBlockedDocument(true);
    let str = node;
    let intIdx = str.indexOf("-");
    let usrActual =0
    let intBoxIdx = 0;
    if ( intIdx === -1){
      usrActual = parseInt(str.substring(1));    
    } else {  
      intBoxIdx = parseInt(str.substring(intIdx+1));
      usrActual = parseInt(str.substring(0, intIdx));      
    }
    
    if (usrActual !==  propsTree.userBox.usrid){      
      ChangeUserActive(usrActual);
    }          
    setSelectedKey(node);
    getData(usrActual, intBoxIdx, str );                       			  
  }
          
  const init = (treeData:any) => {           
    let intMax = state.user.users.length;
    let strUsers = "";
  
    for ( let idx =0;  idx < intMax; idx++ )  {      
      strUsers += "," + state.user.users[idx].psnid;
    }
    
    //if ( treeData === undefined || treeData.length === 0 ) {
    if ( treeData === undefined  ) {
      return null;
    }

    intMax = treeData.length;
    let jsonData:any = {};
    let data: any = [];

    for ( let idx =0;  idx < intMax; idx++ )  {      
      let item = treeData[idx].psnid;

      if ( jsonData[item] === undefined){
        if ( treeData[idx].type === 'OPR' ){
          jsonData[item] = { 'OPR': treeData[idx].count,'NOD':0 }
        } else {
          jsonData[item] = { 'OPR': 0,'NOD': treeData[idx].count}
        }            
      }else {
        if (  treeData[idx].type === 'OPR' ){
          jsonData[item].OPR = treeData[idx].count;
        } else {
          jsonData[item].NOD = treeData[idx].count;
        }
      }
    }              
				      
    if ( state.user.insid  > 0) {
  /// SOLO ADMITEUN USUAIRO NO SE COMPARTE.-
      let key = Object.keys(jsonData);
      let item:any;

      if (key[0] === undefined) {
        item = { "key": "n"+ state.user.psnid, "label": state.user.users[0].psnnombrecomp, 
        "children": [{
          "key":  "0-0",
          "label": "Actividades pendientes",            
          "data": "0"
          },
          {
            "key": "0-1",
            "label": "Trámites en proceso",              
            "data": "0"
          },
          {
            "key": "0-2",
            "label": "Histórico",
            "data": 0
          }
        ]}
      } else {
        item = { "key": "n"+ state.user.psnid, "label": state.user.users[0].psnnombrecomp, 
        "children": [{
          "key":  key[0] + "-0",
          "label": "Actividades pendientes",            
          "data": jsonData[key[0]].NOD
          },
          {
            "key": key[0] + "-1",
            "label": "Trámites en proceso",              
            "data": jsonData[key[0]].OPR
          },
          {
            "key": key[0] + "-2",
            "label": "Histórico",
            "data": 0
          }
        ]}
      }
      data.push(item);   
      
    } else {
      let intNod = 0;
      let intOpr = 0;
      intMax = state.user.users.length;
      for ( let idx =0;  idx < intMax; idx++ )  {      
        let element = state.user.users[idx];

        if ( jsonData[element.psnid] === undefined){
          intNod = 0;
          intOpr = 0;
        } else {
          intNod = jsonData[element.psnid].NOD !== undefined ? jsonData[element.psnid].NOD : 0;
          intOpr = jsonData[element.psnid].OPR !== undefined ? jsonData[element.psnid].OPR : 0;
        }
        /* "icon": 'pi pi-inbox',  "icon": "pi pi-send",  "icon": 'pi pi-flag', */
        let item = { "key": "n"+ element.psnid, "label":element.psnnombrecomp, 
          "children": [{
            "key":  element.psnid + "-0",
            "label": "Actividades pendientes",            
            "data": intNod
            },
            {
              "key": element.psnid + "-1",
              "label": "Trámites en proceso",              
              "data": intOpr
            },
            {
              "key": element.psnid + "-2",
              "label": "Histórico",
              "data": 0
            }
          ]
        };
        data.push(item);   
      }                   
    }
    setNodes( data );        
    setPageState(EPageState.STEP0, "");    
    return null;
  }
    
  const nodeTemplate = (node:any, options:any) => {        
    if ( node.data > 0 ){
      return (
        <span >          
          <b className="p-text-secondary p-overlay-badge">{node.label} </b>
          <Badge value={node.data} size="large" ></Badge>
        </span>
      )
    } else {
      return (
        <span > {node.label} </span>
      )
    }
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			
      case EPageState.LOAD_DATA:
				return <LocalDataView msg="" color={"black"}/>;
        
      case EPageState.ERROR:
				return <LocalDataErrorView msg={pageState.msg} color={"black"}/>
 
			case EPageState.STEP0:
				return  expand();    	

      case EPageState.STEP1:
        return  ( <Tree  value={nodes} selectionMode="single" selectionKeys={selectedKey} 
              expandedKeys={expandedKeys}  
              onSelectionChange={ (e:any) =>onNodeSelect( e.value) }  
              style={{backgroundColor:"whitesmoke"}}
              nodeTemplate={nodeTemplate}
          /> );
              
			default:
				return <LocalDataErrorView msg={pageState.msg} color={"white"}/>
		}
  }

  const getData = (usrActual:number , boxid:number, nodoKey:string) => { 
    if (boxid === 0) {
      propsTree.setListTask([]);
      TramitesSvc.AfdBandejaEntrada(usrActual, getUserInst(usrActual)!)
      .then ((response) => {                      
        if ( response.status !== 200 ) {                 
          setPageState( EPageState.ERROR,  "¡No fue posible leer los datos recibidos !" );
        }            
        propsTree.setUserBox({  usrid:usrActual, boxid:boxid,  selectedKey:nodoKey })           
        propsTree.setListTask(response.data);
        setBlockedDocument(false);
      })
      .catch( (e) => {
        propsTree.setListTask([]);
        setPageState(EPageState.ERROR,  "¡No fue posible conectarse con el servidor, vuelva a intentar!")
        setBlockedDocument(false);
      });
    } else { 
      TramitesSvc.AfdBandejaOper(boxid, usrActual, state.user.insid)    
      .then ((response) => {                      
        if ( response.status !== 200 ) {          
          setPageState( EPageState.ERROR,  "¡No fue posible conectarse con el servidor, vuelva a intentar!")
        }
        propsTree.setUserBox({  usrid:usrActual, boxid:boxid,  selectedKey:nodoKey })           
        propsTree.setListTask(response.data);
        setBlockedDocument(false);
      })
      .catch( (e) => {
        propsTree.setListTask([]);
        setPageState( EPageState.ERROR,  "¡No fue posible conectarse con el servidor, vuelva a intentar!");
        setBlockedDocument(false);
      });
    }      

  }  


  return (
		<Fragment>  
      <BlockUI blocked={blockedDocument} fullScreen />    
      { drawStateCtrl() }      
		</Fragment>
	);
}  