import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
// SERVICIOS
import TramitesSvc from '../../services/TramitesSvc';

// ESTILOS 
interface ITramiteFiles { traid:number, etaid:number, oprid:number, insid:number };
interface IDocTram { etadesc: string, docid :string, adocpath:string, adocname:string, bukdesc:string, docfecha:string };

export const TramFilesOnly: React.FC<ITramiteFiles> = ( tramFilesProps ) => {
  const [filesTram, setFilesTram] = useState<any[]>();

  const file_Click = (filename: string, bucket :string, path:string ) => {
    TramitesSvc.DocumentoGet(filename, bucket, path);    
  }

  useEffect(() => {
    TramitesSvc.TraFilesOnly( tramFilesProps.traid, tramFilesProps.etaid, tramFilesProps.oprid, tramFilesProps.insid)
    .then ((response) => {                              
      if ( response.status !== 200 ) {          
        //GENERAR UN REGISTRO CON ERROR AL MENOS PAR ADIBUJAR UNA FLATA DE DATOS
        setFilesTram([]);
      }
      // AUQI FALTA UN PARSE JSON...???
      let dataDoc : any = {};

      response.data.forEach( (item: IDocTram) => {
        let etapa = item.etadesc;
        if (dataDoc.hasOwnProperty(etapa) ) {
          dataDoc[etapa].push(item);
        } else {
          dataDoc[etapa] = [];
          dataDoc[etapa].push(item);
        }
      });

      setFilesTram(dataDoc);
    })
    .catch( (e) => {                
      setFilesTram([]);
    });        
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Solo se vuelve a ejecutar si count cambia

  const drawFiles = ( ) => {    
    let htmlFileAdd: any = [];    
    let idxFile = 0;

    if (filesTram !== undefined) {      
      let docEtapa = Object.keys(filesTram);
      let objfechas : any = {};
      let htmlBtns: any = []; 

      docEtapa.forEach( (itemKey: any ) => {
        filesTram[itemKey].forEach( ( item:IDocTram) => {
          if ( objfechas[item.docfecha] === undefined) {
            objfechas[item.docfecha] = 1;
          } else {
            objfechas[item.docfecha] = objfechas[item.docfecha]++;
          }
        });
      });

      docEtapa.forEach( (itemKey: any ) => {
        for (var [key] of Object.entries(objfechas)) {
          htmlBtns.push(drawFileContet(idxFile, key, filesTram[itemKey] ));
          idxFile++;
        }
      });

      htmlFileAdd.push(<div  key={"field-step-" + tramFilesProps.oprid}> {htmlBtns} </div>);
    }
    return htmlFileAdd;    
  }

  const drawFileContet = ( idx:number, strFecha : string, dataItems: [] ) => {
  
    let htmlGroupItems: any = [];
    let indx = 1; 
    dataItems.forEach( ( item:IDocTram) => {      
      if ( strFecha === item.docfecha){
        htmlGroupItems.push(
          <Button key={"btnFiles-" + item.docid  + "-" + indx} label={ item.adocname } icon="pi pi-file"  
          className="p-button-outlined p-button-success"
          onClick={ (evt:any) =>  file_Click( item.docid + "_" + item.adocname, item.bukdesc, item.adocpath)}
          style={{ marginRight:"15px", marginTop:"10px", width:"250px", height:"32px", fontSize:"16px" }} />
        );
      }
      indx++;
    });

    let htmlGroup: any = [];
    htmlGroup.push(<div> {htmlGroupItems} </div>);

    return htmlGroup;
  }

  return (
    <div style={{width:"100%", marginTop:"10px", paddingBottom:"30px" }}>      
      { drawFiles() }
    </div>      
  );
}
