import {EFieldTypeRuntime} from "./field"


export const RULE_NO_EMPTY = ( data :  EFieldTypeRuntime) => {
  if ( data.value === null || data.value === '' || data.value === undefined ){
    return  "El dato \"" + data.label + "\" no puede ir vacio";
  } else if ( data.value.length === 0){ 
    return  "El dato \"" + data.label + "\" no puede ir vacio";
  } else {
    return null;
  }
}

export const RULE_MIN_LENGHT = (data :  EFieldTypeRuntime) => {
  const minValue = data.varrules.minvalue;  
  if ( data.value.length < minValue){ 
    return "El dato \"" + data.label + "\" no pueder ser menor de " + minValue + " posiciones";      
  } else {
    return null;
  }
}

export const RULE_NO_CERO = (data:  EFieldTypeRuntime) => {  
  if ( isNaN( data.value) ) {
    return "El dato \"" + data.label + "\" debe ser un número " ;
  }

  if ( parseFloat(data.value) < 1){ 
    return "El dato \"" + data.label + "\" debe ser mayor a cero " ;      
  } else {
    return null;
  }
}

export const RULE_NUMBER_GREATER = (data:  EFieldTypeRuntime) => {
  const minValue = data.varrules.minvalue;
  if ( parseFloat(data.value) < minValue){ 
    return "El dato \"" + data.label + "\" debe ser mayor a " +  minValue ;      
  } else {
    return null;
  }
}

export const RULE_TRUE = ( data: EFieldTypeRuntime) => {
  if ( data.value !== true ){ 
    return "Favor dar clic para aceptar" ;      
  } else {
    return null;
  }
}




/*

var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

export const RULE_EMAIL_VALID = (data) => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if ( re.test(data) ){ 
    return "El correo \"" + data.label + "\" debe ser mayor a " +  minValue ;      
  } else {
    return null;
  }
}
*/