import {Types, ActionMap, IPersonData} from './Model';

/*
  ---------------------------------
    V I E W 
  ---------------------------------

*/
export enum ViewName {
  CALENDAR = "CALENDAR",
  CITA_CONSULTAR = "CITA_CONSULTAR",
  MAIN = "MAIN",  
  MAIN_PROCESOS= "MAIN_PROCESOS",  
  MAIN_VIEWNBOX = "MAIN_VIEWNBOX",
  MAIN_REFRESH_SEG = "MAIN_REFRESH_SEG",  
  REPORT = "REPORT",
  SESSION_LOGIN = "SESSION_LOGIN",
  TRACING = "TRACING",
  TRAMITE_NUEVO = "TRAMITE_NUEVO",  
  TRAMITE_FINTECH = "TRAMITE_FINTECH",
  TRAMITE_FINTECH_SERVPUB = "TRAMITE_FINTECH_SERVPUB",
  TRAMITE_SIABVIDA = "TRAMITE_SIABVIDA",
  TRAMITE_BCD = "TRAMITE_BCD",
  TRAMITE_BCD_XLS = "TRAMITE_BCD_XLS",
  TRAMITE_DEFENSORIA = "TRAMITE_DEFENSORIA"  ,
  TRAMITE_DICTAMEN = "TRAMITE_DICTAMEN",    
  WELCOME = "WELCOME",
  LOGIN_ERROR = "LOGIN_ERROR"
}

export type ViewType = {
  view:   string;
  msg:    string;
  refresh:string;
} 

type ViewModel = {
  [Types.PAGE_SELECT]: {
    view: string;
    msg: string;
    refresh:string;
  };
};

export type ViewActions = ActionMap<ViewModel>[keyof ActionMap<ViewModel>];

export const viewReducer = (state: ViewType, action: ViewActions ) => {
  switch (action.type) {
    case Types.PAGE_SELECT :
      return { ...state,       
          view: action.page.view,
          msg: action.page.msg,
          refresh: action.page.refresh
      };      
    default:
      return state;
  }
};


/*
  ---------------------------------
    V I E W    B U S C A R
  ---------------------------------
*/
export type BuscarType = {
  view:  string;
  msg:  string;
  clave:  string;
  validar:  string;  
}

type BuscarActionType = {
  [Types.PAGE_CITA_BUSCAR]: {
    view: string;
    msg: string;
    clave:string;
    verificar:string;
  };
};

export type BuscarActions = ActionMap<BuscarActionType>[keyof ActionMap<BuscarActionType>];

export const viewBuscarReducer = (state: BuscarType, action: BuscarActions ) => {
  switch (action.type) {
      case Types.PAGE_CITA_BUSCAR:
        return { ...state,       
            view: action.page.view,
            msg: action.page.msg,
            clave: action.page.clave,
            verificar:action.page.verificar
        };
    default:
      return state;
  }
};

/*  ---------------------------------
    D A T O S    D E L   U S U A R I O 
    --------------------------------- */

export type UserType = {
  mainpsnid:   number;
  maininsid:   number;
  psnid:  number;
  insid:  number;
  pflid:  number[];
  users:  IPersonData[];
  session:  string;
  type: number;
  cic: any;
}

type UserActionType = {
  [Types.USER_INIT]: {
    mainpsnid:   number;
    maininsid:   number;
    psnid:  number;
    insid:  number;
    pflid:  number[];
    users:  IPersonData[];
    session:  string;  
    type: number;
    cic: any;
  };
};

export type UserActions = ActionMap<UserActionType>[keyof ActionMap<UserActionType>];

export const UserViewReducer = (state: UserType, action: UserActions ) => {
  switch (action.type) {
      case Types.USER_INIT:
        return { ...state,       
          mainpsnid: action.page.mainpsnid,
          maininsid: action.page.maininsid,
          psnid: action.page.psnid,
          insid: action.page.insid,
          pflid: action.page.pflid,
          users: action.page.users,
          session: action.page.session,
          type: action.page.type,
          cic: action.page.cic
        };
    default:
      return state;
  }
};