import React, { Fragment, useContext, useState} from 'react'
import * as XLSX from 'xlsx';
import { HeaderApp }from '../main/HeaderApp';
import { Button } from 'primereact/button';
import { EPageState, usePageState } from '../../ui/uses/usePageState';
import { AppContext } from '../../Context';
import TableJson from '../../ui/TableJson';
import TramitesSvc from '../../services/TramitesSvc';


export interface IRUTBCD { 
  no:number, fecingsio:string, mesvenc:string, 
  sio:string, rut:string,  bcd:string, 
  usr:string, correo:string, fallecido:string, 
  feccecoban: string, cecoban:string, origen:string,
  fecrecep:string, uau:string, tipo:string, beneficiario:string,
  calle:string, colonia:string, mun: string, entfed:string,cp:string,
  respusr:string, alcance:string, acuses:string, sobre:string, notce:string  
 };


export const BcdXlsx: React.FC = () => {
  const { state } = useContext(AppContext);
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  const [dataXlsx, setDataXlsx] = useState<Array<any>>([]);
  const [dataBcd, setDataBcd] = useState<Array<IRUTBCD>>([]);
  const [respBcd, setRespBcd] = useState({});

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event:any) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);
      //console.log(jsonData)
      setDataXlsx(jsonData)

      // 2024/rut351/5
      const regex : RegExp = /^\d{4}\/rut351\/\d+$/;      
      const newBce : any = [];
    
      jsonData.forEach( (item : any) => {
        if ( regex.test(item["FOLIO RUT"])  ){
          const itemData : IRUTBCD = {  
            no:item["NO."], 
            fecingsio:item["FECHA DE INGRESO AL SIO "], 
            mesvenc:item["MES DE VENCIMIENTO "], 
            rut:item["FOLIO RUT"], 
            sio:item["SIO "], 
            bcd:item["FOLIO BCD "], 
            usr:item["USUARIO "], 
            correo:item["EMAIL "], 
            fallecido:item["FALLECIDO "], 
            feccecoban: item["FECHA INGRESO CECOBAN "], 
            cecoban:item["NO. CECOBAN "],
            origen:item["UNIDAD DE ORIGEN "], 
            fecrecep:item["FECHA DE RECEPCION SOLICITUD "], 
            uau: item["UNIDAD DE ATENCION A USUARIOS "], 
            tipo: item["CORREO ELECTRONICO/PRESENCIAL "], 
            beneficiario: item.BENEFICIARIO,                       
            calle: item.CALLE, 
            colonia: item.COLONIA, 
            mun: item["ALCALDIA O MUN"], 
            entfed: item.ESTADO, 
            cp:item.CP, 
            respusr:item["SE DIO RESPUESTA AL USUARIO "],  
            alcance:item["ALCANCE A RESPUESTA "], 
            acuses:item["ACUSES ENVIADOS "], 
            sobre:item["NOTIFICADOS SOBRE CERRADO "], 
            notce:item["NOTIFICADO C.E "]
          }
          newBce.push(itemData);

        }                  
      });
      setDataBcd(newBce);

    };
    reader.readAsArrayBuffer(file);
  };

  const handleClick = () => {        
    let formData = new FormData();    
    formData.append("BcdData", JSON.stringify(dataBcd) );
    
    TramitesSvc.BcdExcel(formData )
    .then ((response: any) => {  							
      if ( response.status !== 200 ) {          
        return setPageState(EPageState.ERROR, "¡No fue posible enviar el concentrado de BCD !");  
      } else {        
        setRespBcd(response.data);
        return setPageState(EPageState.STEP1, ''); 
      }
    }).catch( (e :any) => {
      return setPageState(EPageState.ERROR, "¡No existe comunicación con el sistema para el envió del concentrado!");  
    })	

    // ENVIAR LOS DATOS AL SERVIDOR
    // VALIDAR LSO CAMPOS - Y EL CONTENIDO DE LOS CMAPOS...
    // SI ESTA MAL LIMITAR
    // SEGUN COM CONTESTE
    // LOS DEMAS DATOS,,, SIO
  };

  const drawBody = () => {        
    return (      
      <div style={{ marginLeft:"20px", position:"relative"}}>      
        <div>
          <h2>Seleccionar concentrado de BCD en formato de MS-EXCEL (XLSX)</h2>          
          <span >
            <input type="file" onChange={handleFileChange} style={{ display:"inline-block"}} />
            <Button  
              style={{ left:"150px", position:"relative",visibility: (dataBcd.length > 0) ? 'visible' : 'hidden' }}
              onClick={handleClick} > 
              Enviar Xlsx al sistema
            </Button>
          </span>
        </div>

        <TableJson data={dataXlsx} ></TableJson> 
      </div>
    );
  }

  const drawStep0 = () => {        
    return (      
      <div>Cuando responde el servidor..</div>
    );
  }

  const drawStep1 = () => {        
    return (      
      <div>
      <h2>Resultado del proceso de los folios</h2>
      <ul>
        {Object.entries(respBcd).map(([clave, valor]) => (
          <li key={clave}>
            <strong>{clave}:</strong> {valor}
          </li>
        ))}
      </ul>
    </div>
    );
  }

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			case EPageState.INIT:											
				return drawBody();
								
      case EPageState.STEP0:
        return drawStep0();

      case EPageState.STEP1:
        return drawStep1();

			case EPageState.ERROR:
				return (
					<div>
						<br/>
						<div className="alert alert-warning"> { pageState.msg } </div>
						<br/>
					</div>
				)				
		}
	}
    
  return (
		<Fragment> 
      <HeaderApp />
      <div style={{ background:"white", width: window.outerWidth - 15, height: window.outerHeight - 150}}>
        <div className="row" style={{width:"100%", paddingTop:"6px"}}>
          {  drawStateCtrl() }
        </div>
      </div>
		</Fragment>
	);
}