import React from 'react'
import { FormType, TramitesType } from '../../reducers/Model';

// SERVICIOS
import { TramPrint } from './TramPrint';

// ESTILOS 
interface ITramite { data: any, bucket: string  }

export const TramFinQueja: React.FC<ITramite> = ( tramProp ) => {

  const drawBody = (contenido : any) => {
    let nombre; 
    let Titnombre; 

    if ( contenido.psntipo.value === 1) {
      nombre = contenido.solnombre.value;
      Titnombre = contenido.solnombre.label;
    } else {
      nombre = contenido.solempresa.value;
      Titnombre= contenido.solempresa.label;
    }

    return (               
      <div className="col-md-12" style={{ width:"95%", left:"35px" }}>
        <div key="renglo0"  style={{ marginTop:"10px", marginBottom:"10px"}} >
          <div className="col-md-12" > <strong> DATOS ENVIADOS </strong></div>      
        </div>

        <div key="renglo1" className="row"  >
          <div className="col-md-4" >
            { contenido.psntipo.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.psntipo.name }
          </div>      
        </div>

        <div key="renglo2" className="row"  >
          <div className="col-md-4" >
            { Titnombre } :
          </div>      
          <div className="col-md-8" >
            { nombre }
          </div>      
        </div>

        <div key="renglo3" className="row"  >
          <div className="col-md-4" >
            { contenido.solcorreo.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solcorreo.value }
          </div>      
        </div>

        <div key="renglo4" className="row"  >
          <div className="col-md-4" >
            { contenido.soltelfijo.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.soltelfijo.value }
          </div>      
        </div>

        <div key="renglo5" className="row"  >
          <div className="col-md-4" >
            { contenido.soltelmovil.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.soltelmovil.value }
          </div>      
        </div>

        <div key="renglo6" className="row"  >
          <div className="col-md-4" >
            { contenido.solidentificacion.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solidentificacion.name }
          </div>      
        </div>

        <div key="renglo7" className="row"  >
          <div className="col-md-4" >
            { contenido.solnumid.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solnumid.value }
          </div>      
        </div>                              

        <div key="renglo8" className="row"  >
          <div className="col-md-4" >
            { contenido.solcodpos.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solcodpos.value }
          </div>      
        </div>        
        <div key="renglo9" className="row"  >
          <div className="col-md-4" >
            { contenido.solentfed.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solentfed.name }
          </div>      
        </div>        
        <div key="renglo10" className="row"  >
          <div className="col-md-4" >
            { contenido.solmunicipio.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solmunicipio.name }
          </div>      
        </div>        
        <div key="renglo11" className="row"  >
          <div className="col-md-4" >
            { contenido.solcolonia.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.solcolonia.name }
          </div>      
        </div>                                      

        <div key="renglo12" className="row"  >
          <div className="col-md-4" >
            { contenido.sectoritf.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.sectoritf.name }
          </div>      
        </div>              
        <div key="renglo13" className="row"  >
          <div className="col-md-4" >
            { contenido.institucion.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.institucion.name }
          </div>      
        </div>              
        <div key="renglo14" className="row"  >
          <div className="col-md-4" >
            { contenido.servicio.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.servicio.name }
          </div>      
        </div>              
        <div key="renglo15" className="row"  >
          <div className="col-md-4" >
            { contenido.contrato.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.contrato.name }
          </div>      
        </div>              
        <div key="renglo16" className="row"  >
          <div className="col-md-4" >
            { contenido.causa.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.causa.name }
          </div>      
        </div>
        <div key="renglo17" className="row"  >
          <div className="col-md-4" >
            { contenido.monto.label } :
          </div>      
          <div className="col-md-8" >
            { contenido.monto.value }
          </div>      
        </div>
      </div>
    );
  }
  
  const drawTypeForm = () => {

    if ( (tramProp.data.hasOwnProperty("tramid") || tramProp.data.hasOwnProperty("traid"))
      && tramProp.data.tramite.traid === TramitesType.FINTECH 
      && tramProp.data.tdatid === FormType.SOLICITUD ) { 

      let Contenido = JSON.parse(tramProp.data.datcontenido);     
      return drawBody(Contenido);
    } else {
      return <TramPrint data={tramProp.data} bucket={tramProp.bucket} />
    }    

  }
  return drawTypeForm();
}