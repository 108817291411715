import React, { Fragment, useEffect, useState} from 'react'

import { HeaderApp }from '../main/HeaderApp';
import { EPageState, usePageState } from '../../ui/uses/usePageState';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import TramitesSvc from '../../services/TramitesSvc';
import { TraceGestion } from './TraceGestion';
import { TRACE_ETAPA, TraceConciliarType, TraceDictamenType, TraceGestionType } from '../../reducers/Model';
import { TraceConciliar } from './TraceConciliar';
import { getFirstDayOfMonth, getLastDayOfMonth } from '../../util/dateUtil';
import { TraceDictamen } from './TraceDictamen';


type TotalsType = {
  gestion:number;
  conciliar: number;
  dictamen: number;
  procedencia: number;
  defensoria: number;  
}

export const Tracing: React.FC = () => {
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  const [prc, setPrc] = useState({ name: 'FINTECH', code: 1 });
  const [status, setStatus] = useState({ name: 'EN PROCESO', code: 1 });
  const [phase, setPhase] = useState({ name: 'GESTIÓN ELECTRÓNICA', code: 1 });
  const [dateBegin, setDateBegin] = useState<Date[]>();
  const [dateEnd, setDateEnd] = useState<Date[]>();
  const [folio, setFolio ] = useState("");
  const [search, setSearch ] = useState(0);
  const [dataGestion, setDataGestion ] =  useState<TraceGestionType[]|undefined>([]); 
  const [dataConciliar, setDataConciliar ] =  useState<TraceConciliarType[]|undefined>([]); 
  const [dataDictamen, setDataDictamen ] =  useState<TraceDictamenType[]|undefined>([]); 

  const [totals, setTotals] = useState<TotalsType>({ "gestion": 0, "conciliar": 0, "dictamen":0, "procedencia":0, "defensoria":0 });

  const ptrSetSearch = (id:number) => {
    setSearch(id);
  }

/*  
  const process = [
    { name: 'FINTECH', code: 1 },
    { name: 'BCD', code: 2 },
    { name: 'SIAB_VIDA', code: 3 }
  ];
*/
  const process = [
    { name: 'FINTECH', code: 1 }
  ];

  const CAT_STATUS = [
    { name: 'TODO', code: 0 },
    { name: 'EN PROCESO', code: 1 },
    { name: 'TERMINADA', code: 2 }
  ];  



  function LimpiarOnClick() {
    setDateBegin(undefined);
    setDateEnd(undefined);
    setFolio("");
    setStatus(CAT_STATUS[0]);
  }

  function dateBeginOnClick(setFunc:any, value:any) {
    setFunc(value);
  }

/*  
  useEffect(() => {
    // inicalizar el año 
    const date = new Date();
    dateBeginOnClick(setDateBegin, getFirstDayOfMonth( date.getFullYear(),date.getMonth() ));
    dateBeginOnClick(setDateEnd, getLastDayOfMonth( date.getFullYear(),date.getMonth() ));
  }, []);
*/

	useEffect(() => {
    let formData = new FormData();    
    formData.append("TraceSearch", JSON.stringify({ process: prc.code, phase: phase.code, status: status.code, datebegin: dateBegin, dateend:dateEnd, folio:folio }) );

		TramitesSvc.TraTrace(formData )
		  .then ((response: any) => {  							

        if (phase.code === 1){
          setDataGestion(response.data.select);
        } else if (phase.code === 2){
          setDataConciliar(response.data.select);
        } else {
          setDataDictamen(response.data.select);
        }
        
        let auxTotals : TotalsType = { gestion: 0, conciliar: 0, dictamen:0, procedencia:0, defensoria:0 };        
        let i = 0;
        while (i < response.data.totals.length) {
            let idx: number = response.data.totals[i].phase
            switch (idx) {
              case 1:
                auxTotals.gestion = response.data.totals[i].total
                break;
              case 2: 
                auxTotals.conciliar = response.data.totals[i].total
                break;
              case 3: 
                auxTotals.dictamen = response.data.totals[i].total
                break;
              case 4: 
                auxTotals.procedencia = response.data.totals[i].total
                break;
              case 5: 
                auxTotals.defensoria = response.data.totals[i].total
                break;                                                
            }
            i++;
        }
        setTotals(auxTotals);
				setPageState(EPageState.STEP1,"");
		  }).catch( (e:any) => {
  		  setPageState(EPageState.STEP1,"No fue posible consultar los catálogos de dirección");
		  })	
	}, [search]);

  const drawScores = () => {
    return (
      <div > 
        <table style={{ position:"relative", top:"10px", left:"6%",  width:"85%"}} >
          <tbody>
            <tr> 
              <td  colSpan={9} style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#98989A", color:"white"}} >TABLERO FINTECH</td>
            </tr>
            <tr> 
              <td  colSpan={9} style={{height:"10px", background:"white"}} ></td>
            </tr>
            <tr> 
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#235B4E", color:"white"}} >GESTIÓN ELECTRÓNICA</td>
              <td style={{ width:"2%"}}></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#235B4E", color:"white"}}>PROCESO DE CONCILIACIÓN</td>
              <td style={{ width:"2%"}}></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#235B4E", color:"white"}}>PROCESO DE DICTAMEN</td>
              <td style={{ width:"2%"}}></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#235B4E", color:"white"}}>PROCEDENCIA DEFENSORÍA LEGAL</td>
              <td style={{ width:"2%"}}></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", background:"#235B4E", color:"white"}}>DEFENSORÍA LEGAL</td>
            </tr>

            <tr>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", fontWeight:"bold"}}>{ totals.gestion }</td>
              <td></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", fontWeight:"bold"}}>{ totals.conciliar }</td>
              <td></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", fontWeight:"bold"}}>{ totals.dictamen }</td>
              <td></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", fontWeight:"bold"}}>{ totals.procedencia }</td>
              <td></td>
              <td style={{border:"1px solid black", width:"19%", textAlign:"center", fontWeight:"bold"}}>{ totals.defensoria }</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const drawConsulta = () => {
    if ( phase.code === 1 ){
      return (<TraceGestion data={ dataGestion!} ptrSearch={ptrSetSearch}  />)
    } else if ( phase.code === 2 ){
      return (<TraceConciliar data={ dataConciliar!} ptrSearch={ptrSetSearch}  />);
    } else {
      return (<TraceDictamen data={ dataDictamen!} ptrSearch={ptrSetSearch}  />);      
    }
  };
    
  return (    
		<Fragment> 
      <HeaderApp />
      <div style={{ background:"white", width: window.outerWidth - 35}}>
        <div className="row" style={{width:"90%", paddingTop:"6px"}}>
          <div className="col-md-5" style={{textAlign: "right", fontWeight:"bold", marginTop:"5px"}}> PROCESO :</div>
          <div className="col-md-7">
            <Dropdown
              value={prc}
              onChange={(e) => setPrc(e.value)}
              options={process}
              optionLabel="name"
              placeholder="Seleccionar un proceso"
              style={{width:"400px"}}
            />
          </div>
        </div>

        <div className="row" style={{width:"90%", paddingTop:"6px"}}>
          <div className="col-md-5" style={{textAlign: "right", fontWeight:"bold", marginTop:"5px"}}> ETAPA :</div>
          <div className="col-md-7">
            <Dropdown
                value={phase}
                onChange={(e) =>  setPhase(e.value) }
                options={TRACE_ETAPA}
                optionLabel="name"
                placeholder="Seleccionar un proceso"
                style={{width:"400px"}}                
              />
          </div>
        </div>

        <div className="row" style={{ width:"90%", paddingTop:"6px" }}>
          <div className="col-md-5" style={{textAlign: "right", fontWeight:"bold", marginTop:"5px"}}> STATUS :</div>
          <div className="col-md-7">
            <Dropdown
              value={status}
              onChange={(e) => setStatus(e.value)}
              options={CAT_STATUS}
              optionLabel="name"
              placeholder="Seleccionar una etapa"
              style={{width:"400px"}}                
            />
          </div>
        </div>

        <div className="row" style={{ width:"90%", paddingTop:"6px"  }}>
          <div className="col-md-5" style={{textAlign: "right", fontWeight:"bold", marginTop:"5px"}}> FECHA DE RECEPCIÓN ENTRE :</div>
          <div className="col-md-7"> 
            <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateBegin}
              onChange={(e) => dateBeginOnClick(setDateBegin, e.value)}
              showIcon            
            />
            <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateEnd}
              onChange={(e) => dateBeginOnClick(setDateEnd, e.value)}
              showIcon
            />
          </div>
        </div>

        <div className="row" style={{ width:"90%", paddingTop:"6px" }}>
          <div className="col-md-5" style={{textAlign: "right", fontWeight:"bold", marginTop:"5px"}}> NO. DE FOLIO :</div>
          <div className="col-md-7">
            <InputText
              value={folio}
              onChange={(e) => setFolio(e.target.value)}
            />
          </div>
        </div>

        <div className="row" style={{ position:"relative", top:"40px" }}>
            <div className="col-md-5"></div>
            <div className="col-md-1">
              <Button label="Limpiar"  style={{ width:"150px"}} onClick={ (e) => LimpiarOnClick( )}/>
            </div>
            <div className="col-md-6" style={{ position:"relative"}}> 
              <Button label="Buscar"  style={{ width:"150px"}} onClick={ (e) => setSearch( search+1)}/>              
            </div>
          </div>

        <br/>
        <br/>
        {
          drawScores()
        }
        
        {             
          drawConsulta()
        }
      </div>
		</Fragment>
	);
}