import React, { useState } from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFinQueja } from '../../TramFinQueja';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import '../Node.css';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N403UsrNotiSimple: React.FC<IData> = (props403) => {
  const [displayQueja, setDisplayQueja] = useState(false);

  const onClick = () => {
    setDisplayQueja(true);
  }

  const onHide = () => {
    setDisplayQueja(false);
  }

  const drawBody = () => {
    try {
      if (props403.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props403.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props403.init.aristas;          
        let changeableData = contenido.accion.data;      

        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props403.init.operacion.etaid} action={accion} oprid={props403.action.oprid} nodori={props403.action.nodid} setListTask={props403.setListTask} 
              edoori={props403.action.edoid }  bucket={props403.init.operacion.bucket}  oprpath={props403.init.operacion.oprpath}
              tramite={props403.init.tramite}></NodoResponder>            
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props403.action.nodfecha} estado={props403.action.edodesc} titulo={""}  folio={props403.action.oprfolio} insid={origen.insid}></NodoHeader>    
            
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div> 
                <span>
                  <Button  className="p-button-success" label="Visualizar queja" icon="pi pi-external-link" onClick={() => onClick()} style={{ marginRight:"10px"}}/>                  
                </span>                
                <div style={{ marginBottom:"10px", marginTop:"10px" }}> 
                  <textarea rows={8} style={{ width:"100%", resize:"none", border:"1px solid white" }} readOnly={true} value={changeableData.value } > </textarea>
                </div>
              </div>            
              
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return (
    <div>
      { drawBody() }
      <Dialog header="DATOS DE LA QUEJA" visible={displayQueja} style={{ width: '50vw' }}  onHide={() => onHide()}>
        <div style={{ fontSize:"18px"}}>
          <TramFinQueja data={props403.init.tramite} bucket={props403.action.bukdesc}></TramFinQueja>
        </div>
      </Dialog>
    </div>
  )
}