import React, { useRef, useState, Fragment } from 'react';
import './Login.css';

import imageBG from '../../images/condusef.png';

import {ProgressBar} from 'primereact/progressbar';
import { HeaderGobMx } from '../main/HeaderGobMx';
import { FooterGobMx } from '../main/FooterGobMx';

export const Welcome = () => {  

  return (  
    <Fragment>
      <HeaderGobMx />     
      <br/>
      <br/>      
      <div className="text-center" style={{ marginTop:"80px", position:"relative"}}>    
        <div className="container">
          <div className="row">
              <img src={imageBG} width="247px" height="292px" alt="Logo" />      
          </div>
          <div style={{ marginTop:"20px" }}>
					  <ProgressBar mode="indeterminate" />
				</div>          
        </div>
      </div>
      <br/>
      <br/>
    <FooterGobMx />
  </Fragment>
  );    
}
