import React from 'react';

//interface IData { data: [] }
//export const TableJson : React.FC<IData> = (props) => {
export const TableJson  = (data) => {
  
  function drawTable( param ){

    let wWidth = window.screen.width;
    let wHeight = window.screen.height - 450;

    const cssTH = {
      backgroundColor: 'gray', // Color de fondo gris
      color: 'white',          // Color del texto
      textAlign: 'center',     // Alineación del texto
    };


    if (param === undefined)
          return null;
    else if(  param.data.length === 0 )
      return null;
    else 
      return(
        <div style={{ marginTop:"30px", height:wHeight+"px", maxWidth:wWidth +"px", 
          oveflowY:"scroll", overflowX:"scroll", fontSize:"1", fontFamily:"Times New Roman" }}>
          <table style={{ borderWidth:"1px", borderColor:"black", borderStyle:"solid" }}>
          <thead >
            <tr>
              <th style={cssTH} >NO.</th>              
              <th style={cssTH}>FECHA DE INGRESO AL SIO</th>
              <th style={cssTH}>MES DE VENCIMIENTO</th>              
              <th style={cssTH}>SIO</th>
              <th style={cssTH}>FOLIO RUT</th>
              <th style={cssTH}>FOLIO BCD</th>
              <th style={cssTH}>USUARIO</th>
              <th style={cssTH}>EMAIL</th>
              <th style={cssTH}>FALLECIDO</th>

              <th style={cssTH}>FECHA INGRESO CECOBAN</th>
              <th style={cssTH}>NO. CECOBAN</th>
              <th style={cssTH}>UNIDAD DE ORIGEN</th>
              <th style={cssTH}>FECHA DE RECEPCION SOLICITUD</th>
              <th style={cssTH}>UNIDAD DE ATENCION A USUARIOS</th>
              <th style={cssTH}>CORREO ELECTRONICO/PRESENCIAL</th>
              <th style={cssTH}>BENEFICIARIO</th>
              <th style={cssTH}>DIRECCION</th>
              <th style={cssTH}>CALLE</th>
              <th style={cssTH}>COLONIA</th>
              <th style={cssTH}>ALCALDIA O MUN</th>
              <th style={cssTH}>ESTADO</th>
  
              <th style={cssTH}>CP</th>
              <th style={cssTH}>SE DIO RESPUESTA AL USUARIO</th>
              <th style={cssTH}>ALCANCE A RESPUESTA</th>
              <th style={cssTH}>ACUSES ENVIADOS</th>
              <th style={cssTH}>NOTIFICADOS SOBRE CERRADO</th>
              <th style={cssTH}>NOTIFICADO C.E</th>
            </tr>
          </thead>
          <tbody>
            { 
            param.data.map((item, index) => (
              <tr key={index}>
                <td>{item["NO."]}</td>                
                <td>{item["FECHA DE INGRESO AL SIO "]}</td>
                <td>{item["MES DE VENCIMIENTO "]}</td>                
                <td>{item["SIO "]}</td>
                
                <td>{item["FOLIO RUT"]}</td>
                <td>{item["FOLIO BCD "]}</td>
                <td>{item["USUARIO "]}</td>
                <td>{item["EMAIL "]}</td>

                <td>{item["FALLECIDO "]}</td>
                <td>{item["FECHA INGRESO CECOBAN "]}</td>
                <td>{item["NO. CECOBAN "]}</td>
                <td>{item["UNIDAD DE ORIGEN "]}</td>
                <td>{item["FECHA DE RECEPCION SOLICITUD "]}</td>
                <td>{item["UNIDAD DE ATENCION A USUARIOS "]}</td>
      
                <td>{item["CORREO ELECTRONICO/PRESENCIAL "]}</td>
                <td>{item["BENEFICIARIO"]}</td>
                <td>{item["DIRECCION"]}</td>
                <td>{item["CALLE"]}</td>
                <td>{item["COLONIA"]}</td>
                <td>{item["ALCALDIA O MUN"]}</td>
      
                <td>{item["ESTADO"]}</td>
                <td>{item["CP"]}</td>
                <td>{item["SE DIO RESPUESTA AL USUARIO "]}</td>
                <td>{item["ALCANCE A RESPUESTA "]}</td>
                <td>{item["ACUSES ENVIADOS "]}</td>
                <td>{item["NOTIFICADOS SOBRE CERRADO "]}</td>    
                <td>{item["NOTIFICADO C.E "]}</td>    
              </tr>
            ))}
          </tbody>
          </table>
        </div>

    )
  }

  return (
    drawTable(data) 
  );
};

export default TableJson;