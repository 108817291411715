import * as React from "react";
import { AppProvider } from "./Context";
import { ViewCtrl } from "./view/main/ViewCtrl";


import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
//import 'primeflex/primeflex.css';


export default function App() {
  return (
    <AppProvider>
      <ViewCtrl/>
    </AppProvider>
  );
}
