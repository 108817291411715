import React, { useContext, useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { CITA_HORAS, FormType, IActionProps} from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';

import {  tramiteAction } from '../ActUtil';
import { csscol } from '../../../../util/cssUtil';

interface IData { onhide: any, data : IActionProps  }

export const Act130ConNvaAud : React.FC<IData> = ( act130Props ) => {  
  const {state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  useEffect(() => {    
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // BUCAR LAS HORAS PARA QUITARLAS DEL COMBO .....
    // EL NUERMO DE FOLIO DE LA OPERAION...
  }, []); 

  const solHorasOnChange = (evt: any) => {				
    let newData = {...dataProcess}
    newData.audienciahora.value = evt.target.value
    onChangeAll(newData);
  }

  const tramdata = JSON.parse(act130Props.data.tramite.datcontenido);

  const DATA_PROCESS = {				

		tramfolio: { gpo:"conciliar",	id:"tramfolio",  type:EFieldObj.TXT, save:1,  label:"No. de Folio",  oper:EFieldOper.READ, value:tramdata.tramfolio.value, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			

    tdatid: { gpo:"conciliar",	id:"tdatid",  type:EFieldObj.PINT, save:1,  label:"Tipo de documento",  oper:EFieldOper.READ, value: FormType.CITATORIO, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },

		audienciafecha:  { gpo:"conciliar", id:"audienciafecha", type:EFieldObj.DATE, save:1,   label:"Fecha de la audiencia", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
      actionevent:"134,135,141", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, classNameGroup:csscol.gpo12, placeholder:"Fecha propuesta para  audiencia" },
    
    audienciahora: { gpo:'bcd',  id:"audienciahora",  type:EFieldObj.CBO, save:1,   label:"Seleccione la hora de la audiencia ( ¡LA HORA ES DE LA CIUDAD DE MÉXICO!)",   oper:EFieldOper.UPDATE, value: "", list: CITA_HORAS, rules:[ RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,
      actionevent:"134,135,141", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, classNameGroup:csscol.gpo12, placeholder:"seleccionar", onChange: solHorasOnChange },

    audiencia: { gpo:"fintech",	id:"audiencia",  type:EFieldObj.TXT, save:1,  label:"Dirección electrónica audiencia (videoconferencia)",  oper:EFieldOper.READ, value:"https://conferenciajuridica.condusef.gob.mx/" + tramdata.tramfolio.value, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW,  classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, classNameGroup:csscol.gpo12,  placeholder:"" },

    numoficio:	{ gpo:"fintech",	id:"numoficio",    type:EFieldObj.TXT, save:1,  label:"Número de oficio",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, style:{width:"200px"},
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5,  classNameGroup:csscol.gpo12, placeholder:"Número de oficio" },
            
    docacuerdo:	{ gpo:'conciliar', id:"docacuerdo", type:EFieldObj.FILE, save:1, label:"Agregar acuerdo", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
      actionevent:"135", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5,  classNameGroup:csscol.gpo12, placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.ACUERDO },
        
    doctraslado:	{ gpo:'conciliar', id:"doctraslado", type:EFieldObj.FILE, save:1, label:"Agregar oficio de traslado a la Institución Financiera", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
      actionevent:"135", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5,  classNameGroup:csscol.gpo12, placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.TRASLADO },

    doccitatorio:	{ gpo:'conciliar', id:"doccitatorio", type:EFieldObj.FILE, save:1, label:"Agregar citatorio para usuario", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
      actionevent:"141", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5,  classNameGroup:csscol.gpo12, placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.CITATORIO },

    titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Ingrese sus archivos para firmar electrónicamente",  display:EFieldDisplay.SHOW },
    dockey:	{ gpo:'conciliar',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      actionevent:"134", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, classNameGroup:csscol.gpo12, placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
  
    doccert:	{ gpo:'conciliar',  id:"doccert",  type:EFieldObj.FILE,    label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      actionevent:"134", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, save:1, classNameGroup:csscol.gpo12, placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'conciliar',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW ,
      actionevent:"134", classNameLabel:csscol.gpo7, classNameObj:csscol.gpo5, classNameGroup:csscol.gpo12, placeholder:"Ingrese su contraseña"}
  }

  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )
    
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }

    setPageState(EPageState.STEP1, "");      
    tramiteAction(act130Props,  FormType.CITATORIO, dataProcess, state, dispatch,  setPageState, {});  
  }
    
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act130Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
		
      case EPageState.INIT:			      
				return <ProgressBar mode="indeterminate" />;
 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}
