import React, { Fragment, useContext, useState, useEffect } from 'react'
import { AppContext } from '../../../../Context';
import { DisplayProp, EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY, RULE_NUMBER_GREATER, RULE_TRUE } from '../../../../form/fields/fieldRules';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { FormType, EtapaType, IAccion, INodoOrigen, InstIdTypes, IOperacion, IPersonCiC, IPersonDirCIC, RUT_REDIRECT,  TramitesType, Types, SOLICITANTE_BCD_TYPE, PARENTESCO_BCD_TYPE, NACIONALIDADES, AUSENCIA_TYPE, SEXO_TYPE, SolTypes, FieldAssingType, PODER_TYPE, UBICACION_TYPE, CAT_PAIS } from '../../../../reducers/Model';
import { ViewName } from '../../../../reducers/ViewReducer';
import TramitesSvc from '../../../../services/TramitesSvc';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';
import { userFindActive } from '../UsersUtil';
import { HeaderApp } from '../../../main/HeaderApp';
import { ProgressBar } from 'primereact/progressbar';

import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';

import imageIne from '../../../../images/credencial-ine.jpg';
import imageDefuncion from '../../../../images/FormatActaDefun.png';
import imageCartaPoder from '../../../../images/cartapoder.png';
import imageActaNac from '../../../../images/ActaNac.jpg';

import { validateFilesSize } from '../../../../util/fileUtil';

import '../Action.css';
import { Accion, NodoEstado } from '../../AfdUtil';
import { fecNacimiento } from '../../../../util/curpUtil';
import { dateGreaterThan, stringToDate } from '../../../../util/dateUtil';
import { csscol } from '../../../../util/cssUtil';

interface ICatalogos { 
	[key: string]: any;		
}

export const Act201UsrBcd: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);	
	const [ pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
	const [ queja, setQueja] = useState<ICatalogos>({});

	// REUIERO DEFINR ILAS OCNDICIONES DEL OBJETO		
	// SolTypes.BENEFICIARIO = 1,
	// SolTypes.REPRESENTANTE= 2,
	// SolTypes.ALBACEA = 3,
	// SolTypes.PADRES = 4,
	// SolTypes.TUTOR = 5,	
	let solTipo_Assing_A : FieldAssingType[] = [	
		{source:"psnnombre", target:"solnombre", 	topr:EFieldOper.READ },
		{source:"psnapepat" ,   target:"solpaterno", topr:EFieldOper.READ },
		{source:"psnapemat" ,   target:"solmaterno", topr:EFieldOper.READ },
		{source:"psnfecha", target:"solfecnac", 	topr:EFieldOper.READ },
		{source:"psncurp" ,  target:"solcurp", 		topr:EFieldOper.READ },

		{source:"", target:"repnombre", 	topr:EFieldOper.UPDATE },
		{source:"",target:"reppat", 		topr:EFieldOper.UPDATE },
		{source:"",target:"repmat", 		topr:EFieldOper.UPDATE },
		{source:"", target:"repfecnac", 	topr:EFieldOper.UPDATE },
		{source:"" ,  target:"repcurp", 		topr:EFieldOper.UPDATE }
	];

	let solTipo_Assing_B : FieldAssingType[]= [	
		{source:"psnnombre", target:"repnombre", 	topr:EFieldOper.READ },
		{source:"psnapepat",target:"reppat", 		topr:EFieldOper.READ },
		{source:"psnapemat",target:"repmat", 		topr:EFieldOper.READ },
		{source:"psnfecha", target:"repfecnac", 	topr:EFieldOper.READ },
		{source:"psncurp" ,  target:"repcurp", 		topr:EFieldOper.READ },

		{source:"",	target:"solnombre", 	topr:EFieldOper.UPDATE },
		{source:"",	target:"solpaterno", topr:EFieldOper.UPDATE },
		{source:"",	target:"solmaterno", topr:EFieldOper.UPDATE },
		{source:"",	target:"solfecnac", 	topr:EFieldOper.UPDATE },
		{source:"",	target:"solcurp", 		topr:EFieldOper.UPDATE }
	];
							
	let solTipo_rules: any = {
		"1": { dsGpo: [ { display:EFieldDisplay.NO_SHOW, target:[ "representante", "idenrepresentante",  "albacea","padres", "actnac", "tutor","carta","notarial","poder", "parentesco"] } 
					], dsAssing: solTipo_Assing_A },
		"2": { dsGpo: [ 
					{ display:EFieldDisplay.NO_SHOW, target:[ "albacea", "padres", "actnac", "tutor",] }, 
					{ display:EFieldDisplay.SHOW, target:[ "representante","poder","idenrepresentante","parentesco" ] }
				], dsAssing: solTipo_Assing_B },
		"3": { dsGpo: [ 
					{ display:EFieldDisplay.NO_SHOW, target:[ "padres", "actnac", "tutor","carta","notarial","poder"] }, 
					{ display:EFieldDisplay.SHOW, target:[ "representante", "albacea", "idenrepresentante","parentesco"] }
				], dsAssing: solTipo_Assing_B },
		"4": { dsGpo: [ 
					{ display:EFieldDisplay.NO_SHOW, target:[  "albacea", "tutor","carta","notarial","poder","idenrepresentante"] }, 
					{ display:EFieldDisplay.SHOW, target:[ "representante", "padres", "actnac","parentesco"] }
				], dsAssing: solTipo_Assing_B },
		"5": { dsGpo: [ 
					{ display:EFieldDisplay.NO_SHOW, target:[  "albacea", "padres","carta","notarial","poder","idenrepresentante"] }, 
					{ display:EFieldDisplay.SHOW, target:[ "representante", "tutor", "actnac","parentesco"] }
				], dsAssing: solTipo_Assing_B }
	};
		
	const drawSolicitante = (newData : any, dataPsn:IPersonCiC, dataPsnDir:IPersonDirCIC ) => {

		newData.solcurp.value = dataPsn.psncurp;
		newData.solcurp.oper = EFieldOper.READ;
		newData.solcurp.error = [];

		newData.solfecnac.value = fecNacimiento(dataPsn.psncurp);
		newData.solfecnac.oper = EFieldOper.READ;
		newData.solfecnac.error = [];

		newData.solnombre.value = dataPsn.psnnombre;
		newData.solnombre.oper = EFieldOper.READ;
		newData.solnombre.error = [];

		newData.solpaterno.value = dataPsn.psnapepat;
		newData.solpaterno.error = [];

		newData.solmaterno.value = dataPsn.psnapemat;
		newData.solmaterno.oper = EFieldOper.READ;
		newData.solmaterno.error = [];

		newData.solcorreo.value = dataPsn.psncorreo;
		newData.solcorreo.oper = EFieldOper.READ;
		newData.solcorreo.error = [];

		newData.solcodpos.value = dataPsn.psncp;
		newData.solcodpos.oper = EFieldOper.READ;
		newData.solcodpos.error = [];

		newData.solentfed.list = [{ label: dataPsnDir.estado , value: dataPsnDir.id_edo }];
		newData.solentfed.value = dataPsnDir.id_edo;
		newData.solentfed.error = [];

		newData.solmunicipio.list = [{ label: dataPsnDir.municipio , value: dataPsnDir.id_mun }];
		newData.solmunicipio.value = dataPsnDir.id_mun;
		newData.solmunicipio.error = [];

		newData.solcolonia.list = [{ label: dataPsnDir.colonia , value: dataPsnDir.id_col }];
		newData.solcolonia.value = dataPsnDir.id_col;
		newData.solcolonia.error = [];

		newData.solcalle.value = dataPsn.psncalle;

		if (dataPsn.psnnumext === null ){
			newData.solnumext.value = '';
		} else {
			newData.solnumext.value = dataPsn.psnnumext;
		}
		newData.solnumext.oper = EFieldOper.READ;

		if (dataPsn.psnnumint === 'null' ){
			newData.solnumint.value = '';
		} else {
			newData.solnumint.value = dataPsn.psnnumint;
		}
		newData.solnumint.oper = EFieldOper.READ;
	}

	useEffect(() => {
		if ( Object.keys(state.user.cic ).length > 0 ) {
			TramitesSvc.TraInitBcd(  NodoEstado.BCD_REVISAR, InstIdTypes.CONDUSEF, state.user.psnid)
			.then ((response: any) => {  							
				let newData = {...dataProcess};

				//definir la fecha de nacimiento				
				let dateMax = stringToDate(response.data.fecha );
				let yearAct = dateMax.getFullYear().toString() ;

				newData.repfecnac.maxDate = dateMax;
				newData.repfecnac.yearRange = "1920:" + dateMax.getFullYear().toString();

				newData.invfecfallec.maxDate = dateMax;
				newData.invfecfallec.yearRange = "1920:" + dateMax.getFullYear().toString();
				
				dateMax = new Date(dateMax.getFullYear() - 18, dateMax.getMonth(), dateMax.getDate());
				newData.invfecnac.maxDate = dateMax;				
				newData.invfecnac.yearRange = "1920:" + dateMax.getFullYear().toString();
											
				//Fehca de fallecimiento
				let dateMin = stringToDate(response.data.fecha );
				dateMin = new Date(dateMin.getFullYear() - 5, 0, 1);
				newData.invfecfallec.minDate = dateMin;
				newData.invfecfallec.yearRange = dateMin.getFullYear().toString()  + ":" + yearAct;
																		
				let entfedmx = JSON.parse(response.data.entfedmx);

				entfedmx.forEach((item : any) => { 
					newData.invlugfallec.list.push( { label:item.descripcion, value:item.id } )
				});

				newData.soltipo.dataSource = response.data.psn;				
				newData.soltipo.dataSource.psnfecha = fecNacimiento(newData.soltipo.dataSource.psncurp); 
				drawSolicitante(newData,  response.data.psn, response.data.psndir );

				// 1: firma electrónica
				if ( state.user.cic.tipofirma !== '1'){
					newData.titlefirma.display = EFieldDisplay.NO_SHOW;
					newData.dockey.display = EFieldDisplay.NO_SHOW;
					newData.doccert.display = EFieldDisplay.NO_SHOW;
					newData.efirma.display = EFieldDisplay.NO_SHOW;
					newData.titlefirmaCIC.display = EFieldDisplay.SHOW;
				}

				onChangeAll(newData);
				setPageState(EPageState.STEP1,"");
		}).catch( (e:any) => {
			setPageState(EPageState.STEP1,"No fue posible consultar los catálogos de dirección");
		})	
		} else {
			setPageState(EPageState.NO_DATA,"");	
		}		
	}, []); 

  const BtnEnvia_Click = () => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"",refresh:""} });
  }

	const BtnRegresarRut_Click = () => {    
		RUT_REDIRECT();		
	}
			
	const txtInvCodPosOnChange = (evt: any) =>{
		if ( evt.target.value.length === 5 && !isNaN(evt.target.value) 
			&& evt.target.value !== dataProcess.invcodpos.value  ) {		
			
			TramitesSvc.GeoColoniaCPGet(evt.target.value)		
			.then( (response: any) => {    
				if ( response.data.length > 0) {
					let dato = 	response.data[0];	
					let newData = {...dataProcess};

					newData.inventfed.list = [{ label: dato.estado , value: dato.id_edo }];
					newData.inventfed.value = dato.id_edo;
					newData.inventfed.error = [];
					newData.invmunicipio.list = [{ label: dato.municipio , value: dato.id_mun }];
					newData.invmunicipio.value = dato.id_mun;
					newData.invmunicipio.error = [];
					newData.invcolonia.list = [];
					newData.invcolonia.value = dato.id_col;
					newData.invcolonia.error = [];	
					response.data.forEach((item : any) => { 
						newData.invcolonia.list.push( { label:item.colonia , value:item.id_col } )
					});												
					onChangeAll(newData);					
				} else {
					let newData = {...dataProcess};
					newData.invcodpos.error.push("El código postal es inválido");
					onChangeAll(newData);
				}
				return;
			})
			.catch( (e:any) => {
				dataProcess.invcodpos.error.push("No fue posible obtener los datos del Código Postal");
				return;
			})
		}
		onItemChange(evt);	
	}	

	const solTipoOnChange = (evt: any) =>{
		ds_as_OnChange( "soltipo", dataProcess.soltipo.dataSource,  dataProcess, solTipo_rules, evt.target.value);

		// if ( evt.target.value.length === 5 && !isNaN(evt.target.value) 
		//	&& evt.target.value !== dataProcess.invcodpos.value  ) {					
		// PSNTIPO: "Persona Física" , value: 1},
	}	

	const DATA_PROCESS = {				
		traid: { gpo:"header",	id:"traid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:TramitesType.BCD, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tdatid: { gpo:"header",	id:"tdatid",  type:EFieldObj.PINT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value: FormType.SOLICITUD, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			

		tramdelid: { gpo:"header",	id:"tramdelid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:"rut351", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramseq: { gpo:"header",	id:"tramseq",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:"", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramfolio: { gpo:"header",	id:"tramfolio",  type:EFieldObj.TXT, save:1,  label:"No. de Folio",  oper:EFieldOper.READ, value:undefined, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramfecrec: { gpo:"header",	id:"tramfecrec",  type:EFieldObj.TXT, save:1,  label:"Fecha de recepción",  oper:EFieldOper.READ, value:undefined, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramdoc: { gpo:'header',  id:"tramdoc",  type:EFieldObj.FILE, save:1,  label:"Trámite solicitud", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.SOLICITUD  },		
		psntipo: { gpo:"fintech", id:"psntipo", type:EFieldObj.SBUTTON, save:1, label:"Tipo de persona", oper:EFieldOper.UPDATE, value:1, rules:[],
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-12", classNameObj:"", classNameGroup:"col-md-12", options: []},


		titLegal:{ gpo:'bcd', type:EFieldObj.H5, id:"titLegal", label:"Defina el tipo de usuario eres: beneficiario, representante legal, padre o tutor.", display:EFieldDisplay.SHOW },			
		soltipo: { gpo:'bcd',  id:"soltipo",  type:EFieldObj.CBO, save:1,   label:"Tipo de persona",   oper:EFieldOper.UPDATE, value: SolTypes.BENEFICIARIO, list: SOLICITANTE_BCD_TYPE, rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:csscol.gpo12, placeholder:"seleccionar", onChange: solTipoOnChange, dataSource:[]
		},
		solnombre:		{ gpo:'solicitante',	id:"solnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:24,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu nombre" },
		solpaterno:		{ gpo:'solicitante',	id:"solpaterno",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:24,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu primer apellido" },
		solmaterno:		{ gpo:'solicitante',	id:"solmaterno",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:24,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu segundo apellido" },		
		solfecnac:	{ gpo:'solicitante',	id:"solfecnac",  type:EFieldObj.DATE, save:1,  label:"Fecha de nacimiento",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], minDate:new Date(1920,1,1),  yearRange:"1920:2030",
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3",  placeholder:"DD/MM/AAAA" },

		solparentesco:{ gpo:'parentesco',	id:"solparentesco",	type:EFieldObj.CBO, save:1,  label:"Parentesco/Interés Jurídico", oper:EFieldOper.UPDATE, value:"",  list:PARENTESCO_BCD_TYPE, rules:[RULE_NO_EMPTY], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW,  classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"Ingresa el parentesco"},
		solrfc: { gpo:'solicitante',	id:"solrfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:13,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu rfc" },
		solcurp: { gpo:'solicitante',	id:"solcurp",  type:EFieldObj.TXT, save:1,  label:"CURP",       oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY], uppercase:true, maxlength:18,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6",classNameObj:csscol.gpo12, classNameGroup:"col-md-5 ", placeholder:"Ingresa tu CURP", info:"CURP"},								


		soltel:   { gpo:'solicitante',   id:"soltel",       type:EFieldObj.PINT, save:1,   label:"Tel. particular",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW,
				classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"particular", maxlength:10 },
		soltelofi:     { gpo:'solicitante',   id:"soltelofi",      type:EFieldObj.PINT, save:1,   label:"Tel. oficina", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Teléfono oficina", maxlength:10},
		solcel:     { gpo:'solicitante',   id:"solcel",      type:EFieldObj.PINT, save:1,   label:"Tel. celular", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"celular", maxlength:10},
		solnacionalidad: { gpo:'solicitante',	id:"solnacionalidad",	type:EFieldObj.CBO,  save:1, label:"Nacionalidad", oper:EFieldOper.UPDATE, value: 223, list: NACIONALIDADES, rules:[RULE_NO_EMPTY],  
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nacionalidad" },	

		solcorreo:{ gpo:'solicitante',	id:"solcorreo",	type:EFieldObj.TXT,  save:1, label:"Correo Electrónico", oper:EFieldOper.READ, value:"", rules:[], uppercase:true,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-5 form-group", placeholder:"Ingresa tu correo"},                

				
		titDatRepresentante:{ gpo:'representante', type:EFieldObj.H3, id:"titDatRepresentante", label:"Datos personales del representante (En el caso de menores de edad o apoderados)", display:EFieldDisplay.NO_SHOW },		
		repnombre:		{ gpo:'representante',	id:"repnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		reppat:		{ gpo:'representante',	id:"reppat",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el primer apellido" },
		repmat:		{ gpo:'representante',	id:"repmat",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.UPDATE, value:"", rules:[], uppercase:true,
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el segundo apellido" },
		repfecnac:	{ gpo:'representante',	id:"repfecnac", type:EFieldObj.DATE, save:1,  label:"Fecha de nacimiento",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], minDate:new Date(1920,1,1),  yearRange:"1920:2030",
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"DD/MM/AAAA"  },
		reprfc: { gpo:'representante',	id:"reprfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,  maxlength:13,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		repcurp: { gpo:'representante',	id:"repcurp", type:EFieldObj.TXT, save:1,  label:"CURP", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], uppercase:true, maxlength:18, 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-6",classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu CURP", info:"CURP"},
		reppoder:{ gpo:'poder',  id:"reppoder",   type:EFieldObj.CBO, save:1,   label:"Defina el poder",   oper:EFieldOper.UPDATE, value: "", list: PODER_TYPE, rules:[ RULE_NO_EMPTY], error: [], display:EFieldDisplay.NO_SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Tipo de poder",
			conditions: { DISPLAY_GPO: [
				{ value: PODER_TYPE[0].value, data:[
					{ display:EFieldDisplay.SHOW, target:[ "carta"] },
					{ display:EFieldDisplay.NO_SHOW, target:[ "notarial"] }
				]},
				{ value:PODER_TYPE[1].value, data:[ 
					{ display:EFieldDisplay.NO_SHOW, target:[ "carta"] },
					{ display:EFieldDisplay.SHOW, target:[ "notarial"] }
				]},
			]}
		}, 


		titDatDom:{ gpo:'domicilio', type:EFieldObj.H3, id:"titDatDom", label:"Domicilio (para recibir notificaciones)", display:EFieldDisplay.SHOW },		
		solcodpos:		{ gpo:'domicilio',	id:"solcodpos",      type:EFieldObj.PINT, save:1,  label:"Código postal", oper:EFieldOper.READ, value:0, rules:[RULE_NUMBER_GREATER], varrules:{  }, error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu código postal", maxlength:5 },
		solentfed:		{ gpo:'domicilio',  id:"solentfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Selecciona la Entidad Federativa"}, 
		solmunicipio:  { gpo:'domicilio',  id:"solmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{},  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						
		solcolonia:    { gpo:'domicilio',  id:"solcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Selecciona la Colonia" },
		solcalle:   { gpo:'domicilio',   id:"solcalle",       type:EFieldObj.TXT, save:1,   label:"Calle", oper:EFieldOper.READ, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:32,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón"}, 						
		solnumext:     { gpo:'domicilio',   id:"solnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Número exterior"}, 						
		solnumint:     { gpo:'domicilio',   id:"solnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Número interior"}, 	
		solmanzana:     { gpo:'domicilio',   id:"solmanzana",      type:EFieldObj.TXT, save:1,   label:"Manzana", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"# mzn"}, 						
		sollote:     { gpo:'domicilio',   id:"sollote",      type:EFieldObj.TXT, save:1,   label:"Lote", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"# lote"},


		titDatasegurado:{gpo:'asegurado',  type:EFieldObj.H4, id:"titDatasegurado", label:"Datos personales del depositante y/o inversionista (persona sobre quien se realiza la búsqueda)", display:EFieldDisplay.SHOW},		
		invnombre:	{ gpo:'asegurado',	id:"invnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:24,		
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		invpat:		{ gpo:'asegurado',	id:"invpat",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,  maxlength:24,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el primer apellido" },
		invmat:		{ gpo:'asegurado',	id:"invmat",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.UPDATE, value:"", rules:[], uppercase:true,  maxlength:24,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el segundo apellido" },


		invfecnac:	{ gpo:'asegurado',	id:"invfecnac", type:EFieldObj.DATE, save:1,  label:"Fecha de nacimiento",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], yearRange:"1920:2030",
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3",  placeholder:"DD/MM/AAAA"  },
		invsexo:	{ gpo:'asegurado',	id:"invsexo", type:EFieldObj.CBO, save:1,  label:"Sexo",  oper:EFieldOper.UPDATE, value:"", rules:[], list:SEXO_TYPE,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2",  placeholder:"Ingresa su sexo"  },							
		invrfc: { gpo:'asegurado',	id:"invrfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:13,
			varrules:{ minvalue:13 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu RFC" },
		invcurp: { gpo:'asegurado',	id:"invcurp", type:EFieldObj.TXT, save:1,  label:"CURP",       oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:18,
			varrules:{ minvalue:18 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6",classNameObj:csscol.gpo12, classNameGroup:"col-md-3",  placeholder:"Ingresa tu CURP", info:"CURP"},						


		invlugfallec: { gpo:'asegurado',	id:"invlugfallec",    type:EFieldObj.CBO, save:1,  label:"Lugar de fallecimiento",  oper:EFieldOper.UPDATE, value:"", list: [], rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW, 
			classNameLabel:EFieldDisplay.SHOW, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Entidad Federativa" },
		invfecfallec:	{ gpo:'asegurado',	id:"invfecfallec", type:EFieldObj.DATE, save:1,  label:"Fecha de fallecimiento",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], yearRange:"1920:2030", minDate:new Date(1920,1,1),  
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-10", classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"DD/MM/AAAA" },
		invausencia:{ gpo:'asegurado',	id:"invausencia",	type:EFieldObj.CBO, save:1,  label:"¿Cuentas con Declaración Especial de Ausencia y Presunción de Muerte?", oper:EFieldOper.UPDATE, value:"",  list:AUSENCIA_TYPE, rules:[RULE_NO_EMPTY], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW,  classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-12",  placeholder:"",
			conditions: { DISPLAY_GPO: [ 				
				{ value: AUSENCIA_TYPE[0].value, data:[
					{ display:EFieldDisplay.SHOW, target:[ "ausencia"] }
				]},
				{ value:AUSENCIA_TYPE[1].value, data:[ 
					{ display:EFieldDisplay.NO_SHOW, target:[ "ausencia"] }
				]},
			]}
		},
		
		invausfecpresun:	{ gpo:'ausencia',	id:"invausfecpresun",  type:EFieldObj.DATE, save:1,  label:"Fecha presunción de ausencia",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], minDate:new Date(1920,1,1),  yearRange:"1920:2030",		
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"DD/MM/AAAA" },
		invausfecresol:	{ gpo:'ausencia',	id:"invausfecresol",  type:EFieldObj.DATE, save:1,  label:"Fecha resolución de la sentencia",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], minDate:new Date(1920,1,1),  yearRange:"1920:2030",
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"DD/MM/AAAA" },
		invausfecejecu:	{ gpo:'ausencia',	id:"invausfecejecu",  type:EFieldObj.DATE, save:1,  label:"Fecha ejecutoria de la declaración",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], minDate:new Date(1920,1,1),  yearRange:"1920:2030",
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4",  placeholder:"DD/MM/AAAA" },


		titDatUltdomase:{ gpo:'ultdom', type:EFieldObj.H4, id:"titDatUltdomase", label:"Último domicilio del depositante/inversionista (persona sobre quien se realiza la búsqueda)", display:EFieldDisplay.SHOW},		
		invpais:		{	 gpo:'ultdom',  id:"invpais",       type:EFieldObj.CBO, save:1,   label:"Domicilio en",   oper:EFieldOper.UPDATE, value:1, list: UBICACION_TYPE, rules:[ RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"seleccione el domicilio",
			conditions: { DISPLAY_GPO: [ 				
				{ value: UBICACION_TYPE[0].value, data:[
					{ display:EFieldDisplay.SHOW, target:[ "mxultdom"] },
					{ display:EFieldDisplay.NO_SHOW, target:[ "exultdom"] }
				]},
				{ value:UBICACION_TYPE[1].value, data:[ 
					{ display:EFieldDisplay.NO_SHOW, target:[ "mxultdom"] },
					{ display:EFieldDisplay.SHOW, target:[ "exultdom"] }
				]},
			]}

		}, 

		invcodpos:		{ gpo:'mxultdom',	id:"invcodpos",      type:EFieldObj.PINT, save:1,  label:"Código postal", oper:EFieldOper.UPDATE, value:"0", rules:[RULE_NUMBER_GREATER], varrules:{}, error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu código postal",  onChange: txtInvCodPosOnChange },
		inventfed:		{ gpo:'mxultdom',  id:"inventfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Selecciona la Entidad Federativa"}, 
		invmunicipio:  { gpo:'mxultdom',  id:"invmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{},  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						
		invcolonia:    { gpo:'mxultdom',  id:"invcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Selecciona la Colonia" },              
		invcalle:   { gpo:'mxultdom',   id:"invcalle",       type:EFieldObj.TXT, save:1,   label:"Calle",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:EFieldDisplay.SHOW, maxlength:32,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón"}, 						
		invnumext:  { gpo:'mxultdom',   id:"invnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, uppercase:EFieldDisplay.SHOW,  maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Número exterior"},
		invnumint:  { gpo:'mxultdom',   id:"invnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:EFieldDisplay.SHOW, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Número interior"},
		invmanzana:     { gpo:'mxultdom',   id:"invmanzana",      type:EFieldObj.TXT, save:1,   label:"Manzana", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"# mzn"}, 						
		invlote:     { gpo:'mxultdom',   id:"invlote",      type:EFieldObj.TXT, save:1,   label:"Lote", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"# lote"},
		
		invextdom:     { gpo:'exultdom',   id:"invextdom",      type:EFieldObj.TXT, save:1,   label:"Domicilio en el extranjero", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.NO_SHOW, uppercase:true, maxlength:16,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-12", placeholder:" "},


		titDocumentos:{ gpo:"documentos", type:EFieldObj.H3, id:"titDocumentos", label:"Documentación soporte", display:EFieldDisplay.SHOW},		
		titDocReglas:{ gpo:"documentos", type:EFieldObj.LABEL, id:"titDocReglas", label:"Esta forma de captura solo admite archivos en formato PDF y la suma del tamaño de los archivos debe ser menor a 40MB.", display:EFieldDisplay.SHOW },
		docidentificacion:	{ gpo:"solicitante",  id:"docidentificacion",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN OFICIAL DEL BENEFICIARIO",   oper:EFieldOper.UPDATE, value: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:"col-md-12", placeholder:"Selecciona una Identificación Oficial", 
			info:"BOX", image:imageIne, alt:"INE", accept:"application/pdf", typedoc:FormType.IDENTIFICACION,
			text:[ { section:"Documento que permite comprobar la identidad de la persona que esté ingresando la soliciutd, es muy importante que el nombre coincida con el que aparezca en el contrato, estado de cuenta, póliza u otro documento que adjuntes, y que esté digitalizada por ambos lados."} ] }, 

		docdefuncion:	{ gpo:'solicitante',  id:"docdefuncion",  type:EFieldObj.FILE, save:1,   label:"ACTA DE DEFUNCIÓN DEL INVERSIONISTA O DEPOSITANTE",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageDefuncion, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.ACTA_DEFUNCION,
			text:[ { section:"Es  un documento  oficial  emitido  por  el  Registro  Civil  mediante  el  cual  se acredita  el  fallecimiento  de  una  persona  que  presumiblemente  hubiese contratado un seguro de vida y en donde se registra el momento exacto de su fallecimiento"}] }, 
	
		docrepreident:	{ gpo:"idenrepresentante",  id:"docrepreident",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN OFICIAL DEL REPRESENTANTE",   oper:EFieldOper.UPDATE, value: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:"col-md-12", placeholder:"Selecciona una Identificación Oficial", 
			info:"BOX", image:imageIne, alt:"INE", accept:"application/pdf", typedoc:FormType.IDENTIFICACION,
			text:[ { section:"Documento que permite comprobar la identidad de la persona que esté ingresando la queja, es muy importante que el nombre coincida con el que aparezca en el contrato, estado de cuenta, póliza u otro documento que adjuntes, y que esté digitalizada por ambos lados."} ] }, 


		docreprecarta:	{ gpo:'carta',  id:"docreprecarta",  type:EFieldObj.FILE, save:1,   label:"CARTA PODER DEL REPRESENTANTE LEGAL",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Representante", accept:"application/pdf", typedoc:FormType.CARTA_PODER_REPRESENTANTE,
			text:[ { section:"Carta Poder del representante legal"}] }, 

		docreprecartatest1:	{ gpo:'carta',  id:"docreprecartatest1",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN TESTIGO 1 DE LA CARTA PODER",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Representante", accept:"application/pdf", typedoc:FormType.CARTA_PODER_REPRESENTANTE,
			text:[ { section:"Identificación del testigo 1"}] }, 
			
		docreprecartatest2:	{ gpo:'carta',  id:"docreprecartatest2",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN TESTIGO 2 DE LA CARTA PODER",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Representante", accept:"application/pdf", typedoc:FormType.CARTA_PODER_REPRESENTANTE,
			text:[ { section:"Identificación del testigo 2"}] }, 			

		docreprenotario:	{ gpo:'notarial',  id:"docreprenotario",  type:EFieldObj.FILE, save:1,   label:"PODER NOTORIAL",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Representante", accept:"application/pdf", typedoc:FormType.CARTA_PODER_REPRESENTANTE,
			text:[ { section:"Anexe el pdoer notarial"}] }, 


		doctutorident:	{ gpo:"tutor",  id:"doctutorident",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN OFICIAL DEL TUTOR",   oper:EFieldOper.UPDATE, value: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", bcd:"", classNameGroup:"col-md-12", placeholder:"Selecciona una Identificación Oficial", 
			info:"BOX", image:imageIne, alt:"INE", accept:"application/pdf", typedoc:FormType.IDENTIFICACION,
			text:[ { section:"Documento que permite comprobar la identidad del padre o madre del menor de edad, es muy importante que el nombre coincida con el que aparezca en el beneficiario, y que esté digitalizada por ambos lados."} ] }, 			  

		doctestamento:	{ gpo:'albacea',  id:"doctestamento",  type:EFieldObj.FILE, save:1,   label:"TESTAMENTO NOTARIAL DONDE SE DESIGNA COMO HEREDERO",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Testamento notarial", accept:"application/pdf", typedoc:FormType.NOMBRAMIENTO,
			text:[ { section:"Testamento notarial donde se designa heredero"}] }, 

		docalbacea:	{ gpo:'albacea',  id:"docalbacea",  type:EFieldObj.FILE, save:1,   label:"ALBACEA",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.NOMBRAMIENTO,
			text:[ { section:"Si cuenta con el documento de aceptación como cargo de albacea"}] }, 

		docsucesion:	{ gpo:'albacea',  id:"docsucesion",  type:EFieldObj.FILE, save:1,   label:"RESOLUCIÓN JUICIO",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.NOMBRAMIENTO,
			text:[ { section:"Si cuenta con una resolución de juicio de sucesión favor de anexar documento"}] }, 			

		docpadresident:	{ gpo:"padres",  id:"docpadresident",  type:EFieldObj.FILE, save:1,   label:"IDENTIFICACIÓN OFICIAL DEL PADRE/MADRE",   oper:EFieldOper.UPDATE, value: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", bcd:"", classNameGroup:"col-md-12", placeholder:"Selecciona una Identificación Oficial", 
			info:"BOX", image:imageIne, alt:"INE", accept:"application/pdf", typedoc:FormType.IDENTIFICACION,
			text:[ { section:"Documento que permite comprobar la identidad del padre o madre del menor de edad, es muy importante que el nombre coincida con el que aparezca en el beneficiario, y que esté digitalizada por ambos lados."} ] }, 			  

		docactanac:	{ gpo:'actnac',  id:"docactanac",  type:EFieldObj.FILE, save:1,   label:"ACTA DE NACIMIENTO",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageActaNac, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.ACTA_NACIMIENTO,
			text:[ { section:"En el caso de menores de edad se debe acompañar la acta de nacimiento del menor"}] }, 

		titlefirma: { gpo:'firma', id:"titlefirma", type:EFieldObj.H3, label:"Favor de ingresar su Firma electrónica (eFirma SAT) para enviar sus documentos y firmar la presente queja", display:EFieldDisplay.SHOW },
		doccert: { gpo:'firma',  id:"doccert",  type:EFieldObj.FILE, save:1,  label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },				
		dockey:	{ gpo:'firma',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
		efirma: { gpo:'firma',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, maxlength:24, 
			classNameLabel:"col-md-5", classNameObj:"col-md-3", classNameGroup:csscol.gpo12, placeholder:"Ingrese su contraseña"},

		titlefirmaCIC: { gpo:'firmacic', id:"titlefirmaCIC", type:EFieldObj.H3,  label:"La solicitud será firmada con su clave CIC", display:EFieldDisplay.NO_SHOW },

		solaviso:{ gpo:'bcd',  id:"solaviso", type:EFieldObj.CHKB, save:1,   label:"He leído y acepto aviso de privacidad*",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_TRUE ], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-11", classNameObj:"col-md-1", classNameGroup:csscol.gpo12, placeholder:"Aviso "},		
			
		docacuse: { gpo:'header',  id:"docacuse",  type:EFieldObj.FILE, save:1,  label:"Acuse", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.ACUSE  },

		docaviso: { gpo:'header',  id:"docaviso",  type:EFieldObj.FILE, save:1,  label:"Aviso de privacidad", oper:EFieldOper.READ, value:0, rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.OTRO }			
	}

	const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )

	const btnSaveOnClick = () => {
		// tamaño de los archivos...
		
		if ( state.user.psnid != 3960  ) {
			setPageState(EPageState.ERROR,"La aplicación ha generado un error favor de reportar");	
			return;
		}
		
		
		let intErrorTotal = onValidateAll();
		let intErrorSize = validateFilesSize(dataProcess, 10240);
				
		if ( dateGreaterThan(dataProcess.invfecnac.value, dataProcess.invfecfallec.value) === true){			
			let newData = {...dataProcess};
			newData.invfecnac.error.push("La fecha de nacimiento no puede ser mayor a la fecha de defunción");
			onChangeAll(newData);						
			intErrorTotal += 1;			
		} 		

		intErrorTotal += intErrorSize;

		if (intErrorTotal === 0 ){					
			setPageState(EPageState.STEP2, "");
			// ESTOS VALORES ESTAN EN LA DEFINCION DE LA ARISTA...					 			 
			let dataMsg = { msg : {id:"msg",  type:EFieldObj.TXT, save:1,  label:"Instrucción",  value:"DATOS DE LA SOLICITUD"} };

			// COLCOAR CONSTANTES .... 
			let operacion : IOperacion = {  traid:TramitesType.BCD, etaid:EtapaType.SOLICITUD, oprid:0, bucket:"", oprpath:"" };
			let origen: INodoOrigen = { nodori:0, edoori:1, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
			let accion : IAccion =  { 
					accid:Accion.BCD_USR_ENVIAR_SOLICITUD, tdatid:FormType.ASIGNAR, 	data:dataMsg, edoid:NodoEstado.BCD_REVISAR, 
					optional:{ accid:Accion.REGISTRAR_TRAMITE, tdatid:FormType.SOLICITUD_BCD, data:dataProcess, edoid:NodoEstado.TRAMITE }
			};


				

			TramitesSvc.TraAction( JsonPost(operacion, origen, accion, state.user.cic) )
			.then((response:any ) => {         								
				if ( response.status === 200 && response.data.folio !== undefined) {					
					setQueja(response.data);
					setPageState(EPageState.STEP3, "");
					return;
				} else {
					setPageState(EPageState.STEP1, "No fue posible registrar su queja, vuelva a intentarlo");			
					return ;					
				}
			})
			.catch( (e:any) => {setPageState(EPageState.STEP1, "No es posible conectarse con el componente de trámites");
				console.log(e);
				return;
			});  
		} else {
			if ( intErrorSize > 0) {				
				setPageState(EPageState.STEP1, "La suma de los archivos suman más de 20MB");
			} else {
				setPageState(EPageState.STEP1, "Favor de revisar los datos de captura");
			}			
		}		
	}

	// definir el objeto más adelate
	//me flata madnar la fucnion de cmabion...
	//converti rdo sfunciones en una
	function ds_as_OnChange( strField:string,  sourceData:any, sourcDest:any, setRules:any, value:any){
			let newData = {...sourcDest};
			
			newData[strField].value = value;
			let strValue = value.toString();
			for (let objItem in setRules[strValue]) {
				if (objItem === DisplayProp.GROUP){
					setRules[strValue][DisplayProp.GROUP].forEach( ( oitem:any ) => { 
						for( let dsGpo in oitem.target){
							Object.keys(newData).forEach( key => {
								if ( newData[key].gpo! === oitem.target[dsGpo]){
									newData[key].display = oitem.display;
								}
							})
						}
					})
				} else if ( objItem === DisplayProp.ASSING  ){
					let objAssing = setRules[strValue][DisplayProp.ASSING];
					for(let i = 0; i < objAssing.length; i++) {
						let obj : FieldAssingType = objAssing[i];					
						if ( obj.source.length> 0) {
							newData[obj.target].value = sourceData[obj.source];
							newData[obj.target].oper = obj.topr;
							newData[obj.target].error = [];
						} else {
							newData[obj.target].value = '';
							newData[obj.target].oper = obj.topr;
							newData[obj.target].error = [];
						}
					}
				}
			}
			onChangeAll(newData);
	}
			 
	const drawStep3 = () => {
		return (
			<div className="container " >
				<br/>
				<h3>LA SOLICITUD DE BÚSQUEDA DE BENEFICIARIOS DE CUENTAS DE DEPÓSITO/INVERSIÓN SE REGISTRÓ CON ÉXITO</h3>
				<hr className="red"></hr>
				<div className="row">        
					<label className="col-sm-3 col-md-6">Fecha de registro</label>
					<label className="col-sm-8 col-md-6">{ queja.fecha }</label>
				</div>
				<br />
				<div className="row">        
					<label className="col-sm-3 col-md-6">Número de Folio</label>
					<label className="col-sm-8 col-md-6">{ queja.folio }</label>
				</div>

				<br />
				<button className="btn btn-primary " id="envia" type="button" onClick={ evt => { BtnEnvia_Click() } }>Ir a seguimiento 
					<span className="glyphicon glyphicon-ok"></span>
				</button>				

				<br />
				<br />

				<div className="row">
					<object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.tramite  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
					</object>
				</div>

				
				<div className="row">
					<object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.acuse  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
					</object>          
				</div>

				<div className="row">
					<object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.aviso  } type="application/pdf" >
						No fue posible descargar el documento. Favor de volver a intentar
					</object>          
				</div>

			</div>  
		);     
	}


	const FormaContenido = () => {
		return (
			<Fragment>
				<div className="pull-right" style={{ marginBottom:"40px" }}  >
					{/*
					<div className="alert alert-danger"> LA APLICACIÓN SE ENCUENTRA EN MANTENIMIENTO </div>
					*/}
					
					<div className="alert alert-info"> ACLARACIONES DE IMPORTANCIA </div>
					<ul>
						<li>Las solicitudes son personales, en el caso de menores de edad se debe acompañar de acta de nacimiento del menor e identificación del padre que ejerza la patria potestad del menor, y en caso de apoderados, el documento en el que conste la representación.</li>
						<li>La información que, en su caso, proporcione(n) la(s) Institución(es) de Crédito que participan en el sistema será de acuerdo con la última designación que tenga(n) registrada y no presume ni garantiza de manera alguna la procedencia de la entrega del saldo existente en las cuentas al momento del fallecimiento del ahorrador o inversionista, para lo cual cada beneficiario deberá cumplir con los requisitos de ley aplicable.</li>
						<li>Los datos asentados en la presente solicitud se encuentran protegidos en términos de lo dispuesto por la Ley Federal de Protección de Datos Personales y de la Ley Federal de Transparencia y Acceso a la Información Pública.</li>
						<li>El resultado de la búsqueda de beneficiarios depende de los datos proporcionados por el solicitante, así como de la información con la que cuenten las Instituciones de Crédito.</li>
					</ul>
				</div>				
				{ DrawMsg() }
				<div id="formReg" >
					<DrawFieldsGroup 
						dataFields={dataProcess}
						onItemChange={onItemChange}
						onEditorTextChange={onEditorTextChange}
						onCompleteMethod={onCompleteMethod}  
						onFieldsValidate={onFieldsValidate}
						onValidateAll={onValidateAll}
				 	/>
					<br/>
					<div className="pull-left text-muted text-vertical-align-button">*Campos obligatorios</div>
					<br/>
					<br/>
					
					<div className="alert alert-info" aria-live="polite" style={{ wordWrap:"break-word"}}> 
						<div style={{ textAlign:"center"}} >
							<strong>Aviso de privacidad del BCD</strong>
						</div>
						<br />
						<div >
							Si deseas conocer nuestro aviso de privacidad integral, lo podrás consultar en el portal : 
							<a href="https://phpapps.condusef.gob.mx/condusefenlinea/formatosTramite/AVISO_BCD.pdf" target="_blank" rel="noopener noreferrer" > https://phpapps.condusef.gob.mx/condusefenlinea/formatosTramite/AVISO_BCD.pdf</a>
						</div>
					</div>
					<br/>
					{ DrawMsg() }
					<br/>				
					{/*
					<div className="alert alert-danger"> LA APLICACIÓN SE ENCUENTRA EN MANTENIMIENTO </div>
					*/}
				</div>
			</Fragment>
		);
	}

	const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}
		
	const drawStep2 = () => {
		return (
			<div className="container " >        	
				{ FormaContenido() }
				<ProgressBar mode="indeterminate" />
				<br/>
			</div>  
		);     
	}

	const drawStep1 = () => {		
		return (
			<div className="container"  >        	
				<br />
				<h2>Solicitud de búsqueda de beneficiarios de cuentas de depósito/inversión</h2>				
				{ FormaContenido() }		
				<div className="pull-right" style={{ marginBottom:"40px" }}  >
					<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSaveOnClick() } >Enviar <span className="glyphicon glyphicon-ok"></span></button>
				</div>		
				
			</div>  
		);     
	}

	const drawNoData = () => {		
		return (
			<div className="container"  >        	
				<br />				
				<div className="alert alert-warning" >No fue posible conectarse con el sistema del Registro Unico de Trámites vuelva a intetar o favor de repotar a la CONDUSEF</div>
			</div>  
		);     
	}

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {


			case EPageState.IN_PROCESS:        
				return <ProgressBar mode="indeterminate" />
			
			case EPageState.STEP1:
				return drawStep1();

			case EPageState.STEP2:
				return drawStep2();
				
			case EPageState.STEP3:
				return drawStep3();
								
			case EPageState.ERROR:
				return (
					<div>
						<br/>
						<div className="alert alert-warning"> {pageState.msg } </div>
						<br/>
					</div>
				)												
			case EPageState.NO_DATA:
					return drawNoData();

			default:
				return DrawMsg();
		}
	}

  return (
		<Fragment>      			
			<HeaderApp />			
			{ drawStateCtrl() }			
		</Fragment>
	);
} 

