import React, {Fragment, useContext } from 'react';
import './Login.css';

import imageBG from '../../images/condusef.png';

import { HeaderGobMx } from '../main/HeaderGobMx';
import { FooterGobMx } from '../main/FooterGobMx';
import { AppContext } from '../../Context';

export const LoginError = () => {  
  const { state } = useContext(AppContext);	

  const drawMeg = () => {

    if(  state.page.msg.length>0 )
    return (
      <Fragment>        
          <div>
            <h3>{state.page.msg}</h3>
          </div>
      </Fragment>
  )    
    else
      return (
        <Fragment>        
            <div>
              <h3>No fue posible conectarse con el servicio de autentificación vuelva a intentar</h3>
            </div>
        </Fragment>
    )
  }

  return (  
    <Fragment>
      <HeaderGobMx />     
      <br/>
      <br/>      
      <div className="text-center" style={{ marginTop:"80px", position:"relative"}}>    
        <div className="container">
          <div className="row">
              <img src={imageBG} width="247px" height="292px" alt="Logo" />      
          </div>
          { drawMeg()}
        </div>
      </div>
      <br/>
      <br/>
    <FooterGobMx />
  </Fragment>
  );    
}
