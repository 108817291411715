import React  from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFiles } from '../../TramFiles';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N310UsrPrevencion: React.FC<IData> = (props310) => {
  const drawBody = () => {
    try {
      if (props310.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props310.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props310.init.aristas;  
        let changeableData = contenido.accion.data;

        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props310.init.operacion.etaid} action={accion} oprid={props310.action.oprid} nodori={props310.action.nodid} setListTask={props310.setListTask} 
              edoori={props310.action.edoid }   bucket={props310.init.operacion.bucket}  oprpath={props310.init.operacion.oprpath} 
              tramite={props310.init.tramite} ></NodoResponder>            
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props310.action.nodfecha} estado={props310.action.edodesc} titulo={""}  folio={props310.action.oprfolio} insid={origen.insid}></NodoHeader>                
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div>                 
                <TramFiles traid={ props310.init.tramite.traid} etaid={props310.init.tramite.etaid} oprid={props310.init.tramite.oprid} insid={0}   />
                <br/>
                <p>FAVOR DE ENVIAR LOS DOCUMENTOS SOLICITADOS</p>
                <br/>
                <textarea rows={8} style={{ width:"100%", resize:"none", backgroundColor:"whitesmoke", borderColor:"whitesmoke", fontWeight:"bold", fontSize:"16px",  marginTop:"30px" }} readOnly={true} value={changeableData.mensaje.value } > </textarea>
                <div style={{ marginBottom:"10px" }}> </div>
              </div>                          
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return (
    <div>
      { drawBody() }
    </div>
  )
}