import React from 'react';
import imageCondusef from '../../../images/condusef2.png';

export const MsgBox = () => {    
  return (
    <div style={{ width:"100%", height:"50vh", padding:"auto", textAlign:"center", color:"black"}}>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>        
      <br/>
      <br/>
      <img src={imageCondusef}  alt="Logo" />
      <br/>
      <br/>                      
      <div>Para iniciar un nuevo trámite<br/>
        Dar clic en el icono <i className="glyphicon glyphicon-cog" style={{ fontSize:"28px" }} ></i> de la barra de menú.
      </div>
    </div>
  );
  
}