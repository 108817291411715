import { InstIdTypes } from "../reducers/Model";

export const csscol = {
  gpo2: "col-lg-2 col-md-6 col-sm-12 col-xs-12",
  gpo3: "col-lg-3 col-md-6 col-sm-12 col-xs-12",
  gpo4: "col-lg-4 col-md-6 col-sm-12 col-xs-12",  
  gpo5: "col-lg-5 col-md-6 col-sm-12 col-xs-12",  
  gpo6: "col-lg-6 col-md-6 col-sm-12 col-xs-12",  
  gpo7: "col-lg-7 col-md-6 col-sm-12 col-xs-12",  
  gpo12: "col-xs-12 col-md-12 col-lg-12"  
};


export const userColor = ( insid: number  ) : string	 => {
  switch ( insid ) {
    case InstIdTypes.CONDUSEF:
      return "#98989A";

    case InstIdTypes.CIUDADANO:
      return  "#235B4E";

    default:
      return "#9F2241";
  }

}


/*
Rojo  	claro	    #9F2241
Rojo  	osbcuro	  #691C32
Verde  	claro	    #235B4E
Verde  	osbcuro	  #10312B
Beige   claro	    #DDC9A3
Beige   osbcuro	  #BC955C
Gris    claro	    #98989A
Girs    osbcuro	  #6F7271


export const csscol = {
  gpo2: "p-col-12 p-md-2",
  gpo4: "p-col-12 p-md-4",  
  gpo5: "p-col-12 p-md-5",  
  gpo6: "p-col-12 p-md-6",  
  row:  "p-col-12 "  
};


  xs (for phones - screens less than 768px wide)
  sm (for tablets - screens equal to or greater than 768px wide)
  md (for small laptops - screens equal to or greater than 992px wide)
  lg (for laptops and desktops - screens equal to or greater than 1200px wide)
*/  