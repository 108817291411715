import "./Tracing.css"
import { Button } from 'primereact/button';
import { IItemInPrc, IItemHead, Task, TraceDictamenType } from "../../reducers/Model";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { JSONToCSVConvertorTitles } from "../../util/exportUtil";
import { Dialog } from "primereact/dialog";
import { Fragment, useState } from "react";
import { TaskInProcess } from "../afd/task/TaskInProcess";
import { EPageState } from "../../ui/uses/usePageState";
import TramitesSvc from "../../services/TramitesSvc";
import { stringToDate } from "../../util/dateUtil";
import { Calendar } from "primereact/calendar";

interface IData { data : TraceDictamenType[], ptrSearch : any   }

export const TraceDictamen: React.FC<IData> = ( gestion ) => {
  const [display, setDisplay] = useState(false);
  const [itemSelect, setItemSelect] = useState(0);
  const [itemInPrc, setItemInPrc]= useState<IItemInPrc>();
  const [itemData, setItemData]= useState<IItemHead>();
  const [listTask, setListTask] = useState([]);
  const [item, setItem] = useState<TraceDictamenType>();
  const [itemDialog, setItemDialog] = useState(false);
  const [dateBegin, setDateBegin] = useState<Date[]>();
  const [dateEnd, setDateEnd] = useState<Date[]>();
  const [msgError, setMsgError] = useState<String>("");


  const editItem = ( item: TraceDictamenType) => {
    if (item.fecrepini !== null &&  item.fecrepini !== ""){
      setDateOnClick(setDateBegin, new Date(item.fecrepini.substring(6) + "/" + item.fecrepini.substring(3,5)+ "/" +  item.fecrepini.substring(0,2)));
    }
              
    if (item.fecrepfin !== null &&  item.fecrepfin !== "") {
      setDateOnClick(setDateEnd, new Date(item.fecrepfin.substring(6) + "/" + item.fecrepfin.substring(3,5)+ "/" +  item.fecrepfin.substring(0,2)) );
    }
        
    setItem({ ...item });
    setItemDialog(true);
  };
  
  const hideDialog = () => {
    setItemDialog(false);
  };

  const btnUpdateGestion = () => {
    let formData = new FormData();    
    formData.append("TraceSearch", JSON.stringify({ oprid: itemData?.oprid, phase: 3, datebegin:dateBegin , dateend: dateEnd}) );
    
		TramitesSvc.TraTraceUpdateDates(formData )
		  .then ((response: any) => {  							
        setItemDialog(false);
				setMsgError("");
        gestion.ptrSearch(-1);      
		  }).catch( (e:any) => {
  		  setMsgError("No fue posible realizar la operación");
		  })	
  };

  
  const gestionDialogFooter = () => {
    return (
      <Fragment>
          <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} />        
          <Button label="Actualizar" icon="pi pi-check" onClick={btnUpdateGestion} />
          <br/>
          <br/>
          { (msgError.length) >0 ? <div className="alert alert-danger " >{msgError}</div>: null}
      </Fragment>
    );
  }

  function setDateOnClick(setFunc:any, value:any) {
    setFunc(value);
  }



  const onHide = () => {
    // TODA UNA BUSQUEDA...
    setDisplay(false);
  }

  function btnExportarOnClick() {        
    const titles = ["FOLIO", "NOMBRE DEL USUARIO", "INSTITUCIÓN",
      "TIPO DE SOL. DICT",
      "FECHA INGRESO DICTMAEN", "FECHA AUD. ELEM. PRUEBAS","FECHA ELEM. PRUEBAS","FECHA DE ELAB PRY",
      "FECHA SOL. USR.", "FECHA SOL. INST.", "FECHA CORRIDA", "FECHA NOTIFICAR RESULTADO",
      "FECHA INICIAL REPORTADA", "FECHA FINAL REPORTADA"
    ];
   
    JSONToCSVConvertorTitles(gestion.data, "DictamenFintech", true, titles);
  }

  const onRowSelect = (event : any) =>     
  {
    let auxItemData: IItemHead  = {
      bukdesc: 'fintech',
      oprfecrecepcion: event.data.fecrec,
      oprfolio : event.data.folio,
      oprid : event.data.oprid,
      pfldesc : 'FINTECH ',
      tradesc : 'Fintech',
      traicono : 'glyphicon glyphicon-phone',
    }
    setItemData(auxItemData);
    setItemInProcess(event.data.oprid);
  };

  const setItemInProcess = ( oprid:number) => {

    TramitesSvc.AfdSeguimiento(oprid)
      .then ((response) => {                              
        if ( response.status !== 200 ) {          
          // COMO MOSTRAR EL ERROR DEL REGISTRO
          return;
        }
        
        let auxtasks  = [];
        let idx=0;
        let limite = response.data.nodos.length;
      
        for (idx = 0; idx< limite; idx++ ){
          let ndata  = response.data.nodos[idx];

          let datIni : Date = stringToDate(ndata.nodfecha);
          let datFin : Date;

          if (ndata.nodfecatendido === null) {
            datFin =  new Date(Date.now());
          } else {
            datFin = stringToDate(ndata.nodfecatendido);
          }

          
          if ( idx === 0){                    
            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: []
            }
            auxtasks.push(task);

          } else {
            let dependencia : string[] = [];

            response.data.flujo.forEach((item : any) => {
              if (item.noddst === ndata.nodid ){
                dependencia.push("N"+ item.nodori);                
              }              
            });

            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: dependencia!
            }
            auxtasks.push(task);      
          }
        }
        setItemSelect(oprid);
        setItemInPrc({ tasks:auxtasks, nodos: response.data.nodos});
        setDisplay(true);               
        return;
      })
      .catch( (e : any) => {                
        // COMO MOSTRAR EL ERROR DEL REGISTRO
      });
                
    return false;
  }

  // rounded outlined
  const actionBodyTemplate = (rowData: TraceDictamenType) => {
    return (
      <div>
        <Button icon="pi pi-pencil" label="Editar" onClick={() => editItem(rowData)} />
      </div>
    );
  };

 return (
  <div style={{position:"relative", top:"40px", left:"1%", width:"98%"}} >

<div className="row">            
      <div className="col-md-10" > <h3 >DICTAMEN ( { gestion.data.length })</h3></div>
      <div className="col-md-2" style={{ paddingTop:"25px", paddingLeft:"120px"}} > 
        <Button label="Exportar EXCEL" style={{ width:"160px"}} onClick={btnExportarOnClick}/>
      </div>
    </div>
      
    <DataTable value={gestion.data} paginator rows={25} dataKey="id"  showGridlines 
      scrollable scrollHeight="700px"
      selectionMode="single"     
      rowsPerPageOptions={[25, 50, 100]} 
      onRowSelect={onRowSelect} 
      >            

      <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }}></Column>
      <Column field="fecrepini" sortable header="FECHA INICIAL REPORTADA" ></Column>
      <Column field="fecrepfin" sortable header="FECHA FINAL REPORTADA" ></Column>
      <Column field="folio" sortable  header="FOLIO" ></Column>
      <Column field="usuario" sortable header="NOMBRE DEL USUARIO" ></Column>
      <Column field="inst" sortable header="INSTITUCIÓN" ></Column>

      <Column field="dictamne" sortable header="TIPO DE SOL. DICT" ></Column>
      <Column field="fecing" sortable header="FECHA INGRESO DICTMAEN" ></Column>
      <Column field="fecaudielem" sortable header="FECHA AUD. ELEM. PRUEBAS" ></Column>
      <Column field="fecinstelem" sortable header="FECHA ELEM. PRUEBAS" ></Column>
      <Column field="fecPry" sortable header="FECHA DE ELAB PRY" ></Column>
      <Column field="fecsolusr" sortable header="FECHA SOL. USR." ></Column>
      <Column field="fecsolinf" sortable header="FECHA SOL. INST." ></Column>
      <Column field="feccorrida" sortable header="FECHA CORRIDA" ></Column>
      <Column field="fecresul" sortable header="FECHA NOTIFICAR RESULTADO" ></Column>  
    </DataTable>

    <br/>
    <br/>
    <div className="dialog-action">
      <Dialog header={"Actividades realizadas"} visible={display} style={{ width: '70vw'  }}   
        onHide={() => onHide()}>        
          <TaskInProcess data={itemData!} setListTask={setListTask} init={EPageState.INIT} tasks={itemInPrc?.tasks} nodos={itemInPrc?.nodos} />                
      </Dialog>
    </div>


    <Dialog visible={itemDialog} style={{ width: '26rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="FECHAS A REPORTAR" 
      modal className="p-fluid" footer={gestionDialogFooter} onHide={hideDialog}>
      <div className="field">
        <label htmlFor="name" className="font-bold">Fecha inicial</label>
        <br />
        <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateBegin}
              onChange={(e) => setDateOnClick(setDateBegin, e.value)}
              showIcon            
            />
      </div>
      <br />
      <div className="field">
        <label htmlFor="name" className="font-bold">Fecha final</label>
        <br />
        <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateEnd}
              onChange={(e) => setDateOnClick(setDateEnd, e.value)}
              showIcon            
            />
      </div>          
    </Dialog>
  </div>
 ) 
}
