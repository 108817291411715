import React, { useState } from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFinQueja } from '../../TramFinQueja';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

import '../Node.css';
import TramitesSvc from '../../../../services/TramitesSvc';
import { TramFiles } from '../../TramFiles';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N408ConImproEfirma: React.FC<IData> = (props408) => {
  const [displayQueja, setDisplayQueja] = useState(false);

  const onClickFile = (name:string,  bucket: string,  path:string) => {
    TramitesSvc.DocumentoGet( name, bucket, path);
  }

  const onClick = () => {
    setDisplayQueja(true);
  }

  const onHide = () => {
    setDisplayQueja(false);
  }

/*
                  <br/>
    <p>Comentarios del proyecto</p>                  
    <textarea rows={8} style={{ width:"100%", resize:"none" }} readOnly={true} value={changeableData.mensaje.value } > </textarea>
*/

  const drawBody = () => {
    try {
      if (props408.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props408.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props408.init.aristas;  
        let changeableData = contenido.accion.data;

        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props408.init.operacion.etaid} action={accion} oprid={props408.action.oprid} nodori={props408.action.nodid} setListTask={props408.setListTask} 
              edoori={props408.action.edoid }  bucket={props408.init.operacion.bucket}  oprpath={props408.init.operacion.oprpath}
              tramite={props408.init.tramite} ></NodoResponder>            
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props408.action.nodfecha} estado={props408.action.edodesc} titulo={""}  folio={props408.action.oprfolio} insid={origen.insid}></NodoHeader>    
            <TramFiles traid={ props408.init.tramite.traid} etaid={props408.init.tramite.etaid} oprid={props408.init.tramite.oprid} insid={0}   />
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div> 
                <span>
                  <Button  className="p-button-success" label="Datos de la solicitud" icon="pi pi-external-link" onClick={() => onClick()} style={{ marginRight:"10px"}}/>
                </span>
                
                
                <div style={{ marginBottom:"10px" }}> </div>
              </div>            
              
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return (
    <div>
      { drawBody() }

      <Dialog header="DATOS DE LA QUEJA" visible={displayQueja} style={{ width: '50vw' }}  onHide={() => onHide()}>
        <div style={{ fontSize:"18px"}}>
          <TramFinQueja data={props408.init.tramite} bucket={props408.action.bukdesc}></TramFinQueja>
        </div>
      </Dialog>

    </div>
  )
}