interface IOpt { 
  [key: string]: any;		
}

export const EXEC_CONDITIONS = (data : IOpt, field : string)=> {
  let dataResult = data;  Object.keys(data[field].conditions).forEach( (value : string) => {
      dataResult = IDX_CONDITIONS[value]( data[field].id, dataResult );
    });
  return dataResult;  
}

const DISPLAY_GPO = ( name : string, dataprocess : IOpt) => {
  dataprocess[name].conditions["DISPLAY_GPO"].forEach( (elem:IOpt) => {
    if ( elem.value === dataprocess[name].value ){      
      elem.data.forEach( ( oitem:any ) => { 
        oitem.target.forEach( ( strGpo:String )=> {
          Object.keys(dataprocess).forEach( key => {
            if ( dataprocess[key].gpo ===strGpo){
              dataprocess[key].display = oitem.display
            }
          })
        })
      });
    }
  });
  return dataprocess;
}

const DISPLAY_GPO_OPR = ( name : string, dataprocess : IOpt) => {
  dataprocess[name].conditions["DISPLAY_GPO"].forEach( (elem:IOpt) => {

    // aqui va el cambio
    if ( elem.value === dataprocess[name].value ){      
      elem.data.forEach( ( oitem:any ) => { 
        oitem.target.forEach( ( strGpo:String )=> {
          Object.keys(dataprocess).forEach( key => {
            if ( dataprocess[key].gpo ===strGpo){
              dataprocess[key].display = oitem.display
            }
          })
        })
      });
    }
  });
  return dataprocess;
}

const DISPLAY_FIELDS = ( name : string, dataprocess : IOpt) => {
  dataprocess[name].conditions["DISPLAY_FIELDS"].forEach( (elem:IOpt) => {
    if ( elem.value === dataprocess[name].value ){      
      elem.target.forEach( (target:string) => {        
        dataprocess[target].display = elem.display;
      });
    }
  });
  return dataprocess;
}

const LINK_COMBO = ( name: string, dataprocess: IOpt) => {
  let fieldtarget = dataprocess[name].conditions["LINK_COMBO"].target
  dataprocess[fieldtarget].list =  dataprocess[name].value;
  return dataprocess;
}

const OPER_FIELD = ( name : string, dataprocess : IOpt) => {
  dataprocess[name].conditions["OPER_FIELD"].forEach( (elem:IOpt) => {
    if ( elem.value === dataprocess[name].value ){      
      elem.target.forEach( (target:string) => {
        dataprocess[target].oper = elem.oper;
      });
    }
  });
  return dataprocess;
}

const IDX_CONDITIONS : IOpt = {
  "DISPLAY_FIELDS" : DISPLAY_FIELDS,
  "DISPLAY_GPO": DISPLAY_GPO, 
  "DISPLAY_GPO_OPR": DISPLAY_GPO_OPR,
  "LINK_COMBO" :  LINK_COMBO,
  "OPER_FIELD" :  OPER_FIELD
};

