import React, { useContext, useState } from 'react';

import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


import { Act102ConAsigAsesor } from '../afd/action/fintech/Act102ConAsigAsesor';
import { Act103ConEnviarPryEfirma } from '../afd/action/fintech/Act103ConEnviarPryEfirma';
import { Act104ConCorregirPry } from '../afd/action/fintech/Act104ConCorregirPry';
import { Act105Desechar } from '../afd/action/fintech/Act105Desechar';
import { Act106CerrarMensaje } from '../afd/action/fintech/Act106CerrarMensaje';
import { Act108ConPrev } from '../afd/action/fintech/Act108ConPrev';
import { Act110ConPry } from '../afd/action/fintech/Act110ConPry';


import { Act111ConNoDesahogo } from '../afd/action/fintech/Act111ConNoDesahogo';
import { Act112UsrEnvFaltante } from '../afd/action/fintech/Act112UsrEnvFaltante';
import { Act113ConEnvNodesahogo } from '../afd/action/fintech/Act113ConEnvNodesahogo';
import { Act114ConEFirmaDesechar } from '../afd/action/fintech/Act114ConEFirmaDesechar';
import { Act115ConDesahCorrecto } from '../afd/action/fintech/Act115ConDesahCorrecto';
import { Act116EfirmaAdmision } from './action/fintech/Act116EfirmaAdmision';

import { Act119InsContestar } from '../afd/action/fintech/Act119InsContestar';


import { Act120ConRespItf } from '../afd/action/fintech/Act120ConRespItf';
import { Act121UsrAceptar } from '../afd/action/fintech/Act121UsrAceptar';
import { Act122UsrReclamacion } from '../afd/action/fintech/Act122UsrReclamacion';
import { Act124ConEnviarPryEfirma } from '../afd/action/fintech/Act124ConEnviarPryEfirma';
import { Act125ConAudIntConc } from '../afd/action/fintech/Act125ConAudIntConc';
import { Act126ConPryRecEfirma } from '../afd/action/fintech/Act126ConPryRecEfirma';
import { Act128ConRecFaltaDat } from './action/fintech/Act128ConRecFaltaDat';
import { Act129ConNotRecDes } from './action/fintech/Act129ConNotRecDes';


import { Act130ConNvaAud } from '../afd/action/fintech/Act130ConNvaAud';
import { Act131ConPryDesechar} from '../afd/action/fintech/Act131ConPryDesechar';
import { Act132ConRecDesechar} from '../afd/action/fintech/Act132ConRecDesechar';
import { Act134ConAudiencia } from '../afd/action/fintech/Act134ConAudiencia';
import { Act136UsrAud } from '../afd/action/fintech/Act136UsrAud';


import { Act142ConSalvoDer } from './action/fintech/Act142ConSalvoDer';
import { Act143ConUsrAusencia } from './action/fintech/Act143ConUsrAusencia';
import { Act144UsrJustif } from './action/fintech/Act144UsrJustif';
import { Act145UsrDictamen } from './action/fintech/Act145UsrDictamen';
import { Act146ConConvenio } from './action/fintech/Act146ConConvenio';
import { Act147ConEnviarActa } from './action/fintech/Act147ConEnviarActa';
import { Act148AllFirmarActa } from './action/fintech/Act148AllFirmarActa';
import { Act149ConAudDiferir } from './action/fintech/Act149ConAudDiferir';

import { Act150UsrSolDict } from './action/fintech/Act150UsrSolDict';
import { Act151InsCumplimiento } from './action/fintech/Act151InsCumplimiento';
import { Act152ConCumplimiento } from './action/fintech/Act152ConCumplimiento';
import { Act153ConJustAusencia } from './action/fintech/Act153ConJustAusencia';
import { Act154ConNoJustDesechar } from './action/fintech/Act154ConNoJustDesechar';
import { Act156ConSalvoDict } from './action/fintech/Act156ConSalvoDict';
import { Act157ConCrearExpDict } from './action/fintech/Act157ConCrearExpDict';
import { ACt158ConDicPry } from './action/fintech/ACt158ConDicPry';
import { Act159ConDictPryCorregir } from './action/fintech/Act159ConDictPryCorregir';


import { Act160ConInfoInst } from './action/fintech/Act160ConInfoUsr';
import { Act161InfoFaltante } from './action/fintech/Act161InfoFaltante';
import { Act162ConRevFirma } from './action/fintech/Act162ConRevFirma';
import { Act163ConDictEfirma } from './action/fintech/Act163ConDictEfirma';
import { Act165ConCorrida } from './action/fintech/Act165ConCorrida';



import { ACt166ConDicPryImpr } from './action/fintech/ACt166ConDicPryImpr';
import { ACt167ConInfoUsr } from './action/fintech/ACt167ConInfoInst';
import { Act168ConOfiTecImp } from './action/fintech/Act168ConOfiTecImp';
import { Act170ConDictAsignar } from './action/fintech/Act170ConDictAsignar';



import { Act171ConResulDict } from './action/fintech/Act171ConResulDict';
import { Act172ConPruebEFirma } from './action/fintech/Act172ConPruebEFirma';
import { Act173InstElementos } from './action/fintech/Act173InstElementos';
import { Act181ConPruebas } from './action/fintech/Act181ConPruebas';
import { Act182ConRegDict } from './action/fintech/Act182ConRegDict';
import { Act183ConQuejaPlzVenc } from './action/fintech/Act183ConQuejaPlzVenc';

import { ActAcuseGral } from './action/fintech/ActAcuseGral';



import { Act202ConSio } from './action/bcd/Act202ConSio';
import { Act203ConEnviarPry } from './action/bcd/Act203ConEnviarPry';
import { Act204ConCerrarMsj } from './action/bcd/Act204ConCerrarMsj';
import { Act205ConProyectoBcd } from './action/bcd/Act205ConProyectoBcd';
import { Act206CorregirBCD } from './action/bcd/Act206CorregirBCD';
import { Act207ConEfirmaBCD } from './action/bcd/Act207ConEfirmaBCD';
import { Act209ConImprocedencia } from './action/bcd/Act209ConImprocedencia';
import { Act210ConPrevencion } from './action/bcd/Act210ConPrevencion';
import { Act211UsrEnviarInfo } from './action/bcd/Act211UsrEnviarInfo';
import { Act214ConFaltaDatos } from './action/bcd/Act214ConFaltaDatos';


import { Act302ConSIO } from './action/dictamen/Act302ConSIO';
import { Act303ConEnviarPry } from './action/dictamen/Act303ConEnviarPry';
import { Act304ConCerrarMsj } from './action/dictamen/Act304ConCerrarMsj';
import { Act305ConProyectoDictamen } from './action/dictamen/Act305ConProyectoDictamen';
import { Act306CorregirDictamen } from './action/dictamen/Act306CorregirDictamen';
import { Act307ConEfirmaDictamen } from './action/dictamen/Act307ConEfirmaDictamen';
import { Act309ConImprocedencia } from './action/dictamen/Act309ConImprocedencia';
import { Act310ConPrevencion } from './action/dictamen/Act310ConPrevencion';
import { Act311UsrEnviarInfo } from './action/dictamen/Act311UsrEnviarInfo';

import { Act402ConSIO } from './action/defensoria/Act402ConSIO';
import { Act403ConEnviarPry } from './action/defensoria/Act403ConEnviarPry';
import { Act404ConCerrarMsj } from './action/defensoria/Act404ConCerrarMsj';
import { Act405ConProyectoDef } from './action/defensoria/Act405ConProyectoDef';
import { Act406CorregirDef } from './action/defensoria/Act406CorregirDef';
import { Act407ConEfirmaDef } from './action/defensoria/Act407ConEfirmaDef';
import { Act409ConImprocedencia } from './action/defensoria/Act409ConImprocedencia';
import { Act410ConPrevencion } from './action/defensoria/Act410ConPrevencion';
import { Act411UsrEnviarInfo } from './action/defensoria/Act411UsrEnviarInfo';

import { IActionProps, Types } from '../../reducers/Model';
import { AppContext } from '../../Context';
import { ViewName } from '../../reducers/ViewReducer';

import { Act3ConDesist } from './action/Act3ConDesist';
import { Act4UsrNotif } from './action/Act4UsrNotif';
import { Act118InsConfLec } from './action/fintech/Act118InsConfLec';
import { Act5ConDictAsigDirecto } from './action/fintech/Act5ConDictAsigDirecto';
import { Act215ConRespBcd } from './action/bcd/Act215ConRespBcd';





// ESTILOS 
// source: {},
interface IData { etaid:number,  action : any, oprid:number, nodori:number, setListTask:any,  edoori:number, bucket:string, oprpath:string, tramite:any }

export const NodoResponder: React.FC<IData> = (propsResp) => {
  const { state, dispatch } = useContext(AppContext);	
  const [display, setDisplay] = useState(false);
  const [action, setAction] = useState({accid:0, text:"", edodst:0, edoori:0});

  const onClick = ( accid : number, title: string, edodst: number, edoori: number) => {
    setDisplay(true);
    setAction({accid:accid, text: title, edodst:edodst, edoori:edoori} );
  }

  const onHide = () => {
    if (action.accid === 122){
      dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
    } else {
      setDisplay(false);
    }    
  }

  const drawAction = () => {
    let dataProps: IActionProps = { etaid:propsResp.etaid ,  edoori:propsResp.edoori, accid: action.accid,  edodst:action.edodst, oprid:propsResp.oprid, nodori:propsResp.nodori, 
      setListTask:propsResp.setListTask, bucket:propsResp.bucket , oprpath:propsResp.oprpath, tramite:propsResp.tramite }

    switch (action.accid) {      
      case 3:
        return <Act3ConDesist data={dataProps} onhide={onHide} />;
      case 4:
        return <Act4UsrNotif data={dataProps} onhide={onHide} />;      
        

      case 102:
        return <Act102ConAsigAsesor data={dataProps} onhide={onHide} />;
      case 103:
        return <Act103ConEnviarPryEfirma data={dataProps} onhide={onHide} />;
      case 104:
        return <Act104ConCorregirPry data={dataProps} onhide={onHide} />;
      case 105:
        return <Act105Desechar data={dataProps} onhide={onHide} />;
      case 106:
        return <Act106CerrarMensaje data={dataProps} onhide={onHide} />;
      case 108:
        return <Act108ConPrev data={dataProps} onhide={onHide} />;                 
      case 110:
        return <Act110ConPry data={dataProps} onhide={onHide} />;                 

                
      case 111:
        return <Act111ConNoDesahogo data={dataProps} onhide={onHide} />;        
      case 112:
        return <Act112UsrEnvFaltante data={dataProps} onhide={onHide} />;
      case 113:
        return <Act113ConEnvNodesahogo data={dataProps} onhide={onHide} />;
      case 114:
        return <Act114ConEFirmaDesechar data={dataProps} onhide={onHide} />;
      case 115:
        return <Act115ConDesahCorrecto data={dataProps} onhide={onHide} />;
      case 116:
        return <Act116EfirmaAdmision data={dataProps} onhide={onHide} />;
      case 118:
        return <Act118InsConfLec data={dataProps} onhide={onHide} />;                  
      case 119:
        return <Act119InsContestar data={dataProps} onhide={onHide} />;
      case 120:
        return <Act120ConRespItf data={dataProps} onhide={onHide} />;
      case 121:
        return <Act121UsrAceptar data={dataProps} onhide={onHide} />;        
      case 122:
        return <Act122UsrReclamacion data={dataProps} onhide={onHide} />;        
      case 124:
        return <Act124ConEnviarPryEfirma data={dataProps} onhide={onHide} />;
      case 125:
        return <Act125ConAudIntConc data={dataProps} onhide={onHide} />;        
      case 126:
        return <Act126ConPryRecEfirma data={dataProps} onhide={onHide} />;
      case 128:
        return <Act128ConRecFaltaDat data={dataProps} onhide={onHide} />;
      case 129:
        return <Act129ConNotRecDes data={dataProps} onhide={onHide} />;        
        
        

      case 130:
          return <Act130ConNvaAud data={dataProps} onhide={onHide} />;        
      case 131:
        return <Act131ConPryDesechar data={dataProps} onhide={onHide} />;      
      case 132:
        return <Act132ConRecDesechar data={dataProps} onhide={onHide} />;              
      case 134:
        return <Act134ConAudiencia data={dataProps} onhide={onHide} />;                
      case 136:
        return <Act136UsrAud data={dataProps} onhide={onHide} />;     


      case 142:
        return <Act142ConSalvoDer data={dataProps} onhide={onHide} />;
      case 143:
        return <Act143ConUsrAusencia data={dataProps} onhide={onHide} />;
      case 144:
        return <Act144UsrJustif data={dataProps} onhide={onHide} />;

        

      case 145:
        return <Act145UsrDictamen data={dataProps} onhide={onHide} />;
      case 146:
        return <Act146ConConvenio data={dataProps} onhide={onHide} />;
        
      case 147:
        return <Act147ConEnviarActa data={dataProps} onhide={onHide} />;
      case 148:
        return <Act148AllFirmarActa data={dataProps} onhide={onHide} />;
      case 149:
        return <Act149ConAudDiferir data={dataProps} onhide={onHide} />;

      case 150:
        return <Act150UsrSolDict data={dataProps} onhide={onHide} />;         
      case 151:
        return <Act151InsCumplimiento data={dataProps} onhide={onHide} />; 
      case 152:
        return <Act152ConCumplimiento data={dataProps} onhide={onHide} />;                 
      case 153:
        return <Act153ConJustAusencia data={dataProps} onhide={onHide} />;
      case 154:
        return <Act154ConNoJustDesechar data={dataProps} onhide={onHide} />;
      case 156:
        return <Act156ConSalvoDict data={dataProps} onhide={onHide} />;
      case 157:
        return <Act157ConCrearExpDict data={dataProps} onhide={onHide} />;
      case 158:
        return <ACt158ConDicPry data={dataProps} onhide={onHide} />;
      case 159:
        return <Act159ConDictPryCorregir data={dataProps} onhide={onHide} />;
        
      
      case 160:
        return <Act160ConInfoInst data={dataProps} onhide={onHide} />;
      case 161:
        return <Act161InfoFaltante data={dataProps} onhide={onHide} />;
      case 162:
        return <Act162ConRevFirma data={dataProps} onhide={onHide} />;      
      case 163:
        return <Act163ConDictEfirma data={dataProps} onhide={onHide} />;
      case 165:
        return <Act165ConCorrida data={dataProps} onhide={onHide} />;
                
      case 166:
        return <ACt166ConDicPryImpr data={dataProps} onhide={onHide} />;          
          
      case 167:
        return <ACt167ConInfoUsr data={dataProps} onhide={onHide} />;                  
      case 168:
        return <Act168ConOfiTecImp data={dataProps} onhide={onHide} />;
      case 170:
        return <Act170ConDictAsignar data={dataProps} onhide={onHide} />;
      case 5:
        return <Act5ConDictAsigDirecto data={dataProps} onhide={onHide} />;        

      case 171:
        return <Act171ConResulDict data={dataProps} onhide={onHide} />;        
      case 172:
        return <Act172ConPruebEFirma data={dataProps} onhide={onHide} />;        
        
      case 173:
        return <Act173InstElementos data={dataProps} onhide={onHide} />;        
      case 181:
        return <Act181ConPruebas data={dataProps} onhide={onHide} />;              
      case 182:
        return <Act182ConRegDict data={dataProps} onhide={onHide} />;                      
      case 183:
        return <Act183ConQuejaPlzVenc data={dataProps} onhide={onHide} />;                                      
        
        


      case 177:
      case 178:
        return <ActAcuseGral data={dataProps} onhide={onHide} />;        
     

      case 202:
        return <Act202ConSio data={dataProps} onhide={onHide} />;
      case 203:
        return <Act203ConEnviarPry data={dataProps} onhide={onHide} />;
      case 204:
        return <Act204ConCerrarMsj data={dataProps} onhide={onHide} />;
      case 205:
        return <Act205ConProyectoBcd data={dataProps} onhide={onHide} />;
      case 206:
        return <Act206CorregirBCD data={dataProps} onhide={onHide} />;        
      case 207:
        return <Act207ConEfirmaBCD data={dataProps} onhide={onHide} />;
      case 209:
        return <Act209ConImprocedencia data={dataProps} onhide={onHide} />;        
      case 210:
        return <Act210ConPrevencion data={dataProps} onhide={onHide} />;        
      case 211:
        return <Act211UsrEnviarInfo data={dataProps} onhide={onHide} />;                                
      case 214:
        return <Act214ConFaltaDatos data={dataProps} onhide={onHide} />;
      case 215:
        return <Act215ConRespBcd data={dataProps} onhide={onHide} />;

        

      case 302:
        return <Act302ConSIO data={dataProps} onhide={onHide} />;
      case 303:
        return <Act303ConEnviarPry data={dataProps} onhide={onHide} />;
      case 304:
        return <Act304ConCerrarMsj data={dataProps} onhide={onHide} />;
      case 305:
        return <Act305ConProyectoDictamen data={dataProps} onhide={onHide} />;
      case 306:
        return <Act306CorregirDictamen data={dataProps} onhide={onHide} />;        
      case 307:
        return <Act307ConEfirmaDictamen data={dataProps} onhide={onHide} />;
      case 309:
        return <Act309ConImprocedencia data={dataProps} onhide={onHide} />;        
      case 310:
        return <Act310ConPrevencion data={dataProps} onhide={onHide} />;        
      case 311:
        return <Act311UsrEnviarInfo data={dataProps} onhide={onHide} />;                
        
      case 402:
        return <Act402ConSIO data={dataProps} onhide={onHide} />;
      case 403:
        return <Act403ConEnviarPry data={dataProps} onhide={onHide} />;
      case 404:
        return <Act404ConCerrarMsj data={dataProps} onhide={onHide} />;
      case 405:
        return <Act405ConProyectoDef data={dataProps} onhide={onHide} />;
      case 406:
        return <Act406CorregirDef data={dataProps} onhide={onHide} />;        
      case 407:
        return <Act407ConEfirmaDef data={dataProps} onhide={onHide} />;
      case 409:
        return <Act409ConImprocedencia data={dataProps} onhide={onHide} />;        
      case 410:
        return <Act410ConPrevencion data={dataProps} onhide={onHide} />;        
      case 411:
        return <Act411UsrEnviarInfo data={dataProps} onhide={onHide} />;  

      default:
        return null;
    }      
  }

  const drawBody = () => {
    let  htmlButtons :any =  [];
    
    propsResp.action.forEach( (item : any) => {      
      if ( item.acctipo === 1 ) {
        htmlButtons.push(<Button key={"btn" + item.accid} label = {item.accdesc} style={{ minWidth:"180px", marginRight:"10px", marginTop:"10px", height:"32px" }} 
            onClick={() => onClick( item.accid, item.accdesc, item.edodst, item.edoori )} ></Button>)
      }
    });

  return (
      <div style={{ width:"100%", height:"15%", backgroundColor:"whitesmoke" }} >
        { htmlButtons }
        <div className="dialog-action">
          <Dialog header={action.text} visible={display} style={{ width: '70vw'  }}   
            onHide={() => onHide()}>
            { drawAction() }
          </Dialog>
        </div>
      </div>
    )
  }
  
  return drawBody();
}