import { IPersonData, PerfilTypes } from "../../../reducers/Model";
import TramitesSvc from "../../../services/TramitesSvc";

export const userFindByID = ( jsndata: IPersonData[], intPsnid: number, intInsid: number) => {
  let intIdx = 0;
  let psnData : IPersonData ;

  for( intIdx = 0; intIdx < jsndata.length; intIdx++){
    if ( intPsnid === jsndata[intIdx].psnid  
      && intInsid === jsndata[intIdx].insid   ) {

      psnData = { insid: jsndata[intIdx].insid,  empid:jsndata[intIdx].empid,  psnid : jsndata[intIdx].psnid,
        psnnombrecomp: jsndata[intIdx].psnnombrecomp, psncorreo: jsndata[intIdx].psncorreo, 
        pstnombre: jsndata[intIdx].pstnombre, inssiglas: jsndata[intIdx].inssiglas, active:jsndata[intIdx].active};

      return psnData;
    }
  }  
}         

export const userFindActive = ( jsndata: IPersonData[]) => {
  let intIdx = 0;
  let psnData : IPersonData ;

  if (jsndata.length === 1 ){
    psnData = { insid: jsndata[intIdx].insid,  empid:jsndata[intIdx].empid,  psnid : jsndata[intIdx].psnid,
      psnnombrecomp: jsndata[intIdx].psnnombrecomp, psncorreo: jsndata[intIdx].psncorreo, 
      pstnombre: jsndata[intIdx].pstnombre, inssiglas: jsndata[intIdx].inssiglas, active:jsndata[intIdx].active};
    return psnData;    
  } else {
    for( intIdx = 0; intIdx < jsndata.length; intIdx++){
      if ( jsndata[intIdx].active === 1    ) {
        psnData = { insid: jsndata[intIdx].insid,  empid:jsndata[intIdx].empid,  psnid : jsndata[intIdx].psnid,
          psnnombrecomp: jsndata[intIdx].psnnombrecomp, psncorreo: jsndata[intIdx].psncorreo, 
          pstnombre: jsndata[intIdx].pstnombre, inssiglas: jsndata[intIdx].inssiglas, active:jsndata[intIdx].active};
        return psnData;
      }
    }    
  }

}



export const userFindComboByID = ( jsndata: any[], intPsnid: number) => {
  let intIdx = 0;  
  let psnData : IPersonData ;

  for( intIdx = 0; intIdx < jsndata.length; intIdx++){
    if (intPsnid === jsndata[intIdx].value  ) {

      psnData = { insid: jsndata[intIdx].insid,  empid:jsndata[intIdx].empid,  psnid : jsndata[intIdx].psnid,
        psnnombrecomp: jsndata[intIdx].psnnombrecomp, psncorreo: jsndata[intIdx].psncorreo, 
        pstnombre: jsndata[intIdx].pstnombre, inssiglas: jsndata[intIdx].inssiglas, active:jsndata[intIdx].active};      
        return psnData;
    }
  }  
}


export const createLstPerfil = ( jsndata: any[], intPerfil: number) => {
  let intIdx = 0;  
  let lstData = [];

  for( intIdx = 0; intIdx < jsndata.length; intIdx++){    
    if (intPerfil === jsndata[intIdx].pflid  ) {      
      lstData.push( { label: jsndata[intIdx].psnnombrecomp , 
        value: jsndata[intIdx].insid + "|" + jsndata[intIdx].psnid  } );
    }
  }  
  return lstData;
}
