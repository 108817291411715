import React from 'react'
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';
import { NodoResponder } from '../../NodoResponder';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NodoHeader } from '../../NodoHeader';
import { Message } from 'primereact/message';

interface IData { action : any, init: any, setListTask:any  }

export const NNoticarEfirma: React.FC<IData> = (propsBody) => {
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });

  const drawNoLectura = () => {
    return (
      <div style={{top:"30%", width:"100%",  border:"1px solid white",
          position:"relative", color:"black",  alignItems:"center", justifyContent: "center" }}>            
          <div style={{  top:"30px", position:"relative"}}>
            
            <Message key="msgInfo"  severity="info" 
              text="Favor de confirmar la recepción de esta notificación con su firma electrónica o CIC por medio del botón: ACUSE DE NOTIFICACIÓN " 
              style={{fontSize:"40px", marginLeft: '.25em'}} />
              
            </div>
          <br/>
          <br/>
      </div>
    );
  }

  const drawBody = () => {
    try {
        let accion = propsBody.init.aristas;                  
        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={propsBody.init.operacion.etaid} action={accion} oprid={propsBody.action.oprid} nodori={propsBody.action.nodid} setListTask={propsBody.setListTask} 
              edoori={propsBody.action.edoid}  bucket={propsBody.init.operacion.bucket}  oprpath={propsBody.init.operacion.oprpath} 
              tramite={propsBody.init.tramite} />
              
            <NodoHeader remitente={propsBody.action.psnnombrecomp} fecha={propsBody.action.nodfecha} estado={propsBody.action.edodesc} titulo=""  
              folio={propsBody.action.oprfolio} insid={propsBody.action.oriinst} ></NodoHeader>                

            { drawNoLectura() }              
          </div>
        );
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }     
  }

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger" style={{ marginTop:"20px" }} >{pageState.msg}</div>
        </div>
      )
    }
	}

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {

			case EPageState.STEP0:
				return drawBody();

			default:
				return DrawMsg();
		}
	}

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}