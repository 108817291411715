import React, { useContext, useEffect} from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType,  IActionProps } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import {  tramiteAction } from '../ActUtil';
import { csscol } from '../../../../util/cssUtil';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';

interface IData { onhide: any, data : IActionProps  }

export const Act112UsrEnvFaltante : React.FC<IData> = ( act112Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });

  useEffect(() => {
  
    if ( Object.keys(state.user.cic ).length > 0 ) {
      let newData = {...dataProcess};

      // 1: firma electrónica
      if ( state.user.cic.tipofirma !== '1'){
        newData.titlefirma.display = EFieldDisplay.NO_SHOW;
        newData.dockey.display = EFieldDisplay.NO_SHOW;
        newData.doccert.display = EFieldDisplay.NO_SHOW;
        newData.efirma.display = EFieldDisplay.NO_SHOW;
        newData.titlefirmaCIC.display = EFieldDisplay.SHOW;
        onChangeAll(newData);
      }    
    }

  }, []);



  const DATA_PROCESS = {				
    TitDatDom:{ type:EFieldObj.H3, id:"TitDatDom", label:"Anexar la información solicitada" , display:EFieldDisplay.SHOW  },		
    mensaje:	{ gpo:'requerimiento',   id:"mensaje", type:EFieldObj.AREA,  save:1, label:"Escriba aqui su respuesta de los datos solicitados", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
    classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"", varrules:{} },

    titlefirma: { gpo:'firma', id:"titlefirma", type:EFieldObj.H3, label:"Favor de ingresar su Firma electrónica (eFirma SAT) para enviar la respuesta con sus anexos", display:EFieldDisplay.SHOW },

    docefirma:	{ gpo:'fintech',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo de respuesta (PDF)", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.PROYECTO  },

    doccert: { gpo:'firma',  id:"doccert",  type:EFieldObj.FILE, save:1,  label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },				
    dockey:	{ gpo:'firma',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
      classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
    efirma: { gpo:'firma',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, maxlength:24, 
      classNameLabel:"col-md-5", classNameObj:"col-md-3", classNameGroup:csscol.gpo12, placeholder:"Ingrese su contraseña"},

    titlefirmaCIC: { gpo:'firmacic', id:"titlefirmaCIC", type:EFieldObj.H3,  label:"La respuesta será firmada con su clave CIC", display:EFieldDisplay.NO_SHOW },


    TitAnexo:{ type:EFieldObj.H3, id:"TitAnexo", label:"Esta forma de captura tiene la capacidad de agregar hasta 5 archivos (PDFs). La suma del tamaño de los archivos debe ser menor a los 20MB" , display:EFieldDisplay.SHOW  },		
    anexo1:	{ gpo:'requerimiento',   id:"anexo1", type:EFieldObj.FILE, save:1, label:"Anexo documento 1", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },
    anexo2:	{ gpo:'requerimiento',   id:"anexo2", type:EFieldObj.FILE, save:1, label:"Anexo documento 2", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },        
    anexo3:	{ gpo:'requerimiento',   id:"anexo3", type:EFieldObj.FILE, save:1, label:"Anexo documento 3", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },
    anexo4:	{ gpo:'requerimiento',   id:"anexo4", type:EFieldObj.FILE, save:1, label:"Anexo documento 4", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO }
    
  }

  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )
  
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");        
    tramiteAction(act112Props,  FormType.ASIGNAR, dataProcess, state, dispatch,  setPageState, {});         
  }  

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act112Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}