import React, {Fragment, useState, useContext } from 'react'

import {ProgressBar} from 'primereact/progressbar';
import { MenuLogout } from '../menu/MenuLogout'

import {AppContext } from '../../Context';
import {FieldStrType, Types } from '../../reducers/Model';
import {usePageState, EPageState }  from '../../ui/uses/usePageState';
import { ViewName } from '../../reducers/ViewReducer'
import TramitesSvc from '../../services/TramitesSvc'

interface ICitaConsultar { clave: string; validar: string  }

interface ICitaDatos { 
	solclave : FieldStrType;
	solnombre: FieldStrType;
	solcurp: FieldStrType;
	solcorreo: FieldStrType;
	soltelcasa: FieldStrType;
	soltelmovil: FieldStrType;
	prcfecini: FieldStrType;
	oprname: FieldStrType;
	estado: FieldStrType;
	empnombre: FieldStrType;
	emppuesto: FieldStrType;
	direccion: FieldStrType;
	area: FieldStrType;
}

export const CitaConsultar: React.FC<ICitaConsultar> = (props) => {
	const { dispatch } = useContext(AppContext);

	const INIT : ICitaDatos = { 
		solclave: { value:"", msg:""}, 
    solnombre: { value:"", msg:""}, 
    solcurp: { value:"", msg:""}, 
		solcorreo: { value:"", msg:""}, 
		soltelcasa: { value:"", msg:""}, 
		soltelmovil: { value:"", msg:""}, 
    prcfecini: { value:"", msg:""}, 
    oprname: { value:"", msg:""}, 
		estado:{ value:"", msg:""}, 	
		empnombre:{ value:"", msg:""}, 
		emppuesto:{ value:"", msg:""}, 
		direccion:{ value:"", msg:""},
		area:{ value:"", msg:""} 
	};  

	const [cita, setCita] =  useState(INIT);
	const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

	const btnRegresarOnclick = () => {		
		dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_NUEVO, msg:"", refresh:""} });
	}

	const btnCancelarOnClick = () => {
		TramitesSvc.CitaDelete(props.clave)		
			.then( response => {    
				if ( response.data.edo  === "OK" ) {
					setPageState(EPageState.INIT, "La cita fue cancelada");
				} else {
					setPageState(EPageState.INIT, "Error al cancelar");
				}
			})
			.catch(e => {
				setPageState(EPageState.INIT, "Error en la comunicación ");
			})
	}

	const drawStep1 = () =>{
		//"color:#"
		let locEstilo = {}

		if (cita.estado.value === "ACTIVA")
			locEstilo = {backgroundColor:"#0CA227", color:"#FFFFFF"}
		else
			locEstilo= {backgroundColor:"#CCCCCC", color:"#C12626"}

		return (
			<div className="container"> 
				<div className="row"> 
					<div className="col xs-12 col-sm-12 col-md-12">
						{ pageState.msg !== "" ? <div className="alert alert-info"> {pageState.msg }</div>  : null}					
						<p><i><strong>USUARIO REGISTRADO</strong></i></p>
            <p>Nombre: <strong>{cita.solnombre.value}</strong></p>
            <p>CURP: <strong>{cita.solcurp.value}</strong></p>
            <p>E-mail: <strong>{cita.solcorreo.value}</strong></p>
            <p>Teléfono: <strong>{cita.soltelcasa.value}</strong></p>
            <p>Celular: <strong>{cita.soltelmovil.value}</strong></p>
            <p>Fecha de la cita: <strong>{cita.prcfecini.value}</strong></p>
            <p>Trámite: <strong>{cita.oprname.value}</strong></p>
						<p>Estado de la cita: <strong style={locEstilo} >{ cita.estado.value}</strong></p>						
						<br/>
						<p><i><strong>PERSONAL DE LA CONDUSEF QUE RECIBE A LA PERSONA</strong></i></p>
            <p>Nombre: <strong>{cita.empnombre.value}</strong></p>
            <p>Puesto: <strong>{cita.emppuesto.value}</strong></p>
						<p>Área: <strong> {cita.area.value}</strong></p>
						<p>Dirección: <strong>{cita.direccion.value}</strong></p>
						<br/>
						<button className="btn btn-danger" onClick={e => btnRegresarOnclick()} style={{width:"160px"} } >Regresar</button>
																												
						{ cita.estado.value === "ACTIVA" 
								? <button type="button" className="btn btn-default" style={{width:"160px", marginLeft:"10px"} } data-toggle="modal" data-target="#mdlCancelar">Cancelar cita</button>
								: null
						}						
						<br/>						
						<br/>
						<br/>						
				  </div>
				</div>
			</div>
		);
	}

	const drawStateCtrl = () => {
		let strMsg = "";
    switch ( pageState.state ) {

      case EPageState.INIT:        
        /* INFORMACION DE LA BASE DE DATOS */
				TramitesSvc.CitaGet( props.clave, props.validar )
					.then( response => {         
						let objTempcita = {...cita}; 
						if (response.data.solnombre !== "") {							
							objTempcita.solclave.value  = response.data.solclave;
              objTempcita.solnombre.value  = response.data.solnombre;
              objTempcita.solcurp.value  = response.data.solcurp;
              objTempcita.solcorreo.value  = response.data.solcorreo;
              objTempcita.soltelcasa.value  = response.data.soltelcasa === 'null' ? " " : response.data.soltelcasa;
              objTempcita.soltelmovil.value  = response.data.soltelmovil=== 'null' ? " " : response.data.soltelmovil;
              objTempcita.prcfecini.value= response.data.prcfecini;
							objTempcita.oprname.value  = response.data.oprname;
							objTempcita.empnombre.value  = response.data.empnombre;
							objTempcita.emppuesto.value  = response.data.emppuesto;
							objTempcita.direccion.value  = response.data.direccion;
							objTempcita.area.value  = response.data.area;

							// AGREGAR LOS ESTADOS DE LOS NODOS
							if (response.data.nodstatus === EPageState.IN_PROCESS)	
								objTempcita.estado.value = "ACTIVA";
							else if (response.data.nodstatus === EPageState.FINALIZED)	
								objTempcita.estado.value = "CONCLUIDA";
							else 
								objTempcita.estado.value  = "CANCELADA";
						}
						else {
							
							objTempcita.solclave.value  = "";            
							objTempcita.solnombre.value  = "";
							objTempcita.solcurp.value  = "";
							objTempcita.solcorreo.value  = "";
							objTempcita.soltelcasa.value  = "";
							objTempcita.soltelmovil.value  = "";
							objTempcita.prcfecini.value= "";
							objTempcita.oprname.value  = "";
							objTempcita.estado.value  ="NO EXISTE";
							objTempcita.empnombre.value  = "";
							objTempcita.emppuesto.value  = "";
							objTempcita.direccion.value  = "";
							objTempcita.area.value  = "";
							strMsg = "No se encontro una cita con esas claves"							
						}
						setPageState(EPageState.STEP1,strMsg);
						setCita(objTempcita);
						return null;
					})
					.catch( (e:any) => {						
						let objTempcita = {...cita};             
						objTempcita.solclave.value  = ""; 
						objTempcita.solnombre.value  = "";
						objTempcita.solcurp.value  = "";
						objTempcita.solcorreo.value  = "";
						objTempcita.soltelcasa.value  = "";
						objTempcita.soltelmovil.value  = "";
						objTempcita.prcfecini.value= "";
						objTempcita.oprname.value ="";
						objTempcita.estado.value  ="NO EXISTE";
						objTempcita.empnombre.value  = "";
						objTempcita.emppuesto.value  = "";
						objTempcita.direccion.value  = "";
						objTempcita.area.value  = "";												
						setCita(objTempcita);				
						setPageState(EPageState.STEP1,  "No fue posible comunicarse a la base de datos, favor de volver a intentar");
						console.log(e);
						return null;
					});  
					setPageState(EPageState.IN_PROCESS,  "");
        return null;

      case EPageState.IN_PROCESS:        
        return <ProgressBar mode="indeterminate" />

      case EPageState.STEP1:        
				return drawStep1();
					
      default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
    }
	}
	
	return (
	<Fragment>      
		<MenuLogout menu="Consultar cita" titulo="Consultar una cita registrada" header={true} />
		{ drawStateCtrl() }

		<div className="modal fade" id="mdlCancelar">
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
							<h4 className="modal-title">Confirmar cancelar cita</h4>
					</div>
					<div className="modal-body">
							<p>¿Está seguro de que desea cancelar la cita?</p>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-default" data-dismiss="modal">No</button>																						
						<button type="button" className="btn btn-default" data-dismiss="modal" onClick={ (evt) => btnCancelarOnClick()  }>Si</button>	
					</div>
				</div>
			</div>
		</div>
	</Fragment>
	);
}