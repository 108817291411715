import React, { Fragment, useContext} from 'react'

import './Procesos.css';
import { HeaderApp }from './HeaderApp';
import { Button } from 'primereact/button';
import { EPageState, usePageState } from '../../ui/uses/usePageState';
import TramitesSvc from '../../services/TramitesSvc';
import { AppContext } from '../../Context';
import { PerfilTypes } from '../../reducers/Model';
import { VerificarPerfil } from '../../util/seguridad';

export const ReportPluf: React.FC = () => {
  const { state } = useContext(AppContext);
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  const PflFintech = [PerfilTypes.FINTECH_DIR, PerfilTypes.FINTECH_SUBDIR];
  const PflDictamen = [PerfilTypes.DICTAMEN_DIR, PerfilTypes.DICTAMEN_PRY, PerfilTypes.DICTAMEN_REVISOR];

  const JSONToCSVConvertor = (JSONData : any, ReportTitle: string, ShowLabel: boolean) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var idx in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += idx + ",";
      }
      row = row.slice(0, -1);
      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "Reporte_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    //link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const onBtnGralClick = () => { 
    // que tipo de perfil tengo...
    var etaid : number = 0;
            
    if ( VerificarPerfil( state.user.pflid, PflFintech) )   {
      etaid = 0;
    } else if ( VerificarPerfil( state.user.pflid, PflDictamen) ) {
      etaid = 3;
    }      
             
    TramitesSvc.TraReporte(etaid)
    .then ((response) => {                      
      if ( response.status !== 200 ) {                 
        setPageState( EPageState.ERROR,  "¡No fue posible leer los datos recibidos !" );
      }                   
      const today = new Date();
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();
      
      let letra = '' + yyyy + mm + dd;

      JSONToCSVConvertor(response.data, "Fintech_" + letra , true);
    })
    .catch( (e) => {
      setPageState(EPageState.ERROR,  "¡No fue posible conectarse con el servidor, vuelva a intentar!")
    });
  }

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}



  const drawBody = () => {       
    return (      
    <div style={{ marginTop:"50px", marginLeft:"20px" }}>
      <Button  label="Generar reporte" icon="pi pi-check" onClick={() => onBtnGralClick() } />
    </div>
    );
  }

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			case EPageState.INIT:											
				return drawBody();
								
			case EPageState.ERROR:
				return (
					<div>
						<br/>
						<div className="alert alert-warning"> {pageState.msg } </div>
						<br/>
					</div>
				)
				
			default:
				return DrawMsg();
		}
	}
  
  
  return (
		<Fragment> 
      <HeaderApp />
      {  drawStateCtrl() }
		</Fragment>
	);
}