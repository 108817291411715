export function  VerificarPerfil ( psnpfl : number[], pflRol:number[]) {
  let Result = false;

  if (psnpfl!== null)
  {
    for(var i = 0; i < psnpfl.length; i++) {
      for(var j = 0; j < pflRol.length; j++) {
        if ( psnpfl[i] === pflRol[j] ){       
          Result = true;
          break;
        }
      }
      if (Result === true)
        break;
    }
  }
  
  return Result;    
}