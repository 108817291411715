import React, { useContext } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

//SERVICES
import  TramitesSvc  from '../../../../services/TramitesSvc';

//UTILS
import { userFindActive } from "../UsersUtil";

// PRIME
import {ProgressBar} from 'primereact/progressbar';


// ESTILOS 
import '../Action.css';
import { FormType, IAccion, IActionProps, INodoOrigen, IOperacion, RESP_FAVORABLE, SioOperacion, Types } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { ViewName } from '../../../../reducers/ViewReducer';

interface IData { onhide: any, data : IActionProps  }

export const Act307ConEfirmaDictamen : React.FC<IData> = ( act307Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  const DATA_PROCESS = {
    titleNotificar:{ type:EFieldObj.H5, id:"titleNotificar", label:"Notificar Dictamen",  display:EFieldDisplay.SHOW },

    numoficio:	{ gpo:"desechar",	id:"numoficio",    type:EFieldObj.TXT, save:1,  label:"Número de oficio",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, style:{width:"200px"},
      varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Número de oficio" },
          
    docefirma:	{ gpo:'desechar',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo en formato PDF", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.PROYECTO  },
          
    sentidoresp:	{ gpo:'desechar',   id:"sentidoresp", type:EFieldObj.CBO, save:1, label:"Sentido de la respuesta", oper:EFieldOper.UPDATE, value:1, rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-7", classNameObj:"col-md-5", classNameGroup:"col-md-12", placeholder:"Selecione una opción", list:RESP_FAVORABLE  },
              
    sioacep: { gpo:"header",	id:"sioacep",  type:EFieldObj.PINT, save:1,  label:"Estado de la solicitud",  oper:EFieldOper.READ, value: SioOperacion.RESPONDER_USUARIO, rules:[], 
      varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" }
  }
/*  ALTA AGREGAR UN ESTADO EN EL SIO
    sioacep: { gpo:"header",	id:"sioacep",  type:EFieldObj.PINT, save:1,  label:"Estado de la solicitud",  oper:EFieldOper.READ, value: SioOperacion.RESPONDER_USUARIO, rules:[], 
      varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
*/

  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )
  
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");          
    
    // COLCOAR CONSTANTES .... 
    let operacion : IOperacion = {  traid:act307Props.data.tramite.traid , etaid:act307Props.data.etaid, oprid:act307Props.data.oprid, bucket:act307Props.data.bucket,  oprpath:act307Props.data.oprpath };    
    let origen: INodoOrigen = { nodori: act307Props.data.nodori, edoori:act307Props.data.nodori, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
    let accion :IAccion = { accid:act307Props.data.accid, tdatid:FormType.ASIGNAR, data:dataProcess, edoid:act307Props.data.edodst,   optional:{} };
                        
    TramitesSvc.TraAction( JsonPost(operacion, origen, accion, state.user.cic) )           
    .then(response => {         				
      if ( response.status === 200 ) {					        
        dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
        return;
      } else {
        setPageState(EPageState.STEP0, "No fue posible registrar su queja, vuelva a intentarlo");			
        return ;					
      }
    })
    .catch( (e) => {                
      setPageState(EPageState.STEP0, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });
  }
      
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act307Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}