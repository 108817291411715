import React from "react"
import {Field} from  "./Field";

export const DrawFileBoxMsg: React.FC<any> = (props) => {

  const drawDivText = () => {
    let htmlAdd: JSX.Element[] = [];

    props.data.text.forEach( (item: any) => {
      htmlAdd.push(<div key={"divfile-" + item.section } style={{ marginBottom:"20px"}}> {item.section}</div>);      
    });
    
    return htmlAdd;
  }

  return (
    <div key={"divMsg-" + props.data.id} className="col-xs-12 col-md-12 col-lg-12" style={{borderStyle: "solid", borderColor:"#999999", borderWidth:"1px", marginTop:"20px"  }}> 
      <div className="col-md-8"> 
        <label   style={{ width:"98%", fontSize:18, fontWeight:"bold", paddingTop:"15px", bottom:"15px", position:"static" }}>{props.data.label}</label>

        <div className="cuerpo" style={{  textAlign:"justify", marginTop:"10px" }}>
          {drawDivText()}
          <Field { ...props} />              
          <br/>
        </div>
      </div>
      <div className="col-md-4" style={{ textAlign:"center", marginTop:"50px"}}>
        <img src={props.data.image} style={{ width:"40%", height:"40%", verticalAlign:"middle",  borderStyle: "solid", borderColor:"#999999", borderWidth:"1px" }} alt={props.data.alt} />
      </div>
      <br/>
    </div>
  );
}