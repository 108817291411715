import React, { useState } from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFinQueja } from '../../TramFinQueja';
import { Dialog } from 'primereact/dialog';
//import { Button } from 'primereact/button';

import '../Node.css';
import { Button } from 'primereact/button';
import TramitesSvc from '../../../../services/TramitesSvc';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N307ConConfLectura: React.FC<IData> = (props307) => {
  const [displayQueja, setDisplayQueja] = useState(false);


  const onHide = () => {
    setDisplayQueja(false);
  }


const onClickFile = (name:string,  bucket: string,  path:string) => {
  TramitesSvc.DocumentoGet( name, bucket, path);
}
  
  const drawBody = () => {
    try {
      if (props307.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props307.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props307.init.aristas;  
        let fecha = props307.action.nodfecha;
        let changeableData = contenido.accion.data;      

        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props307.init.operacion.etaid} action={accion} oprid={props307.action.oprid} nodori={props307.action.nodid} setListTask={props307.setListTask} 
              edoori={props307.action.edoid }  bucket={props307.init.operacion.bucket}  oprpath={props307.init.operacion.oprpath} 
              tramite={props307.init.tramite} ></NodoResponder>            
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props307.action.nodfecha} estado={props307.action.edodesc} titulo=""  folio={props307.action.oprfolio} insid={origen.insid} ></NodoHeader>    
            
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <br/>
              <div>                 
                Aviso de confirmación de lectura por parte del usuario realizado el dia {fecha }
              </div>                          
              <br/>
              <br/>
              <Button  className="p-button-success" label="Visualizar acuse recepción" icon="pi pi-external-link" 
                onClick={() => onClickFile(changeableData.notifdoc.value + "_" + changeableData.notifdoc.name, props307.action.bukdesc, changeableData.notifdoc.url)}  style={{ marginRight:"10px"}}/>              
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return (
    <div>
      { drawBody() }

      <Dialog header="DATOS DE LA QUEJA" visible={displayQueja} style={{ width: '50vw' }}  onHide={() => onHide()}>
        <div style={{ fontSize:"18px"}}>
          <TramFinQueja data={props307.init.tramite} bucket={props307.action.bukdesc}></TramFinQueja>
        </div>
      </Dialog>

    </div>
  )
}