import React, { useContext } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

//SERVICES
import  TramitesSvc  from '../../../../services/TramitesSvc';

//UTILS
import { userFindActive } from "../UsersUtil";

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType, IAccion, IActionProps, INodoOrigen, IOperacion,  Types } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { ViewName } from '../../../../reducers/ViewReducer';

interface IData { onhide: any, data : IActionProps  }

export const Act411UsrEnviarInfo : React.FC<IData> = ( act411Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  const DATA_PROCESS = {				
    TitDatDom:{ type:EFieldObj.H3, id:"TitDatDom", label:"Anexar la información solicitada" , display:EFieldDisplay.SHOW  },		
    mensaje:	{ gpo:'requerimiento',   id:"mensaje", type:EFieldObj.AREA,  save:1, label:"Escriba aqui su respuesta de los datos solicitados", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
    classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"", varrules:{} },

    TitAnexo:{ type:EFieldObj.H3, id:"TitAnexo", label:"Esta forma de captura tiene la capacidad de agregar hasta 5 archivos (PDFs). La suma del tamaño de los archivos debe ser menor a los 20MB" , display:EFieldDisplay.SHOW  },		
    anexo1:	{ gpo:'requerimiento',   id:"anexo1", type:EFieldObj.FILE, save:1, label:"Anexo documento 1", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },
    anexo2:	{ gpo:'requerimiento',   id:"anexo2", type:EFieldObj.FILE, save:1, label:"Anexo documento 2", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },        
    anexo3:	{ gpo:'requerimiento',   id:"anexo3", type:EFieldObj.FILE, save:1, label:"Anexo documento 3", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },
    anexo4:	{ gpo:'requerimiento',   id:"anexo4", type:EFieldObj.FILE, save:1, label:"Anexo documento 4", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO },
    anexo5:	{ gpo:'requerimiento',   id:"anexo5", type:EFieldObj.FILE, save:1, label:"Anexo documento 5", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.OTRO }
  }
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )
  
  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");
    
    
    // COLCOAR CONSTANTES .... 
    let operacion : IOperacion = {  traid:act411Props.data.tramite.traid , etaid:act411Props.data.etaid, oprid:act411Props.data.oprid, bucket:act411Props.data.bucket,  oprpath:act411Props.data.oprpath };    
    let origen: INodoOrigen = { nodori: act411Props.data.nodori, edoori:act411Props.data.nodori, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
    let accion :IAccion = { accid:act411Props.data.accid, tdatid:FormType.ASIGNAR, data:dataProcess, edoid:act411Props.data.edodst,   optional:{} };
                        
    TramitesSvc.TraAction( JsonPost(operacion, origen, accion, state.user.cic) )           
    .then(response => {         				
      if ( response.status === 200 ) {					
        dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"", refresh:""} });
        return;
      } else {
        setPageState(EPageState.STEP0, "No fue posible registrar su queja, vuelva a intentarlo");			
        return ;					
      }
    })
    .catch( (e) => {                
      setPageState(EPageState.STEP0, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
    });
  }

  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act411Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}