import React, { Fragment, useContext, useState, useEffect } from 'react'

import { ProgressBar } from 'primereact/progressbar';
import { AppContext } from '../../../../Context';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT,  RULE_NO_EMPTY, RULE_NUMBER_GREATER } from '../../../../form/fields/fieldRules';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { DelegacionType, FormType, EtapaType, IAccion, INodoOrigen, IOperacion, IPersonCiC, IPersonData, IPersonDirCIC, RUT_REDIRECT, 
	ESCOLARIDAD_TYPE, VIVIENDA_TYPE, SEXO_TYPE, EDOCIVIL_TYPE, OCUPACION_TYPE, TramitesType, Types, NACIONALIDADES, PARENTESCO, SioOperacion, InstIdTypes, SOLICITANTE_DEF_TYPE } from '../../../../reducers/Model';
import { ViewName } from '../../../../reducers/ViewReducer';
import TramitesSvc from '../../../../services/TramitesSvc';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';
import { userFindActive } from '../UsersUtil';
import { HeaderApp } from '../../../main/HeaderApp';

import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';

import imageCURP from '../../../../images/CURP.jpg';
import imageRFC from '../../../../images/RFC_SAT.png';
import imageCFE from '../../../../images/CFE.png';
import imageINGRESOS from '../../../../images/ingresos.png';
import imageFORMATO from '../../../../images/formato.png';
import imageCartaPoder from '../../../../images/cartapoder.png';

import { Accion, NodoEstado } from '../../AfdUtil';
import { validateFilesSize } from '../../../../util/fileUtil';
import '../Action.css';
import { csscol } from '../../../../util/cssUtil';

interface ICatalogos { 
	[key: string]: any;		
}

export const Act401UsrDefensoria: React.FC = () => {
	const { state, dispatch } = useContext(AppContext);	
	const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
	const [queja, setQueja] = useState<ICatalogos>({});
	const [usrPerson, setUsrPerson] = useState<IPersonData>();
	const [initForm, setInitForm] = useState(0);

  const BtnEnvia_Click = () => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"",refresh:""} });
  }

	const BtnRegresarRut_Click = () => {    
		RUT_REDIRECT()
	}

	const btnSeguimientoOnClick = () => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"",refresh:""} });
  }
	
	const solmontoOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solmonto.value = evt.value
		onChangeAll(newData);
	}

	const solingsolOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solingsol.value = evt.value
		onChangeAll(newData);
	}

	const solegrsolOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solegrsol.value = evt.value
		onChangeAll(newData);
	}
	const solingrconyOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solingrcony.value = evt.value
		onChangeAll(newData);
	}
	const solegrconyOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solegrcony.value = evt.value
		onChangeAll(newData);
	}

	const solviviendavalorOnChange = (evt: any) => {				
		let newData = {...dataProcess}
		newData.solviviendavalor.value = evt.value
		onChangeAll(newData);
	}
		
	const txtCodPosOnChange = (evt: any) =>{			
		if ( evt.target.value.length === 5 && !isNaN(evt.target.value) 
			&& evt.target.value !== dataProcess.solcodpos.value ) {		

			TramitesSvc.GeoColoniaCPGet(evt.target.value)		
			.then( (response: any) => {    
				if ( response.data.length > 0) {
					let dato = 	response.data[0];	
					let newData = {...dataProcess};
					newData.solempentfed.list = [{ label: dato.estado , value: dato.id_edo }];
					newData.solempentfed.value = dato.id_edo;
					newData.solempentfed.error = [];
					newData.solempmunicipio.list = [{ label: dato.municipio , value: dato.id_mun }];
					newData.solempmunicipio.value = dato.id_mun;
					newData.solempmunicipio.error = [];
					newData.solempcolonia.list = [];
					newData.solempcolonia.value = dato.id_col;
					newData.solempcolonia.error = [];
					newData.solempcodpos.error = [];
	
					response.data.forEach((item : any) => { 
						newData.solempcolonia.list.push( { label:item.colonia , value:item.id_col } )
					});												
					onChangeAll(newData);					
				} else {
					let newData = {...dataProcess};
					newData.solcodpos.error.push("El código postal es invalido");
					onChangeAll(newData);					
				}
				return;
			})
			.catch( (e:any) => {
				setPageState(EPageState.STEP1,"No fue posible obtener los datos del Código Postal");
				return;
			})
		}		
		onItemChange(evt);
	}
		
	const solOcupacionOnChange = (evt: any) =>{			
		let newData = {...dataProcess};		
		let intValor =  evt.target.value;
		newData.solocupacion.value = intValor;


/*		
		{ label:"DESEMPLEADO", value:1 },
		{ label:"JUBILADO", value:2 },
		{ label:"PENSIONADO", value:3 },
		{ label:"CONYUGE DE PENS/JUBILADO", value:4 },
		{ label:"ENFERMO TERMINAL", value:5 },
		{ label:"DISCAPACITADO", value:6 },
		{ label:"EVENTUAL", value:7 },
		{ label:"SUBEMPLEADO", value:8 },
		{ label:"EMPLEADO", value:9 },
		{ label:"EMPRESARIO", value:10 },
		{ label:"ESTUDIANTE", value:11 },
		{ label:"AMA DE CASA", value:12 },
		{ label:"EJERCICIO LIBRE", value:13 }
*/


		if ( intValor === 7 || intValor === 8 || intValor === 9 || intValor === 10 ) {
			newData.solempresalab.display = EFieldDisplay.SHOW;
			newData.solempcodpos.display = EFieldDisplay.SHOW;
			newData.solempentfed.display = EFieldDisplay.SHOW;
			newData.solempmunicipio.display = EFieldDisplay.SHOW;
			newData.solempcolonia.display = EFieldDisplay.SHOW;
			newData.solempcalle.display = EFieldDisplay.SHOW;
			newData.solempnumext.display = EFieldDisplay.SHOW;
			newData.solempnumint.display = EFieldDisplay.SHOW;

			newData.docingresos.display = EFieldDisplay.SHOW;

			newData.docdesempleado.display = EFieldDisplay.NO_SHOW;
			newData.docjubilado.display = EFieldDisplay.NO_SHOW;
			
		} else {
			newData.solempresalab.display = EFieldDisplay.NO_SHOW;
			newData.solempcodpos.display = EFieldDisplay.NO_SHOW;
			newData.solempentfed.display = EFieldDisplay.NO_SHOW;
			newData.solempmunicipio.display = EFieldDisplay.NO_SHOW;
			newData.solempcolonia.display = EFieldDisplay.NO_SHOW;
			newData.solempcalle.display = EFieldDisplay.NO_SHOW;
			newData.solempnumext.display = EFieldDisplay.NO_SHOW;
			newData.solempnumint.display = EFieldDisplay.NO_SHOW;

			newData.docingresos.display = EFieldDisplay.NO_SHOW;
			if ( intValor === 2 || intValor === 3 ){
				newData.docjubilado.display = EFieldDisplay.SHOW;
				newData.docdesempleado.display = EFieldDisplay.NO_SHOW;				
			}else {
				newData.docjubilado.display = EFieldDisplay.NO_SHOW;
				newData.docdesempleado.display = EFieldDisplay.SHOW;				
			}
		}
		onChangeAll(newData);
	}
	
	const DATA_PROCESS = {				
		traid: { gpo:"header",	id:"traid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:TramitesType.SIO, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tdatid: { gpo:"header",	id:"tdatid",  type:EFieldObj.PINT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value: FormType.SOLICITUD, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramdelid: { gpo:"header",	id:"tramdelid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:DelegacionType.METRO_CENTRAL, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramseq: { gpo:"header",	id:"tramseq",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:"", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },		

		tramfecrec: { gpo:"defensoria",	id:"tramfecrec",  type:EFieldObj.TXT, save:1,  label:"Fecha de recepción",  oper:EFieldOper.READ, value:undefined, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramdoc: { gpo:'header',  id:"tramdoc",  type:EFieldObj.FILE, save:1,  label:"Trámite solicitud", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.SOLICITUD  },		

		psntipo: { gpo:'defensoria', id:"psntipo", type:EFieldObj.PINT, save:1, label:"formato", oper:EFieldOper.UPDATE, value:1, rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:""},

		tranombre: { gpo:"defensoria",	id:"tranombre",  type:EFieldObj.TXT, save:1,  label:"Nombre",  oper:EFieldOper.READ, value:"", rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		

		titasunto:{ type:EFieldObj.H3, id:"titasunto", label:"INSTITUCIÓN FINANCIERA", display:EFieldDisplay.SHOW },		
		tramfolio: { gpo:"defensoria",	id:"tramfolio",  type:EFieldObj.TXT, save:1,  label:"Folio SIO",  oper:EFieldOper.READ, value:"", rules:[], varrules:{ }, error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"" },

			


		solinst: { gpo:'defensoria',   id:"solinst",      type:EFieldObj.TXT, save:1,   label:"Institución financiera", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-10", placeholder:"Institución"},
		solsector: { gpo:'defensoria',   id:"solsector",      type:EFieldObj.TXT, save:1,   label:"Tipo de Institución", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Institución"},
		solmonto: { gpo:'defensoria',   id:"solmonto",      type:EFieldObj.INUM, save:1,   label:"Monto reclamado", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
			onChange:solmontoOnChange, max:2300000, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"$", maxlength:"6"},

		pretensiones:		{ gpo:"defensoria",   id:"pretensiones", type:EFieldObj.AREA,   save:1, label:"Pretensiones (MAX: 4000 caracteres)", oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ],  error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:csscol.gpo12, placeholder:"Relatoría de las pretenciones", rows:"4", cols:"40", maxlength:"4000"},
			
		titDatPer:{ type:EFieldObj.H3, id:"titDatPer", label:"DATOS GENERALES DEL USUARIO", display:EFieldDisplay.SHOW },
		soltipo: { gpo:'defensoria',  id:"soltipo",  type:EFieldObj.CBO, save:1,   label:"Seleccione según sea su caso",   oper:EFieldOper.UPDATE, value: "", list: [], rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:csscol.gpo12, placeholder:"seleccionar" },

		solnombre: { gpo:'defensoria',	id:"solnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:"32",
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		solpaterno:	{ gpo:'defensoriacd',	id:"solpaterno",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:"32",
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu primer apellido" },
		solmaterno:	{ gpo:'defensoria',	id:"solmaterno",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:"32",
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu segundo apellido" },

		solcodpos:		{ gpo:'defensoria',	id:"solcodpos",      type:EFieldObj.PINT, save:1,  label:"Código postal", oper:EFieldOper.READ, value:0, rules:[RULE_NUMBER_GREATER], varrules:{ }, error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Ingresa tu código postal", maxlength:5 },
		solentfed:		{ gpo:'defensoria',  id:"solentfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Selecciona la Entidad Federativa"}, 
		solmunicipio:  { gpo:'defensoria',  id:"solmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{},  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						


		solcolonia:    { gpo:'defensoria',  id:"solcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Selecciona la Colonia" },              
		solcalle:   { gpo:'defensoria',   id:"solcalle",       type:EFieldObj.TXT, save:1,   label:"Calle",         oper:EFieldOper.READ, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón"}, 						
		solnumext:     { gpo:'defensoria',   id:"solnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Número exterior"}, 										
		solnumint:     { gpo:'defensoria',   id:"solnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Número interior"}, 	

		solcorreo: { gpo:'defensoria',	id:"solcorreo",	type:EFieldObj.TXT,  save:1, label:"Correo Electrónico", oper:EFieldOper.READ, value:"", rules:[], uppercase:true,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:csscol.gpo12, placeholder:"Ingresa tu correo"},												

		soltelpar:   { gpo:'defensoria',   id:"soltelpar",       type:EFieldObj.PINT, save:1,   label:"Tel. particular",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"celular o fijo", maxlength:10 },

		soltelofi:     { gpo:'defensoria',   id:"soltelofi",      type:EFieldObj.PINT, save:1,   label:"Tel. oficina", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"celular o fijo", maxlength:10}, 										
		solotros:     { gpo:'defensoria',   id:"solotros",      type:EFieldObj.PINT, save:1,   label:"Otro", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:" ",  maxlength:10}, 				
			
		solnacionalidad: { gpo:'defensoria',	id:"solnacionalidad",	type:EFieldObj.CBO,  save:1, label:"Nacionalidad", oper:EFieldOper.UPDATE, value: "MEXICANA", list: NACIONALIDADES, rules:[RULE_NO_EMPTY],  
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Ingresa tu nacionalidad" },	


		solcurp: { gpo:'defensoria',	id:"solcurp",  type:EFieldObj.TXT, save:1,  label:"CURP",       oper:EFieldOper.READ, value:"", rules:[RULE_MIN_LENGHT], uppercase:true, maxlength:18,
			varrules:{ minvalue:18 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6",classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Ingresa tu CURP", info:""},								
		solrfc: { gpo:'defensoria',	id:"solrfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Ingresa tu rfc", maxlength:13 },			

		titDatRep:{ type:EFieldObj.H3, id:"titDatRep", label:"APODERADO O REPRESENTANTE LEGAL", display:EFieldDisplay.NO_SHOW },			
		repcurp: { gpo:'defensoria',	id:"repcurp", type:EFieldObj.TXT, save:1,  label:"CURP", oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY], uppercase:true, maxlength:18, 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"col-md-6",classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu CURP", info:"CURP"},
		reprfc: { gpo:'defensoria',	id:"reprfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		reptipo: { gpo:'defensoria',	id:"reptipo",  type:EFieldObj.TXT, save:1,  label:"Tipo de poder",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		repnombre:		{ gpo:'defensoria',	id:"repnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:"32",
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa tu nombre" },
		reppat:		{ gpo:'defensoria',	id:"reppat",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, maxlength:"32",
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el primer apellido" },
		repmat:		{ gpo:'defensoria',	id:"repmat",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.READ, value:"", rules:[], uppercase:true, maxlength:"32",
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ingresa el segundo apellido" },



		titSocEco:{ type:EFieldObj.H3, id:"titSocEco", label:"DATOS SOCIOECONÓMICOS", display:EFieldDisplay.SHOW},		
		soledad:{ gpo:'defensoria',	id:"soledad",	type:EFieldObj.PINT, save:1,  label:"Edad (Años)", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], uppercase:true,  maxlength:"2",
			varrules:{ }, error: [], display:EFieldDisplay.SHOW,  classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2",  placeholder:""},
		solsexo:{ gpo:'defensoria',	id:"solsexo",	type:EFieldObj.CBO, save:1,  label:"Sexo", oper:EFieldOper.UPDATE, list: [], value:"", rules:[RULE_NO_EMPTY], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW,  classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3",  placeholder:""},			
		soledocivil:		{ gpo:'defensoria',  id:"soledocivil", type:EFieldObj.CBO, save:1,   label:"Estado civil",   oper:EFieldOper.UPDATE, value: "", list: [], rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Seleccione su estado civil"}, 
		solocupacion:  { gpo:'defensoria',   id:"solocupacion", type:EFieldObj.CBO, save:1,   label:"Ocupación actual", oper:EFieldOper.UPDATE, value:"", list: [], rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Ocupación",  onChange: solOcupacionOnChange  },
			
		solescolaridad:{ gpo:'defensoria',  id:"solescolaridad", type:EFieldObj.CBO, save:1,   label:"Escolaridad",   oper:EFieldOper.UPDATE, value: "", list: [], rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,		
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Seleccione su escolaridad"}, 
		solultgrado:{ gpo:'defensoria',  id:"solultgrado", type:EFieldObj.TXT, save:1,   label:"Último grado",   oper:EFieldOper.UPDATE, value: "", list: [], rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,	uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"Último grado", maxlength:24 }, 
		solprofesion:   { gpo:'defensoria',   id:"solprofesion", type:EFieldObj.TXT, save:1,   label:"Profesión", oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"",  maxlength:32 },

		titSegSocial:{ type:EFieldObj.H3, id:"titSegSocial", label:"SEGURIDAD SOCIAL", display:EFieldDisplay.SHOW},		
		solimss:{ gpo:'defensoria',  id:"solimss", type:EFieldObj.TXT, save:1,   label:"No. IMSS",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Seguridad social IMSS", maxlength:11}, 
		solissste:{ gpo:'defensoria',  id:"solissste", type:EFieldObj.TXT, save:1,   label:"No. ISSSTE",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"Seguridad social ISSSTE", maxlength:11}, 

		titDepen:{ type:EFieldObj.H3, id:"titDepen", label:"DEPENDIENTES ECONÓMICOS", display:EFieldDisplay.SHOW},	
    soldependientes:	{ gpo:'defensoria',	id:"soldependientes",	type:EFieldObj.PINT, save:1,  label:"No. dependientes económicos", oper:EFieldOper.UPDATE, value:0, rules:[], varrules:{}, error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Dependientes económicos", maxlength:"2" },
    soldepparentesco:	{ gpo:"defensoria",   id:"soldepparentesco",	type:EFieldObj.CBO, save:1,   label:"Parentesco de los dependientes", oper:EFieldOper.UPDATE, value:"", list:PARENTESCO, rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-8", placeholder:"", maxlength:"64" },

	      		
			
		titVivienda:{ type:EFieldObj.H3, id:"titVivienda", label:"DATOS DE LA VIVIENDA QUE HABITA", display:EFieldDisplay.SHOW},	
		solvivienda:	{ gpo:"defensoria",   id:"solvivienda",      type:EFieldObj.CBO, save:1,   label:"Datos de la vivienda", oper:EFieldOper.UPDATE, value:"",  list: [], rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-3", placeholder:"" },
		solviviendavalor:	{ gpo:"defensoria",   id:"solviviendavalor",      type:EFieldObj.INUM, save:1,   label:"Valor catastral predial de la casa", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, onChange:solviviendavalorOnChange, max:1000000,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"", maxlength:"7" },

		titIngFam:{ type:EFieldObj.H3, id:"titIngFam", label:"INGRESOS FAMILIARES", display:EFieldDisplay.SHOW},	
	      		
		solempresalab: { gpo:'defensoria',   id:"solempresalab",      type:EFieldObj.TXT, save:1,   label:"Nombre de la empresa (Razón social)", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.NO_SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:csscol.gpo12, placeholder:"Nombre de la empresa",  maxlength:96}, 	
    solempcodpos:		{ gpo:'defensoria',	id:"solempcodpos",      type:EFieldObj.MSK, save:1,  label:"CP", oper:EFieldOper.UPDATE, value:0, rules:[RULE_NUMBER_GREATER], varrules:{}, error: [], display:EFieldDisplay.NO_SHOW,
			classNameLabel:"col-md-1", classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Ingresa tu código postal", onChange: txtCodPosOnChange, info:"CP", mask:"99999"  },
		solempentfed:		{ gpo:'defensoria',  id:"solempentfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Selecciona la Entidad Federativa"}, 
		solempmunicipio:  { gpo:'defensoria',  id:"solempmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{},  display:EFieldDisplay.NO_SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						
		solempcolonia:    { gpo:'defensoria',  id:"solempcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.NO_SHOW,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Selecciona la Colonia" },              
		solempcalle:   { gpo:'defensoria',   id:"solempcalle",       type:EFieldObj.TXT, save:1,   label:"Calle",         oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.NO_SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-4", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón", maxlength:"32"  }, 						
		solempnumext:     { gpo:'defensoria',   id:"solempnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.NO_SHOW,uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Número exterior", maxlength:"16" }, 						
		solempnumint:     { gpo:'defensoria',   id:"solempnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.NO_SHOW, uppercase:true,
			classNameLabel:csscol.gpo12, classNameObj:csscol.gpo12, classNameGroup:"col-md-2", placeholder:"Número interior", maxlength:"16" }, 


		solingsol:	{ gpo:"defensoria",   id:"solingsol",      type:EFieldObj.INUM, save:1,   label:"Ingreso mensual neto del solicitante", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, onChange:solingsolOnChange, max:1000000,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"$", maxlength:"6"  },									
		solegrsol:	{ gpo:"defensoria",   id:"solegrsol",      type:EFieldObj.INUM, save:1,   label:"Egreso mensual neto ", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], display:EFieldDisplay.SHOW, onChange:solegrsolOnChange, max:1000000,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"$",  maxlength:"6"   },
		solingrcony:	{ gpo:"defensoria",   id:"solingrcony",      type:EFieldObj.INUM, save:1,   label:"Ingreso mensual neto del conyuge u otro", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, onChange:solingrconyOnChange, max:1000000,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"$", maxlength:"6"  },									
		solegrcony:	{ gpo:"defensoria",   id:"solegrcony",      type:EFieldObj.INUM, save:1,   label:"Egreso mensual neto del conyuge u otro", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, onChange:solegrconyOnChange, max:1000000,
			classNameLabel:csscol.gpo12,classNameObj:csscol.gpo12, classNameGroup:"col-md-6", placeholder:"$",  maxlength:"6"   },


		titObs:{ type:EFieldObj.H5, id:"titObs", label:"Observaciones o datos adicionales que el solicitante proporcione para su valoración al momento de estudiar su solicitud de defensoría", display:EFieldDisplay.SHOW },					

		solobs:		{ gpo:"fintech",   id:"solobs",     type:EFieldObj.AREA,   save:1, label:"Observaciones (MAX: 4000 caracteres)", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Datos adicionales", rows:"10", cols:"40", maxlength:"4000"},


		titDocReglas:{ type:EFieldObj.H5, id:"titDocReglas", label:"Esta forma de captura solo admite archivos en formato PDF y la suma del tamaño de los archivos debe ser menor a 40MB.", display:EFieldDisplay.SHOW },				
		docrepresentante:	{ gpo:'bcd',  id:"docrepresentante",  type:EFieldObj.FILE, save:1,   label:"CARTA PODER DEL REPRESENTANTE LEGAL",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"Representante", accept:"application/pdf", typedoc:FormType.CARTA_PODER_REPRESENTANTE,
			text:[ { section:"Carta Poder del representante legal"}] }, 

		docrapoderado:	{ gpo:'bcd',  id:"docrapoderado",  type:EFieldObj.FILE, save:1,   label:"CARTA PODER APODERADO LEGAL",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCartaPoder, alt:"APODERADO", accept:"application/pdf", typedoc:FormType.CARTA_PODER_APODERADO,
			text:[ { section:"Carta Poder del apoderado legal"}] }, 

		doccurp:	{ gpo:'defensoria',  id:"doccurp",  type:EFieldObj.FILE, save:1,   label:"CURP",   oper:EFieldOper.UPDATE, value: undefined, list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCURP, alt:"Estado de cuenta", accept:"application/pdf", typedoc:FormType.CURP,
			text:[ { section:"La Clave Única de Registro de Población, mejor conocida como CURP sirve para registrar en forma individual a todas las personas que residen en México, nacionales y extranjeras, así como a las mexicanas y mexicanos que radican en otros países."}] }, 

		docrfc:	{ gpo:'defensoria',  id:"docrfc",  type:EFieldObj.FILE, save:1,   label:"RFC",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageRFC, alt:"CURP", accept:"application/pdf", typedoc:FormType.RFC,
			text:[ { section:"El Registro Federal de Contribuyentes (RFC). Es un padrón en el que el SAT de la Secretaría de Hacienda y Crédito Público (SHCP) registra a todas las personas y organizaciones nacionales que realizan actividades económicas formales. Debido a que están sujetas al pago obligatorio de impuestos."}] }, 

		docdomicilio:	{ gpo:'defensoria',  id:"docdomicilio",  type:EFieldObj.FILE, save:1,   label:"Comprobante de domicilio",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageCFE, alt:"domicilio", accept:"application/pdf", typedoc:FormType.COMPROBANTE_DOMICILIO,
			text:[ { section:"Comprobante de domilicio"}] }, 

		docdictamen:	{ gpo:'defensoria',  id:"docdictamen",  type:EFieldObj.FILE, save:1,   label:"Dictamen Técnico",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageFORMATO, alt:"dictamen", accept:"application/pdf", typedoc:FormType.DICTAMEN,
			text:[ { section:"Dictamen Técnico u oficio emitido por la Dirección de Dictaminación de la CONDUSEF, según sea el caso."}] }, 			

		docingresos:	{ gpo:'defensoria',  id:"docingresos",  type:EFieldObj.FILE, save:1,   label:"Comprobante de ingresos",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageINGRESOS, alt:"domicilio", accept:"application/pdf", typedoc:FormType.COMPROBANTE_INGRESOS,
			text:[ { section:"Ejemplos.- recibos de nómina, carta patronal, declaración de impuestos (ejercicio fiscal completo- no acuse-), recibos de honorarios. (En caso de ser adulto mayor no se deberá presentar)."}] }, 
			
		docdesempleado:	{ gpo:'defensoria',  id:"docdesempleado",  type:EFieldObj.FILE, save:1,   label:"Escrito de estar desempleado",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageFORMATO, alt:"docdesempleado", accept:"application/pdf", typedoc:FormType.ESCRITO_VERDAD,
			text:[ { section:"Para el caso de ser desempleado, escrito bajo protesta de decir verdad en que se señale la actividad que se realiza y el ingreso aproximado mensual que se percibe por ello, dicho escrito deberá estar firmado por usted y 2 testigos (adjuntar la identificación de estos últimos)."}] }, 			

		docjubilado:	{ gpo:'defensoria',  id:"docjubilado",  type:EFieldObj.FILE, save:1,   label:"Pensionado o Jubilado",   oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.NO_SHOW,
			classNameLabel:"", classNameObj:"", classNameGroup:csscol.gpo12, placeholder:"", 
			info:"BOX", image:imageFORMATO, alt:"jubilado", accept:"application/pdf", typedoc:FormType.PENSION_JUBILADO,
			text:[ { section:"Documento que acredite tal situación de pensión o jubilado"}] }, 			
			
	
		titlefirma: { type:EFieldObj.H3, id:"titlefirma", label:"Favor de ingresar su Firma electrónica (eFirma SAT) para enviar sus documentos y firmar la presente queja", display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'defensoria',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
		doccert: { gpo:'defensoria',  id:"doccert",  type:EFieldObj.FILE, save:1,  label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:csscol.gpo12, placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },		
		efirma: { gpo:'defensoria',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, maxlength:24, 
			classNameLabel:"col-md-5", classNameObj:"col-md-3", classNameGroup:csscol.gpo12, placeholder:"Ingrese su contraseña"},

		titlefirmaCIC: { type:EFieldObj.H3, id:"titlefirmaCIC", label:"La solicitud será firmada con su clave CIC", display:EFieldDisplay.NO_SHOW },
	
		docacuse: { gpo:'header',  id:"docacuse",  type:EFieldObj.FILE, save:1,  label:"Acuse", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.ACUSE  },

		sioacep: { gpo:"header",	id:"sioacep",  type:EFieldObj.PINT, save:1,  label:"Estado de la solicitud",  oper:EFieldOper.READ, value: SioOperacion.INICIAR, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" }
	}

	const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )

	const setSolicitante = (newData : any, dataPsn:IPersonCiC, dataPsnDir:IPersonDirCIC, tipo:number  ) => {

		if (tipo === 1) {
			newData.solcurp.value = dataPsn.psncurp;
			newData.solcurp.oper = EFieldOper.READ;
			newData.solcurp.error = [];

			newData.solrfc.value = dataPsn.psncurp.substring(0,10);		
			//newData.solsexo.value = fecSexo(dataPsn.psncurp);

			newData.solnombre.value = dataPsn.psnnombre;
			newData.solnombre.oper = EFieldOper.READ;
			newData.solnombre.error = [];

			newData.solpaterno.value = dataPsn.psnapepat;
			newData.solpaterno.oper = EFieldOper.READ;
			newData.solpaterno.error = [];
			
			newData.solmaterno.value = dataPsn.psnapemat;
			newData.solmaterno.oper = EFieldOper.READ;
			newData.solmaterno.error = [];
				
			newData.titDatRep.display = EFieldDisplay.NO_SHOW;
			newData.repcurp.value= ""; 
			newData.repcurp.display = EFieldDisplay.NO_SHOW;
			newData.reprfc.value = ""; 
			newData.reprfc.display = EFieldDisplay.NO_SHOW;
			newData.repnombre.value = ""; 
			newData.repnombre.display = EFieldDisplay.NO_SHOW;
			newData.reppat.value = ""; 
			newData.reppat.display = EFieldDisplay.NO_SHOW;
			newData.repmat.value = ""; 
			newData.repmat.display = EFieldDisplay.NO_SHOW;

			newData.docrepresentante.display = EFieldDisplay.NO_SHOW;
			newData.docrapoderado.display = EFieldDisplay.NO_SHOW;

		} else {
			newData.solnombre.value = "";
			newData.solnombre.oper = EFieldOper.UPDATE;
			newData.solnombre.error = [];

			newData.solpaterno.value = "";
			newData.solpaterno.oper = EFieldOper.UPDATE;
			newData.solpaterno.error = [];
			
			newData.solmaterno.value = "";
			newData.solmaterno.oper = EFieldOper.UPDATE;
			newData.solmaterno.error = [];
			
			newData.solcurp.value  = "";
			newData.solcurp.oper= EFieldOper.UPDATE;
			newData.solcurp.error = [];			
			
			newData.titDatRep.display = EFieldDisplay.SHOW;
			newData.repcurp.value = dataPsn.psncurp ;
			newData.repcurp.display = EFieldDisplay.SHOW;
			newData.reprfc.value = dataPsn.psncurp.substring(0,10);		
			newData.reprfc.display = EFieldDisplay.SHOW;
			newData.repnombre.value =dataPsn.psnnombre;
			newData.repnombre.display = EFieldDisplay.SHOW;
			newData.reppat.value =  dataPsn.psnapepat;
			newData.reppat.display = EFieldDisplay.SHOW;
			newData.repmat.value =dataPsn.psnapemat;
			newData.repmat.display = EFieldDisplay.SHOW;
		}

		newData.solcorreo.value = dataPsn.psncorreo;
		newData.solcorreo.oper = EFieldOper.READ;
		newData.solcorreo.error = [];

		newData.solcodpos.value = dataPsn.psncp;
		newData.solcodpos.oper = EFieldOper.READ;
		newData.solcodpos.error = [];

		newData.solentfed.list = [{ label: dataPsnDir.estado , value: dataPsnDir.id_edo }];
		newData.solentfed.value = dataPsnDir.id_edo;
		newData.solentfed.error = [];

		newData.solmunicipio.list = [{ label: dataPsnDir.municipio , value: dataPsnDir.id_mun }];
		newData.solmunicipio.value = dataPsnDir.id_mun;
		newData.solmunicipio.error = [];

		newData.solcolonia.list = [{ label: dataPsnDir.colonia , value: dataPsnDir.id_col }];
		newData.solcolonia.value = dataPsnDir.id_col;
		newData.solcolonia.error = [];

		newData.solcalle.value = dataPsn.psncalle;

		if (dataPsn.psnnumext === 'null' || dataPsn.psnnumext === null ){
			newData.solnumext.value = '';
		} else {
			newData.solnumext.value = dataPsn.psnnumext;
		}
		newData.solnumext.oper = EFieldOper.READ;

		if (dataPsn.psnnumint === 'null' || dataPsn.psnnumint === null ){
			newData.solnumint.value = '';
		} else {
			newData.solnumint.value = dataPsn.psnnumint;
		}
		newData.solnumint.oper = EFieldOper.READ;		
	}
	
	useEffect(() => {
		if ( Object.keys(state.user.cic ).length > 0 ) {												
			TramitesSvc.TraInitDefensoria( state.user.psnid, state.user.cic.folio )
			.then ((response: any) => {  			
				let newData = {...dataProcess};				
				let tipoPsn = 1;

				if (state.user.cic.id_apo !== null) {					
					newData.soltipo.list = [ SOLICITANTE_DEF_TYPE.APODERADO ];
					newData.soltipo.value = SOLICITANTE_DEF_TYPE.APODERADO.value;
					tipoPsn = 2;
				} else {									
					newData.soltipo.list = [SOLICITANTE_DEF_TYPE.SOLICITANTE ];
					newData.soltipo.value = SOLICITANTE_DEF_TYPE.SOLICITANTE.value;								
				}
				newData.tramfolio.value = state.user.cic.folio;

				// ESTE ES UN ARREGLO EN CONTRA DE LAS INSTITUCIONES QUE TIENE UNA DFENSORIA
				newData.solinst.value = response.data.defensa[0].institucion;
				newData.solsector.value = response.data.defensa[0].sector;										
				setSolicitante (newData,  response.data.psn, response.data.psndir,tipoPsn );
				
				newData.soledocivil.list = EDOCIVIL_TYPE;									
				newData.solocupacion.list = OCUPACION_TYPE;								
				newData.solescolaridad.list = ESCOLARIDAD_TYPE;								
				newData.solvivienda.list = VIVIENDA_TYPE;			
				newData.solsexo.list = SEXO_TYPE;			

				// 1: firma electrónica
				if ( state.user.cic.tipofirma !== '1'){
					newData.titlefirma.display = EFieldDisplay.NO_SHOW;
					newData.dockey.display = EFieldDisplay.NO_SHOW;
					newData.doccert.display = EFieldDisplay.NO_SHOW;
					newData.efirma.display = EFieldDisplay.NO_SHOW;
					newData.titlefirmaCIC.display = EFieldDisplay.SHOW;
				}
											
				newData.soltelpar.value = response.data.psn.psncel;
				if (response.data.psn.psntel === 'null') {
					newData.soltelofi.value = '';
				} else {
					newData.soltelofi.value = response.data.psn.psntel;
				}
				
				onChangeAll(newData);			
				if ( response.data.sio.sioconsecutivo !== null ) {
					setPageState(EPageState.STEP5,"");

				}else if ( response.data.opr.oprfecfin  !== null  && response.data.opr.oprfecfin !== undefined ) {
					setPageState(EPageState.STEP4,"");
					return;
				}
			}).catch( (e:any) => {
				setPageState(EPageState.ATTEMPT,"No fue posible consultar los catálogos de dirección, favor de volver a intentar ");
				
			})	
		} else {
			window.location.reload(); 
		}
		setPageState(EPageState.STEP1,"");	

	}, [initForm]);

	const btnSaveOnClick = () => {
		// tamaño de los archivos...
		let intErrorTotal = onValidateAll();
		let intErrorSize = validateFilesSize(dataProcess, 10240);
				
		intErrorTotal += intErrorSize;

		if (intErrorTotal === 0 ){					
			setPageState(EPageState.STEP2, "");			 			
			let dataMsg = { id:"msg",  type:EFieldObj.TXT, save:1,  label:"Instrucción",  value:"Usted ha recibido la siguiente queja :" };

			// COLCOAR CONSTANTES .... 		
			let operacion : IOperacion = {  traid:TramitesType.SIO, etaid:EtapaType.PROCEDENCIA_DEFENSORIA, oprid:0, bucket:"", oprpath:"" };
			let origen: INodoOrigen = { nodori: 0, edoori:1, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };														
			let accion : IAccion =  { 
					accid:Accion.DEFENSORIA_SOLICITUD_ENVIAR, tdatid:FormType.ASIGNAR, 	data:dataMsg, edoid:NodoEstado.DEFENSORIA_SOLICITUD_REVISAR, 
					optional:{ accid:Accion.REGISTRAR_TRAMITE, tdatid:FormType.SOLICITUD, data:dataProcess, edoid:NodoEstado.TRAMITE  }
			};

			TramitesSvc.TraAction( JsonPost(operacion, origen, accion, state.user.cic) )
			.then(response => {         								
				if ( response.status === 200 && response.data.folio !== undefined) {					
					setQueja(response.data);
					setPageState(EPageState.STEP3, "");
					return;
				} else {
					setPageState(EPageState.STEP1, "No fue posible registrar su queja, vuelva a intentarlo");			
					return ;					
				}
			})
			.catch(e => {setPageState(EPageState.STEP1, "No es posible conectarse con el componente de trámites");
				console.log(e);
				return;
			});  
		} else {
			if ( intErrorSize > 0) {				
				setPageState(EPageState.STEP1, "La suma de los archivos suman más de 20MB");
			} else {
				setPageState(EPageState.STEP1, "Favor de revisar los datos de captura");
			}			
		}		
	}

	const drawStep3 = () => {
		return (
			<div className="container " >
				<br/>
        <h3>LA SOLICITUD DE PROCEDENCIA DE DEFENSORÍA SE REGISTRÓ CON ÉXITO</h3>
        <hr className="red"></hr>
        <div className="row">        
          <label className="col-sm-3 col-md-6">Fecha de registro</label>
          <label className="col-sm-8 col-md-6">{ queja.fecha }</label>
        </div>
				<br />
				<button className="btn btn-primary " id="envia" type="button" onClick={ evt => { BtnEnvia_Click() } }>Ir a seguimiento 
					<span className="glyphicon glyphicon-ok"></span>
				</button>				

				<button className="btn btn-primary " id="regresar" type="button" onClick={ evt => { BtnRegresarRut_Click() } }>Regresar al RUT
					<span className="glyphicon glyphicon-ok"></span>
				</button>				

        <br />
				<br />

				<div className="row">
          <object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.tramite  } type="application/pdf" >
            No fue posible descargar el documento. Favor de volver a intentar
          </object>          
        </div>

				<div className="row">
          <object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.acuse  } type="application/pdf" >
            No fue posible descargar el documento. Favor de volver a intentar
          </object>          
        </div>   
			</div>  
		);     
	}

	const FormaContenido = () => {
		return (
			<Fragment>
				{ DrawMsg() }
				<div id="formReg" >
					<DrawFieldsGroup 
						dataFields={dataProcess}
						onItemChange={onItemChange}
						onEditorTextChange={onEditorTextChange}
						onCompleteMethod={onCompleteMethod}  
						onFieldsValidate={onFieldsValidate}
						onValidateAll={onValidateAll}
				 	/>
					<br/>
					<div className="pull-left text-muted text-vertical-align-button">*Campos obligatorios</div>
					<br/>
					<br/>
					<br/>
					{ DrawMsg() }
					<br/>				

				</div>
			</Fragment>
		);
	}

	const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}
		
	const drawStep2 = () => {
		return (
			<div className="container " >        	
				{ FormaContenido() }
				<ProgressBar mode="indeterminate" />
				<br/>
			</div>  
		);     
	}

	const drawStep1 = () => {		
		return (
			<div className="container"  >        	
				<br />
				<h2>Solicitud de Defensoría Legal Gratuita</h2>
				{ FormaContenido() }
				<br/>
				<div className="pull-right" style={{ marginBottom:"40px" }}  >
					<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSaveOnClick() } >Enviar <span className="glyphicon glyphicon-ok"></span></button>
				</div>				
			)
			</div>  
		);     
	}

	const drawEnProceso = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-success " >La procedencia de defensoria se encuentra en revisión. Favor de dar clic para mostrar los trámites en proceso</div>
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSeguimientoOnClick() } >Trámites en proceso <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}
	
	const drawEnProcesoSIO = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-success " >La procedencia de defensoria se encuentra en trámite. Favor de dar clic para mostrar los trámites en proceso</div>
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSeguimientoOnClick() } >Trámites en proceso <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}
	
	const drawAttempt = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-warning"> {pageState.msg } </div>
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => setInitForm(initForm + 1 ) } >Volver a intentar <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}

	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			case EPageState.INIT:							
				return <ProgressBar mode="indeterminate" />;

			case EPageState.STEP1:
				return drawStep1();

			case EPageState.STEP2:
				return drawStep2();
				
			case EPageState.STEP3:
				return drawStep3();
				
			case EPageState.STEP4:
				return drawEnProceso();			

			case EPageState.STEP5:
				return drawEnProcesoSIO();

			case EPageState.ATTEMPT:
				return drawAttempt();

			default:
				return DrawMsg();
		}
	}

  return (
		<Fragment>      			
			<HeaderApp />			
			{ drawStateCtrl() }			
		</Fragment>
	);
} 
