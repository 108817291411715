import { useState } from 'react';




export enum EPageState {
	INIT = 'INIT',
	IN_PROCESS = 'IN_PROCESS',
	LOAD_DATA = 'LOAD_DATA',
	NO_DATA = 'NO_DATA',
	STEP0 = "STEP0",
	STEP1 = "STEP1",
	STEP2 = "STEP2",
	STEP3 = "STEP3",
	STEP4 = "STEP4",
	STEP5 = "STEP5",
	ERROR = "ERROR",
	ATTEMPT = "ATTEMPT",
	FINALIZED = 'FINALIZED',
	EDO_NO_LECTURA = "EDO_NO_LECTURA"
}


type PageStatusType = {
  state:  string;
  msg:  string;
}
  

export const usePageState = (props: PageStatusType ) => {
  const [pageState, setPageState] = useState(props);
  
  const changeStatePage = ( state:string, msg:string) =>{
		let objPageState = {...pageState}
		objPageState.state = state;
		objPageState.msg = msg;
		setPageState(objPageState);
	}
  
  return [pageState, changeStatePage ] as const;
}

