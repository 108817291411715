import React from 'react'

// COMPONENTES 
interface IItemData { 
  itemSelect: any;  
  setItemInProcess: any;
  listTask:any;
}

export const ListTaskInProcess: React.FC<IItemData> = (propsLstNewAct ) => {
    
  const appStylesSelect = {
    borderBottom:"1px solid #888888",
    background:  "lightgray",
    fontWeight: "bold", 
    fontSize:"20px",    
    color:"black",
    cursor: "default"
  };
    
  const appStyles = {    
    background:  "white",
    borderBottom:"1px solid #888888",
    color:"black"    
  };

  const drawItem = (data : any) => {
    let idx = 0 ;

    if (data.nodid !== undefined) {
      idx = data.nodid;
    } else {
      idx = data.oprid;
    }
    
    let appStyle; 
    if ( propsLstNewAct.itemSelect === idx ){
      appStyle = appStylesSelect;
    } else {
      appStyle = appStyles;
    }

    return (
      <div key={`div-${idx}`} style={{ backgroundColor:"white", marginTop:"5px" }}>
        <div key={`item-${idx}`} className="col-xs-12 item" style={ appStyle } onClick={ () => propsLstNewAct.setItemInProcess(idx)}>         
          <div key={`item-header-${idx}`} className="row"  >
            <table key={`item-table-${idx}`}>
              <thead key={`item-table-head1-${idx}`}>
                <tr key={`item-table-tr1-${idx}`}>
                  <th style={{ width:"10%" }}></th>
                  <th style={{ width:"90%" }}></th>
                </tr>              
              </thead>
              <tbody key={`item-table-tbody-${idx}`}>
                <tr key={`item-table-tr2-${idx}`}>
                  <td key={`item-table-td1-${idx}`} className={data.traicono} style={{ fontSize:"32px", color:"#BC955C", marginLeft:"8px", marginTop:"15px"  }} >
                  </td>
                  <td key={`item-table-td2-${idx}`}>
                    <div key={`item-row-tra${idx}`}   className="col-xs-12"  style={{ textOverflow: "ellipsis", fontSize:"13px", marginLeft:"8px", marginTop:"4px" }}> {data.tradesc }</div>
                    <div key={`item-row-folio${idx}`}  className="col-xs-12"  style={{ textOverflow: "ellipsis", fontSize:"15px", marginLeft:"8px", fontWeight:"bold" }}>Folio: {data.oprfolio }</div>
                    <div key={`item-row-fecha${idx}`}  className="col-xs-6"  style={{ textOverflow: "ellipsis", fontSize:"13px", marginLeft:"8px", marginBottom:"4px" }}>{data.oprfecrecepcion} </div>                                                                  
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }

  const drawBody = () => {	
    let htmlAdd : any = [];

    if ( propsLstNewAct.listTask.length > 0) {
      propsLstNewAct.listTask.map( 
        (x :any) => htmlAdd.push(drawItem ( x )) 
      );  
    }
      
    if (htmlAdd.length > 0) {      
      return (
        <div>
          { htmlAdd }
          <div   className="col-xs-12" style={{  borderTop:"1px solid #888888"  }}> </div>
        </div>
      );
    } else {
      return (
        <div style={{ color:"black"}}>        
          <div style={{ textAlign:"center" }}>
            <br/>
            No existen actividades
          </div>
        </div>
      )
    }      
  }
  
  return ( drawBody() );
}