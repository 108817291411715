import React, {useRef, Fragment } from 'react'                    
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import {AutoComplete} from 'primereact/autocomplete';
import {Editor} from 'primereact/editor';
import {Message} from 'primereact/message';
import {FileUpload} from 'primereact/fileupload';
import { RadioButton } from 'primereact/radiobutton';
import { SelectButton } from 'primereact/selectbutton';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';

import {EFieldObj, EFieldOper} from '../fields/field';

/*  PROPIEDADES DE LOS CAMPOS  - 
  BASE:     [ id, type, oper, status, className, placeholder, value, error ]
  cbo:      base + [ list ] 
  auto:     base + [ list, listfilter ] 
  msk:      base + [ mask ]
  date:     base + [ fecmin, fecmax ]   
  pint:     base  
*/
// = ( { data, onClick,  onChange, onCompleteMethod, onEditorTextChange, dataGlobal }) =>{     

  interface IField { 
    data: any;
    onClick:any;
    onChange:any;
    onCompleteMethod:any;
    onEditorTextChange:any;
    [key: string]: any;		
  }
  
  interface IOpt { 
    [key: string]: any;		
  }
  

export const Field: React.FC<IField> = (props) => {  
    const refObjTag = useRef(null);    

    addLocale('es', {
        firstDayOfWeek: 1,
        dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
        dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
        monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
        today: 'Hoy',
        clear: 'Claro'
    });

    const onCompleteMethodLocal = (evt: any) => {        
      let results = props.dataGlobal.dataApp[props.data.list].filter( (brand: any) => {
        return brand.toLowerCase().startsWith(evt.query.toLowerCase());
      });        
      props.onCompleteMethod(props.data.id, results);          
    }
  
    const onTextChangeLocal = (evt:any) =>{
      props.onEditorTextChange(props.data.id, evt.htmlValue, evt.textValue );      
    }   

    const onFileSelect = (evt:any) =>{
        evt.target = {};
        evt.target.id = props.data.id;
        props.onChange(evt)
    }

    const drawField = () => {
      let htmlElem = [];        
      let claseCSS = null;
       
      if( 'error' in  props.data ){
        if (props.data.error.length > 0 ){
          claseCSS = props.data.classNameObj + " FieldLeft p-error";    
        } else {
          claseCSS = props.data.classNameObj + " FieldLeft";
        }            
      } else {
        alert("objeto con error " + props.data.label);
        claseCSS = props.data.classNameObj;
      } 

/*        
        //Dependiento del la validadcion desplegar el mensaje
        // min="1920-01-01" max="2019-12-31"
        /// o solo el borde.. a ver como se ve.
*/

        let opts: IOpt;

        if (props.data.oprid === EFieldOper.READ ) {
            opts = {
                id:             props.data.id,
                name:           props.data.id,
                key:            props.data.id,
                value:          props.data.value,
                className:      claseCSS,
                placeholder:    props.data.placeholder
            };
        } else {
            opts = {
                id:             props.data.id,
                name:           props.data.id,
                key:            props.data.id,
                value:          props.data.value,
                className:      claseCSS,
                placeholder:    props.data.placeholder,
                onClick:        props.onClick,         
                onChange:       props.data.onChange !== undefined? props.data.onChange: props.onChange            
            };
        }
               
        if( 'style' in  props.data ){
            opts['style'] = props.data.style;
        } 

        if ( props.data.oper === EFieldOper.READ ){              

            if (props.data.type === EFieldObj.CBO) {
                htmlElem.push( <Dropdown  {...opts} options={props.data.list}  disabled={true}  style={{opacity:"1.0",  background:"#eee",  color:"black" }} ></Dropdown> );                
            } else if (props.data.type === EFieldObj.SBUTTON) {
                htmlElem.push( <SelectButton {...opts }  options={props.data.options}  optionLabel="name" disabled={true} /> );
            } else if (props.data.type === EFieldObj.AREA) {
                htmlElem.push( <InputTextarea  {...opts }  rows={props.data.rows} cols={props.data.cols} disabled /> );                    
            } else {
                htmlElem.push( <InputText type="text" {...opts} validateOnly={false} style={{ background:"#eee", cursor:"no-drop", color:"black"}}   /> );
            }
                    

        }  else if ( props.data.type === EFieldObj.TXT){                           
            htmlElem.push( <InputText type="text" {...opts}  maxLength={props.data.maxlength} />); 

        }  else if ( props.data.type === EFieldObj.INUM){                           

            if (  props.data.hasOwnProperty("max") ) {
                htmlElem.push( <InputNumber {...opts}  mode="currency" currency="USD" locale="en-US" max={props.data.max} />); 
            } else {
                htmlElem.push( <InputNumber {...opts}  mode="currency" currency="USD" locale="en-US"  />); 
            }

        
        }  else if (  props.data.type === EFieldObj.MSK){
            // convertir le numero a STRING
            htmlElem.push( <InputMask {...opts} mask={props.data.mask} /> );
        }  else if ( props.data.type === EFieldObj.CBO){ 
            if (  props.data.list=== "" || props.data.list === null || props.data.list === undefined || props.data.list.length === 0 ){
                htmlElem.push( <Dropdown  {...opts} options={ [{ label:"SIN DATOS", value:"0" }]}  disabled={true}/>  );
            }  else {
                htmlElem.push( <Dropdown  {...opts} options={props.data.list}  showClear={true} ></Dropdown> );
            }                    
        } else if (  props.data.type === EFieldObj.DATE){

            if (  props.data.hasOwnProperty("minDate") && props.data.hasOwnProperty("maxDate") ) {
                htmlElem.push(<Calendar  {...opts }  locale="es" dateFormat="dd/mm/yy" minDate={props.data.minDate}   maxDate={props.data.maxDate} yearRange={props.data.yearRange} monthNavigator yearNavigator ></Calendar>);
            } else if (  props.data.hasOwnProperty("minDate") ) {
			    htmlElem.push(<Calendar  {...opts }  locale="es" dateFormat="dd/mm/yy" minDate={props.data.minDate}  yearRange={props.data.yearRange} monthNavigator yearNavigator ></Calendar>);
            } else if (  props.data.hasOwnProperty("maxDate") ) {
                htmlElem.push(<Calendar  {...opts }  locale="es" dateFormat="dd/mm/yy" maxDate={props.data.maxDate}  yearRange={props.data.yearRange} monthNavigator yearNavigator ></Calendar>);
            } else {
            	htmlElem.push(<Calendar  {...opts }  locale="es" dateFormat="dd/mm/yy" yearRange="1920:2030" monthNavigator yearNavigator ></Calendar>);
            }                                        
        } else if (  props.data.type === EFieldObj.SBUTTON) {
            htmlElem.push( <SelectButton {...opts }  options={props.data.options}  optionLabel="name"/> );            
        } else if (  props.data.type === EFieldObj.RADIO) {
            // SE REQUEIRE UN CICLO PARA DIBUJAR LAS ACCIONES
            opts.name = props.data.name;
            htmlElem.push( <RadioButton {...opts } checked={props.data.value}  /> );
        } else if (  props.data.type === EFieldObj.AREA){
            if (  props.data.hasOwnProperty("maxlength") ) {
                htmlElem.push( <InputTextarea {...opts }  rows={props.data.rows} cols={props.data.cols} maxLength={props.data.maxlength} />);
            } else {
                htmlElem.push( <InputTextarea {...opts }  rows={props.data.rows} cols={props.data.cols}  />);
            }                              
        } else if (  props.data.type === EFieldObj.CHKB) {       
            // cambiar el valor a TRUE  OR FALSE     
            htmlElem.push( <Checkbox {...opts }  checked={props.data.value} />);            
            htmlElem.push( <label key={ "lblkey" + props.data.id } className={ props.data.classNameLabel} >{props.data.label }:</label> );


        } else if (  props.data.type === EFieldObj.AUTO){
            htmlElem.push( <AutoComplete  ref={refObjTag} {...opts} dropdown={true} suggestions={props.data.listfilter} completeMethod={onCompleteMethodLocal} /> );        
        } else if (  props.data.type === EFieldObj.EDIT){
            htmlElem.push( <Editor {...opts}  ref={refObjTag} onTextChange={onTextChangeLocal} style={{height:"200px"}}/> );            
        } else if (  props.data.type === EFieldObj.PINT){
            htmlElem.push(<InputText  keyfilter="pint"  {...opts } maxLength={props.data.maxlength} />);

            
        } else if (  props.data.type === EFieldObj.PWD){
            htmlElem.push(<Password   {...opts } feedback={false} toggleMask  />);

        } else if (  props.data.type === EFieldObj.FILE){            
            if (props.data.multiple=== true) {                
                htmlElem.push(<FileUpload {...opts} url="./upload" multiple={true} accept={props.data.accept} maxFileSize={parseInt(props.data.maxFileSize)} chooseLabel="Seleccionar archivo" onSelect={onFileSelect} customUpload={true} />);
            } else {
                htmlElem.push(<FileUpload {...opts} url="./upload" mode="basic" accept={props.data.accept} maxFileSize={parseInt(props.data.maxFileSize)} chooseLabel="Seleccionar archivo" onSelect={onFileSelect} customUpload={true}/>);
            }                                
        } else {
            htmlElem.push(<span className="StatusDanger"> ERROR EN CONTROL </span>);
        }                   
        //Colocar un mensaje si existe un error...
        if (props.data.error.length > 0 ) {       
            // concatenar los erroes o solo el primero         
            htmlElem.push(<Message key={"Error" + props.data.id}  severity="error" text={ props.data.error[0] } style={{marginLeft: '.25em'}} />);
        }
        return htmlElem;
    }

    return (      
        <Fragment>
            { drawField() }
        </Fragment>
    );
}