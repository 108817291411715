export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        page: M[Key];
      }
};

export enum Types {
  PAGE_SELECT = 'PAGE_SELECT',
  PAGE_CITA_BUSCAR = 'PAGE_CITA_BUSCAR',
  USER_INIT= 'USER_INIT'
}

export type FieldStrType = {
  value:  string;
  msg:  string;
}

export type FieldIntType = {
  value:  number;
  msg:  string;
}


export type StatusType = {
  pageState:  string;
  pageMsg:  string;
  docName:  string;
  docUrl:  string;
}


export type FieldAssingType = {
  source:  string;
  target:  string;
  topr:  number;
}



export const TRACE_ETAPA = [
  { name: 'GESTIÓN ELECTRÓNICA', code: 1 },
  { name: 'CONCILIACIÓN', code: 2 },
  { name: 'DICTAMEN', code: 3 },
  { name: 'PROCENDENCIA', code: 4 },
  { name: 'DEFENSORÍA', code: 5 }
];  




export type TraceDate = {
  oprid: number; 
  phase: number;
  datebegin: string;
  dateend: string;
}


export type TraceSearchType = {
  process: number;
  status: number;
  datebegin: string;
  dateend: string;
  folio: string;
}


export type TraceGestionType = {
  oprid:number;
  folio: string;
  usuario: number;
  inst: string;
  fecrec: string;
  fecdes: string;
  fecprev: string;  
  fecaceptar: string;
  fecvenc: string;
  dias: number;    
  status: string;
  diasvenc: number;
  fecrepini: string;
  fecrepfin: string;
}

export type TraceConciliarType = {
  oprid:number;
  folio: string;
  usuario: number;
  inst: string;

  fecing:string;
  fecprev:string;
  fecnoadm:string;
  feccit1:string;
  fecaud1:string;
  fecinas:string;
  fecjust:string;
  feccit2:string;
  fecaud2:string;
  fecdesa:string;
  fecmesrep:string;
  sentidoaud:string;
  soldt:string;
  fecrepini: string;
  fecrepfin: string;  
}

export type TraceDictamenType = {
  oprid:number;
  folio: string;
  usuario: number;
  inst: string;
    
  fecsalvo:string;
  fecaudi:string;
  fecaudielem:string;
  fecinstelem:string;
  fecPry:string;
  fecsolusr:string;
  fecsolinf:string;
  feccorrida:string;
  fecresul:string;
  fecrepini: string;
  fecrepfin: string;  
}

export interface IActionProps { etaid:number, edoori:number, accid : number, edodst : number,  oprid:number, nodori:number, setListTask:any,  bucket:string, oprpath:string, tramite:any }
export interface IPersonData {insid:number, empid:number, psnid:number, psnnombrecomp:string, psncorreo:string, pstnombre:string, inssiglas:string, active:number }
export interface IPersonCiC { psnid: number, psnnombre: string, psnapepat: string, psnapemat: string, psncurp: string, psnrfc: string, psncorreo: string, psnsecreto: string, psntel: string, psncel: string, psnnombrecomp: string, psnfecha: string, psnsysusrid: number, psnsysinsid: number, psnsysinsnam: string, psncp: string, psnedoid: number, psnmunid: number, psncolid: number, psncalle: string, psnnumext: string, psnnumint: string, psnentre: string, psnmunidsio:number, psnsysinstipo:number } 
export interface IPersonDirCIC { id_edo: number, estado: string, id_mun: number, municipio:string, id_col: number, colonia: string }
export interface ICic { idsol: number, nombre:string, paterno: string, materno: string, curp: string, tipo_firma: string, email: string, calle: string, numext: string, numint: string, entrecalles: string, id_col: number, colonia: string, id_mun: number, municipio: string, id_edo: number, cp: string, referencia: string, teléfono: string, tipofirma: number, chainsign: string }
export interface INodoOrigen { nodori:number, edoori:number, psn: any, nodausencia:number }
export interface IOperacion { traid:number, etaid:number, oprid:number, bucket:string, oprpath:string  }

export enum InstTypes {
  CONDUSEF = 0, 
  CIUDADANO = 1,
  INSTITUCION_FINANCIERA = 2
}

export enum InstIdTypes {
  CONDUSEF = -8237,
  CIUDADANO = 0
}

export enum EtapaType {
  QUEJA = 1,
  RECLAMACION = 2,
  CONCILIACION = 3,
  DICTAMEN = 4,
  PROCEDENCIA_DEFENSORIA = 5,
  SOLICITUD = 6
}

export enum TramitesType {
  FINTECH = 1,  
  BCD = 2,
  SIO = 3,
  SIAB = 4
}

/* BEGIN  -  RUT */
export enum TramRutType {
  BCD = "2",
  DICTAMEN = "9",
  DEFENSORIA = "11",
  FINTECH = "18",
  SEGUIMIENTO= "20",
  PUR_FINTECH= "3"
}

export enum TramRutStrType {
  BCD = 'BCD',
  DICTAMEN = 'DICTAMEN',
  DEFENSORIA = 'DEFENSORIA',
  FINTECH = 'FINTECH',
  SEGUIMIENTO = 'SEGUIMIENTO',
  PUR = "PUR"
}
/* END -  RUT */


/* VARIABLES PARA LA COMUNICACION CON EL SIO*/
export enum SioOperacion {
  ERROR = -10,
  INICIAR = -1,
  NO_PROCEDE = 0,
  PROCEDE = 1,  
  RESPONDER_USUARIO = 2,  
  DESINTERES_USUARIO = 10
}

export enum FormType  {      
  SIN_DATOS = 0,
  ACTA = 1,
  ACTA_DEFUNCION = 2,
  ACTA_NACIMIENTO = 3,  
  ACTA_CONFORMIDAD = 4,
  ACUSE = 5,
  ASIGNAR = 6,
  CARTA_PODER_APODERADO = 7,
  CARTA_PODER_REPRESENTANTE = 8,
  FILE_CERT = 9,
  COMPROBANTE_DOMICILIO = 10,
  COMPROBANTE_INGRESOS = 11,
  CONFIRMAR_LECTURA = 12,  
  CURP = 13, 
  DESECHAR_TRAMITE = 14,
  DESISTIR = 15,
  DICTAMEN = 16,  
  EDO_CUENTA = 17,
  ESCRITO_VERDAD = 18,
  EVIDENCIA = 19,
  IDENTIFICACION = 20,
  FILE_KEY = 21,
  NOMBRAMIENTO = 22,
  NOTIFICAR = 23,
  NOTIFICAR_LECTURA_EFIRMA = 24,
  OTRO = 25,
  PENSION_JUBILADO = 26,
  PROYECTO = 27,
  QUEJA = 28,
  RECLAMACION = 29,
  REPRESENTACION = 30,
  RFC = 31,
  SOLICITUD = 32,
  TRASLADO = 33,
  CITATORIO = 34,
  PREVENCION = 35,
  ACUERDO = 36,
  DOC_AUDIENCIA = 37,
  ACTA_CONCILIACIÓN = 38,
  ESCRITO_JUSTIFICACION_USAURIO_AUDIENCIA = 39,
  OFICIO_JUSTITICACION_USR_AUSENCIA = 40,
  OFICIO_JUSTIFICACION_INST_AUSENCIA = 41,
  EXPEDIENTE_DICTAMEN = 42,
  MEMORAMDUM = 43,
  OFICIO_TÉCNICO = 44,
  SOLICITUD_DICTAMEN = 45,
  SOLICITUD_BCD = 46
};

//value:DelegacionType.
export enum DelegacionType{
  METRO_CENTRAL= '090',
  FINTECH = '351',
  RUT_BCD = 'rutbcd'
}

export enum PerfilTypes {
  CIUDADANO = 1,
  FINTECH_DIR = 2, 
  INST_FINANCIERA = 3, 
  FINTECH_SUBDIR = 4, 
  DICTAMEN_DIR = 5,
  DICTAMEN_PRY = 6,
  DICTAMEN_REVISOR = 7,
  CONSULTIVA_DIR = 8,
  CONSULTIVA_BCD_COOR = 9,
  CONSULTIVA_PD_COOR = 10,  
  DEFLEG_DIR = 11, 
  DEFLEG_PRY = 12,
  DICTAMEN_CORR_FINAN = 13
}

export interface IOpciones { 
  nodid: number;
  traid: number;
  edoid: number;
  oprid: number;
  psnid: number;
  insid: number;
  nodfecha: string;
  nodfeclectura: string;
  nodfecatendido: string;
  nodausencia: number;
  nodstatus: number;
  nodregresar: number;
  datcontenido: string;
  edodesc:string;
}

export interface IAccion { 
  accid:number, tdatid:number, data:any, edoid:number, optional:any
}

export interface IDocProp { 
  name: string, value: number, url: string 
};

export type BoxType = {
  label:  string;
  icon:  string;
}

export const URL = {
  CIC: ""
}

export const RUT_REDIRECT = () => {    
  //window.location.href = "https://phpappsdesa.condusef.gob.mx/RUT/portalusu/home.php";
  window.location.href = "https://rut.condusef.gob.mx/portalusu/home.php";
}

export const SOLICITANTE_DEF_TYPE = {
  "SOLICITANTE": { label: "Soy el solicitante" , value: 1},
  "APODERADO":{ label: "Soy el representante legal" , value: 2},
}

export enum SolTypes {
  BENEFICIARIO = 1,
  REPRESENTANTE= 2,
  ALBACEA= 3,
  PADRES= 4,
  TUTOR= 5
}

export const SOLICITANTE_BCD_TYPE = [
  { label: "Soy el beneficiario" , value: SolTypes.BENEFICIARIO},
  { label: "Soy el representante legal" , value: SolTypes.REPRESENTANTE},
  { label: "Soy el albacea" , value: SolTypes.ALBACEA},
  { label: "Soy la madre o padre" , value: SolTypes.PADRES},  
  { label: "Soy el tutor" , value: SolTypes.TUTOR}  
]


export const PARENTESCO_BCD_TYPE = [
  { label: "Madre" , value: 1},
  { label: "Padre" , value: 2},
  { label: "Hermana(o)" , value: 3},
  { label: "Hija(o)" , value: 4},
  { label: "Esposa(o)" , value: 5},
  { label: "Concuvina(o)" , value: 6},
  { label: "Tía(o)" , value: 7},
  { label: "Sobrina(o)" , value: 8},
  { label: "Abuela(o)" , value: 9},
  { label: "Nieta(o)" , value: 10},
  { label: "OTRO" , value: 11}
]

export const AUSENCIA_TYPE = [
  { label: "SI" , value: 1},
  { label: "NO" , value: 2}
]

export const PERSONA_TYPE = [
  { name: "Persona Física", value: 1},
  { name: "Persona Moral", value: 2}
]




export const EDOCIVIL_TYPE = {
  "SOLTERO": { label: "SOLTERO(A)" , value: 1},
  "CASADO":{ label: "CASADO(A)" , value: 2},
  "CONCUBINO": { label: "CONCUBINO(A)" , value: 3},
  "DIVORCIADO": { label: "DIVORCIADO(A)" , value: 4},
  "VIUDO":{ label: "VIUDO(A)" , value: 5},
  "PADRES":{ label: "MADRE/PADRE SOLTERA(O)" , value: 6},
  "UNIÓN":{ label: "UNIÓN LIBRE" , value: 7}
}

export const OCUPACION_TYPE = {
  "DESEMPLEADO": { label: "DESEMPLEADO" , value: 1},
  "JUBILADO":{ label: "JUBILADO" , value: 2},
  "PENSIONADO": { label: "PENSIONADO" , value: 3},
  "CONYUGEJUB": { label: "CONYUGE DE PENS/JUBILADO" , value: 4},
  "ENFERMO":{ label: "ENFERMO TERMINAL" , value: 5},
  "DISCAPACITADO":{ label: "DISCAPACITADO" , value: 6},
  "EVENTUAL": { label: "EVENTUAL" , value: 7},
  "SUBEMPLEADO":{ label: "SUBEMPLEADO" , value: 8},
  "EMPLEADO": { label: "EMPLEADO" , value: 9},
  "EMPRESARIO": { label: "EMPRESARIO" , value: 10},
  "ESTUDIANTE":{ label: "ESTUDIANTE" , value: 11},
  "AMA":{ label: "AMA DE CASA/A" , value: 12},
  "LIBRE":{ label: "EJERCICIO LIBRE" , value: 13}
}

export const ESCOLARIDAD_TYPE = {
  "PRIMARIA": { label: "PRIMARIA" , value: 1},
  "SECUNDARIA":{ label: "SECUNDARIA" , value: 2},
  "COMERCIAL": { label: "C.COMERCIAL" , value: 3},
  "PREPARATORIA": { label: "PREPARATORIA" , value: 4},
  "PROFESIONAL":{ label: "PROFESIONAL" , value: 5},
  "OTROS":{ label: "OTROS" , value: 6}
}

export const VIVIENDA_TYPE = {
  "PROPIA": { label: "PROPIA" , value: 1},
  "RENTADA":{ label: "RENTADA" , value: 2},
  "FAMILIARES": { label: "DE FAMILIARES" , value: 3},
  "COMPRANDO": { label: "SE ESTA COMPRANDO" , value: 4},
  "PRESTADA":{ label: "PRESTADA" , value: 5}
}

export const MESES = [
"",
 "ENERO",
 "FEBRERO",
 "MARZO",
 "ABRIL",
 "MAYO",
 "JUNIO",
 "JULIO",
 "AGOSTO",
 "SEPTIEMBRE",
 "OCTUBRE",
 "NOVIEMBRE",
 "DICIEMBRE"
]



export const SEXO_TYPE = [
  { label: "MUJER" , value: 1},
  { label: "HOMBRE" , value: 2}
]

export const PODER_TYPE = [
  { label: "CARTA PODER" , value: 1},
  { label: "PODER NOTARIAL" , value: 2}
]

export const IDENT_TYPE = [
  { label: "CARTILLA" , value: 3},
  { label: "IFE" , value: 7},
  { label: "INE" , value: 1},
  { label: "PASAPORTE" , value: 4},
  { label: "MATRICULA" , value: 100}
]

export const UBICACION_TYPE = [
  { label: "MEXICO" , value: 1},
  { label: "EXTRANJERO" , value: 2}
]

export interface IItemHead {
  bukdesc: string;
  oprfecrecepcion:string;
  oprfolio : string;
  oprid : number;
  pfldesc : string;
  tradesc : string;
  traicono : string;
}

export interface IItemInPrc {  
  tasks: any; 
  nodos:any
}

export interface Task {
  start: Date;
  end: Date;
  name:string;
  id: string;
  type:string;
  progress: number;
  isDisabled: boolean,
  styles: any;
  dependencies: any;
}


export interface IAccionFind { 
	edodst: number;		
	accid: number,
	accdesc: String;
	acctipo: number;
}

export const AFD_OPERFINAL = [
  { label:"Inicio", value:0 },
  { label:"Favorable al usuario", value:1 },
  { label:"No favorable al usuario", value:2 },
  { label:"Incompetencia notoria", value:3 },
  { label:"Desahogo incorrecto", value:4 },
  { label:"Improcedente por falta de datos", value:5 },
  { label:"Improcedencia", value:6 },
  { label:"Falta de interes", value:7 },
  { label:"El usuario se desistió", value:10 }
]


export const RESP_FAVORABLE_ONE = [  
  { label:"Favorable al usuario", value:1 }
]

export const RESP_FAVORABLE = [  
  { label:"Favorable al usuario", value:1 },
  { label:"No favorable al usuario", value:2 }
]

export const RESP_FAVORABLE_CON = [  
  { label:"Favorable conciliada", value:8 }
]


export const RESP_CONCILIADA = [  
  { label:"Favorable al usuario", value:1 },
  
]

export const RESP_DESISTIMIENTO = [
  { label:"El usuario se desistió", value:4 }
]


export const PLAZO_10DIAS = [
  { label:"10 Días", value:10 },
  { label:"9 Días", value:9 },
  { label:"8 Días", value:8 },
  { label:"7 Días", value:7 },
  { label:"6 Días", value:6 },
  { label:"5 Días", value:5 },
  { label:"4 Días", value:4 },
  { label:"3 Días", value:3 },
  { label:"2 Días", value:2 },
  { label:"1 Día", value:1 }
]

export const PLAZO_20DIAS = [
  { label:"20 Días", value:20 },
  { label:"19 Días", value:19 },
  { label:"18 Días", value:18 },
  { label:"17 Días", value:17 },
  { label:"16 Días", value:16 },
  { label:"15 Días", value:15 },
  { label:"14 Días", value:14 },
  { label:"13 Días", value:13 },
  { label:"12 Días", value:12 },
  { label:"11 Días", value:11 },
  { label:"10 Días", value:10 },
  { label:"9 Días", value:9 },
  { label:"8 Días", value:8 },
  { label:"7 Días", value:7 },
  { label:"6 Días", value:6 },
  { label:"5 Días", value:5 },
  { label:"4 Días", value:4 },
  { label:"3 Días", value:3 },
  { label:"2 Días", value:2 },
  { label:"1 Día", value:1 }  
]

export const RESP_NO_PROCEDE = [
  { label:"Incompetencia notoria", value:3 }, 
  { label:"Improcedencia", value:6 }
]

export const IMPROCEDENTE_FALTADATOS = [
  { label:"Improcedente por falta de datos", value:5 }
]

export const DESAHOGO_INCORRECTO = [
  { label:"Desahogo incorrecto", value:4 },
]

export const FALTA_INTERES = [
  { label:"Falta de interes", value:7 },
]


export const PARENTESCO = [
  { label:"PADRES - CÓNYUGE - HIJOS ", value:1},
  { label:"PADRES - HIJOS", value:2},
  { label:"CÓNYUGE - HIJOS", value:3},
  { label:"PADRES", value:4},
  { label:"CÓNYUGE", value:5},
  { label:"HIJOS", value: 6},
  { label:"OTROS", value: 7},
]


export const NACIONALIDADES = [
  { label:"AFGANA", value:301}, 
  { label:"ALBANESA", value:401}, 
  { label:"ALEMANA", value:403}, 
  { label:"AMERICANA", value:299}, 
  { label:"ANDORRANA", value:404}, 
  { label:"ANGOLESA", value:103}, 
  { label:"ARABE", value:311}, 
  { label:"ARGELIANA", value:104}, 
  { label:"ARGENTINA", value:201}, 
  { label:"AUSTRALIANA", value:501}, 
  { label:"AUSTRIACA", value:405}, 
  { label:"BAHAMEÑA", value:202}, 
  { label:"BELGA", value:406}, 
  { label:"BELICEÑA", value:204}, 
  { label:"BHUTANESA", value:305}, 
  { label:"BIRMANA", value:306}, 
  { label:"BOLIVIANA", value:205}, 
  { label:"BOTSWANESA", value:106}, 
  { label:"BRASILEÑA", value:206}, 
  { label:"BRITANICA", value:218}, 
  { label:"BRITANICA", value:427}, 
  { label:"BULGARA", value:407}, 
  { label:"BURKINA FASO", value:609}, 
  { label:"BURUNDESA", value:107}, 
  { label:"CAMBOYANA", value:320}, 
  { label:"CAMERUNESA", value:136}, 
  { label:"CANADIENSE", value:207}, 
  { label:"CENTRO AFRICANA", value:135}, 
  { label:"CHADIANA", value:112}, 
  { label:"CHECOSLOVACA", value:408}, 
  { label:"CHILENA", value:211}, 
  { label:"CHINA", value:309}, 
  { label:"CHIPRIOTA", value:310}, 
  { label:"COLOMBIANA", value:208}, 
  { label:"COMORENSE", value:109}, 
  { label:"CONGOLESA", value:110}, 
  { label:"COSTARRICENSE", value:209}, 
  { label:"CUBANA", value:210}, 
  { label:"DANESA", value:409}, 
  { label:"DE BAHREIN", value:303}, 
  { label:"DE BARBADOS", value:203}, 
  { label:"DE BENNIN", value:105}, 
  { label:"DE CABO VERDE", value:108}, 
  { label:"DE DJIBOUTI", value:113}, 
  { label:"DE SANTO TOME", value:141}, 
  { label:"DE SEYCHELLES", value:143}, 
  { label:"DE ZIMBAWI", value:154}, 
  { label:"DEL QATAR", value:329}, 
  { label:"DEL SAHARA", value:140}, 
  { label:"DOMINICA", value:212}, 
  { label:"DOMINICANA", value:229}, 
  { label:"EGIPCIA", value:114}, 
  { label:"ESLOVACA", value:667}, 
  { label:"ESLOVAQUIA", value:684}, 
  { label:"ESLOVENIA", value:668}, 
  { label:"ESPAÑOLA", value:411}, 
  { label:"ESTADOUNIDENSE", value:215}, 
  { label:"ESTONIA", value:621}, 
  { label:"ETIOPE", value:115}, 
  { label:"FIJIANA", value:502}, 
  { label:"FILIPINA", value:312}, 
  { label:"FINLANDESA", value:412}, 
  { label:"FRANCESA", value:413}, 
  { label:"GABONESA", value:116}, 
  { label:"GAMBIANA", value:117}, 
  { label:"GHANATA", value:118}, 
  { label:"GRANADINA", value:216}, 
  { label:"GRIEGA", value:414}, 
  { label:"GUATEMALTECA", value:217}, 
  { label:"GUINEA", value:119}, 
  { label:"GUINEA", value:120}, 
  { label:"GUINEA ECUATORIANA", value:121}, 
  { label:"GUYANESA", value:219}, 
  { label:"HAITIANA", value:220}, 
  { label:"HINDU", value:313}, 
  { label:"HOLANDESA", value:424}, 
  { label:"HONDUREÑA", value:221}, 
  { label:"HUNGARA", value:415}, 
  { label:"INDONESA", value:314}, 
  { label:"IRANI", value:316}, 
  { label:"IRAQUI", value:315}, 
  { label:"IRLANDESA", value:416}, 
  { label:"ISLANDESA", value:417}, 
  { label:"ISRAELI", value:317}, 
  { label:"ITALIANA", value:418}, 
  { label:"JAMAIQUINA", value:222}, 
  { label:"JAPONESA", value:318}, 
  { label:"JORDANA", value:319}, 
  { label:"KENIANA", value:123}, 
  { label:"KIRGUISTAN", value:642}, 
  { label:"KUWAITI", value:321}, 
  { label:"LAOSIANA", value:331}, 
  { label:"LESOTHENSE", value:124}, 
  { label:"LIBANESA", value:322}, 
  { label:"LIBERIANA", value:125}, 
  { label:"LIBIA", value:122}, 
  { label:"LIECHTENSTENSE", value:419}, 
  { label:"LITUANIA ", value:645}, 
  { label:"LUXEMBURGUESA", value:420}, 
  { label:"MACEDONIA", value:650}, 
  { label:"MALASIA", value:323}, 
  { label:"MALAWIANA", value:127}, 
  { label:"MALDIVA", value:324}, 
  { label:"MALIENSE", value:128}, 
  { label:"MALTESA", value:421}, 
  { label:"MARFILEÑA", value:111}, 
  { label:"MARROQUI", value:129}, 
  { label:"MAURICIANA", value:130}, 
  { label:"MAURITANA", value:131}, 
  { label:"MEXICANA", value:223}, 
  { label:"MICRONECIA", value:624}, 
  { label:"MOLDOVIA, REPUBLICA DE", value:648}, 
  { label:"MONEGASCA", value:422}, 
  { label:"MONGOLESA", value:325}, 
  { label:"MOZAMBIQUEÑA", value:132}, 
  { label:"NAMIBIANA", value:101}, 
  { label:"NAURUANA", value:504}, 
  { label:"NEPALESA", value:326}, 
  { label:"NICARAGUENSE", value:224}, 
  { label:"NIGERIANA", value:134}, 
  { label:"NIGERINA", value:133}, 
  { label:"NORCOREANA", value:307}, 
  { label:"NORUEGA", value:423}, 
  { label:"NORVIETNAMITA", value:337}, 
  { label:"OMANESA", value:327}, 
  { label:"PAKISTANI", value:328}, 
  { label:"PANAMEÑA", value:225}, 
  { label:"PAPUENSE", value:506}, 
  { label:"PARAGUAYA", value:226}, 
  { label:"PERUANA", value:227}, 
  { label:"PORTUGUESA", value:426}, 
  { label:"PUERTORRIQUEÑA", value:228}, 
  { label:"REINO UNIDO", value:629}, 
  { label:"REINO UNIDO(BRIT. DEL EXT.)", value:626}, 
  { label:"REINO UNIDO(C. BRIT. DEL EXT.)", value:627}, 
  { label:"REINO UNIDO(DEPEN. TET. BRIT.)", value:625}, 
  { label:"RUMANA", value:430}, 
  { label:"RWANDESA", value:139}, 
  { label:"SALOMONESA", value:503}, 
  { label:"SALVADOREÑA", value:214}, 
  { label:"SAMOANA", value:507}, 
  { label:"SAN MARINENSE", value:431}, 
  { label:"SANTA LUCIENSE", value:230}, 
  { label:"SENEGALESA", value:142}, 
  { label:"SIERRA LEONESA", value:144}, 
  { label:"SINGAPORENSE", value:332}, 
  { label:"SIRIA", value:330}, 
  { label:"SOMALI", value:145}, 
  { label:"SOVIETICA BIELORRUSA", value:428}, 
  { label:"SOVIETICA UCRANIANA", value:429}, 
  { label:"SUDAFRICANA", value:146}, 
  { label:"SUDANESA", value:147}, 
  { label:"SUDCOREANA", value:308}, 
  { label:"SUECA", value:432}, 
  { label:"SUIZA", value:433}, 
  { label:"SURINAMENSE", value:231}, 
  { label:"SWAZI", value:148}, 
  { label:"TAILANDESA", value:334}, 
  { label:"TAIWANESA", value:335}, 
  { label:"TANZANIANA", value:137}, 
  { label:"TOGOLESA", value:149}, 
  { label:"TRINITARIA", value:232}, 
  { label:"TUNECINA", value:150}, 
  { label:"TURCA", value:336}, 
  { label:"UGANDESA", value:151}, 
  { label:"URUGUAYA", value:233}, 
  { label:"VATICANA", value:410}, 
  { label:"VENEZOLANA", value:234}, 
  { label:"YEMENI", value:339}, 
  { label:"YUGOSLAVA", value:435}, 
  { label:"ZAIRANA", value:152}, 
  { label:"ZAMBIANA", value:153}
]


export const CITA_HORAS = [
  { label:"10:00", value:"10:00"},
  { label:"10:30", value:"10:30"},
  { label:"11:00", value:"11:00"},
  { label:"11:30", value:"11:30"},
  { label:"12:00", value:"12:00"},
  { label:"12:30", value:"12:30"},
  { label:"13:00", value:"13:00"},
  { label:"13:30", value:"13:30"},
  { label:"14:00", value:"14:00"},
  { label:"14:30", value:"14:30"}
]

export const CAT_PAIS = [
  { label:"Andorra (Principado de)", value:"AD" }, 
  { label:"Emiratos Árabes Unidos)", value:"AE" }, 
  { label:"Afganistán (Emirato Islámico de)", value:"AF" }, 
  { label:"Antigua y Barbuda (Comunidad Británica de", value:"AG" }, 
  { label:"Naciones) Anguila", value:"AI" }, 
  { label:"Albania (República de)", value:"AL" }, 
  { label:"Armenia (República de)", value:"AM" }, 
  { label:"Angola (República de)", value:"AO" }, 
  { label:"Antártida", value:"AQ" }, 
  { label:"Argentina (República)", value:"AR" }, 
  { label:"Samoa", value:"AS" }, 
  { label:"Austría (República de)", value:"AT" }, 
  { label:"Australia (Comunidad de)", value:"AU" }, 
  { label:"Aruba (Territorio Holandés de Ultramar)", value:"A" },  
  { label:"Islas Åland", value:"AX" }, 
  { label:"Azerbaijan (República Azerbaijani)", value:"AZ" }, 
  { label:"Bosnia y Herzegovina", value:"BA" }, 
  { label:"Barbados (Comunidad Británica de Naciones)", value:"BB" }, 
  { label:"Bangladesh (República Popular de)", value:"BD" }, 
  { label:"Bélgica (Reino de)", value:"BE" }, 
  { label:"Burkina Faso", value:"BF" }, 
  { label:"Bulgaria (República de)", value:"BG" }, 
  { label:"Bahréin (Estado)", value:"BH" }, 
  { label:"Burundi (República de)", value:"BI" }, 
  { label:"Benín (República de)", value:"BJ" },  
  { label:"Saint Barthelemy", value:"BL" }, 
  { label:"Bermudas", value:"BM" }, 
  { label:"Brunei (Estado de) (Residencia de Paz)", value:"BN" }, 
  { label:"Bolivia (República de)", value:"BO" }, 
  { label:"Bonaire, San Eustaquio y Saba", value:"BQ" }, 
  { label:"Brasil (República Federativa de)", value:"BR" }, 
  { label:"Bahamas (Comunidad de las)", value:"BS" }, 
  { label:"Bután (Reino)", value:"BT" }, 
  { label:"Isla Bouvet", value:"BV" }, 
  { label:"Botswana (República de)", value:"B" }, 
  { label:"Belarus", value:"BY" }, 
  { label:"Belice", value:"BZ" }, 
  { label:"Canadá", value:"CA" }, 
  { label:"Islas Cocos (Keeling)", value:"CC" }, 
  { label:"Congo (la República Democrática del)", value:"CD" }, 
  { label:"República Centroafricana", value:"CF" }, 
  { label:"Congo (Brazzaville)", value:"CG" }, 
  { label:"Suiza (Confederación)", value:"CH" }, 
  { label:"Costa de Marfil (República de la)", value:"CI" }, 
  { label:"Islas Cook", value:"CK" }, 
  { label:"Chile (República de)", value:"CL" }, 
  { label:"Camerún (República del)", value:"CM" }, 
  { label:"China (República Popular)", value:"CN" }, 
  { label:"Colombia (República de)", value:"CO" }, 
  { label:"Costa Rica (República de)", value:"CR" }, 
  { label:"Cuba (República de)", value:"CU" },  
  { label:"Cabo Verde (República de)", value:"CV" }, 
  { label:"Curaçao", value:"CW" }, 
  { label:"Isla de Navidad", value:"CX" }, 
  { label:"Chipre (República de)", value:"CY" }, 
  { label:"República Checa", value:"CZ" }, 
  { label:"Granada", value:"GD" }, 
  { label:"Georgia (República de)", value:"GE" }, 
  { label:"Guayana Francesa", value:"GF" }, 
  { label:"Guernesey", value:"GG" },   
  { label:"Ghana (República de)", value:"GH" }, 
  { label:"Gibraltar (R.U.)", value:"GI" }, 
  { label:"Groenlandia (Dinamarca)", value:"GL" }, 
  { label:"Gambia (República de la)", value:"GM" }, 
  { label:"Guinea (República de)", value:"GN" }, 
  { label:"Guadalupe (Departamento de)", value:"GP" }, 
  { label:"Guinea Ecuatorial (República de)", value:"GQ" }, 
  { label:"Grecia (República Helénica)", value:"GR" },               
  { label:"Georgia del Sur e Islas Sándwich", value:"GS" },
  { label:"Guatemala (República de)", value:"GT" },
  { label:"Guam (E.U.A.)", value:"GU" },
  { label:"Guinea-Bissau (República de)", value:"GW" },
  { label:"Guayana", value:"GY" },
  { label:"Hong Kong (Región Administrativa Especial de la República)", value:"HK" },
  { label:"Islas Heard y McDonald", value:"HM" },
  { label:"Honduras (República de)", value:"HN" },
  { label:"Croacia (República de)", value:"HR" },
  { label:"Haití (República de)", value:"HT" },                               
  { label:"Hungría (República de)", value:"HU" },
  { label:"Indonesia (República de)", value:"ID" },
  { label:"Irlanda (República de)", value:"IE" },
  { label:"Israel (Estado de)", value:"IL" },
  { label:"Isla de Man", value:"IM" },
  { label:"India (República de)", value:"IN" },
  { label:"Territorios Británicos del Océano Índico", value:"IO" },
  { label:"Irak (República de)", value:"IQ" },
  { label:"Irán (República Islámica del)", value:"IR" },
  { label:"Islandia (República de)", value:"IS" },
  { label:"Italia (República Italiana)", value:"IT" },
  { label:"Jersey", value:"JE" },                           
  { label:"Jamaica", value:"JM" },
  { label:"Jordania (Reino Hachemita de)", value:"JO" },
  { label:"Japón", value:"JP" },
  { label:"Kenya (República de)", value:"KE" },
  { label:"Kirguistán", value:"KG" },
  { label:"Camboya (Reino de)", value:"KH" },
  { label:"Kiribati (República de)", value:"KI" },
  { label:"Islas Comoros", value:"KM" },
  { label:"San Cristóbal y Nieves (Federación de) (San Kitts-Nevis)", value:"KN" },
  { label:"Corea (República Popular Democrática de) (Corea del Norte)", value:"KP" },
  { label:"Corea (República de) (Corea del Sur)", value:"KR" },
  { label:"Kuwait (Estado de)", value:"KW" },
  { label:"Islas Caimán", value:"KY" },
  { label:"Kazakhstan (República de)", value:"KZ" },
  { label:"Laos", value:"LA" },
  { label:"Líbano (República de)", value:"LB" },
  { label:"Santa Lucia", value:"LC" },
  { label:"Liechtenstein (Principado de)", value:"LI" },
  { label:"Sri Lanka (República Democrática Socialista de)", value:"LK" },
  { label:"Liberia (República de)", value:"LR" },
  { label:"Lesotho (Reino de)", value:"LS" },
  { label:"Lituania (República de)", value:"LT" },
  { label:"Luxemburgo (Gran Ducado de)", value:"LU" },
  { label:"Letonia (República de)", value:"LV" },
  { label:"Libia (Jamahiriya Libia Árabe Popular Socialista)", value:"LY" },
  { label:"Marruecos (Reino de)", value:"MA" },
  { label:"Mónaco (Principado de)", value:"MC" },
  { label:"Moldavia (República de)", value:"MD" },
  { label:"Montenegro", value:"ME" },
  { label:"Saint Martin", value:"MF" },
  { label:"Madagascar (República de)", value:"MG" },
  { label:"Islas Marshall", value:"MH" },
  { label:"Macedonia (Antigua República Yugoslava de)", value:"MK" },
  { label:"Mali (República de)", value:"ML" },
  { label:"Myanmar (Unión de)", value:"MM" },
  { label:"Mongolia", value:"MN" },
  { label:"Macao", value:"MO" },                                                
  { label:"Islas Marianas del Norte", value:"MP" },
  { label:"Martinica (Departamento de Francia)", value:"MQ" },
  { label:"Mauritania (República Islámica de)", value:"MR" },
  { label:"Monserrat (Isla)", value:"MS" },
  { label:"Malta (República de)", value:"MT" },
  { label:"Mauricio (República de)", value:"MU" },
  { label:"Maldivas (República de)", value:"MV" },
  { label:"Malawi (República de)", value:"MW" },
  { label:"México (Estados Unidos Mexicanos)", value:"MX" },                         
  { label:"Malasia", value:"MY" },
  { label:"Mozambique (República de)", value:"MZ" },
  { label:"Namibia (República de)", value:"NA" },
  { label:"Nueva Caledonia (Territorio Francés de Ultramar)", value:"NC" },
  { label:"Níger (República de)", value:"NE" },
  { label:"Isla Norfolk", value:"NF" },
  { label:"Nigeria (República Federal de)", value:"NG" },
  { label:"Nicaragua (República de)", value:"NI" },
  { label:"Países Bajos (Reino de Los) (Holanda)", value:"NL" },
  { label:"Noruega (Reino de)", value:"NO" },
  { label:"Nepal (Reino de)", value:"NP" },
  { label:"Nauru", value:"NR" },
  { label:"Nive (Isla)", value:"NU" },
  { label:"Nueva Zelandia", value:"NZ" },
  { label:"Omán (Sultanato de)", value:"OM" },  
  { label:"Panamá (República de)", value:"PA" },
  { label:"Perú (República del)", value:"PE" },
  { label:"Polinesia Francesa", value:"PF" },
  { label:"Papúa Nueva Guinea (Estado Independiente de)", value:"PG" },
  { label:"Filipinas (República de las)", value:"PH" },
  { label:"Pakistán (República Islámica de)", value:"PK" },
  { label:"Polonia (República de)", value:"PL" },
  { label:"San Pedro y Miquelón", value:"PM" },
  { label:"Islas Pitcairn", value:"PN" },
  { label:"Puerto Rico (Estado Libre Asociado de la Comunidad de)", value:"PR" },
  { label:"Palestina", value:"PS" },
  { label:"Portugal (República Portuguesa)", value:"PT" },
  { label:"Palau (República de)", value:"PW" },
  { label:"Paraguay (República del)", value:"PY" },
  { label:"Qatar (Estado de)", value:"QA" },
  { label:"Reunión (Departamento de la) (Francia)", value:"RE" },
  { label:"Rumania", value:"RO" },
  { label:"Serbia", value:"RS" },
  { label:"Rusia (Federación Rusa)", value:"RU" },
  { label:"Ruanda", value:"RW" },
  { label:"Arabia Saudita (Reino de)", value:"SA" },
  { label:"Islas Salomón (Comunidad Británica de Naciones)", value:"SB" },
  { label:"Seychelles (República de las)", value:"SC" },
  { label:"Sudan (República del)", value:"SD" },
  { label:"Suecia (Reino de)", value:"SE" },
  { label:"Singapur (República de)", value:"SG" },
  { label:"Santa Elena", value:"SH" },
  { label:"Eslovenia (República de)", value:"SI" },
  { label:"Svalbard y Jan Mayen", value:"SJ" },
  { label:"Eslovaquia", value:"SK" },
  { label:"Sierra Leona (República de)", value:"SL" },
  { label:"San Marino (Serenísima República de)", value:"SM" },
  { label:"Senegal (República del)", value:"SN" },
  { label:"Somalia", value:"SO" },
  { label:"Surinam (República de)", value:"SR" },
  { label:"Sudan Sur", value:"SS" },
  { label:"Santo Tome y Príncipe (República Democrática de)", value:"ST" },
  { label:"El Salvador (República de)", value:"SV" },
  { label:"Sint Maarten", value:"SX" },
  { label:"Siria (República Árabe)", value:"SY" },
  { label:"Suazilandia", value:"SZ" },
  { label:"Islas Turcas y Caicos", value:"TC" },
  { label:"Chad (República del)", value:"TD" },
  { label:"Territorios Franceses del Sur", value:"TF" },
  { label:"Togo (República Togolesa)", value:"TG" },
  { label:"Tailandia (Reino de)", value:"TH" },    
  { label:"Tayikistán", value:"TJ" },    
  { label:"Tokelau", value:"TK" },    
  { label:"Timor Oriental", value:"TL" },    
  { label:"Turkmenistán (República de)", value:"TM" },
  { label:"Túnez (República de)", value:"TN" },    
  { label:"Tonga (Reino de)", value:"TO" },    
  { label:"Turquía (República de)", value:"TR" },    
  { label:"Trinidad y Tobago (República de)", value:"TT" },    
  { label:"Tuvalu (Comunidad Británica de Naciones)", value:"TV" },    
  { label:"Taiwán (República de China)", value:"TW" },    
  { label:"Tanzania (República Unida de)", value:"TZ" },    
  { label:"Ucrania", value:"UA" },    
  { label:"Uganda (República de)", value:"UG" },    
  { label:"Estados Unidos (Islas Menores Alejadas", value:"UM " },    
  { label:"Estados Unidos de America", value:"US" },        
  { label:"Uruguay (República Oriental del)", value:"UY" },    
  { label:"Uzbekistán (República de)", value:"UZ" },    
  { label:"Ciudad del Vaticano (Estado de la)", value:"VA" },
  { label:"San Vicente y Las Granadinas", value:"VC" },  
  { label:"Venezuela (República de)", value:"VE" },
  { label:"Islas Vírgenes Británicas", value:"VG" },
  { label:"Islas Vírgenes de EE.UU.", value:"VI" },
  { label:"Vietnam (República Socialsita de)", value:"VN" },
  { label:"Vanuatu", value:"VU" },
  { label:"Wallis y Futuna", value:"WF" },
  { label:"Samoa (Estado Independiente de)", value:"WS" },
  { label:"Yemen (Repúlica de)", value:"YE" },
  { label:"Mayotte", value:"YT" },
  { label:"Sudáfrica (República de)", value:"ZA" },
  { label:"Zambia (República de)", value:"ZM" },
  { label:"Zimbabue (República de)", value:"ZW" },
  { label:"Paises no declarados", value:"ZZ" }
]