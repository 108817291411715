import React, { useContext, useEffect } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType,  IActionProps, PLAZO_20DIAS, PerfilTypes  } from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';
import { tramiteAction } from '../ActUtil';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { Accion, NodoEstado } from '../../AfdUtil';
import { createLstPerfil } from '../UsersUtil';
import TramitesSvc from '../../../../services/TramitesSvc';

interface IData { onhide: any, data : IActionProps  }

export const Act116EfirmaAdmision : React.FC<IData> = ( act116Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  const DATA_PROCESS = {				
    tiuloItf:{ type:EFieldObj.H5, id:"tiuloItf", label:"Destinatario de la notificación ", display:EFieldDisplay.SHOW },
		numoficio:	{ gpo:"fintech",	id:"numoficio",    type:EFieldObj.TXT, save:1,  label:"Número de oficio",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true, style:{width:"200px"},
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Número de oficio" },

    itf:     { gpo:'admitir',   id:"itf",      type:EFieldObj.TXT, save:1,   label:"Institución de Tecnología Financiera", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"" },      

    lstPerfil3:	{ gpo:'dictamen', id:"lstPerfil3", type:EFieldObj.CBO, save:1, label:"Seleccione a una persona de la Institución Financiera", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Seleccionar una persona", list: [] },

    edoterm:	{ gpo:'prev',   id:"edoterm", type:EFieldObj.CBO, save:1, label:"Ajuste el plazo o término procesal", oper:EFieldOper.UPDATE, value:10, rules:[], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Selecione una opción", list:PLAZO_20DIAS },


    titleAdmitir:{ type:EFieldObj.H5, id:"titleAdmitir", label:"Ingrese el proyecto de admisión", display:EFieldDisplay.SHOW },
    docefirma:	{ gpo:'admitir',   id:"docefirma", type:EFieldObj.FILE, save:1, label:"Archivo en formato PDF", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW, 
      classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf", typedoc:FormType.PROYECTO  },
        
		titlefirma:{ type:EFieldObj.H5, id:"titlefirma", label:"Ingrese sus archivos para firmar electrónicamente", display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'admitir',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },

		doccert:	{ gpo:'admitir',  id:"doccert",  type:EFieldObj.FILE,    label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-6", classNameObj:"col-md-6", save:1, classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },

    efirma: { gpo:'admitir',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"}, 

      
    mensaje:	{ gpo:'admitir',   id:"mensaje", type:EFieldObj.AREA,  save:1, label:"Comentarios", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
    classNameLabel:"col-md-6", classNameObj:"col-md-6", classNameGroup:"col-md-12", placeholder:"", varrules:{} }            
  }
  
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL )  
  
  useEffect( () => {
    
    if ( act116Props.data === undefined ){
      setPageState(EPageState.ERROR, "No hay datos que mostrar"); 
    } else {      
      TramitesSvc.TraEdoPsnPflInst( act116Props.data.tramite.traid, PerfilTypes.INST_FINANCIERA, act116Props.data.oprid  )      
      
      
      .then ((response) => {                              
         if ( response.status !== 200 ) {          
          setPageState(EPageState.ERROR, "¡No fue posible obtener datos, vuelva a intentar!");  
          return;
        }

        let newData = {...dataProcess}
        newData.lstPerfil3.list = createLstPerfil(response.data, PerfilTypes.INST_FINANCIERA);
        newData.lstPerfil3.value = "";
        newData.itf.value = response.data[0].psnsysinsnam;

        setPageState(EPageState.STEP0, "");
        onChangeAll(newData);
      })
      .catch( (e) => {                
        setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
      });
      setPageState(EPageState.LOAD_DATA, "");  
    }
  }, []); 


  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }    
    setPageState(EPageState.STEP1, "");     
    let dataMsg = { id:"Mensaje", name:"Mensaje", type:EFieldObj.TXT, save:1,  label:"Comentarios",  value:"Su solicitud ha sido admitida por parte de la CONDUSEF, esto es un mensaje informativo" };    
    let optional  = { accid:Accion.FINTECH_CON_NOTIFICAR_ADMISION,  	tdatid:FormType.NOTIFICAR, data:dataMsg, edoid:NodoEstado.FINTECH_USR_QUEJA_ADMITIDA };

    tramiteAction(act116Props,  FormType.ASIGNAR, dataProcess, state, dispatch,  setPageState, optional);
  }
            
  const DrawMsg = () => {
    if (pageState.msg.length > 0) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act116Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {

      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}
