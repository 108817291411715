import React, { useState } from 'react'
import { EFieldObj } from '../../../form/fields/field';
import TramitesSvc from '../../../services/TramitesSvc';
import { EPageState, usePageState } from '../../../ui/uses/usePageState';
import { LocalDataErrorView } from '../../states/LoadDataErrorView';
import { LocalDataView } from '../../states/LoadDataView';

import './Table.css';

interface IItemData { 
  data: any;  
  setListTask :any;
  init: string;
	tasks:any;
	nodos:any;
}

export const TaskInProcess: React.FC<IItemData> = (props) => {
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
  const [item, setItem] = useState<number>(0);
  
  const actividades = () => {
    let dhtml = [];
    let dhtmlBody = [];
    let idx=0;
    let limite = props.tasks.length;

    dhtml.push(
      <thead>
        <tr>
          <th>No.</th>
          <th>Estado</th>
          <th>Fecha actividad</th>
          <th>Actividad</th>
          <th>Responsable</th>
          <th>Archivos</th>    
        </tr>
      </thead>
    );  

    for (idx = 0; idx< limite; idx++ ){      
      let nodestado ="Concluido";

      if (props.nodos[idx].nodstatus===1){
        nodestado ="En proceso";
      }
      
      //DEFINIR  SI ES: USUARIO - INSTITUCION - SERVIDOR PÚBLICO 
      let strPuesto = "";
      if (props.nodos[idx].insid ===0 ){
        strPuesto = "USUARIO SISTEMA FINANCIERO"
      } else if (props.nodos[idx].insid > 0 ){
        strPuesto = "INSTITUCIÓN FINANCIERA"
      } else {
        strPuesto = "CONDUSEF"
      }

      dhtmlBody.push(
        <tr key={"R" + idx}  >
          <td key={"divTaskC1R" + idx} >{idx+1}</td>
          <td key={"divTaskC2R" + idx} >{nodestado}</td>
          <td key={"divTaskC3R" + idx} >{props.nodos[idx].nodfecha}</td>
          <td key={"divTaskC4R" + idx} >{props.nodos[idx].edodesc.toUpperCase()}</td>
          <td key={"divTaskC5R" + idx} >{strPuesto}</td>
          <td key={"divTaskC6R" + idx} >{actFiles(props.nodos[idx].datcontenido) }</td>    
        </tr>
      );
    }
    return ( <table id="customers"> {dhtml} <tbody> {dhtmlBody}</tbody></table>);
  };

  const file_Click = (filename: string, bucket :string, path:string ) => {
    TramitesSvc.DocumentoGet(filename, bucket, path);    
  }

  const actFiles = (datcontenido:any) => {
    let dhtmlFiles:any = [];
    let data = JSON.parse(datcontenido);

    if ( data.hasOwnProperty("accion") ) {
      if ( data.accion.hasOwnProperty("optional") && data.accion.optional.hasOwnProperty("data") ) {
        data = data.accion.optional.data;
      } else {
        data = data.accion.data;
      }      
    }
    
    Object.keys(data).forEach(idx => {
      if (data[idx].type === EFieldObj.FILE  &&  data[idx].name.length > 0 &&  data[idx].name.indexOf(".key") === -1 &&  data[idx].name.indexOf(".cer") === -1 ) {
        dhtmlFiles.push(
          <span key={"file-" + idx } onClick={ (evt) =>  file_Click( data[idx].value + "_" + data[idx].name, props.data.bukdesc, data[idx].url  )} 
          className="col-md-12">
            <i key={"file-i-" + data[idx].value }  className="pi pi-file" style={{ fontSize:"20px",cursor:"pointer", color:"black" }}>{data[idx].name};</i>
          </span>
        )
      }
    });    
    return dhtmlFiles;    
  }

  const drawBody = () => {		
    if ( props.data === undefined) {
      setPageState(EPageState.INIT, "");  
      return;
    } else {
      return  (
        <div style={{ top:"55px", position:"relative" }} >
          <div style={{ backgroundColor:"white", height:"85vh", overflowY:"scroll" }}>
            <br />
            <div className="col-xs-12" > 
              <div className="col-xs-3"><strong>Trámite:</strong> </div>
              <div className="col-xs-9"><strong>{props.data.tradesc}</strong> </div>
            </div>
            <div className="col-xs-12" > 
              <div className="col-xs-3"><strong>Fecha recepción:</strong> </div>
              <div className="col-xs-9"><strong>{props.data.oprfecrecepcion}</strong> </div>                                                            
            </div>  
            <div className="col-xs-12" > 
              <div className="col-xs-3"><strong>Folio:</strong> </div>
              <div className="col-xs-9"><strong>{props.data.oprfolio}</strong> </div>
            </div>
            <div className="col-xs-12" > 
              <div className="col-xs-3"><strong>Área responsable:</strong> </div>
              <div className="col-xs-9"><strong>{props.data.pfldesc}</strong> </div>
            </div>

            <div className="col-xs-12" style={{ marginTop:"20px" }} ><strong>ACTIVIDADES</strong></div>
            <div className="col-xs-12"> 
              { actividades() }              
            </div>

          </div>    
        </div>
      )
    }        
  }

/*
            <div className="col-xs-12 " style={{ marginTop:"30px" }} >
              <div><strong>DÍAS TRANSCURRIDOS DE ATENCIÓN</strong></div>
              <div style={{ backgroundColor:"white", zoom:"0.9", position:"static"}}>
                <TaskGantt tasks={props.tasks} viewMode={ViewMode.Month}  ></TaskGantt>
              </div>
            </div>
*/            


  const drawStateCtrl = () => {	    
    if ( props.data === undefined  ){
      return;
    }

    if (props.data.oprid !== item   ) {
      setItem(props.data.oprid);
      return;
    }

		switch ( pageState.state ) {			
			case EPageState.INIT:
				return drawBody();
 
      case EPageState.LOAD_DATA:
				return <LocalDataView msg="" color={"black"}/>;
        
      case EPageState.ERROR:
				return <LocalDataErrorView msg={pageState.msg} color={"black"}/>
        
			default:
				return <LocalDataErrorView msg={pageState.msg} color={"black"}/>
		}
  }
  return (< div style={{backgroundColor:"whitesmoke"}}> {drawStateCtrl()} </div>);
}