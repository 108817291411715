
import React from 'react'
// ESTILOS 
import './Nodo.css';
import { userColor } from '../../util/cssUtil';
interface IData { remitente: string, fecha:string, estado:string, titulo:string, folio:string, insid:number  }

// { props.estado} <-- corregi rel estado..
// { props.estado} <-- corregi rel estado..
// { props.estado} <-- corregi rel estado..
/*
    Rojo  	claro	    #9F2241
    Rojo  	osbcuro	  #691C32
    Verde  	claro	    #235B4E
    Verde  	osbcuro	  #10312B
    Beige   claro	    #DDC9A3
    Beige   osbcuro	  #BC955C
    Gris    claro	    #98989A
    Girs    osbcuro	  #6F7271

    <Avatar icon="pi pi-user" className="p-mr-2" size="xlarge" />
    <Avatar icon="pi pi-user"  size="xlarge" style={{ backgroundColor: '#235B4E', color: '#ffffff',  }}  shape="circle" />
*/

export const NodoHeader: React.FC<IData> = (props) => {
  const iconColor = userColor(props.insid);
  //<Avatar icon="pi pi-user"  className="p-mr-2"  size="xlarge" style={{ backgroundColor: '#AAAAAA', color: iconColor }}  shape="square" />


  return (    
    <div style={{ width:"100%", bottom:"56px"}}>
      <div style={{ backgroundColor:"whitesmoke", width:"100%", paddingTop:"8px", float:"inherit" }} >
        <div className="col-md-1" style={{ textAlign:"center",  margin:"auto", paddingTop:"20px", paddingLeft:"40px" }}>
          <span className="glyphicon glyphicon-user" style={{  transform:"scale(4.0)", paddingTop:"8px", color: iconColor  }}></span> 
        </div>
        <div className="col-md-11" style={{ marginBottom:"12px"}} >
          <div className="col-md-12" style={{  }}>{props.remitente}</div>
          <div className="col-md-12" style={{ fontSize:"18px", fontWeight:"bold" }}>{props.estado}</div>
          <div className="col-md-12" style={{ fontSize:"18px" }}> <strong>FOLIO: {props.folio }</strong> </div>        
          <div className="col-md-6" style={{ fontSize:"18px" }}> {props.fecha } </div>
          
        </div>      
      </div>
      <div style={{ borderBottom: "3px solid #BC955C", backgroundColor:"whitesmoke", width:"100%", height:"1px", float:"left" }}></div>    
    </div>
  );
}