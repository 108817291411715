import React, { useRef, ChangeEvent, useState, Fragment, useContext   } from 'react';
import './Login.css';

import imageBG from '../../images/condusef.png';

import { TabView, TabPanel } from 'primereact/tabview';
import {ProgressBar} from 'primereact/progressbar';
import { Captcha } from 'primereact/captcha';
import {Message} from 'primereact/message';
import  TramitesSvc  from '../../services/TramitesSvc';

import { HeaderGobMx } from '../main/HeaderGobMx';
import { FooterGobMx } from '../main/FooterGobMx';

import { AppContext } from '../../Context';
import { PerfilTypes, Types} from '../../reducers/Model'
import { ViewName } from '../../reducers/ViewReducer'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export const Login = () => {  
  const { dispatch } = useContext(AppContext);

  const inputCert = useRef<HTMLInputElement>(null);
  const inputKey = useRef<HTMLInputElement>(null);
  const [fileCert, setfileCert] = useState<File>();
  const [fileKey, setFileKey] = useState<File>();
  const [secretFileKey, setSecretFileKey] = useState<string>("");
  const [user, setUser] = useState<string>("");
  const [userSecret, setUserSecret] = useState<string>("");
  const [labelWarning,  setLabelWarning] = useState<string>("");
  const [showProgbar, setShowProgbar] = useState<string>("");  
  const [ displayMsg, setDisplayMsg ] = useState<boolean>(false);  

/*
  <div className="pull-right">
  <input className="btn btn-primary" type="button" value="TEST" id="btnEnviarTest" name="btnEnviarTest" onClick={ ()=> btnEfirmaTestOnClick() } />                      
</div>
*/
  const DrawProgBar = ( seccion: String ) => {
    if (showProgbar!) {
      return (
        <ProgressBar mode="indeterminate" />
      )
    } else {
      if (seccion === "EFIRMA" ){
        return (
          <div className="clearfix">
            <div className="pull-right">
              <input className="btn btn-primary" type="button" value="Enviar" id="btnEnviarEfirma" name="btnEnviarEfirma" onClick={ ()=> btnEfirmaOnClick() } />                      
            </div>

        </div>
        )                
      } else if (seccion === "SIPRES"){
        return (
          <div className="clearfix">
            <div className="pull-right">
              <input className="btn btn-primary" type="button" value="Enviar" id="btnEnviarEfirma" name="btnEnviarEfirma" onClick={ ()=> btnSipresOnClick() } />                      
            </div>
        </div>
        )
      } else if (seccion === "ACTIVE_DIRECTORY"){ 
        return (
          <div className="clearfix">
            <div className="pull-right">
              <input className="btn btn-primary" type="button" value="Enviar" id="btnActDir" name="btnActDir"  onClick={ ()=> btnActDirOnClick() } />                            
            </div>
          </div>          
        )
      } else
      return (
        <div className="clearfix">
          <div className="pull-right">
            {
              //<input className="btn btn-primary" type="button" value="Enviar" id="btnActDir" name="btnActDir"  onClick={ ()=> btnActDirOnClick() } /> 
            }
            <input className="btn btn-primary" type="button" value="Enviar" id="btnActDir" name="btnActDir"  onClick={ ()=> alert("CIC") } /> 
          </div>
        </div>          
      )
    }
  }

  const DrawMsg = () => {
    if (labelWarning!) {
      return (
        <div>
          <div className="alert alert-danger " >{labelWarning}</div>
        </div>
      )
    }
  }

  const btnCertOnClick = () => {
    if(inputCert && inputCert.current) {
      inputCert.current.click();
    }     
  }

  const onCertFileChange = (evt:  ChangeEvent<HTMLInputElement> ) => {
    const FileList = evt.target.files;
    let i;
    for (i = 0; i < FileList!.length; i++) {
      setfileCert(FileList![i]);
    }
  };

  const btnKeyOnClick = () => {
    if(inputKey && inputKey.current) {
      inputKey.current.click();
    }     
  }

  // CAMBIAR LOS DATOS Y LOS CMAPOS....
  const txtUserOnChange= ( evt: ChangeEvent<HTMLInputElement> ) => {
    setUser(evt.target.value);    
    setLabelWarning("");
  }
  
  const txtPwdOnChange= ( evt: ChangeEvent<HTMLInputElement> ) => {
    setUserSecret(evt.target.value);    
    setLabelWarning("");
  }
  
  const onTxtKeySecret = ( evt: ChangeEvent<HTMLInputElement> ) => {
    setSecretFileKey(evt.target.value);    
  }

  const onKeyFileChange = (evt:  ChangeEvent<HTMLInputElement> ) => {
    const FileList = evt.target.files;
    let i;
    for (i = 0; i < FileList!.length; i++) {
      setFileKey(FileList![i]);
    }
  };

  const btnActDirOnClick= () =>{    
    let formData = new FormData();
  
    if (user === undefined){
      setLabelWarning("Es necesario teclear un nombre de usuario"); 
      return ;
    }

    if (userSecret === undefined){
      setLabelWarning("Es necesario teclear un contraseña");
      return ;
    }    
    
    formData.append("user", user!);
    formData.append("secret", userSecret!);
    setShowProgbar("PROGRESS_BAR");

    //    SegActDirPost
    TramitesSvc.SegToken(formData)			
    .then(response => {         				
      if ( response.status ===  200) {
        if ( response.data.psnid !== undefined) {                                         
          //VERIFICAR ESTE CAMBIO
          //dispatch({ type: Types.USER_INIT, page:{ mainpsnid: response.data.mainpsnid, psnid:response.data.psnid, users:[...response.data.users],  session:"12324313", type: response.data.type, cic:response.data.cic }});
          
          let aPfl : number[] = [];
          response.data.pflid.split(",").forEach( (ele:string) => aPfl.push(Number(ele)))

          dispatch({ type: Types.USER_INIT, page:{ mainpsnid: response.data.mainpsnid, maininsid: response.data.maininsid, psnid:response.data.psnid, insid:response.data.insid,  
            users:[...response.data.users],  session:response.data.session, type: response.data.type, cic:{}, pflid: aPfl }});
          
          dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"", refresh:""} });
          return;
        } else {
          setLabelWarning("Los datos recibidos por la aplicación no son correctos");
          setShowProgbar("");
        }              
      } else {
        setLabelWarning("Los datos recibidos por la aplicación no son correctos (CODE)");
        setShowProgbar("");
      }
    })
    .catch(e => {
      setShowProgbar("");
      setLabelWarning("No fue posible conectarse con el servicio de autentificación ");
      console.log(e);
      return;
    });  
  }
  
  const btnSipresOnClick= () =>{
    let formData = new FormData();
  
    if (user === undefined){
      setLabelWarning("Es necesario teclear un nombre de usuario"); 
      return ;
    }

    if (userSecret === undefined){
      setLabelWarning("Es necesario teclear un contraseña");
      return ;
    }    
    
    formData.append("user", user!);
    formData.append("secret", userSecret!);

    setShowProgbar("PROGRESS_BAR");

    TramitesSvc.SegSipresPost(formData)			
    .then(response => {         				
      if ( response.status ===  200) {
        if ( response.data.psnid !== undefined) {                    
          let aPfl : number[] = [PerfilTypes.INST_FINANCIERA];
          dispatch({ type: Types.USER_INIT, page:{ mainpsnid: response.data.mainpsnid, maininsid: response.data.maininsid, psnid:response.data.psnid, insid:response.data.insid,  
            users:[...response.data.users],  session:"12324313", type: response.data.type, cic:{}, pflid: aPfl }});
          dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"", refresh:""} });
          return;
        } else {
          setLabelWarning("Los datos recibidos por la aplicación no son correctos");
          setShowProgbar("");
        }              
      } else {
        setLabelWarning("Los datos recibidos por la aplicación no son correctos (CODE)");
        setShowProgbar("");
      }
    })
    .catch(e => {
      setShowProgbar("");
      setLabelWarning("No fue posible conectarse con el servicio de autentificación ");
      console.log(e);
      return;
    });    
  }

  const btnEfirmaOnClick = () =>{
    let formData = new FormData();        
    formData.append("fileCer", fileCert!);
    formData.append("fileKey", fileKey!);    
    formData.append("secreto", secretFileKey!);
    formData.append("fileCerName", fileCert!.name);
    formData.append("fileKeyName", fileKey!.name);    

    setShowProgbar("PROGRESS_BAR");

    TramitesSvc.SegEfirmaPost(formData)			
    .then(response => {
      setShowProgbar("");
      if ( response.status ===  200) {
        if ( response.data.psnid !== undefined) {        
          let aPfl : number[] = [PerfilTypes.CIUDADANO];
          dispatch({ type: Types.USER_INIT, page:{ mainpsnid: response.data.mainpsnid, maininsid: response.data.maininsid, psnid:response.data.psnid, insid:response.data.insid,  
            users:[...response.data.users],  session:"12324313", type: response.data.type, cic:{}, pflid: aPfl }});
          //que oepracion se realizo...alta o ya estaba registrado...
          if (response.data.view === 1 )
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_PROCESOS , msg:"", refresh:""} });
          else
            dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"", refresh:""} });
            
        } else {
          setLabelWarning("Los datos no son correctos, vuelva a intentar");  
        }
      } else {
        setLabelWarning("Los datos no son correctos, vuelva a intentar");
      }      
    })
    .catch(e => {
      setShowProgbar("");
      if (e.response !== undefined) {
        setLabelWarning(e.response.data);
      } else {
        setLabelWarning("Los servicios de la app no responden, vuelva a intentar más tarde");
      }      
      console.log(e);
      return;
    });      
  }

  const btnEfirmaTestOnClick = () =>{
    let formData = new FormData();        
    formData.append("fileCer", fileCert!);
    formData.append("fileKey", fileKey!);    
    formData.append("secreto", secretFileKey!);

    formData.append("fileCerName", fileCert!.name);
    formData.append("fileKeyName", fileKey!.name);    

    setShowProgbar("PROGRESS_BAR");

    TramitesSvc.SegEfirmaTestPost(formData)			
    .then(response => {
      setShowProgbar("");
      if ( response.status ===  200) {               
        
        const respuesta = "RFC:"  + response.data.RFC 
          + " CURP: " + response.data.CURP
          + " NOMBRE: " + response.data.USUARIO
          + " CORREO: " + response.data.CORREO
          + " STATUS: " + response.data.STATUS
          + " STATUSMSB: " + response.data.STATUSMSG;

          setLabelWarning(respuesta);
      } else {
        setLabelWarning("Los datos no son correctos, vuelva a intentar");
      }      
    })
    .catch(e => {
      setShowProgbar("");
      if (e.response !== undefined) {
        setLabelWarning(e.response.data);
      } else {
        setLabelWarning("Los servicios de la app no responden, vuelva a intentar más tarde");
      }      
      console.log(e);
      return;
    });      
  }

  const drawUserForm = () => {
    return (
      <div style={{textAlign:"left" }} >                  
        <div className="form-group">
          <div className="input-group">
            <label className="control-label" htmlFor="txtCertificate">Certificado (.cer):</label>
            <input className="form-control" type="text" placeholder="Ubicación del certificado" name="txtCertificate" id="txtCertificate" value={fileCert?.name} size={25} readOnly={true} style={{ width:"95%" }}   />
            <input type="file" ref={inputCert} style={{ display:"none"}} accept=".cer" id="fileCerttificate" onChange={onCertFileChange} />


            <span className="input-group-btn" style={{ verticalAlign:"bottom" }}>
                <button type="button" className="btn btn-default" id="btnCertificate" name="btnCertificate" onClick={ () => btnCertOnClick() }>Buscar</button>
            </span>
          </div>
          <span id="msgErrorCert" style={{ display:"none", color:"#D0021B", fontSize:"11px", fontWeight:"initial" }} >Este campo es obligatorio.</span>
        </div>

        <div className="form-group" >
          <div className="input-group">
            <label className="control-label" htmlFor="txtPrivateKey">Clave privada (.key):</label>
            <input className="form-control" id="txtPrivateKey" name="txtPrivateKey" style={{ width:"95%" }} value={fileKey?.name} size={25} type="text" placeholder="Ubicación de la llave privada" readOnly={true}  />
            <input type="file" ref={inputKey} style={{ display:"none" }} accept=".key" id="filePrivateKey" onChange={onKeyFileChange} />
            <span className="input-group-btn" style={{verticalAlign:"bottom"}} >
              <button type="button" className="btn btn-default" name="btnPrivateKey" id="btnPrivateKey" onClick={ () => btnKeyOnClick() }>Buscar</button>
            </span>
          </div>
            <span id="msgErrorKey" style={{ display:"none", color:"#D0021B", fontSize:"11px", fontWeight:"initial" }} >Este campo es obligatorio.</span>
        </div>

        <div className="form-group" >
          <label className="control-label" htmlFor="privateKeyPassword">Contraseña de clave privada:
            <span style={{ left:"-2px" }}  className="glyphicon glyphicon-question-sign" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="Esta contraseña es la que corresponde la clave privada .key"></span>
          </label>
          <input className="form-control" id="txtKeySecret" type="password" name="txtKeySecret" placeholder="Contraseña" autoComplete="off" onChange={onTxtKeySecret} />
        </div>

        <div className="form-group" >
          <a href="https://www.sat.gob.mx/tramites/operacion/19941/valida-la-vigencia-de-tu-e.firma-(antes-firma-electronica)" target="_blank"  rel="noopener noreferrer">
            Para conocer el estado de tu efirma (antes firma electrónica) dar clic aquí
          </a>
        </div>
        <br/>
        { DrawMsg() }
        <br/>
        { DrawProgBar("EFIRMA") }
        <br/>
        <br/>
      </div>);  
  }

  const dialogFooter = () => {
    return (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cerrar mensaje" icon="pi pi-check" onClick={ () => setDisplayMsg(false)} />
      </div>);
  }    

  const onClose = () => {
    setDisplayMsg(false);
  }

  return (  
    <Fragment>
      <HeaderGobMx />     
        <div className="text-center" style={{ marginTop:"80px", position:"relative"}}>    
        <div className="container">
          <div className="row">
            <div className="col-sm-6" style={{ height:"400px", margin:"auto", verticalAlign: "middle"}}>
              <br/>
              <br/>
              <br/>
              <img src={imageBG} width="247px" height="292px" alt="Logo" />                    
              <div>
                <div>Para cualquier duda, consulta o asesoría con relación a las Instituciones de Tecnología Financiera (ITF), favor de redactar un correo electrónico a la siguiente dirección: </div>
                <br/>
                <div><strong><a href="mailto:asesoriafintech@condusef.gob.mx" target="_blank" rel="noreferrer noopener" >asesoriafintech@condusef.gob.mx</a> </strong></div>                                     
              </div>
              <br/>

              <br/>
            </div>
            <div className="col-sm-6">
              <br/>
              <div className="card">
                <form>
                  <TabView className="tabview-custom">
                    <TabPanel header="USUARIOS" >
                      <div className="bottom-buffer">
                        <br></br>
                        <h3>Utilizar Efirma (SAT)</h3>
                        <h3><strong>EL SISTEMA SOLO ACEPTA LA EFIRMA DE PERSONAS FÍSICAS </strong></h3>
                      </div>                    
                        { drawUserForm() }
                      <br/>
                      <br/>
                    </TabPanel>
                    <TabPanel header="CONDUSEF" >
                      <div className="bottom-buffer"><h3>Acceso servidor público CONDUSEF</h3></div>
                      <div className="row" style={{ display:"none"}} id="divMsgError">
                        <div className="col-sm-12">
                          <div className="alert alert-danger" id="msgError"></div>
                        </div>
                      </div>

                      <div role="form" id="IDPLogin"  className="form-horizontal" >
                        <input type="hidden" name="option" value="credential"/>
                        <br/>
                        <div className="form-group">
                          <label className="col-sm-4 control-label" htmlFor="rfc">Usuario CONDUSEF:</label>
                          <div className="col-sm-8">
                            <input className="form-control" id="txtActDirUsr" name="txtActDirUsr"  type="text"  
                              size={25} autoComplete="off" value={user} onChange={txtUserOnChange}/>
                          </div>
                        </div>
                        <br/>
                        <div className="form-group">
                          <label className="col-sm-4 control-label" htmlFor="password">Contraseña:
                            <span style={{ left:"-2px"}} className="glyphicon glyphicon-question-sign" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="Esta contraseña es la misma que usa en la CONDUSEF"></span>
                          </label>
                          <div className="col-sm-8">
                            <input className="form-control" id="txtActDirPwd" name="txtActDirPwd" placeholder="Contraseña" type="password" 
                              size={25} maxLength={32} autoComplete="off" value={userSecret} onChange={txtPwdOnChange}  />
                          </div>
                        </div>
                        <br/>
                        { DrawMsg() }
                        <br/>
                        { DrawProgBar("ACTIVE_DIRECTORY") }                      
                      </div>
                      <br/>
                    </TabPanel>                
                    <TabPanel header="INSTITUCIONES FINANCIERAS">
                      <div className="bottom-buffer">
                        <br></br>
                        <h3> Iniciar sesión con su clave del SIPRES</h3>
                      </div>
                      <div role="form" id="IDPLogin"  className="form-horizontal" >
                        <input type="hidden" name="option" value="credential"/>
                        <div className="form-group">
                          <label className="col-sm-5 control-label" htmlFor="rfc">Usuario institución financiera:</label>
                          <div className="col-sm-7">
                            <input className="form-control" id="txtInsFinUsr" name="txtInsFinUsr" placeholder="Usuario" type="text"  
                              size={25} autoComplete="off" value={user} onChange={txtUserOnChange}/>
                          </div>
                        </div>
                        <br/>
                        <div className="form-group">
                          <label className="col-sm-5 control-label" htmlFor="password">Contraseña:
                            <span style={{ left:"-2px"}} className="glyphicon glyphicon-question-sign" aria-hidden="true" data-toggle="tooltip" data-placement="right" title="Esta contraseña es la misma que su usuario de Windows"></span>
                          </label>
                          <div className="col-sm-7">
                            <input className="form-control" id="txtInsFinPwd" name="txtInsFinPwd" placeholder="Contraseña" type="password" 
                              size={25} maxLength={16} autoComplete="off" value={userSecret} onChange={txtPwdOnChange}/>
                          </div>
                        </div>                      
                        <br/>
                        { DrawMsg() }
                        <br/>
                        { DrawProgBar("SIPRES") }                      
                      </div>
                      <br/>
                    </TabPanel>                  
                  </TabView>                
                </form>
                <br />            
              </div>
            </div>
          </div>
        </div>
        </div>
        <br/>
        <br/>
      <FooterGobMx />

      <Dialog header={"Aviso"} visible={displayMsg} style={{ width: '70vw'  }}   
            onHide={() => onClose()} footer={dialogFooter()} >
          <div className="p-grid p-fluid  FieldLeft"> 
            Estimado usuario, le informamos que, por disposición oficial del <strong>18 de julio del 2024 al 31 de julio de 2024</strong>, 
            esta Comisión Nacional cerrará sus puertas y suspenderá operaciones; por lo que se dará atención a su asunto a partir del <strong> 01 de agosto de 2024</strong>, 
            fecha en que se dará continuidad a los términos que marca el Proceso de Atención a Usuarios en sus diversas etapas.
            El registro de sus datos puede realizarlo sin problema, el sistema le arrojará un número de folio con el que puede dar seguimiento a partir de la fecha señalada.
            De antemano, agradecemos su comprensión.
            <br />
            <p style={{top:"20px", position:"relative"}} > Atentamente CONDUSEF</p>
          </div>
      </Dialog>          
    </Fragment>
  );    
}
/*
<Message key="mslmsg" severity="info" style={{marginLeft: '.25em'}}
text="Se les informa que la CONDUSEF cerrará sus puertas y suspenderá operaciones dentro del periodo comprendido del 18 al 31 de julio de 2024. Por lo cual, los correos para asesorías y recepcion de quejas en materia de Fintech, dentro de dicho plazo se tendrán por recibidos a partir del 01 de agosto de 2024."  />
*/
//<Captcha siteKey="YOUR_SITE_KEY" ></Captcha>