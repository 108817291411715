import React, { createContext, useReducer, Dispatch } from 'react';
import { ViewName } from './reducers/ViewReducer';
import { viewReducer, ViewActions, ViewType } from './reducers/ViewReducer';
import { BuscarType, BuscarActions, viewBuscarReducer } from './reducers/ViewReducer';
import { UserType, UserViewReducer, UserActions } from './reducers/ViewReducer';

type InitialStateType = {
  page: ViewType;
  pageBuscar : BuscarType;
  user : UserType;
}
 
const initialState = {
  page: {view: ViewName.WELCOME, msg: "", refresh:""  },
  pageBuscar: {view: ViewName.CITA_CONSULTAR, msg: "", clave:"", validar:""  },
  user:{mainpsnid: 0, maininsid: 0, psnid:0, insid:0, users: [], session: "", type:0, cic:{}, pflid:[] }
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ViewActions | BuscarActions | UserActions>;
}>({
  state: initialState,
  dispatch:  () => null
});

const mainReducer = (
    { page, pageBuscar, user }: InitialStateType, 
    action: ViewActions | BuscarActions | UserActions) => ({ 
    page: viewReducer( page, action as ViewActions),
    pageBuscar:viewBuscarReducer ( pageBuscar, action as BuscarActions),
    user: UserViewReducer( user, action as UserActions)
  });


const AppProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider };