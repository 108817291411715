import React, { Fragment, useContext, useState, useEffect} from 'react'
import { ProgressBar } from 'primereact/progressbar';
import { AppContext } from '../../../../Context';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_MIN_LENGHT, RULE_NO_EMPTY, RULE_NUMBER_GREATER } from '../../../../form/fields/fieldRules';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { DelegacionType, FormType, EtapaType, IAccion, IActionProps, INodoOrigen, InstIdTypes, IOperacion, IPersonCiC, IPersonData, IPersonDirCIC, RUT_REDIRECT, SioOperacion, TramitesType, Types } from '../../../../reducers/Model';
import { ViewName } from '../../../../reducers/ViewReducer';
import TramitesSvc from '../../../../services/TramitesSvc';
import { JsonPost } from '../../../../ui/uses/UseFormDataETL';
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';
import { userFindActive } from '../UsersUtil';
import { HeaderApp } from '../../../main/HeaderApp';

import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';

import { validateFilesSize } from '../../../../util/fileUtil';
import '../Action.css';
import { Accion, NodoEstado } from '../../AfdUtil';


interface ICatalogos { 
	[key: string]: any;		
}

interface IData { onhide: any, data : IActionProps  }

export const Act145UsrDictamen: React.FC<IData> = ( act145Props ) => {  
	const {state, dispatch } = useContext(AppContext);	
	const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
	const [queja, setQueja] = useState<ICatalogos>({});
	const [usrPerson, setUsrPerson] = useState<IPersonData>();	
	const [initForm, setInitForm] = useState(0);

  const BtnEnvia_Click = () => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX , msg:"",refresh:""} });
  }

	const BtnRegresarRut_Click = () => {    
		RUT_REDIRECT();
	}

	const btnSeguimientoOnClick = () => {    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_REFRESH_SEG , msg:"",refresh:""} });
  }


	const DATA_PROCESS = {
		traid: { gpo:"header",	id:"traid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:TramitesType.FINTECH, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tdatid: { gpo:"header",	id:"tdatid",  type:EFieldObj.PINT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value: FormType.SOLICITUD, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },
		tramdelid: { gpo:"header",	id:"tramdelid",  type:EFieldObj.TXT, save:1,  label:"Trámite",  oper:EFieldOper.READ, value:DelegacionType.FINTECH, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },

		tramfolio: { gpo:"dictamen",	id:"tramfolio",  type:EFieldObj.TXT, save:1,  label:"No. de Folio",  oper:EFieldOper.READ, value:undefined, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramfecrec: { gpo:"dictamen",	id:"tramfecrec",  type:EFieldObj.TXT, save:1,  label:"Fecha de recepción",  oper:EFieldOper.READ, value:undefined, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.SHOW_ONLY_WITHVALUE, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" },			
		tramdoc: { gpo:'header',  id:"tramdoc",  type:EFieldObj.FILE, save:1,  label:"Trámite solicitud", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.SOLICITUD  },		
		psntipo: { gpo:'dictamen', id:"psntipo", type:EFieldObj.PINT, save:1, label:"formato", oper:EFieldOper.UPDATE, value:1, rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:""},
    
		titDatPer:{ type:EFieldObj.H3, id:"titDatPer", label:"Datos del solicitante", display:EFieldDisplay.SHOW },			
		solcurp: { gpo:'dictamen',	id:"solcurp",  type:EFieldObj.TXT, save:1,  label:"CURP",       oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY], uppercase:true, maxlength:18,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"col-md-6",classNameObj:"col-md-12", classNameGroup:"col-md-3", placeholder:"Ingresa tu CURP", info:""},								
		solrfc: { gpo:'dictamen',	id:"solrfc",  type:EFieldObj.TXT, save:1,  label:"RFC",  oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"control-label", classNameObj:"form-control", classNameGroup:"col-md-3 form-group", placeholder:"Ingresa tu rfc" },
		solcorreo: { gpo:'dictamend',	id:"solcorreo",	type:EFieldObj.TXT,  save:1, label:"Correo Electrónico", oper:EFieldOper.READ, value:"", rules:[], uppercase:true,
			varrules:{ }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"control-label",classNameObj:"form-control", classNameGroup:"col-md-6 form-group", placeholder:"Ingresa tu correo"},															

		solnombre: { gpo:'dictamen',	id:"solnombre",    type:EFieldObj.TXT, save:1,  label:"Nombre(s)",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"control-label", classNameObj:"form-control", classNameGroup:"col-md-4 form-group", placeholder:"Ingresa tu nombre" },
		solpaterno:	{ gpo:'dictamen',	id:"solpaterno",    type:EFieldObj.TXT, save:1,  label:"Primer apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"control-label", classNameObj:"form-control", classNameGroup:"col-md-4 form-group", placeholder:"Ingresa tu primer apellido" },
		solmaterno:	{ gpo:'dictamen',	id:"solmaterno",    type:EFieldObj.TXT, save:1,  label:"Segundo apellido",  oper:EFieldOper.READ, value:"", rules:[RULE_NO_EMPTY, RULE_MIN_LENGHT], uppercase:true,
			varrules:{ minvalue:2 }, error: [], display:EFieldDisplay.SHOW, classNameLabel:"control-label", classNameObj:"form-control", classNameGroup:"col-md-4 form-group", placeholder:"Ingresa tu segundo apellido" },

		titDatDom:{ type:EFieldObj.H3, id:"titDatDom", label:"Domicilio (para recibir notificaciones)", display:EFieldDisplay.SHOW },		
		solcodpos:		{ gpo:'dictamen',	id:"solcodpos",      type:EFieldObj.PINT, save:1,  label:"CP", oper:EFieldOper.READ, value:0, rules:[RULE_NUMBER_GREATER], varrules:{ minvalue:999 }, error: [], display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Ingresa tu código postal", maxlength:5 },
		solentfed:		{ gpo:'dictamen',  id:"solentfed",       type:EFieldObj.CBO, save:1,   label:"Entidad Federativa",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-5", placeholder:"Selecciona la Entidad Federativa"}, 
		solmunicipio:  { gpo:'dictamen',  id:"solmunicipio",       type:EFieldObj.CBO, save:1,   label:"Alcaldía/Municipio",  oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{},  display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-5", placeholder:"Selecciona el Municipío o Alcaldía" },						
		solcolonia:    { gpo:'dictamen',  id:"solcolonia",       type:EFieldObj.CBO, save:1,   label:"Colonia",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [],  display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Selecciona la Colonia" },              
		solcalle:   { gpo:'dictamen',   id:"solcalle",       type:EFieldObj.TXT, save:1,   label:"Calle",         oper:EFieldOper.READ, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-4", placeholder:"Avenida, bulevar, carretera, calzada, camino, calle, callejón"}, 						
		solnumext:     { gpo:'dictamen',   id:"solnumext",      type:EFieldObj.TXT, save:1,   label:"No. Exterior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW,uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número exterior"}, 						
		solnumint:     { gpo:'dictamen',   id:"solnumint",      type:EFieldObj.TXT, save:1,   label:"No. Interior", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Número interior"},
    
		titasunto:{ type:EFieldObj.H3, id:"titDatSIO", label:"Datos del asunto", display:EFieldDisplay.SHOW },		
		solfoliosio: { gpo:'dictamen',   id:"solfoliosio",      type:EFieldObj.TXT, save:1,   label:"Folio SIO", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-2", placeholder:"Folio SIO"},
		solfoliopsn: { gpo:'dictamen',   id:"solfoliopsn",      type:EFieldObj.TXT, save:1,   label:"Usuario", oper:EFieldOper.READ, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, uppercase:true,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-3", placeholder:"Usuario"},						

		institucion:	{ gpo:"fintech",  id:"institucion",  type:EFieldObj.CBO, save:1,   label:"Institución financiera",   oper:EFieldOper.READ, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-12", classNameObj:"col-md-12", classNameGroup:"col-md-12", placeholder:"Institución"}, 	

			
		titlefirma: { type:EFieldObj.H3, id:"titlefirma", label:"Favor de ingresar su Firma electrónica (eFirma SAT) para generar la solicitud y firmarla con los datos antes presentados", display:EFieldDisplay.SHOW },
		dockey:	{ gpo:'dictamen',  id:"dockey",  type:EFieldObj.FILE,  save:1,  label:"Archivo .KEY", oper:EFieldOper.UPDATE, value: "", list: "", rules:[ RULE_NO_EMPTY], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:"col-md-12", placeholder:"", accept:".key", typedoc:FormType.FILE_KEY },
		doccert: { gpo:'dictamen',  id:"doccert",  type:EFieldObj.FILE, save:1,  label:"Archivo .CER", oper:EFieldOper.UPDATE, value: "", list: "", rules:[], error: [], conditions:{}, display:EFieldDisplay.SHOW,
			classNameLabel:"col-md-5", classNameObj:"col-md-7", classNameGroup:"col-md-12", placeholder:"", accept:"application/pkix-cert", typedoc:FormType.FILE_CERT  },		
		efirma: { gpo:'dictamen',   id:"efirma",  type:EFieldObj.PWD, save:0, label:"Contraseña de la e-Firma",  oper:EFieldOper.UPDATE, value:"", rules:[ RULE_NO_EMPTY ], error: [], display:EFieldDisplay.SHOW, 
			classNameLabel:"col-md-5", classNameObj:"col-md-3", classNameGroup:"col-md-12", placeholder:"Ingrese su contraseña"},

		titlefirmaCIC: { type:EFieldObj.H3, id:"titlefirmaCIC", label:"La solicitud será firmada con su clave CIC", display:EFieldDisplay.NO_SHOW },
		docacuse: { gpo:'header',  id:"docacuse",  type:EFieldObj.FILE, save:1,  label:"Acuse", oper:EFieldOper.READ, value: "", rules:[], 
			varrules:{}, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"", classNameObj:"", classNameGroup:"", placeholder:"", accept:"application/pdf", typedoc:FormType.ACUSE  },

		sioacep: { gpo:"header",	id:"sioacep",  type:EFieldObj.PINT, save:1,  label:"Estado de la solicitud",  oper:EFieldOper.READ, value: SioOperacion.INICIAR, rules:[], 
			varrules:{ }, error: [], display:EFieldDisplay.NO_SHOW, classNameLabel:"",classNameObj:"", classNameGroup:"", placeholder:"" }
	}

	const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll, onChangeAll] = useFieldEvents(DATA_PROCESS, EventType.CHANGEALL );

	const setSolicitante = (newData : any, dataPsn:IPersonCiC, dataPsnDir:IPersonDirCIC ) => {
		newData.solcurp.value = dataPsn.psncurp;
		newData.solcurp.oper = EFieldOper.READ;
		newData.solcurp.error = [];

		newData.solrfc.value = dataPsn.psncurp.substring(0,10);		
		
		newData.solnombre.value = dataPsn.psnnombre;
		newData.solnombre.oper = EFieldOper.READ;
		newData.solnombre.error = [];

		newData.solpaterno.value = dataPsn.psnapepat;
		newData.solpaterno.error = [];

		newData.solmaterno.value = dataPsn.psnapemat;
		newData.solmaterno.oper = EFieldOper.READ;
		newData.solmaterno.error = [];

		newData.solcorreo.value = dataPsn.psncorreo;
		newData.solcorreo.oper = EFieldOper.READ;
		newData.solcorreo.error = [];

		newData.solcodpos.value = dataPsn.psncp;
		newData.solcodpos.oper = EFieldOper.READ;
		newData.solcodpos.error = [];

		newData.solentfed.list = [{ label: dataPsnDir.estado , value: dataPsnDir.id_edo }];
		newData.solentfed.value = dataPsnDir.id_edo;
		newData.solentfed.error = [];

		newData.solmunicipio.list = [{ label: dataPsnDir.municipio , value: dataPsnDir.id_mun }];
		newData.solmunicipio.value = dataPsnDir.id_mun;
		newData.solmunicipio.error = [];

		newData.solcolonia.list = [{ label: dataPsnDir.colonia , value: dataPsnDir.id_col }];
		newData.solcolonia.value = dataPsnDir.id_col;
		newData.solcolonia.error = [];

		newData.solcalle.value = dataPsn.psncalle;

		if (dataPsn.psnnumext === 'null' ){
			newData.solnumext.value = '';
		} else {
			newData.solnumext.value = dataPsn.psnnumext;
		}
		newData.solnumext.oper = EFieldOper.READ;

		if (dataPsn.psnnumint === 'null' ){
			newData.solnumint.value = '';
		} else {
			newData.solnumint.value = dataPsn.psnnumint;
		}
		newData.solnumint.oper = EFieldOper.READ;
	}

	useEffect(() => {
		if ( Object.keys(state.user.cic ).length > 0 ) {																
			TramitesSvc.TraInitDictamen( state.user.psnid, state.user.cic.folio )
				.then ((response: any) => {  			
					let newData = {...dataProcess};
															
					setSolicitante (newData,  response.data.psn, response.data.psndir);
					newData.solfoliosio.value = state.user.cic.folio;
					newData.solfoliopsn.value = response.data.dictamen[0].nombre + " " + response.data.dictamen[0].paterno + " " + response.data.dictamen[0].materno;
					//newData.solasuinst.value = response.data.dictamen[0].institucion;
		
					let objInstitucion: { label: any; value: any }[] = [];
					objInstitucion.push( { label:response.data.dictamen[0].institucion, value:response.data.dictamen[0].idins } )
		
					newData.institucion.list = objInstitucion;
					newData.institucion.value = response.data.dictamen[0].idins;										
					newData.tramfolio.value = state.user.cic.folio;			
					
					// 1: firma electrónica
					if ( state.user.cic.tipofirma !== '1'){
						newData.titlefirma.display = EFieldDisplay.NO_SHOW;
						newData.dockey.display = EFieldDisplay.NO_SHOW;
						newData.doccert.display = EFieldDisplay.NO_SHOW;
						newData.efirma.display = EFieldDisplay.NO_SHOW;
						newData.titlefirmaCIC.display = EFieldDisplay.SHOW;
					}
	
					onChangeAll(newData);
				
					if (response.data.sio.sioconsecutivo !== null  ) {
						setPageState(EPageState.STEP5,"");
	
					}else if ( response.data.opr.oprfecfin  !== null && response.data.opr.oprfecfin !== undefined ) {
						setPageState(EPageState.IN_PROCESS,"");
						return;
					}
	
				}).catch( (e:any) => {
					setPageState(EPageState.ATTEMPT,"No fue posible consultar los catálogos de dirección, favor de volver a intentar ");
				})	
	
			} else {
				window.location.reload(); 
			}
			setPageState(EPageState.STEP1,"");			
	}, [initForm]);

	const btnSaveOnClick = () => {
		// tamaño de los archivos...
		let intErrorTotal = onValidateAll();
		let intErrorSize = validateFilesSize(dataProcess, 10240);
				
		intErrorTotal += intErrorSize;
		if (intErrorTotal === 0 ){					
			setPageState(EPageState.STEP2, "");				 
			
			let dataMsg = { id:"msg",  type:EFieldObj.TXT, save:1,  label:"Instrucción",  value:"Usted ha recibido la siguiente queja :" };
			// COLCOAR CONSTANTES .... 
			let operacion : IOperacion = {  traid:TramitesType.SIO, etaid:EtapaType.DICTAMEN, oprid:0, bucket:"", oprpath:"" };
			let origen: INodoOrigen = { nodori: 0, edoori:1, psn: userFindActive( state.user.users), nodausencia:state.user.mainpsnid };
			let accion : IAccion =  { 
					accid:Accion.DICTAMEN_SOLICITUD_ENVIAR, tdatid:FormType.ASIGNAR, 	data:dataMsg, edoid:NodoEstado.DICTAMEN_SOLICITUD_REVISAR,
					optional:{ accid:Accion.REGISTRAR_TRAMITE, tdatid:FormType.SOLICITUD, data:dataProcess, edoid:NodoEstado.TRAMITE}
			};

			TramitesSvc.TraAction( JsonPost(operacion, origen, accion,  state.user.cic) )
			.then(response => {         								
				if ( response.status === 200 && response.data.folio !== undefined) {					
					setQueja(response.data);
					setPageState(EPageState.STEP3, "");
					return;
				} else {
					setPageState(EPageState.STEP1, "No fue posible registrar la solicitud, vuelva a intentarlo");			
					return ;					
				}
			})
			.catch(e => {setPageState(EPageState.STEP1, "No es posible conectarse con el componente de trámites");
				console.log(e);
				return;
			});  
		} else {
			if ( intErrorSize > 0) {				
				setPageState(EPageState.STEP1, "La suma de los archivos suman más de 20MB");
			} else {
				setPageState(EPageState.STEP1, "Favor de revisar los datos de captura");
			}			
		}		
	}

	const drawStep3 = () => {
		return (
			<div className="container " >
				<br/>
        <h3>LA SOLICITUD DE DICTAMEN SE REGISTRÓ CON ÉXITO</h3>
        <hr className="red"></hr>
        <div className="row">        
          <label className="col-sm-3 col-md-6">Fecha de registro</label>
          <label className="col-sm-8 col-md-6">{ queja.fecha }</label>
        </div>
				<br />
				<button className="btn btn-primary " id="envia" type="button" onClick={ evt => { BtnEnvia_Click() } }>Ir a seguimiento 
					<span className="glyphicon glyphicon-ok"></span>
				</button>				

				<button className="btn btn-primary " id="regresar" type="button" onClick={ evt => { BtnRegresarRut_Click() } }>Regresar al RUT
					<span className="glyphicon glyphicon-ok"></span>
				</button>				
        <br />
				<br />

				<div className="row">
          <object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.tramite  } type="application/pdf" >
            No fue posible descargar el documento. Favor de volver a intentar
          </object>          
        </div>

				<div className="row">
          <object width="100%" height="600px" data={ TramitesSvc.URL + "doc/consulta/" + queja.acuse  } type="application/pdf" >
            No fue posible descargar el documento. Favor de volver a intentar
          </object>          
        </div>   
			</div>  
		);     
	}

	const FormaContenido = () => {
		return (
			<Fragment>
				{ DrawMsg() }
				<form id="formReg" >
					<DrawFieldsGroup 
						dataFields={dataProcess}
						onItemChange={onItemChange}
						onEditorTextChange={onEditorTextChange}
						onCompleteMethod={onCompleteMethod}  
						onFieldsValidate={onFieldsValidate}
						onValidateAll={onValidateAll}
				 	/>
					<br/>
					<div className="pull-left text-muted text-vertical-align-button">*Campos obligatorios</div>
					<br/>											
					{ DrawMsg() }
					<br/>				

				</form>
			</Fragment>
		);
	}

	const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}
		
	const drawStep2 = () => {
		return (
			<div className="container " >        	
				{ FormaContenido() }
				<ProgressBar mode="indeterminate" />
				<br/>
			</div>  
		);     
	}

	const drawStep1 = () => {		
		return (
			<div className="container"  >        	
				<br />
				<h2>Solicitud de Dictamen</h2>
				{ FormaContenido() }
				<br/>
				<div className="pull-right" style={{ marginBottom:"40px" }}  >
					<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSaveOnClick() } >Enviar <span className="glyphicon glyphicon-ok"></span></button>
				</div>				
			</div>  
		);     
	}
	
	const drawAttempt = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-warning"> {pageState.msg } </div>				
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => setInitForm(initForm + 1 ) } >Volver a intentar <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}
	
	const drawEnProceso = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-success " >La solicitud de Dictamen se encuentra en revisión. Favor de dar clic para mostrar los trámites en proceso</div>
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSeguimientoOnClick() } >Trámites en proceso <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}

	const drawEnProcesoSIO = () => {		
		return (
			<div className="container">
				<br/>
				<div className="alert alert-success " >La solicitud de Dictamen que seleccionó se encuentra en trámite. Favor de dar clic para mostrar los trámites en proceso</div>
				<button className="btn btn-primary " id="Regresar" type="button" onClick={evt => btnSeguimientoOnClick() } >Trámites en proceso <span className="glyphicon glyphicon-retweet"></span></button>				
				<br/>
			</div>
		);
	}
		
	const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			case EPageState.INIT:							
				return <ProgressBar mode="indeterminate" />;
			
			case EPageState.STEP1:
				return drawStep1();

			case EPageState.STEP2:
				return drawStep2();
				
			case EPageState.STEP3:
				return drawStep3();
								
			case EPageState.STEP4:
				return drawEnProceso();			

			case EPageState.STEP5:
				return drawEnProcesoSIO();		

			case EPageState.ATTEMPT:
				return drawAttempt();
				
			default:
				return DrawMsg();
		}
	}

  return (
		<Fragment>      			
			<HeaderApp />			
			{ drawStateCtrl() }			
		</Fragment>
	);
} 