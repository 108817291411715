
import React from 'react'

// ESTILOS 
interface IData { msg : string, color:string  }

export const LocalDataErrorView: React.FC<IData> = (props) => {      
  return (
    <div style={{ width:"100%", height:"40vh", padding:"auto", textAlign:"center", color:props.color}}>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>        
      <p ><i className="pi pi-times" style={{ fontSize:'96px'}} ></i></p>
      <p> { props.msg ==="" ? "No fue posible obtener los datos solicitados" : props.msg  }</p>
    </div>
  )
}