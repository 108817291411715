import axios from "axios";

//const URL = "http://localhost:8089/";
//const URL = "https://calidadvicjuridica.condusef.gob.mx/";
const URL = "https://vicjuridica.condusef.gob.mx/";

//timeout: 25000, 
const http = axios.create({
  baseURL: URL,  
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
});

/* AFD */
const AfdBandejaEntrada = ( psnid : number,  insid : number) => {
  return http.get(`afd/consulta/nuevos/1/${psnid}/${insid}`);
}

const AfdBandejaOper = ( status: number,  psnid : number,  insid : number ) => {
  return http.get(`afd/consulta/opr/${status}/${psnid}/${insid}`);
}

const AfdBoxCount = ( psns: string, instid: number ) => {
  return http.get(`afd/consulta/count/${psns}/${instid}`);  
}

const AfdDocInicial = ( nodid: number, edoid :number, traid:number) => {
  return http.get(`afd/consulta/item/${nodid}/${edoid}/${traid}`);
}

const AfdAristaNodoAccion = ( nodid:number, accid: number) => {
  return http.get(`afd/consulta/arista/nodo/${nodid}/${accid}`);
}


const AfdAristaDictamen = ( oprid: number ) => {
  return http.get(`afd/consulta/arista/dictamen/${oprid}`);
}




const AfdSeguimiento = ( oprid: number) => {
  return http.get(`afd/consulta/seguimiento/${oprid}`);
}

const AfdFuncSeguimiento = ( oprid: number) => {
  return http.get(`afd/consulta/funcseguimiento/${oprid}`);
}

const AfdSolDictamen = ( oprid: number) => {
  return http.get(`afd/consulta/soldictamen/${oprid}`);
}

/* DOCUMENTO */
const DocumentoGet = (filename: string, bucket: string, path:string ) => {
  window.open(URL + "doc/consulta?filename="+ filename + "&bucket=" + bucket + "&path=" + path, "_blank","toolbar=yes,scrollbars=yes,resizable=yes,fullscreen=yes");              
}

/* FINANCIERO */
export const FinSectorConst =  {
  all:"all",
  fintech:"fintech"
}

const FinSector = (sector:string) => {
  return http.get(`fin/sector/${sector}`);
}

const FinInstSector = ( sector:string) => {
  return http.get(`fin/institucion/sector/${sector}`);
}

const FinServicioInst = ( inst:string) => {
  return http.get(`fin/prodserv/institucion/${inst}`);
}

const FinCausaServ = ( sector:string,  serv:string) => {
  return http.get(`fin/causa/prodserv/${sector}/${serv}`);
}

/* GEO REFERENCIA  */
const GeoColoniaCPGet = (codpos : string ) => {
  return http.get(`geo/codpos/${codpos}`);
};


/* S E G U R I D A D  */
const SegSipresPost = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/login/sipres", data, config);
};


const SegActDirPost = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/login/actdir", data, config);      
};


const SegToken = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/login/token", data, config);      
};

const SegEfirmaPost = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/login/efirma", data, config);    
};

const SegEfirmaTestPost = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/certkey/test", data, config);
};

const SegCic = ( data : FormData) => {
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }  
  return http.post("seg/login/cic", data, config);    
}


/* T R A M I T E S  */
const TraPsnPerfil = ( traid: number, pflid:number ) => {
  return http.get(`/tra/psn/perfil/${traid}/${pflid}`);
  
}

const TraAction = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }    
  return http.post("afd/action", data, config);
};


const TraFiles = (  traid:number, etaid:number, oprid:number, insid:number ) => {
  return http.get(`tra/init/files/${traid}/${etaid}/${oprid}/${insid}`);
}

const TraFilesOnly = (  traid:number, etaid:number, oprid:number, insid:number ) => {
  return http.get(`tra/init/filesonly/${traid}/${etaid}/${oprid}/${insid}`);
}

const TraFirma = (  nodid:number ) => {
  return http.get(`tra/init/trafirma/${nodid}`);
}

const TraInitDefensoria = (  psnid:number, folio:string) => {
  return http.get(`tra/init/defensoria/${psnid}/${folio}`);
}

const TraInitDictamen = ( psnid:number, folio:string) => {
  return http.get(`tra/init/dictamen/${psnid}/${folio}`);
}

const TraInitFintech = ( edoid: number, inst:number, psnid:number) => {
  return http.get(`tra/init/fintech/${edoid}/${inst}/${psnid}`);
}

const TraInitBcd = ( edoid: number, inst:number, psnid: number ) => {
  return http.get(`tra/init/bcd/${edoid}/${inst}/${psnid}`);
}

const TraSioMunicipios = ( edoid:number ) => {
  return http.get(`tra/sio/municipios/${edoid}`);
}


const TraEdoPsnPfl = ( traid:number, pflid:number, instid:number ) => {
  return http.get(`tra/edopsnpfl/${traid}/${pflid}/${instid}`);
}

const TraEdoPsnPflInst = ( traid:number, pflid:number, oprid:number ) => {
  return http.get(`tra/edopsnpflinst/${traid}/${pflid}/${oprid}`);
}

const TraDatos = ( oprid:number, nodid:number, field:string ) => {
  return http.get(`tra/datos/${oprid}/${nodid}/${field}`);
}

const TraDatosFecha = ( traid:number, etaid:string, edoid:string, tdatid:string, fecha:string ) => {
  return http.get(`tra/datosfecha/${traid}/${etaid}/${edoid}/${tdatid}/${fecha}`);
}

const TraBcdCecobanIni = ( data:FormData ) => {
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }    
  return http.post("tra/bcdcecobanini", data, config);
};

/* B C D  */
const BcdExcel = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }    
  return http.post(`tra/excel/`, data, config);
};



/*  R E P O R T E S  */
const TraReporte = ( oprid:number ) => {
  return http.get(`tra/reporte/general/${oprid}`);
}

const TraTrace = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }    
  return http.post(`tra/trace/`, data, config);
};

const TraTraceUpdateDates = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }    
  return http.post(`tra/traceupdatedates/`, data, config);
};

/* *************************************
  SECCION DE CITAS ESTA RELEGADO
************************************* */

const CitaGet = (clave :string, confirmacion :string) => {  
  return http.get(`tramites/cita?clave=${clave}&confirmacion=${confirmacion}`);
};
const CitaDelete = (clave:string) => {  
  return http.delete(`tramites/cita?clave=${clave}`);
};
const UauGet = () => {  
  return http.get(`tramites/uau`);
};

const doAfd = { 
  CitaGet,  
  CitaDelete,  
  UauGet,  
  AfdDocInicial,
  AfdBandejaEntrada,
  AfdBandejaOper,
  AfdBoxCount,
  AfdAristaNodoAccion, 
  AfdAristaDictamen,
  AfdSeguimiento,
  AfdSolDictamen,
  AfdFuncSeguimiento,
  BcdExcel,
  DocumentoGet,
  FinSector,  
  FinInstSector,  
  FinServicioInst,
  FinCausaServ,
  GeoColoniaCPGet,      
  SegActDirPost,   
  SegEfirmaPost,
  SegEfirmaTestPost,
  SegCic,
  SegSipresPost,
  SegToken,   
  TraAction,
  TraDatos,
  TraDatosFecha,
  TraBcdCecobanIni,
  TraEdoPsnPfl,
  TraEdoPsnPflInst,
  TraFiles,
  TraFilesOnly,  
  TraFirma,  
  TraInitFintech,
  TraInitDefensoria,
  TraInitDictamen,
  TraInitBcd,  
  TraPsnPerfil,
  TraReporte,
  TraSioMunicipios,
  TraTrace,
  TraTraceUpdateDates,
  URL
};

export default doAfd;

//TraUnion, 
/*
  ActividadesGet,
  CatalogosGet,
  CitaGet,
  CitaDisponibilidadGet,  
  CitaCrearPost,
  
  TramiteFintechPost,
    
const CatalogosGet = () => {
  return http.get(`tramites/catalogos`);
};

const ActividadesGet = (fecha :string , psnid:number) => {  
  return http.get(`tramites/actividades?fecha=${fecha}&psnid=${psnid}` );
};

const CitaDisponibilidadGet = (proceso:number, fecini :string, fecfin :string) => {  
  return http.get(`tramites/cita/disponibilidad?prcid=${proceso}&fecini=${fecini}&fecfin=${fecfin}`);
};

const CitaCrearPost = (data :JSON) => {  
  return http.post("tramites/cita", data );
};

const TramiteFintechPost = (data : FormData) => {  
  const config = {
    headers: {    
      'content-type': 'multipart/form-data'
    }
  }      
  return http.post("quejafintech", data, config);  
};  
*/  