import React, {useContext } from 'react';
// COMPONENTE LOCAL

import { AppContext } from '../../Context';
import { EPageState, usePageState } from '../../ui/uses/usePageState';
import { LocalDataView } from '../states/LoadDataView';
import { LocalDataErrorView } from '../states/LoadDataErrorView';
import { Types } from '../../reducers/Model';
import { ViewName } from '../../reducers/ViewReducer';

export const ViewRefreshSeg = () => {
  const { dispatch } = useContext(AppContext);
  const [pageState] = usePageState({ state: EPageState.INIT, msg:"" });
/*  
  const { state, dispatch } = useContext(AppContext);
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  OPTIMIZAR

*/

  const init = () => {       
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.MAIN_VIEWNBOX, msg:"", refresh:""} });

    return <LocalDataView msg="" color={"black"}/>
  }
        
  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			
      case EPageState.INIT:			      
				return init();
 
      case EPageState.LOAD_DATA:
				return <LocalDataView msg="" color={"black"}/>
        
			default:
				return <LocalDataErrorView msg={pageState.msg} color={"white"}/>
		}
  }

  return drawStateCtrl();
}  