import React  from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFiles } from '../../TramFiles';



// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N410UsrPrevencion: React.FC<IData> = (props410) => {

  const drawBody = () => {
    try {
      if (props410.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(props410.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = props410.init.aristas;  
        let changeableData = contenido.accion.data;

        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={props410.init.operacion.etaid} action={accion} oprid={props410.action.oprid} nodori={props410.action.nodid} setListTask={props410.setListTask} 
              edoori={props410.action.edoid }   bucket={props410.init.operacion.bucket}  oprpath={props410.init.operacion.oprpath} 
              tramite={props410.init.tramite} ></NodoResponder>            
            <NodoHeader remitente={origen.psnnombrecomp} fecha={props410.action.nodfecha} estado={props410.action.edodesc} titulo={""}  folio={props410.action.oprfolio} insid={origen.insid}></NodoHeader>    
            
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div> 
                <p>DOCUMENTOS RECIBIDOS</p>
                <TramFiles traid={ props410.init.tramite.traid} etaid={props410.init.tramite.etaid} oprid={props410.init.tramite.oprid} insid={0}   />
                <textarea rows={8} style={{ width:"100%", resize:"none", backgroundColor:"whitesmoke", borderColor:"whitesmoke", fontWeight:"bold", fontSize:"16px",  marginTop:"30px" }} readOnly={true} value={changeableData.mensaje.value } > </textarea>
                <div style={{ marginBottom:"10px" }}> </div>
              </div>            
              
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return (
    <div>
      { drawBody() }
    </div>
  )
}