import React from 'react'
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { NoDataView } from '../../../states/NoDataView';
import { NodoHeader } from '../../NodoHeader'
import { NodoResponder } from '../../NodoResponder';
import { TramFiles } from '../../TramFiles';
import { TramFinQueja } from '../../TramFinQueja';

// ESTILOS 
interface IData { action : any, init: any, setListTask:any  }

export const N402ConSIO: React.FC<IData> = (aprops402) => {

  const drawBody = () => {
    try {
      if (aprops402.action.hasOwnProperty("datcontenido") === true ) {
        
        let contenido = JSON.parse(aprops402.action.datcontenido);
        let origen = contenido.origen.psn;
        let accion = aprops402.init.aristas;  
        let changeableData = contenido.accion.data;      
        let strMsg = changeableData.mensaje.value;
        if (strMsg === '' ) {
          strMsg = "Sin instrucciones adicionales ";
        }
            
        return (
          <div style={{ marginTop:"6px"}}>
            <NodoResponder etaid={aprops402.init.operacion.etaid} action={accion} oprid={aprops402.action.oprid} nodori={aprops402.action.nodid} setListTask={aprops402.setListTask} 
              edoori={aprops402.action.edoid }  bucket={aprops402.init.operacion.bucket}  oprpath={aprops402.init.operacion.oprpath} 
              tramite={aprops402.init.tramite}></NodoResponder>
            <NodoHeader remitente={origen.psnnombrecomp} fecha={aprops402.action.nodfecha} estado={aprops402.action.edodesc} titulo={""}  folio={aprops402.action.oprfolio} insid={origen.insid}></NodoHeader>    
            <TramFiles traid={ aprops402.init.tramite.traid} etaid={aprops402.init.tramite.etaid} oprid={aprops402.init.tramite.oprid} insid={0}   />
            <div style={{  backgroundColor:"whitesmoke", width:"100%", height:"100%", padding: "10px 10px 5px 20px" }} >
              <div> 
                <p>Instrucciones adicionales:</p>
                <div style={{ marginBottom:"10px" }}> {strMsg }</div>
              </div>      

              <TramFinQueja data={aprops402.init.tramite} bucket={aprops402.action.bukdesc} ></TramFinQueja>
                    
            </div>        
          </div>
        )
      } else {
        return <NoDataView msg="No existen datos a desplegar" color="black"> </NoDataView>
      }
    } catch (error) {
      console.error(error);
      return <LocalDataErrorView msg="No fue posible mostrar esta asignación" color="black"> </LocalDataErrorView>
    }      
  }
  
  return drawBody();
}