
import React, {Fragment, useState, useRef, useContext } from 'react'
import { AppContext } from '../../Context';
import { MenuLogout } from '../menu/MenuLogout'

import { Types} from '../../reducers/Model'
import { ViewName } from '../../reducers/ViewReducer'
import { usePageState, EPageState } from '../../ui/uses/usePageState'

import {ProgressBar} from 'primereact/progressbar';
import { Captcha } from 'primereact/captcha';

interface IBusqqueda { 
  clave: { value: string, msg:string};   
  verificar: {value:string, msg:string }; 
  [key: string]: any;
}

export const Main = () => {  
  const { dispatch } = useContext(AppContext);

  const _refButtonCerrar = useRef<HTMLButtonElement >(null);    
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  const [busqueda, setBusqueda] = useState<IBusqqueda>({ 
    clave: { value:"", msg:""}, 
    verificar: {value:"", msg:"" }    
  })

  const btnBuscarOnclick = () => {    
    let objValidar = {...busqueda};
    let bolValidar = true;

    if ( objValidar.clave.value === "") {
      objValidar.clave.msg = "Debe de escribir la clave";      
      bolValidar = false;
    }

    if ( objValidar.verificar.value === "") {
      objValidar.verificar.msg = "Debe de escribir la confirmación";      
      bolValidar = false;
    }

    if (bolValidar)
      dispatch({ type: Types.PAGE_CITA_BUSCAR, page: {view: ViewName.CITA_CONSULTAR, msg:"", clave:busqueda.clave.value, verificar:busqueda.verificar.value} });
    else
    setBusqueda(objValidar);    
  }

  const btnRegistrarOnclick = ( ) => {    
    if (_refButtonCerrar.current !== null){
      _refButtonCerrar.current.click();    
    }    
    dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_NUEVO, msg:"", refresh:""} });
  }
  
  const  onChangeField = (evt: React.ChangeEvent<HTMLInputElement> ) => {
    const target = evt.target.id;
    const targetVal = evt.target.value;

    let newValue = {...busqueda};
    newValue[target].value = targetVal;    
    setBusqueda(newValue) ;
	}

  const drawStep1 = () => {
    
    return (
      <Fragment>        
        <MenuLogout menu={undefined} titulo={undefined} header={true} />
        <div className="container">      
          <h2 style={{textAlign:"center"}} >@Citas CONDUSEF</h2>
          <h3 style={{textAlign:"center"}} >SISTEMA DE CITAS/TRÁMITES DEL ÁREA JURÍDICA DE LA CONDUSEF</h3>  
        </div>
        <br/>             
			  <div id="mdlCaptcha" className="modal fade" role="dialog">
  			  <div className="modal-dialog">
    			  <div className="modal-content">
      			  <div className="modal-header">
        			  <h4 className="modal-title">Verificar que no es un robot</h4>
      			  </div>
      			  <div className="modal-body">
                <div className="col-sm-offset-3">
                  <Captcha  siteKey="6LdOsbgZAAAAAOYXLK_6tewNZkvw_RuUNHkKeiqJ" onResponse={ evt => {  btnRegistrarOnclick()}}  language="es" />
                </div>
      			  </div>
      			  <div className="modal-footer">
        			  <button ref={_refButtonCerrar} type="button" className="btn btn-primary" data-dismiss="modal">Cerrar</button>              
      			  </div>
    			  </div>
  			  </div>
			  </div>
        <div id="Controles" className="container">
          <div className="row">                
            <div className="col-xs-12 col-sm-6 col-md-6"> 
              <div className="text-left">
                <h3><label>Registra aquí tu cita o trámite</label></h3>
                <hr className="red"/>
                {
                  //  onClick={ e => btnRegistrarOnclick( )}
                  //  data-toggle="modal" data-target="#mdlCaptcha"
                }                      
                <button className="btn btn-primary" style={{width:"330px"}} onClick={ e => btnRegistrarOnclick( )}  >Registrar cita o trámite</button>
                <br/><br/><br/>
                <br/>                                                
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6"> 
              <div className="text-left">
                <h3><label>Consulta o cancela tu cita o trámite</label></h3>
                <hr className="red"/>                                   
                <div className="form-group" >
                  <label>Clave de cita*:</label>
                  <input name="clave" type="text" id="clave" className="form-control" autoComplete="off" maxLength={12}
                    placeholder="Registre su clave " onChange={ e =>  onChangeField(e) }/>
                  { busqueda.clave.msg !== "" ? <div className="alert alert-danger" style={{ marginTop:"10px"}}> {busqueda.clave.msg} </div> : null }
                </div> 
                <div className="form-group">
                  <label>Número de confirmación*:</label>
                  <input name="confirmacion" type="text" id="confirmacion" className="form-control" autoComplete="off" maxLength={12}
                    placeholder="Registre su confirmacion"  onChange={ e =>  onChangeField(e) }/>
                    { busqueda.verificar.msg !== "" ?  <div className="alert alert-danger" style={{ marginTop:"10px"}}> {busqueda.verificar.msg} </div>: null }
                </div> 
                <div className="form-group">* Campos obligatorios para consulta o cancelación</div>                                          
                  <button id="btnBuscar" className="btn btn-primary" onClick={ e => btnBuscarOnclick()}>Consultar/Cancelar cita o trámite</button>                   
                </div>
              </div>
            </div>       
          <br/>
          <br/>
          <div style={{ fontSize:"12px"}}>Ver. 2.0.0</div>
        </div>   
        <br/><br/>
      </Fragment>
    );
  }
  const drawStateCtrl = () => {		
    switch ( pageState.state ) {
      case EPageState.INIT:        							
        setPageState(EPageState.STEP1, "");
        return null;

      case EPageState.IN_PROCESS:        
        return <ProgressBar mode="indeterminate" />

			case EPageState.STEP1:        
				return drawStep1();
					      
      default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
    }
  }

  return ( drawStateCtrl() )
}


