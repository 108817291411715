import React, {Fragment, useState, useContext } from 'react'

import { AppContext } from '../../Context';
import { Types} from '../../reducers/Model'
import { ViewName} from '../../reducers/ViewReducer'

import { MenuLogout } from '../menu/MenuLogout'
import { usePageState, EPageState } from '../../ui/uses/usePageState'
import TramitesSvc from '../../services/TramitesSvc'

import {Tree} from 'primereact/tree';
import {ProgressBar} from 'primereact/progressbar';

// Como usar el registro que me llega??? con el FOR
interface IRegTramite { 
	uauid:string; uaunombre:string; uautipo:string; uautelefono:string; uaucorreo:string; uauhorario:string; uaudireccion:string; 
	traid:string; tranombre:string; tradesc:string; psnid:string; psnapepri:string; psnapeseg:string; empcorreo:string; uaucoord:string;
}

interface ITreeData {
	key: string; label: string; data: { direccion: string; horario:string, telefono:string; correo: string; coord:string}; icon:string; children:Array<ITreeData>;
}

interface IObjTreeData { 
  [key: string]: any;
}

export const Tramite = () => {
	const { dispatch } = useContext(AppContext);

  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });
	// DATOS DEL ARBOL
	const [treeAll, setTreeAll] = useState<IObjTreeData>({});
	const [treeUau, setTreeUau] = useState<any>([]);
	const [treeItem, setTreeItem] = useState();
	const [areaAct, setAreaAct]= useState<string>("");

	// TRAMITE SELECCIONADO
	const [tramite, setTramite] = useState<number>(0);

	const TransformDataUau = (uauData : any) => {
		// como asignarlo mejorar esta parte...??
		//let treeData:Array<ITreeData> = [];
		let treeData:Array<any> = [];

		uauData.forEach((item : any) => {
			if (treeData.some(e => e.key === item.uauid) === false ) {
				treeData.push( { key: item.uauid, label: item.uaunombre, 
				data: { direccion:item.uaudireccion, horario: item.uauhorario, telefono: item.uautelefono, correo:item.uaucorreo,  coord: item.uaucoord}, 
				icon: "pi pi-fw pi-inbox",children: []});
			}
		});

		let objTreeAll:IObjTreeData = {}
		uauData.forEach((item : any) => {
			if ( objTreeAll.hasOwnProperty("uau" + item.uauid ) === false) {				
				objTreeAll["uau" + item.uauid] = { key:item.uauid, label: item.uaunombre, direccion:item.uaudireccion, horario: item.uauhorario, 
					telefono: item.uautelefono, correo:item.uaucorreo,  coord: item.uaucoord, tramites:{}}; 
				objTreeAll["uau" + item.uauid].tramites["tra" + item.traid ] = {  traid:item.traid, tranombre: item.tranombre,  tradesc: item.tradesc, personas:{}};
			} else if ( 	objTreeAll["uau" + item.uauid].tramites.hasOwnProperty("tra" + item.traid) === false) {
				objTreeAll["uau" + item.uauid].tramites["tra" + item.traid ] = {  traid:item.traid, tranombre: item.tranombre,  tradesc: item.tradesc, personas:{}};
			}
		});
		// Solo hacer filtros y pasalor como arreglos.....		
		setTreeAll(objTreeAll);
		setTreeUau(treeData);
	}

	const btnNextOnClick = (value: any) => {
		/////////dispatch({ type: Types.PAGE_SELECT, page: {view: ViewTramite[tramite], msg:""} });
		//window.open("http://google.com","_self");
	}

	const btnSalirOnClick = () => {
		dispatch({ type: Types.PAGE_SELECT, page: {view: ViewName.TRAMITE_NUEVO, msg:"", refresh:""} });
	}

	const TreeUauOnChange = (evt : any) => {
		setTreeItem(evt.value);		
		setAreaAct("uau"+ evt.value);
		setTramite(0);
	}

	const radProcesoOnClick = (evt: any) => {
		setTramite(evt.currentTarget.value);
	}

	const drawServicios = () => {
		let objHtml:any = [];
/*
		<div className="p-col-12">
		<RadioButton inputId="rb3" name="city" value="Los Angeles" onChange={(e) => setCity(e.value)} checked={city === 'Los Angeles'} />
		<label htmlFor="rb3" className="p-radiobutton-label">Los Angeles</label>
		</div>
*/

		if ( treeItem !== undefined) {
			Object.keys(treeAll[areaAct].tramites).forEach(item=> { 
				objHtml.push( <label className="col xs-12 col-sm-12 col-md-12" key={"lbl"+ treeAll[areaAct].tramites[item].traid }  style={{ marginBottom:"20px"}} >
					<input type="radio" name="proceso" value={ treeAll[areaAct].tramites[item].traid } onClick={ evt => radProcesoOnClick(evt)}/> {treeAll[areaAct].tramites[item].tranombre } 							
				</label>
					 )
			});
		}		
		return objHtml;
	}

	const drawServSel = () => {
		if ( tramite !== 0 ){
	// AQUI SELECCIONAMS A LAS PERSONAS
			return ( 	<Fragment>
				<p style={{ textAlign:"justify"}}>	{treeAll[areaAct].tramites["tra" + tramite].tradesc}</p>
				<span id="lbltramite"> </span>
				<br/>
			</Fragment>);
		} else {
			return null;
		}

/*  FALTA LOSREQUISITOS::: DESPUES 
		<label>Requisitos:</label>
		<ul>
			<li>Identificación oficial vigente</li>
			<li>Copia simple del acta de defunción de la persona que presumiblemente mantenía una cuenta de depósito</li>
		</ul>
		<div className="alert alert-info">Nota: todos los documentos debes presentarlos en original.</div>
*/
	}

	const drawStep1 = () =>{
		return (
			<div className="container"> 
				<div className="row"> 
					{ pageState.msg !== ""  ? <div className="alert alert-danger">{pageState.msg }</div> : null}
					<div className="col xs-12 col-sm-10 col-md-5">									
						<div className="panel panel-default"> 
							<h4>Vicepresidencia Jurídica</h4>            
							<Tree selectionMode="single" value={treeUau}  selectionKeys={treeItem} 
								onSelectionChange={evt => TreeUauOnChange(evt)} style={{height:"420px", width:"100%"}}/>
						</div>
						<div className="panel panel-default"> 
							<div className="panel-body">													
								<label>Área:</label>										
								<p><span id="lblModulo"> { treeAll[areaAct] !== undefined ? treeAll[areaAct].label : null}</span></p>
								<label>Dirección:</label>
								<p><span id="lblDireccion">{  treeAll[areaAct] !== undefined ?  treeAll[areaAct].direccion : null}</span></p>
								<label>Horario de atención:</label>
								<p><span id="lblHorario">{  treeAll[areaAct] !== undefined ? treeAll[areaAct].horario : null}</span></p>												 
								<label>Teléfono:</label>
								<p><span id="lblTelefono">{  treeAll[areaAct] !== undefined ? treeAll[areaAct].telefono : null}</span></p>
{
	/*
										<div className="text-center">
										<a id="HyperLinkMapa" href={"http://maps.google.com/maps?q=" + uauDatos.coord } target="_blank">Mostrar mapa</a>
								</div>
*/								
}										
									</div>
							</div>
						</div>                    
					<div className="col xs-12 col-sm-10 col-md-7"> 
							<div className="panel panel-default">
									<div className="panel-body">
										<h3>Servicios disponibles en el área</h3>
										<hr className="red"/>         
										{drawServicios() }
									</div>

									<div className="panel-body">
										<label className="col xs-12 col-sm-12 col-md-12">Servicio seleccionado:</label>
										<br></br>
										<hr className="row red"/>																				
										{ drawServSel() }
										<br/>
										{ tramite !== 0 ? 
											<button className="btn btn-primary pull-right" style={{ width:"130px", marginLeft:"10px"}} onClick={ (evt) => btnNextOnClick("Avanzar") } >Siguiente</button> 
											: null 
										}														
										<button className="btn btn-danger pull-right" style={{ width:"130px",  marginLeft:"10px"}} onClick={ evt => btnSalirOnClick() }>Salir</button>										
								</div>
						</div>                        
				</div>
				</div>
			</div> 
		);
	}

	const drawStateCtrl = () => {		
    switch ( pageState.state ) {

			case EPageState.INIT:        							
			TramitesSvc.UauGet()
					.then( response=> {
						TransformDataUau(response.data);
						setPageState(EPageState.STEP1, "");
					})
					.catch( (e : any) => {
						setPageState(EPageState.STEP1, "Existe un error de comunicación en la aplicación, favor de volver a intentar");
						console.log(e);
					});  
					setPageState(EPageState.IN_PROCESS, "");
        return null;

      case EPageState.IN_PROCESS:        
        return <ProgressBar mode="indeterminate" />

			case EPageState.STEP1:        
				return drawStep1();				

      case EPageState.STEP2:
        //return drawStep2();
				return null;

      case EPageState.STEP3:
        //return drawStep3();
				return null;
			
      default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
    }
	}

  return (
		<Fragment>      
			<MenuLogout menu="Registrar cita"  titulo="Registrar cita nuevo trámite" header={true} />
				{ drawStateCtrl() }
		</Fragment>
	);
}



/*

			<div id="mdlAviso" className="modal fade" role="dialog">
  			<div className="modal-dialog">
    			<div className="modal-content">
      			<div className="modal-header">
        			<h4 className="modal-title">Confirmación de la cita</h4>
      			</div>
      			<div className="modal-body">
        			<p>Por favor confirme que cumple con las siguientes. Condiciones al momento de presentarse a su cita:</p>
							<p>1. Acudir a la oficina de la CONDUSEF que eligió, en la fecha y horarios seleccionados, toda vez que, si no asiste a 2 citas en un período de 15 días no pordrá registrar una nueva cita por 5 días naturales a partir de la segunda inasistencia</p>
							<p>Sus datos personales son incorporados y protegidos en los sistemas de la CONDUSEF, de conformidad con la Ley General de Protección de Datos Personales en Posesión de Sujetos Obligados, 
								los Lineamientos de Protección de Datos Personales y las diversas disposiciones legales sobre la confindencialidad y protección de datos, a fin de ejercer las facultades conferidas esta autoridad.</p>
							<p> Nuevamente ponemos a su disposción para su lectura el <a href="../privacidad.pdf" target="_blank"> aviso de privacidad  </a> </p>										
      			</div>
      			<div className="modal-footer">
        			<button type="button" className="btn btn-default" data-dismiss="modal">No acepto</button>
        			<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={ (evt) => btnNextOnClick(STATE_PAGE.STEP2) } >Acepto</button>
      			</div>
    			</div>
  			</div>
			</div>
*/