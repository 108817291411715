import { useState} from 'react';

import { EFieldDisplay, EFieldOper, FORM_JSON_INIT } from './field';
import { EXEC_CONDITIONS} from './fieldConditions';

export const EventType = {
  "TABLE": 1,   
  "CHANGEALL": 2, 
  "NOCHANGEALL": 3
}

export const useFieldEvents = (init, tipo) => {
  const [dataProcess, setDataProcess] = useState(init);

  const onSelectedDataTableRow = (objName, objOper, value) =>{    
    let arrayDataBody = [...dataProcess[objName].body];

    arrayDataBody.forEach(item => {
      item.value = value[item.id];
    });
  }

  const onChangeDataTable = (p_objName, p_objOper, p_value) =>{    
    let objField = {...dataProcess[p_objName]};

    // LIMPIAR LOS ERROES DE LA TABAL
    objField.error = [];
    if (p_objOper === EFieldOper.INSERT) {
      //agrego el nuevo valor y limpíar campos
      objField.value.push(p_value)
      objField.body = FORM_JSON_INIT(objField.body);

  } else if (p_objOper === EFieldOper.UPDATE) {                
      const idSearch = p_value.rowidx;
      const idxFound = objField.value.findIndex( item => item.rowidx === idSearch );
      let arrayitems = [...objField.value];
      arrayitems[idxFound] = p_value;
      objField.value = arrayitems;

    } else if (p_objOper === EFieldOper.DELETE) {    
      const idSearch = p_value.rowidx;
      let arrayitems =[...objField.value];
      arrayitems = arrayitems.filter(item => item.rowidx !== idSearch )  
      objField.value = arrayitems;
    }
    setDataProcess({...dataProcess, [p_objName]: objField } );
  }

  const onItemChange = evt => {    
    let target;
    let targetVal;

    if ( evt.target.id ==='' && evt.target.name ==='' ) {
      return false;
    } else if ( evt.target.id ==='') {
      target= evt.target.name;
    } else {
      target= evt.target.id;
    }

    let newData = {...dataProcess}

    if ( dataProcess[target].oper === EFieldOper.READ ) {
      return false;
    }
    
    //SI ES DE TIPO FILE TIENE OTRO PROCESO
    if ( evt.hasOwnProperty('files')) {
      
      newData[target].value = {...evt.files};
      newData[target].error = [];
      
      if (newData[target].conditions !== undefined){
        newData = EXEC_CONDITIONS(newData, target);
      }  
      
      setDataProcess(newData);            
      return evt;

    } else if (evt.target.type === "checkbox" ||  evt.target.type === "radio"){
      targetVal = evt.target.checked ? true: false;
      newData[target].value = targetVal;
      newData[target].error = [];
      setDataProcess(newData);
      return targetVal;

    } else {      
      targetVal = ( evt.target.type === "textarea" )  ? targetVal  = evt.target.value.replace("'", " ") : evt.target.value;   
      
      //PARA CAMPOS TXT..
      if ( newData[target].hasOwnProperty("uppercase") === true ){
        targetVal = targetVal.toUpperCase();
      }      

      // EJECUTAMOS TODAS LAS CONDICIONES EXISTENTES
      newData[target].value = targetVal;
      newData[target].error = [];
  
      if (newData[target].conditions !== undefined){
        newData = EXEC_CONDITIONS(newData, target);
      }
  
      setDataProcess(newData);
      return evt.target;
    }
  }
  
  const onEditorTextChange = (p_objName, p_htmlValue, p_textValue) => {
    let newText  = p_textValue.replace("'", " ");
    setDataProcess({...dataProcess, [p_objName]: { ...dataProcess[p_objName], value: p_htmlValue, textvalue:newText,  error:[]}});  
  }

  const onFieldsValidate = objFields => {
    let localDataProcess = {...dataProcess};

    Object.keys(objFields).forEach( key => {          
      localDataProcess[key] = objFields[key];
    });      
    setDataProcess(localDataProcess);
  }

  const onChangeAll = (data) => {
    setDataProcess(data);
  }
  /* *************************
    AUTO COMPLETE FOR FIELD
  ************************** */
  const onCompleteMethod = (target, results) =>{
    setDataProcess({...dataProcess, [target]: { ...dataProcess[target], listfilter: results}});  
  }
  
  const OnValidateAll = () => {
    let objDataFields = {...dataProcess};
    let ErrorCount = 0;
    
    Object.keys(dataProcess).forEach( item => {          
      if ( dataProcess[item].hasOwnProperty('error') 
      && ( dataProcess[item].display === EFieldDisplay.SHOW
        || dataProcess[item].display === EFieldDisplay.SHOW_ONLY_WITHVALUE) ) {   
        dataProcess[item].error = [];
        dataProcess[item].rules.forEach( validate => {
          let result = validate(dataProcess[item]);
          if (result && result !==''){
            ErrorCount++;
            objDataFields[item].error.push(result);
          }
        });
      }
    });
    
    onChangeAll(objDataFields);
    
    return ErrorCount;
  }

  if (tipo === EventType.TABLE)
    return [dataProcess,  onSelectedDataTableRow, onEditorTextChange, onChangeDataTable, onItemChange, onFieldsValidate, onCompleteMethod, OnValidateAll];
  else if (tipo === EventType.CHANGEALL)
    return [dataProcess,  onEditorTextChange, onItemChange, onFieldsValidate, onCompleteMethod, OnValidateAll, onChangeAll];
  else
    return [dataProcess,  onEditorTextChange, onItemChange, onFieldsValidate, onCompleteMethod, OnValidateAll];  

};