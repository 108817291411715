import React, { useContext } from 'react'

// ESTADO DE LA PAGINA 
import { EPageState, usePageState } from '../../../../ui/uses/usePageState';

// PRIME
import {ProgressBar} from 'primereact/progressbar';

// ESTILOS 
import '../Action.css';
import { FormType, IActionProps, IPersonData} from '../../../../reducers/Model';
import { LocalDataView } from '../../../states/LoadDataView';
import { LocalDataErrorView } from '../../../states/LoadDataErrorView';
import { Button } from 'primereact/button';
import { EFieldDisplay, EFieldObj, EFieldOper } from '../../../../form/fields/field';
import { RULE_NO_EMPTY } from '../../../../form/fields/fieldRules';
import { EventType, useFieldEvents } from '../../../../form/fields/useFieldEvents';
import { DrawFieldsGroup } from '../../../../form/fieldsprime/DrawFieldsGroup';
import { AppContext } from '../../../../Context';

import {  tramiteAction } from '../ActUtil';

interface IData { onhide: any, data : IActionProps  }

export const Act126ConPryRecEfirma : React.FC<IData> = ( act126Props ) => {  
  const { state, dispatch } = useContext(AppContext);	
  const [pageState, setPageState] = usePageState({ state: EPageState.STEP0, msg:"" });
  
  const DATA_PROCESS = {				
    anexo:	{ gpo:'desechar',   id:"anexo", type:EFieldObj.FILE, save:1, label:"Agregue el proyecto para E.FIRMA del Director(a) para desechar o prevenir al usuario", oper:EFieldOper.UPDATE, value:"", rules:[RULE_NO_EMPTY], error: [], 
      display:EFieldDisplay.SHOW, classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"Selecione un archivo", accept:"application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document", typedoc:FormType.PROYECTO },
        
    mensaje:	{ gpo:'desechar',   id:"mensaje", type:EFieldObj.AREA,  save:1, label:"mensaje", oper:EFieldOper.UPDATE, value:"", rules:[], error: [], display:EFieldDisplay.SHOW, style:{resize: "none"},
      classNameLabel:"col-md-5", classNameObj:"col-md-7",  classNameGroup:"col-md-12", placeholder:"", varrules:{} }
  }
  const [dataProcess,  onEditorTextChange,  onItemChange, onFieldsValidate, onCompleteMethod, onValidateAll] = useFieldEvents(DATA_PROCESS, EventType.NOCHANGEALL )

  const btnAceptarClick = () => {
		let intErrorTotal = onValidateAll();				
		if (intErrorTotal > 0 ) {
			return;
    }
    
    setPageState(EPageState.STEP1, "");          
    tramiteAction(act126Props,  FormType.PROYECTO, dataProcess, state, dispatch,  setPageState, {});  
  }
    
  const DrawMsg = () => {
    if (pageState.msg.length > 0!) {
      return (
        <div>
          <div className="alert alert-danger " >{pageState.msg}</div>
        </div>
      )
    }
	}

  const FormaContenido = () => {
		return (
      <>
        <DrawFieldsGroup 
          dataFields={dataProcess}
          onItemChange={onItemChange}
          onEditorTextChange={onEditorTextChange}
          onCompleteMethod={onCompleteMethod}  
          onFieldsValidate={onFieldsValidate}
          onValidateAll={onValidateAll}
        />           
        <br />
        { DrawMsg() }
        <br />
      </>        
		);
	}

  const drawStep1= () => {
    return (
      <div >
        { FormaContenido() }
        <ProgressBar mode="indeterminate" />
      </div>
      )    
  }

  const drawStep0 = () => {
    return (
      <div >
        { FormaContenido() }
        <div className="col-md-12" style={{ marginTop:"30px", textAlign:"right"}}  >
            <Button label="Cancelar" icon="pi pi-times" onClick={() => act126Props.onhide() } className="p-button-text" />
            <Button label="Aceptar" icon="pi pi-check" onClick={() => btnAceptarClick() } autoFocus />
        </div>
      </div>
      )
  }

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
		
      case EPageState.INIT:			      
				return <ProgressBar mode="indeterminate" />;
 
      case EPageState.LOAD_DATA:
        return <LocalDataView msg="" color={"black"} />;
        
      case EPageState.ERROR:
        return <LocalDataErrorView msg={pageState.msg} color={"black"} />

			case EPageState.STEP0:
				return drawStep0();

      case EPageState.STEP1:
          return drawStep1();

			default:
				return <div className="alert alert-warning"> { pageState.msg}</div> 
		}
  }

  return <React.Fragment> { drawStateCtrl()} </React.Fragment>
}