import React, { Fragment} from 'react'

import './Procesos.css';
import { HeaderApp }from './HeaderApp';

//import { Calendar } from '@fullcalendar/core';

export const ViewCalendar: React.FC = () => {
  

  document.body.style.backgroundColor="white";
  
      
  const drawBody = () => {
    return DrawCalendar();
  }

  const DrawCalendar = () =>{
    return (
      <div className="col-sm-12">
				  
      </div>
    )
  }


  return (
		<Fragment> 
      <HeaderApp />
      {  drawBody() }
		</Fragment>
	);
}