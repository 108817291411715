export enum EFieldOper  {
  READ = 0,
  INSERT=  1,
  UPDATE=  2,
  DELETE=  3
};

export enum EFieldObj  {
  AREA = 'area',
  AUTO = 'auto',  
  CBO = 'cbo',
  CHKB = 'chkb',
  DATE = 'date',
  EDIT = 'edit',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  INUM = 'inum',
  FILE = 'file',
  LABEL = "label",
  MSJ = 'div',
  MSK = 'msk',
  PINT = 'pint',  
  PWD = 'pwd',
  RADIO = 'radio',
  SBUTTON = 'sbutton',
  TABLE = 'table',
  TIT = 'titulo',
  TXT = 'txt',
  URL = 'url'
};


export enum EFieldDisplay  {
  NO_SHOW = 1,
  SHOW= 2,
  SHOW_ONLY_WITHVALUE = 3
}

export enum DisplayProp  {
  GROUP = "dsGpo",
  FIELD = "dsField",
  ASSING ="dsAssing"
}


export type TTituloRuntime = {
	type: string; label: string; 
}

export type EFieldTypeRuntime = {
	gpo:string, id: string; type: string; label: string; oper: string; value: any; rules: any; varrules:any; error:any; display:boolean; 
	className:string; classNameGroup:string; placeholder:string
}

interface IOpt { 
  [key: string]: any;		
}

export const FORM_JSON_CREATE = ( data :IOpt ) => {
  var jsonData :IOpt = { };
  data.forEach( (column:IOpt) => {      
    const columnName = column.id;      
    // buscar los que son nulos y los demas en blnaco......
    if (column.type === EFieldObj.PINT ){
      jsonData[columnName] = 0;
    } else if (column.type === EFieldObj.TXT ){
      jsonData[columnName] = "";
    } else if (column.type === EFieldObj.DATE ){
      jsonData[columnName] = "2020-01-01";
    } else if (column.type === EFieldObj.CBO ){
      jsonData[columnName] = [];
    } else {
      jsonData[columnName] = null;
    }             
  });
  jsonData["id"] = Date.now();    
  return jsonData;
}


export const FORM_JSON_INIT = ( data :IOpt) => {
  // Inicialziar con algun valor
  data.forEach( (field :IOpt)=> {      
    if (field.type === EFieldObj.PINT ){
      field.value = 0;
    } else if (field.type === EFieldObj.TXT ){
      field.value = "";
    } else if (field.type === EFieldObj.DATE ){
      field.value = "2020-01-01";
    } else if (field.type === EFieldObj.CBO ){
      field.value = [];
    } else {
      field.value = null;
    }             
  });
  return data;
}

export const FORM_CBO_DATA = (dataFieldsJson : IOpt )=>{  
  let localCat :IOpt ={};
  
  Object.keys(dataFieldsJson).forEach( key => {
    // ESTO ES UN OBJETO 
    if (dataFieldsJson[key].type === EFieldObj.CBO || 
      dataFieldsJson[key].type === EFieldObj.AUTO ) {

      let idx = dataFieldsJson[key].list;
      if ( idx !== ''){
        if (!( idx in localCat)) {
          localCat[idx] = dataFieldsJson[key].list 
        }
      } 
      //value
      idx = dataFieldsJson[key].link;
      if ( idx !== '') {
        if (dataFieldsJson[key].value.length > 0){
          if (!( dataFieldsJson[key].value in localCat)) {
            localCat[dataFieldsJson[key].value] = dataFieldsJson[key].value 
          }
        }
      }
    } else if (dataFieldsJson[key].type === EFieldObj.TABLE ) {
      // ES SENCILLO PORQUE ES UN ARRAY
      dataFieldsJson[key].body.forEach( (item :IOpt)=> {
        if (item.type === EFieldObj.CBO || 
          item.type === EFieldObj.AUTO ) {
  
          let idx = item.list;
          if ( idx !== ''){
            if (!( idx in localCat)) {
              localCat[idx] = item.list 
            }
          } 
          //value
          idx = item.link;
          if ( idx !== '') {
            if (item.value.length > 0){
              if (!( item.value in localCat)) {
                localCat[item.value] = item.value 
              }
            }
          }
        }
      });
    }
  });

  return Object.keys(localCat);
}