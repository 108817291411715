import React, {useContext, useState, Fragment } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Splitter, SplitterPanel } from 'primereact/splitter';

import {HeaderApp} from '../../main/HeaderApp';
import {TaskNew} from '../task/TaskNew';

import TramitesSvc from '../../../services/TramitesSvc';
import { AppContext } from '../../../Context';

import { EPageState, usePageState } from '../../../ui/uses/usePageState';
import './Seguimiento.css'

// COMPONENTE LOCAL
import { ListTaskNew } from '../task/ListTaskNew';
import { LocalDataView } from '../../states/LoadDataView';
import { LocalDataErrorView } from '../../states/LoadDataErrorView';

import { MsgBox } from './MsgBox';
import { TreeBoxView } from './TreeBoxView';
import { ListTaskInProcess } from '../task/ListTaskInProcess';
import { TaskInProcess } from '../task/TaskInProcess';
import { stringToDate } from '../../../util/dateUtil';
import { IItemInPrc, Task } from '../../../reducers/Model';


export const MainBoxView = () => {
  const { state } = useContext(AppContext);

  document.body.style.backgroundColor="#454545";
  const [itemSelect, setItemSelect] = useState(0);
  const [listTask, setListTask] = useState([]);
  const [docData, setDocData] = useState({});
  const [itemInPrc, setItemInPrc]= useState<IItemInPrc>();
  const [userBox, setUserBox] = useState({ usrid: state.user.psnid, boxid: 0, selectedKey:"n" + state.user.psnid});
  const [pageState, setPageState] = usePageState({ state: EPageState.INIT, msg:"" });

  const [treeStatus, setTreeStatus] = useState({});

  const setItemInbox = ( nodid:number,  edoid:number, traid:number) => {
    setItemSelect(-1);
    TramitesSvc.AfdDocInicial(nodid, edoid, traid )
      .then ((response) => {                              
        if ( response.status !== 200 ) {          
          setPageState(EPageState.ERROR, "¡No fue posible obtener datos de esta actividad, vuelva a intentar!");  
        } else if ( response.data.hasOwnProperty("tramite") === false) {          
          setPageState(EPageState.ERROR, "¡No se encontraron datos para esta actividad, vuelva a intentar!");  
        }        
        setDocData(response.data);        
        setItemSelect(nodid);
        setPageState(EPageState.STEP0, "");                 
        return;        
      })
      .catch( (e) => {                
        setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
      });
    return false;
  }

  const setItemInProcess = ( oprid:number) => {
    setItemSelect(-1);
    TramitesSvc.AfdSeguimiento(oprid)
      .then ((response) => {                              
        if ( response.status !== 200 ) {          
          setPageState(EPageState.ERROR, "¡No fue posible obtener datos de esta actividad, vuelva a intentar!");  
        }
        
        let auxtasks  = [];
        let idx=0;
        let limite = response.data.nodos.length;
      
        for (idx = 0; idx< limite; idx++ ){
          let ndata  = response.data.nodos[idx];

          let datIni : Date = stringToDate(ndata.nodfecha);
          let datFin : Date;

          if (ndata.nodfecatendido === null) {
            datFin =  new Date(Date.now());
          } else {
            datFin = stringToDate(ndata.nodfecatendido);
          }

          
          if ( idx === 0){                    
            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: []
            }
            auxtasks.push(task);

          } else {
            let dependencia : string[] = [];

            response.data.flujo.forEach((item : any) => {
              if (item.noddst === ndata.nodid ){
                dependencia.push("N"+ item.nodori);                
              }              
            });

            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: dependencia!
            }
            auxtasks.push(task);      
          }
        }
        setItemSelect(oprid);
        setItemInPrc({ tasks:auxtasks, nodos: response.data.nodos});
        setPageState(EPageState.STEP0, "");              
        return;
      })
      .catch( (e) => {                
        setPageState(EPageState.ERROR, "¡No fue posible conectarse con el servidor, vuelva a intentar!");  
      });
                
    return false;
  }

  const drawList = (itemSelect:any , setItemInbox:any,  listTask:any, setItemInProcess:any)=> {
    let addHtml = []
    addHtml.push(<br key="TitBegin"  />)
    if ( userBox.boxid === 0) {
      addHtml.push(<div key="Tit" className="col-xs-12" > ACTIVIDADES PENDIENTES  </div>);
      addHtml.push(<ListTaskNew key="lstitem"  itemSelect={itemSelect}  setItemInbox={setItemInbox}  listTask={listTask}  ></ListTaskNew>);

    } else if ( userBox.boxid === 1){
      addHtml.push(<div key="Tit" className="col-xs-12" > TRÁMITES EN PROCESO  </div>);  
      addHtml.push(<ListTaskInProcess key="lsttaskprc" itemSelect={itemSelect}  setItemInProcess={setItemInProcess}  listTask={listTask} ></ListTaskInProcess>);

    } else if ( userBox.boxid === 2) {
      addHtml.push(<div key="Tit" className="col-xs-12" > HISTÓRICO </div>);
      addHtml.push(<ListTaskInProcess itemSelect={itemSelect}  setItemInProcess={setItemInProcess}  listTask={listTask} ></ListTaskInProcess>);

    } else {
      addHtml.push(<div key="Tit" className="col-xs-12" > ERROR EN LA CONSULTA </div>);
    }      
    addHtml.push(<br key="TitEnd"  />);
    return addHtml;
  }
  
  const drawItem = (itemData:any) => {    
    if ( itemSelect === -1 ) {
      return <LocalDataView msg="" color={"black"}/>

    } else if ( itemSelect > 0 ) {      
      if ( userBox.boxid === 0) {

        if ( Object.keys(docData).length > 0 ) {
          if (itemSelect > 0 && listTask !== undefined && listTask.length > 0) {
            itemData = listTask.filter( (item:any) => item.nodid === itemSelect)[0];
          } 

          return <TaskNew data={itemData!} setListTask={setListTask} init={EPageState.INIT} document={docData}/>
        } else {
          return null;
        }

      } else {
        if (itemInPrc !== undefined){
          if (itemSelect > 0 && listTask !== undefined && listTask.length > 0) {
            itemData = listTask.filter( (item:any) => item.oprid === itemSelect)[0];
          } 
        return <TaskInProcess data={itemData!} setListTask={setListTask} init={EPageState.INIT} tasks={itemInPrc?.tasks} nodos={itemInPrc?.nodos} />

        } else  {
          return null;
        }        
      }      
    } else {
      return <MsgBox />
    }
  }

  const drawBox = () => {
    return (
      <Splitter className="p-d-flex p-ai-center p-jc-center"  style={{height:"87vh", marginLeft:"10px", marginRight:"10px" }} gutterSize={10}>
        <SplitterPanel size={20} minSize={15} style={{ backgroundColor:"whitesmoke" }} >
          <div style={{ height:"80vh", overflowY:"scroll"}}>
            <TreeBoxView treeStatus={treeStatus}  setUserBox={setUserBox} userBox={userBox} setPageState={setPageState} setListTask={setListTask} setItemSelect={setItemSelect}/>
          </div>
        </SplitterPanel>
        <SplitterPanel size={80} minSize={70}  > 
          <Splitter layout="vertical" gutterSize={10}> 
              <SplitterPanel size={85} style={{height:"88vh"}}>
                  <Splitter gutterSize={10}  >
                      <SplitterPanel  size={30} minSize={20}  >
                        <div style={{ height:"88vh", overflowY:"scroll"}}>
                          <div className="p-inputgroup" >
                            <Button icon="pi pi-search" style={{ backgroundColor:"#D4C19C", border:"1px solid #B38E5D", width:"50px"}} />
                            <InputText placeholder="buscar"  style={{ position:"relative", width:"100%", backgroundColor:"white"}} />
                          </div>
                          { drawList(itemSelect, setItemInbox, listTask, setItemInProcess ) }                          
                        </div>
                      </SplitterPanel>
                      <SplitterPanel  size={70} minSize={50} style={{ height:"89vh", overflowY:"scroll", backgroundColor:"whitesmoke"}}>
                        { drawItem(docData)  }
                      </SplitterPanel>
                  </Splitter>
              </SplitterPanel>
            </Splitter>
        </SplitterPanel>
    </Splitter>
    );
  }
  

  const drawStateCtrl = () => {		
		switch ( pageState.state ) {
			
      case EPageState.INIT:			      
				////return getData();
        return setPageState( EPageState.STEP0,  "");

      case EPageState.LOAD_DATA:
				return <LocalDataView msg="" color={"white"}/>;
        
      case EPageState.ERROR:
				return <LocalDataErrorView msg={pageState.msg} color={"white"}/>
 
			case EPageState.STEP0:
				return drawBox();

			default:
				return <LocalDataErrorView msg={pageState.msg} color={"white"}/>
		}
  }

  return (
		<Fragment>  
      <HeaderApp />      
      { drawStateCtrl() }      
		</Fragment>
	);
}  