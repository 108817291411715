
import "./Tracing.css"
import { Button } from 'primereact/button';
import { IItemInPrc, IItemHead, Task, TraceConciliarType } from "../../reducers/Model";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { JSONToCSVConvertorTitles } from "../../util/exportUtil";
import { Dialog } from "primereact/dialog";
import { Fragment, useState } from "react";
import { TaskInProcess } from "../afd/task/TaskInProcess";
import { EPageState } from "../../ui/uses/usePageState";
import TramitesSvc from "../../services/TramitesSvc";
import { stringToDate } from "../../util/dateUtil";
import { Calendar } from "primereact/calendar";

interface IData { data : TraceConciliarType[], ptrSearch : any  }

export const TraceConciliar: React.FC<IData> = ( gestion ) => {
  const [display, setDisplay] = useState(false);
  const [itemSelect, setItemSelect] = useState(0);
  const [itemInPrc, setItemInPrc]= useState<IItemInPrc>();
  const [itemData, setItemData]= useState<IItemHead>();
  const [listTask, setListTask] = useState([]);
  const [item, setItem] = useState<TraceConciliarType>();
  const [itemDialog, setItemDialog] = useState(false);
  const [dateBegin, setDateBegin] = useState<Date[]>();
  const [dateEnd, setDateEnd] = useState<Date[]>();
  const [msgError, setMsgError] = useState<String>("");


  const editItem = ( item: TraceConciliarType ) => {
    if (item.fecrepini !== null &&  item.fecrepini !== ""){
      setDateOnClick(setDateBegin, new Date(item.fecrepini.substring(6) + "/" + item.fecrepini.substring(3,5)+ "/" +  item.fecrepini.substring(0,2)));
    }
              
    if (item.fecrepfin !== null &&  item.fecrepfin !== "") {
      setDateOnClick(setDateEnd, new Date(item.fecrepfin.substring(6) + "/" + item.fecrepfin.substring(3,5)+ "/" +  item.fecrepfin.substring(0,2)) );
    }
                      
    setItem({ ...item });
    setItemDialog(true);
  };
  
  const hideDialog = () => {
    setItemDialog(false);
  };

  const btnUpdateGestion = () => {
    let formData = new FormData();    
    formData.append("TraceSearch", JSON.stringify({ oprid: itemData?.oprid, phase: 2, datebegin:dateBegin , dateend: dateEnd}) );
    
		TramitesSvc.TraTraceUpdateDates(formData )
		  .then ((response: any) => {  							
        setItemDialog(false);
				setMsgError("");
        gestion.ptrSearch(-1);
		  }).catch( (e:any) => {
  		  setMsgError("No fue posible realizar la operación");
		  })	
  };

  
  const gestionDialogFooter = () => {
    return (
      <Fragment>
          <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} />        
          <Button label="Actualizar" icon="pi pi-check" onClick={btnUpdateGestion} />
          <br/>
          <br/>
          { (msgError.length) >0 ? <div className="alert alert-danger " >{msgError}</div>: null}
      </Fragment>
    );
  }

  function setDateOnClick(setFunc:any, value:any) {
    setFunc(value);
  }



  const onHide = () => {
    // TODA UNA BUSQUEDA...
    setDisplay(false);
  }

  function btnExportarOnClick() {    
    const titles = ["FOLIO", "NOMBRE DEL USUARIO", "INSTITUCIÓN", 
      "FECHA DE INGRESO", "FECHA PREVENCIÓN",  "FECHA NO ADMITIDA",
      "FECHA CITATORIO 1", "FECHA MAX CIT 1", "DIAS LAB CIT 1", "CIT 1 STATUS", 
      "FECHA AUDIENCIA 1", "FECHA MAX AUD 1", "DIAS LAB AUD 1", "AUD 1 STATUS", 
      "FECHA INASISTENCIA", "FECHA JUSTIFICIÓN","FECHA CITATORIO 2", 
      "FECHA AUDIENCIA 2", "FECHA MAX AUD 2", "DIAS LAB AUD 2", "AUD 2 STATUS", "FECHA DESAHOGO AUDIENCIA", "FECHA SENTIDO AUDIENCIA", "FECHA  SOL DE DT",
      "FECHA INICIAL REPORTADA", "FECHA FINAL REPORTADA"
    ];
                                    
    JSONToCSVConvertorTitles(gestion.data, "ConciliarFintech", true, titles);
  }

  const onRowSelect = (event : any) =>     
  {
    let auxItemData: IItemHead  = {
      bukdesc: 'fintech',
      oprfecrecepcion: event.data.fecrec,
      oprfolio : event.data.folio,
      oprid : event.data.oprid,
      pfldesc : 'FINTECH ',
      tradesc : 'Fintech',
      traicono : 'glyphicon glyphicon-phone',
    }
    setItemData(auxItemData);
    setItemInProcess(event.data.oprid);
  };

  const setItemInProcess = ( oprid:number) => {

    TramitesSvc.AfdSeguimiento(oprid)
      .then ((response) => {                              
        if ( response.status !== 200 ) {          
          // COMO MOSTRAR EL ERROR DEL REGISTRO
          return;
        }
        
        let auxtasks  = [];
        let idx=0;
        let limite = response.data.nodos.length;
      
        for (idx = 0; idx< limite; idx++ ){
          let ndata  = response.data.nodos[idx];

          let datIni : Date = stringToDate(ndata.nodfecha);
          let datFin : Date;

          if (ndata.nodfecatendido === null) {
            datFin =  new Date(Date.now());
          } else {
            datFin = stringToDate(ndata.nodfecatendido);
          }

          
          if ( idx === 0){                    
            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: []
            }
            auxtasks.push(task);

          } else {
            let dependencia : string[] = [];

            response.data.flujo.forEach((item : any) => {
              if (item.noddst === ndata.nodid ){
                dependencia.push("N"+ item.nodori);                
              }              
            });

            let task: Task = {
              start: datIni,
              end: datFin,
              name: ndata.edodesc,
              id: 'N' + ndata.nodid,
              type:'task',
              progress: ( ndata.status === 2 ? 100 :0 ),
              isDisabled: true,
              styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
              dependencies: dependencia!
            }
            auxtasks.push(task);      
          }
        }
        setItemSelect(oprid);
        setItemInPrc({ tasks:auxtasks, nodos: response.data.nodos});
        setDisplay(true);               
        return;
      })
      .catch( (e : any) => {                
        // COMO MOSTRAR EL ERROR DEL REGISTRO
      });
                
    return false;
  }

  // rounded outlined
  const actionBodyTemplate = (rowData: TraceConciliarType) => {
    return (
      <div>
        <Button icon="pi pi-pencil" label="Editar" onClick={() => editItem(rowData)} />
      </div>
    );
  };

 return (
  <div style={{position:"relative", top:"40px", left:"1%", width:"98%"}} >

<div className="row">            
      <div className="col-md-10" > <h3 >CONCILIACIÓN ( { gestion.data.length })</h3></div>
      <div className="col-md-2" style={{ paddingTop:"25px", paddingLeft:"80px"}} > 
      <Button label="Exportar EXCEL" style={{ width:"160px"}} onClick={btnExportarOnClick}/>
      </div>
    </div>
      
    <DataTable value={gestion.data} paginator rows={25} dataKey="id"  showGridlines 
      scrollable scrollHeight="800px"      
      selectionMode="single"     
      onRowSelect={onRowSelect} 
      rowsPerPageOptions={[25, 50, 100]} >

      <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '4rem' }}></Column>
      <Column field="fecrepini" sortable header="FECHA INICIAL REPORTADA" ></Column>
      <Column field="fecrepfin" sortable header="FECHA FINAL REPORTADA" ></Column>
      <Column field="folio" sortable style={{ width: '400px' }}  header="CLAVE DE FOLIO " ></Column>
      <Column field="usuario" sortable header="NOMBRE DEL USUARIO" ></Column>
      <Column field="inst" sortable  header="INSTITUCIÓN" ></Column>

      <Column field="fecing" sortable header="FECHA DE INGRESO" ></Column>      
      <Column field="fecprev" sortable  header="PREVENCIÓN" ></Column>      
      <Column field="fecnoadm" sortable header="NO ADMITIDA" ></Column>

      <Column field="feccit1" sortable header="CITATORIO 1" ></Column>
      <Column field="feccit1max" sortable header="FECHA MAX CIT 1" ></Column>
      <Column field="feccit1dias" sortable header="DIAS LAB CIT 1" ></Column>
      <Column field="feccit1edo" sortable header="STATUS CIT 1" ></Column>


      <Column field="fecaud1" sortable header="AUDIENCIA 1" ></Column>
      <Column field="fecaud1max" sortable header="FECHA MAX AUD 1" ></Column>
      <Column field="fecaud1dias" sortable header="DIAS LAB AUD 1" ></Column>
      <Column field="fecaud1edo" sortable header="STATUS AUD 1" ></Column>


      <Column field="fecinas" sortable header="INASISTENCIA" ></Column>
      <Column field="fecjust" sortable header="JUSTIFICIÓN" ></Column>
      <Column field="feccit2" sortable header="CITATORIO 2" ></Column>
      <Column field="fecaud2" sortable header="AUDIENCIA 2" ></Column>
      <Column field="fecaud2max" sortable header="FECHA MAX AUD 2" ></Column>
      <Column field="fecaud2dias" sortable header="DIAS LAB AUD 2" ></Column>
      <Column field="fecaud2edo" sortable header="STATUS AUD 2" ></Column>


      <Column field="fecdesa" sortable header="DESAHOGO AUDIENCIA" ></Column>      
      <Column field="sentidoaud" sortable header="SENTIDO AUDIENCIA" ></Column>
      <Column field="soldt" sortable header="SOL DE DT" ></Column>

    </DataTable>
    <br/>
    <br/>
    <div className="dialog-action">
      <Dialog header={"Actividades realizadas"} visible={display} style={{ width: '70vw'  }}   
        onHide={() => onHide()}>        
          <TaskInProcess data={itemData!} setListTask={setListTask} init={EPageState.INIT} tasks={itemInPrc?.tasks} nodos={itemInPrc?.nodos} />                
      </Dialog>
    </div>


    <Dialog visible={itemDialog} style={{ width: '26rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="FECHAS A REPORTAR" 
      modal className="p-fluid" footer={gestionDialogFooter} onHide={hideDialog}>
      <div className="field">
        <label htmlFor="name" className="font-bold">Fecha inicial</label>
        <br />
        <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateBegin}
              onChange={(e) => setDateOnClick(setDateBegin, e.value)}
              showIcon            
            />
      </div>
      <br />
      <div className="field">
        <label htmlFor="name" className="font-bold">Fecha final</label>
        <br />
        <Calendar
              dateFormat="dd/mm/yy"
              style={{width:"200px"}}
              value={dateEnd}
              onChange={(e) => setDateOnClick(setDateEnd, e.value)}
              showIcon            
            />
      </div>          
    </Dialog>
  </div>
 ) 
}
